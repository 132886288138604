import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
  ISlowingAndStopping,
  ISlowingAndStoppingDto,
} from '../../interfaces/RecordOfRoadTest/slowing-and-stopping';

export const slowingAndStoppingApi = createApi({
  reducerPath: 'slowing_and_stopping',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/slowing-and-stopping`,
  }),
  tagTypes: ['SlowingAndStopping'],
  keepUnusedDataFor: 60 * 10, // 10 minutes
  endpoints: (builder) => ({
    createSlowingAndStopping: builder.mutation<ISlowingAndStopping, ISlowingAndStoppingDto>({
      query: (body) => ({
        url: '/',
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'SlowingAndStopping', id: 'SlowingAndStopping'}],
    }),
    updateSlowingAndStopping: builder.mutation<ISlowingAndStopping, ISlowingAndStopping>({
      query: (body) => ({
        url: `/${body.id}/`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'SlowingAndStopping', id: 'SlowingAndStopping'}],
    }),
    deleteSlowingAndStopping: builder.mutation<void, number>({
      query: (id) => ({
        url: `/${id}/`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'SlowingAndStopping', id: 'SlowingAndStopping'}],
    }),
    getSlowingAndStopping: builder.query<ISlowingAndStopping, number | void>({
      query: (id) => ({
        url: `/get-slowing-and-stopping/`,
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'SlowingAndStopping', id: 'SlowingAndStopping'}],
    }),
    getSlowingAndStoppingByUser: builder.query<ISlowingAndStopping, void>({
      query: () => ({
        url: `/get-slowing-and-stopping-by-user/`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'SlowingAndStopping', id: 'SlowingAndStopping'}],
    }),
    getSlowingAndStoppingByUserId: builder.query<ISlowingAndStopping, number | void>({
      query: (id) => ({
        url: `/get-slowing-and-stopping-by-user-id/`,
        method: 'POST',
        body: {
          userId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'SlowingAndStopping', id: 'SlowingAndStopping'}],
    }),
  }),
});

export const {
  useCreateSlowingAndStoppingMutation,
  useUpdateSlowingAndStoppingMutation,
  useGetSlowingAndStoppingQuery,
  useGetSlowingAndStoppingByUserQuery,
  useGetSlowingAndStoppingByUserIdQuery,
  useDeleteSlowingAndStoppingMutation,
} = slowingAndStoppingApi;
