import { Box, Button, Grid, MenuItem, Paper, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { useAuth } from '../../../app/modules/auth';
import { IMember } from '../../../models';
import {
    DatTextChoices,
    IHoursWorked,
    IHoursWorkedDto
} from '../../../store/interfaces/DriversStatementDutyHours/application-info-and-hours';
import {
    useDeleteHoursWorkedMutation,
    useUpdateHoursWorkedMutation
} from '../../../store/reducers/DriverStatementDutyHours/applicant-info-and-hours';
import { useThemeMode } from '../../partials';

const hoursWorked: Partial<IHoursWorkedDto> = {
    date: '',
    hours_worked: 0,
    day: '' as DatTextChoices
};

const hoursWorkedValidationSchema = Yup.object({
    date: Yup.string().required(),
    hours_worked: Yup.number().required(),
    day: Yup.string().required()
});

const HoursWorkedCard = ({ hoursWorkedItem, member }: { hoursWorkedItem: IHoursWorked; member?: IMember }) => {
    const { currentUser } = useAuth();
    const { mode } = useThemeMode();
    const [updateHoursWorked, { isLoading: isUpdating }] = useUpdateHoursWorkedMutation();
    const [deleteHoursWorked, { isLoading: isDeleting }] = useDeleteHoursWorkedMutation();

    const hoursWorkedFormik = useFormik({
        initialValues: hoursWorked,
        validationSchema: hoursWorkedValidationSchema,
        onSubmit: async (values, { resetForm }) => {
            if (values) {
                // @ts-ignore
                updateHoursWorked({
                    ...values,
                    hours_worked: Number(values.hours_worked)
                });
            }
            resetForm();
        },
        validateOnBlur: true,
        validateOnChange: true,
        validate: (values) => {
            const errors: Partial<IHoursWorkedDto> = {};
            if (!values.date) {
                errors.date = 'Required';
            }
            if (!values.day) {
                // @ts-ignore
                errors.day = 'Required';
            }
            if (!values.hours_worked) {
                // @ts-ignore
                errors.hours_worked = 'Required';
            }
            return errors;
        }
    });

    useEffect(() => {
        if (hoursWorkedItem) {
            hoursWorkedFormik.setValues(hoursWorkedItem);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hoursWorkedItem]);

    return (
        <Box
            sx={{
                pl: 3,
                pr: 3,
                Radius: 2,
                backgroundColor: mode === 'dark' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                width: {
                    lg: '47vw',
                    md: '55vw',
                    sm: '60vw'
                },
                marginLeft: 'auto',
                marginRight: 'auto',
                marginBottom: 3
            }}
            component={Paper}
        >
            <div
                style={{
                    padding: 10,
                    margin: 10
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Typography
                            sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                            variant="body1"
                            gutterBottom
                            component="p"
                        >
                            Date
                        </Typography>
                        <TextField
                            fullWidth
                            id="date"
                            size="small"
                            variant="outlined"
                            value={hoursWorkedFormik.values.date}
                            type="date"
                            onChange={(e) => {
                                hoursWorkedFormik.setFieldValue('date', e.target.value);
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        // Check Validation
                                        borderColor:
                                            hoursWorkedFormik.touched.date && Boolean(hoursWorkedFormik.errors.date)
                                                ? 'red'
                                                : 'green'
                                    },
                                    '&:hover fieldset': {
                                        // Check Validation
                                        borderColor:
                                            hoursWorkedFormik.touched.date && Boolean(hoursWorkedFormik.errors.date)
                                                ? 'red'
                                                : 'green'
                                    },
                                    '&.Mui-focused fieldset': {
                                        // Check Validation
                                        borderColor:
                                            hoursWorkedFormik.touched.date && Boolean(hoursWorkedFormik.errors.date)
                                                ? 'red'
                                                : 'green'
                                    }
                                }
                            }}
                            InputLabelProps={{
                                style: {
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                            disabled={member?.terminated_at || hoursWorkedItem ? true : false}
                            InputProps={{
                                style: {
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography
                            sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                            variant="body1"
                            gutterBottom
                            component="p"
                        >
                            Day
                        </Typography>
                        <TextField
                            fullWidth
                            id="day"
                            size="small"
                            variant="outlined"
                            placeholder="Day"
                            value={hoursWorkedFormik.values.day}
                            select
                            type="day"
                            onChange={(e) => {
                                hoursWorkedFormik.setFieldValue('day', e.target.value);
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        // Check Validation
                                        borderColor:
                                            hoursWorkedFormik.touched.day && Boolean(hoursWorkedFormik.errors.day)
                                                ? 'red'
                                                : 'green'
                                    },
                                    '&:hover fieldset': {
                                        // Check Validation
                                        borderColor:
                                            hoursWorkedFormik.touched.day && Boolean(hoursWorkedFormik.errors.day)
                                                ? 'red'
                                                : 'green'
                                    },
                                    '&.Mui-focused fieldset': {
                                        // Check Validation
                                        borderColor:
                                            hoursWorkedFormik.touched.day && Boolean(hoursWorkedFormik.errors.day)
                                                ? 'red'
                                                : 'green'
                                    }
                                }
                            }}
                            InputLabelProps={{
                                style: {
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                            disabled={member?.terminated_at || hoursWorkedItem ? true : false}
                            InputProps={{
                                style: {
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                        >
                            {Object.values(DatTextChoices).map((day) => (
                                <MenuItem key={day} value={day}>
                                    {day}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography
                            sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                            variant="body1"
                            gutterBottom
                            component="p"
                        >
                            Hours Worked
                        </Typography>
                        <TextField
                            fullWidth
                            id="hours_worked"
                            size="small"
                            variant="outlined"
                            placeholder="Hours Worked"
                            value={hoursWorkedFormik.values.hours_worked}
                            onChange={(e) => {
                                hoursWorkedFormik.setFieldValue('hours_worked', e.target.value);
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        // Check Validation
                                        borderColor:
                                            hoursWorkedFormik.touched.hours_worked &&
                                            Boolean(hoursWorkedFormik.errors.hours_worked)
                                                ? 'red'
                                                : 'green'
                                    },
                                    '&:hover fieldset': {
                                        // Check Validation
                                        borderColor:
                                            hoursWorkedFormik.touched.hours_worked &&
                                            Boolean(hoursWorkedFormik.errors.hours_worked)
                                                ? 'red'
                                                : 'green'
                                    },
                                    '&.Mui-focused fieldset': {
                                        // Check Validation
                                        borderColor:
                                            hoursWorkedFormik.touched.hours_worked &&
                                            Boolean(hoursWorkedFormik.errors.hours_worked)
                                                ? 'red'
                                                : 'green'
                                    }
                                }
                            }}
                            InputLabelProps={{
                                style: {
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                            disabled={member?.terminated_at || hoursWorkedItem ? true : false}
                            InputProps={{
                                style: {
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                        />
                    </Grid>
                    {!hoursWorkedItem ||
                    (hoursWorkedItem && currentUser && ['Admin', 'SuperAdmin'].includes(currentUser?.role)) ? (
                        <>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    fullWidth
                                    disabled={isUpdating || isDeleting || !!member?.terminated_at}
                                    onClick={() => hoursWorkedFormik.handleSubmit()}
                                    variant="contained"
                                    color="primary"
                                >
                                    Update
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    fullWidth
                                    disabled={isUpdating || isDeleting || !!member?.terminated_at}
                                    onClick={() => {
                                        const res = window.confirm('Are you sure you want to delete this item?');
                                        if (res) {
                                            deleteHoursWorked(hoursWorkedItem.id);
                                        }
                                    }}
                                    variant="contained"
                                    color="error"
                                >
                                    Delete
                                </Button>
                            </Grid>
                        </>
                    ) : null}
                </Grid>
            </div>
        </Box>
    );
};

export default HoursWorkedCard;
