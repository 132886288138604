import {IPreEmploymentInvestigation, IPreEmploymentInvestigationDto} from '../../models';
import client from '../Client';

export function createPreEmploymentInvestigation(
  data: IPreEmploymentInvestigationDto,
): Promise<IPreEmploymentInvestigation> {
  return client(`auth/api/user/create-member-screening/`, {
    body: data,
  });
}

export function updatePreEmploymentInvestigation(
  data: Partial<IPreEmploymentInvestigation>,
): Promise<IPreEmploymentInvestigation> {
  return client(
    `auth/api/user/update-member-screening/`,
    {
      body: data,
    },
    true,
    'PUT',
  );
}

export function paymentSuccessLink(id: number): Promise<IPreEmploymentInvestigation | undefined> {
  return client(`auth/api/user/pre-employment-payment-success?investigation_id=${id}`);
}

export function paymentFailedLink(id: number) {
  return client(`auth/api/user/pre-employment-payment-failed?investigation_id=${id}`);
}

export function getPreEmploymentScreeningByMemberId(id: number) {
  return client(`auth/api/user/get-member-screening/${id}`);
}

export function getPreEmploymentScreening() {
  return client(`auth/api/user/get-screening/`);
}

export function getPreEmploymentScreeningByStatus(status: boolean) {
  if (status) {
    return client('auth/api/user/get-screening/');
  } else {
    return client('auth/api/user/get-unscreened-investigations/');
  }
}
