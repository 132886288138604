import { useState } from 'react';
import { useQuery } from 'react-query';
import Button from '../../../../app/modules/buttons/Button';
import { StripeProduct } from '../../../../app/modules/models';
import { IMember, IMemberRequest } from '../../../../models';
import { getMemberRequestByMember } from '../../../../shared/client/memberRequest';
import ConfirmRequestModal from './ConfirmRequestModal';

interface IProps {
    member: IMember;
    memberSubscription?: StripeProduct;
    onSubmit?: () => void;
}
export default function ConfirmRequestButton({ member, onSubmit, memberSubscription }: Readonly<IProps>) {
    const [open, setOpen] = useState(false);
    const { data: requests = [] } = useQuery<IMemberRequest[]>(
        'memberRequests',
        async () => getMemberRequestByMember(Number(member?.id), 'Pending'),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            refetchInterval: 10_000
        }
    );

    const pendingRequest = requests.find(
        (request) =>
            request.status === 'Requested' &&
            request.type.name === 'Random Drug and Alcohol Test' &&
            !request.confirm_driver_notified
    );

    return (
        <>
            {pendingRequest && (
                <>
                    <ConfirmRequestModal
                        memberSubscription={memberSubscription}
                        open={open}
                        setOpen={setOpen}
                        pendingRequest={pendingRequest}
                    />
                    <Button
                        className={'btn btn-sm btn-success mx-1'}
                        disabled={!!member?.terminated_at}
                        onClick={() => setOpen(true)}
                    >
                        Confirm Random Test Request
                    </Button>
                </>
            )}
        </>
    );
}
