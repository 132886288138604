import { useEffect, useState } from 'react';
import { IMember } from '../../../models';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
    IDriverAccidentRecord,
    IDriverAccidentRecordDto,
    IDriversAccidentHistory
} from '../../../store/interfaces/PreviousEmployeeSafetyPerformanceHistory/previous-employer-information';
import { useThemeMode } from '../../partials';
import { useAuth } from '../../../app/modules/auth';
import {
    useCreateDriverAccidentRecordMutation,
    useUpdateDriverAccidentRecordMutation
} from '../../../store/reducers/EmploymentHistory/employer-information';
import { Button, Checkbox, Divider, FormControlLabel, Grid, TextField, Typography } from '@mui/material';

interface IProps {
    member?: IMember;
    id?: number;
    has_acident_record?: IDriversAccidentHistory;
    isDriverFile?: boolean;
}

const initialValues: IDriverAccidentRecordDto = {
    driver_accident_history: undefined,
    date: '',
    location: '',
    number_of_injuries: 0,
    number_of_fatalities: 0,
    details: ''
};

const validationSchema = yup.object({
    date: yup.string().required('Date is required'),
    location: yup.string().required('Location is required'),
    number_of_injuries: yup.number().required('Number of injuries is required'),
    number_of_fatalities: yup.number().required('Number of fatalities is required'),
    details: yup.string().required('Details is required')
});

const AccidentRecordItem = ({
    driver_accident_record,
    member,
    isDriverFile
}: {
    driver_accident_record: IDriverAccidentRecord;
    member?: IMember;
    isDriverFile?: boolean;
}) => {
    const { currentUser } = useAuth();
    const { mode } = useThemeMode();

    const [updateDriverAccidentRecord, { isLoading: isUpdating }] = useUpdateDriverAccidentRecordMutation();
    // const [deleteDriverAccidentRecord, {isLoading: isDeleting}] = useDeleteDriverAcci

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            updateDriverAccidentRecord({
                ...driver_accident_record,
                ...values
            });
        }
    });

    useEffect(() => {
        if (driver_accident_record) {
            formik.setValues(driver_accident_record);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [driver_accident_record]);

    return (
        <div
            style={{
                padding: 10,
                margin: 10
            }}
        >
            <Divider />
            <Grid container spacing={3}>
                <Grid item sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Date of Accident
                    </Typography>
                    <TextField
                        fullWidth
                        id="date"
                        size="small"
                        variant="outlined"
                        type="date"
                        value={formik.values.date}
                        onChange={(e) => {
                            formik.setFieldValue('date', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? 'red' : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? 'red' : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? 'red' : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={!!member?.terminated_at}
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Location
                    </Typography>
                    <TextField
                        fullWidth
                        id="date"
                        size="small"
                        variant="outlined"
                        placeholder="Location"
                        value={formik.values.location}
                        onChange={(e) => {
                            formik.setFieldValue('location', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.location && Boolean(formik.errors.location) ? 'red' : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.location && Boolean(formik.errors.location) ? 'red' : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.location && Boolean(formik.errors.location) ? 'red' : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={!!member?.terminated_at}
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Number of Injuries
                    </Typography>
                    <TextField
                        fullWidth
                        id="date"
                        size="small"
                        variant="outlined"
                        placeholder="Number of Injuries"
                        value={formik.values.number_of_injuries}
                        onChange={(e) => {
                            formik.setFieldValue('number_of_injuries', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.number_of_injuries && Boolean(formik.errors.number_of_injuries)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.number_of_injuries && Boolean(formik.errors.number_of_injuries)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.number_of_injuries && Boolean(formik.errors.number_of_injuries)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={!!member?.terminated_at}
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Number of Fatalities
                    </Typography>
                    <TextField
                        fullWidth
                        id="date"
                        size="small"
                        variant="outlined"
                        placeholder="Number of Fatalities"
                        value={formik.values.number_of_fatalities}
                        onChange={(e) => {
                            formik.setFieldValue('number_of_fatalities', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.number_of_fatalities &&
                                        Boolean(formik.errors.number_of_fatalities)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.number_of_fatalities &&
                                        Boolean(formik.errors.number_of_fatalities)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.number_of_fatalities &&
                                        Boolean(formik.errors.number_of_fatalities)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={!!member?.terminated_at}
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Details
                    </Typography>
                    <TextField
                        fullWidth
                        id="date"
                        size="small"
                        variant="outlined"
                        placeholder="Details"
                        multiline
                        rows={3}
                        value={formik.values.details}
                        onChange={(e) => {
                            formik.setFieldValue('details', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.details && Boolean(formik.errors.details) ? 'red' : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.details && Boolean(formik.errors.details) ? 'red' : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.details && Boolean(formik.errors.details) ? 'red' : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={!!member?.terminated_at}
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                {(!driver_accident_record ||
                    (driver_accident_record && currentUser && ['Admin', 'SuperAdmin'].includes(currentUser?.role))) &&
                !isDriverFile ? (
                    <>
                        <Grid item xs={12} sm={6}>
                            <Button
                                fullWidth
                                disabled={isUpdating || !!member?.terminated_at}
                                onClick={() => formik.handleSubmit()}
                                variant="contained"
                                color="primary"
                            >
                                Update
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button
                                fullWidth
                                disabled={/* isDeleting ||  */ !!member?.terminated_at}
                                onClick={() => {
                                    // if (window.confirm('Are you sure you want to delete this?')) {
                                    //     deletePreviousEmployer(data.id)
                                    // }
                                }}
                                variant="contained"
                                color="error"
                            >
                                Delete
                            </Button>
                        </Grid>
                    </>
                ) : null}
            </Grid>
        </div>
    );
};

const AccidentRecord = ({ id, member, has_acident_record, isDriverFile }: IProps) => {
    const { mode } = useThemeMode();

    const [createDriverAccidentRecord, { isLoading: isCreating }] = useCreateDriverAccidentRecordMutation();

    const [addNewItem, setAddNewItem] = useState(true);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            createDriverAccidentRecord(values).then(() => {
                formik.resetForm();
            });
        }
    });

    useEffect(() => {
        if (id) {
            formik.setFieldValue('driver_accident_history', id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (has_acident_record && has_acident_record?.driver_accident_record?.length > 0) {
            setAddNewItem(false);
        } else {
            setAddNewItem(true);
        }
    }, [has_acident_record]);

    return (
        <div
            style={{
                padding: 10,
                margin: 10
            }}
        >
            {has_acident_record?.driver_accident_record?.map((item) => (
                <AccidentRecordItem driver_accident_record={item} member={member} key={item?.id} />
            ))}
            <Grid container spacing={3}>
                {has_acident_record && has_acident_record?.driver_accident_record.length > 0 ? (
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <FormControlLabel
                            sx={{
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }}
                            control={
                                <Checkbox
                                    sx={{
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }}
                                    checked={addNewItem}
                                />
                            }
                            onChange={() => setAddNewItem(!addNewItem)}
                            label="Add Another Accident"
                        />
                    </Grid>
                ) : null}
                {addNewItem && (
                    <>
                        <Grid item sm={12}>
                            <Typography
                                sx={{
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                    textAlign: 'center'
                                }}
                                variant="h5"
                                gutterBottom
                                component="h4"
                            >
                                Add Accident Details
                            </Typography>
                        </Grid>
                        <Grid item sm={12}>
                            <Typography
                                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Date of Accident
                            </Typography>
                            <TextField
                                fullWidth
                                id="date"
                                size="small"
                                variant="outlined"
                                type="date"
                                value={formik.values.date}
                                onChange={(e) => {
                                    formik.setFieldValue('date', e.target.value);
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.date && Boolean(formik.errors.date) ? 'red' : 'green'
                                        },
                                        '&:hover fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.date && Boolean(formik.errors.date) ? 'red' : 'green'
                                        },
                                        '&.Mui-focused fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.date && Boolean(formik.errors.date) ? 'red' : 'green'
                                        }
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                                disabled={!!member?.terminated_at}
                                InputProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <Typography
                                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Location
                            </Typography>
                            <TextField
                                fullWidth
                                id="date"
                                size="small"
                                variant="outlined"
                                placeholder="Location"
                                value={formik.values.location}
                                onChange={(e) => {
                                    formik.setFieldValue('location', e.target.value);
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.location && Boolean(formik.errors.location)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&:hover fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.location && Boolean(formik.errors.location)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&.Mui-focused fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.location && Boolean(formik.errors.location)
                                                    ? 'red'
                                                    : 'green'
                                        }
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                                disabled={!!member?.terminated_at}
                                InputProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <Typography
                                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Number of Injuries
                            </Typography>
                            <TextField
                                fullWidth
                                id="date"
                                size="small"
                                variant="outlined"
                                placeholder="Number of Injuries"
                                value={formik.values.number_of_injuries}
                                onChange={(e) => {
                                    formik.setFieldValue('number_of_injuries', e.target.value);
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.number_of_injuries &&
                                                Boolean(formik.errors.number_of_injuries)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&:hover fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.number_of_injuries &&
                                                Boolean(formik.errors.number_of_injuries)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&.Mui-focused fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.number_of_injuries &&
                                                Boolean(formik.errors.number_of_injuries)
                                                    ? 'red'
                                                    : 'green'
                                        }
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                                disabled={!!member?.terminated_at}
                                InputProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <Typography
                                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Number of Fatalities
                            </Typography>
                            <TextField
                                fullWidth
                                id="date"
                                size="small"
                                variant="outlined"
                                placeholder="Number of Fatalities"
                                value={formik.values.number_of_fatalities}
                                onChange={(e) => {
                                    formik.setFieldValue('number_of_fatalities', e.target.value);
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.number_of_fatalities &&
                                                Boolean(formik.errors.number_of_fatalities)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&:hover fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.number_of_fatalities &&
                                                Boolean(formik.errors.number_of_fatalities)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&.Mui-focused fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.number_of_fatalities &&
                                                Boolean(formik.errors.number_of_fatalities)
                                                    ? 'red'
                                                    : 'green'
                                        }
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                                disabled={!!member?.terminated_at}
                                InputProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <Typography
                                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Details
                            </Typography>
                            <TextField
                                fullWidth
                                id="date"
                                size="small"
                                variant="outlined"
                                placeholder="Details"
                                multiline
                                rows={3}
                                value={formik.values.details}
                                onChange={(e) => {
                                    formik.setFieldValue('details', e.target.value);
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.details && Boolean(formik.errors.details)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&:hover fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.details && Boolean(formik.errors.details)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&.Mui-focused fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.details && Boolean(formik.errors.details)
                                                    ? 'red'
                                                    : 'green'
                                        }
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                                disabled={!!member?.terminated_at}
                                InputProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                            />
                        </Grid>
                        {!isDriverFile && (
                            <Grid item xs={12} sm={12}>
                                <Button
                                    fullWidth
                                    disabled={isCreating || !!member?.terminated_at}
                                    onClick={() => formik.handleSubmit()}
                                    variant="contained"
                                    color="primary"
                                >
                                    Save
                                </Button>
                            </Grid>
                        )}
                    </>
                )}
            </Grid>
        </div>
    );
};

export default AccidentRecord;
