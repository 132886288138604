import { Button, Grid, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { useAuth } from '../../../app/modules/auth';
import { IMember } from '../../../models';
import { IDriverReleaseFormDto } from '../../../store/interfaces/DriverReleaseForm/driver-release-form';
import {
    useCreateDriverReleaseFormMutation,
    useDeleteDriverReleaseFormMutation,
    useGetDriverReleaseFormByUserIdQuery,
    useUpdateDriverReleaseFormMutation
} from '../../../store/reducers/DriverReleaseForm/driver-release-form';
import { useThemeMode } from '../../partials';

const initialValues: IDriverReleaseFormDto = {
    applicant_name: '',
    applicant_signature: '',
    date: ''
};

const validationSchema = Yup.object({
    applicant_name: Yup.string().required('Applicant Name is required'),
    applicant_signature: Yup.string().required('Signature is required'),
    date: Yup.string().required('Date is required')
});

const DriverReleaseForm = ({ member, isDriverFile }: { member?: IMember; isDriverFile?: boolean }) => {
    const { mode } = useThemeMode();
    const { currentUser } = useAuth();
    const { data } = useGetDriverReleaseFormByUserIdQuery(member?.user?.id!, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true
    });
    const [createDriverReleaseForm, { isLoading: isCreating }] = useCreateDriverReleaseFormMutation();
    const [updateDriverReleaseForm, { isLoading: isUpdating }] = useUpdateDriverReleaseFormMutation();
    const [deleteDriverReleaseForm, { isLoading: isDeleting }] = useDeleteDriverReleaseFormMutation();

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (data) {
                updateDriverReleaseForm({
                    ...data,
                    ...values
                });
            } else {
                createDriverReleaseForm({
                    ...values,
                    member: Number(member?.id),
                    company: Number(member?.company?.id)
                });
            }
        },
        validateOnBlur: true
    });

    useEffect(() => {
        if (data) {
            formik.setValues(data);
        } else if (member) {
            const localDate = new Date();
            const year = localDate.getFullYear();
            const month = String(localDate.getMonth() + 1).padStart(2, '0');
            const day = String(localDate.getDate()).padStart(2, '0');
            const formattedDate = `${year}-${month}-${day}`;
            formik.setFieldValue('applicant_name', `${member?.user?.first_name} ${member?.user?.last_name}`);
            formik.setFieldValue('date', formattedDate);
        }
        // eslint-disable-next-line
    }, [data, member]);

    return (
        <div
            style={{
                padding: 10,
                margin: 10
            }}
        >
            <Typography sx={{ textAlign: 'center' }} variant="h5" gutterBottom component="h5">
                DISCLOSURE AND AUTHORIZATION FORM TO OBTAIN CONSUMER REPORTS FOR EMPLOYMENT PURPOSES
            </Typography>
            <Typography
                sx={{
                    textAlign: 'center',
                    fontStyle: 'italic',
                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                }}
                variant="body1"
                gutterBottom
                component="p"
            >
                Please Read Carefully Before Signing the Authorization
            </Typography>
            <Typography sx={{ textAlign: 'center' }} variant="h6" gutterBottom component="h6">
                DISCLOSURE
            </Typography>
            <Typography
                sx={{ textAlign: 'justify', color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                variant="body1"
                gutterBottom
                component="p"
            >
                {member?.company ? (
                    <span
                        style={{
                            textDecoration: 'underline'
                        }}
                    >
                        {`${member?.company?.name} `}
                    </span>
                ) : (
                    '__________________________'
                )}
                or its affiliates (“the Company”) may request one or more consumer reports or investigative consumer
                reports about you for employment purposes.
            </Typography>
            <Typography
                sx={{ textAlign: 'justify', color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                variant="body1"
                gutterBottom
                component="p"
            >
                For explanation purposes, these reports may include information on your credit worthiness, credit
                standing, credit capacity, character, general reputation, personal characteristics, or mode of living
                which may be used as a factor in making an employment-related decision about you. Such information may
                include, for example, credit information, criminal history reports, driving records, or personal
                interviews with your prior employers, neighbors, friends, or associates, or with others who may have
                knowledge concerning any such items of information.
            </Typography>
            <Typography
                sx={{ textAlign: 'justify', color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                variant="body1"
                gutterBottom
                component="p"
            >
                The Company will obtain the consumer reports and investigative consume reports from Safety Holdings,
                Inc. dba SambaSafety. SambaSafety can be contacted by mail at Attn: Compliance Department, P.O. Box
                92890, Albuquerque, NM 87199-2890; or phone: (888) 947-2622; or website:
                <a href="www.safetyholdingsinc.com" target="_blank" style={{ marginLeft: 3 }}>
                    www.safetyholdingsinc.com.
                </a>
            </Typography>
            <Typography
                sx={{ textAlign: 'center', color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                variant="h6"
                gutterBottom
                component="h6"
            >
                DISCLOSURE
            </Typography>
            <Typography
                sx={{ textAlign: 'justify', color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                variant="body1"
                gutterBottom
                component="p"
            >
                By signing below, I agree that have read and understand the foregoing Disclosure, and authorize the
                Company to obtain consumer reports or investigative consumer reports about me for employment purposes
                and, if I am hired or I have already been hired, from time to time while I am employed by Company
                without further authorization from me. I further authorize the Company to share the information in the
                consumer reports and investigative consumer reports with any person involved in the employment decision
                about me.
            </Typography>
            <Grid
                container
                spacing={3}
                p={1}
                sx={{
                    alignItems: 'center'
                }}
            >
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Applicant Name
                    </Typography>
                    <TextField
                        fullWidth
                        id="applicant_name"
                        placeholder="Applicant Name"
                        size="small"
                        variant="outlined"
                        value={formik.values.applicant_name}
                        className={
                            formik.touched.applicant_name && Boolean(formik.errors.applicant_name) ? 'green' : 'red'
                        }
                        error={formik.touched.applicant_name && Boolean(formik.errors.applicant_name)}
                        helperText={formik.touched.applicant_name && formik.errors.applicant_name}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.applicant_name && Boolean(formik.errors.applicant_name)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.applicant_name && Boolean(formik.errors.applicant_name)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.applicant_name && Boolean(formik.errors.applicant_name)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Applicant Signature
                    </Typography>
                    <TextField
                        fullWidth
                        id="applicant_signature"
                        placeholder="Applicant Signature"
                        size="small"
                        variant="outlined"
                        {...formik.getFieldProps('applicant_signature')}
                        className={
                            formik.touched.applicant_signature && Boolean(formik.errors.applicant_signature)
                                ? 'green'
                                : 'red'
                        }
                        error={formik.touched.applicant_signature && Boolean(formik.errors.applicant_signature)}
                        helperText={formik.touched.applicant_signature && formik.errors.applicant_signature}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.applicant_signature && Boolean(formik.errors.applicant_signature)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.applicant_signature && Boolean(formik.errors.applicant_signature)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.applicant_signature && Boolean(formik.errors.applicant_signature)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                fontStyle: 'italic'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Date
                    </Typography>
                    <TextField
                        fullWidth
                        id="date"
                        size="small"
                        type="date"
                        variant="outlined"
                        {...formik.getFieldProps('date')}
                        className={formik.touched.date && Boolean(formik.errors.date) ? 'green' : 'red'}
                        error={formik.touched.date && Boolean(formik.errors.date)}
                        helperText={formik.touched.date && formik.errors.date}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? 'red' : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? 'red' : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? 'red' : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                {(!data || (data && currentUser && ['Admin', 'SuperAdmin'].includes(currentUser?.role))) &&
                !isDriverFile ? (
                    <Grid item xs={12} sm={12}>
                        <Button
                            fullWidth
                            disabled={isCreating || isUpdating || !!member?.terminated_at}
                            onClick={() => formik.handleSubmit()}
                            variant="contained"
                            color="primary"
                        >
                            Save
                        </Button>
                        {currentUser?.role !== 'Admin' && currentUser?.role !== 'SuperAdmin' && (
                            <Typography
                                sx={{ color: 'red', textAlign: 'center', mt: 2 }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Once you click save, you will not be able to edit this form.
                            </Typography>
                        )}
                    </Grid>
                ) : null}
                {data && currentUser?.role === 'SuperAdmin' && (
                    <Grid item xs={12} sm={12}>
                        <Button
                            fullWidth
                            disabled={isDeleting || !!member?.terminated_at}
                            onClick={() => {
                                const res = window.confirm('Are you sure you want to delete this form?');
                                if (res && data) {
                                    deleteDriverReleaseForm(data.id);
                                }
                            }}
                            variant="contained"
                            color="primary"
                        >
                            Delete Form
                        </Button>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default DriverReleaseForm;
