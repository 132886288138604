/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { IFileObject } from '../../../../../../models';
import { useNavigation } from '../../../../../../shared';
import { toAbsoluteUrl } from '../../../../../helpers';

const ExpiringFileRow = ({ file }: { file: IFileObject }) => {
    const [dateToShow, setDateToShow] = useState<string>('');
    const { navigateToCompanyMemberProfile } = useNavigation();
    function goToProfile() {
        navigateToCompanyMemberProfile(file.user.member);
    }

    useEffect(() => {
        if (file.expires_at) {
            const utcDate = new Date(file.expires_at);
            utcDate.setDate(utcDate.getDate()); // this will also handle month and year underflows

            // Adjust for timezone offset
            const offsetMinutes = utcDate.getTimezoneOffset();
            utcDate.setMinutes(utcDate.getMinutes() + offsetMinutes);

            // Format the date to "YYYY-MM-DD"
            const adjustedYear = utcDate.getFullYear();
            const adjustedMonth = String(utcDate.getMonth() + 1).padStart(2, '0');
            const adjustedDay = String(utcDate.getDate()).padStart(2, '0');
            const formattedDate = `${adjustedMonth}-${adjustedDay}-${adjustedYear}`;
            setDateToShow(formattedDate);
        }
    }, [file.expires_at]);

    return (
        <tr>
            <td>
                <div className="d-flex align-items-center">
                    <div className="symbol symbol-45px me-5">
                        <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt="" />
                    </div>
                    <div className="d-flex justify-content-start flex-column" onClick={goToProfile}>
                        <a href="#" className="text-dark fw-bold text-hover-primary fs-6 cursor-pointer">
                            {file.user.first_name} {file.user.last_name}
                        </a>
                    </div>
                </div>
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <div className="symbol symbol-45px me-5">
                        <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt="" />
                    </div>
                    <div className="d-flex justify-content-start flex-column" onClick={goToProfile}>
                        <a href="#" className="text-dark fw-bold text-hover-primary fs-6 cursor-pointer">
                            {file.user.email}
                        </a>
                    </div>
                </div>
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">{file.title}</div>
                </div>
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">
                        {file.expires_at ? dateToShow : 'N/A'}
                    </div>
                </div>
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">{file.category}</div>
                </div>
            </td>
        </tr>
    );
};

export default ExpiringFileRow;
