import { Grid, InputLabel, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { useFormik } from 'formik';
import { forwardRef, ReactElement, Ref, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { IMember } from '../../../../models';
import { IManagerNotes, IManagerNotesDto } from '../../../../models/IMaanagerNotes';
import { downloadFile } from '../../../../shared/client/manager-notes';

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement<any, any>;
    },
    ref: Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    member?: IMember;
    managerNote?: IManagerNotes;
    setManagerNote?: (managerNote?: IManagerNotes) => void;
}

const initialValues: IManagerNotesDto = {
    member: 0,
    company: 0,
    date: new Date(),
    time: new Date(),
    note_subject: '',
    note: ''
};

const validationSchema = Yup.object({
    note_subject: Yup.string().required('Required'),
    note: Yup.string().required('Required'),
    date: Yup.string().required('Required'),
    time: Yup.string().required('Required')
});

export default function ViewManagerNoteModal({ open, setOpen, managerNote, setManagerNote }: Readonly<IProps>) {
    const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
    const handleClose = () => {
        setManagerNote?.(undefined);
        formik.resetForm();
        setOpen(false);
        setImageUrl(undefined);
    };

    const formik = useFormik({
        initialValues: managerNote ?? initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {}
    });

    function handleDownloadFile(id: number) {
        downloadFile(id)
            .then((res) => {
                if (!res.url) return;
                if (managerNote?.file_type?.includes('image')) {
                    setImageUrl(res.url);
                } else {
                    setImageUrl(undefined);
                    window.open(res.url, '_blank');
                }
            })
            .catch((err) => {
                console.error(err);
                setImageUrl(undefined);
            });
    }

    useEffect(() => {
        if (managerNote && open) {
            formik.setValues(managerNote);
            if (managerNote.file_name && managerNote.file_type) {
                handleDownloadFile(managerNote.id);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, managerNote]);

    return (
        <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle style={{ textAlign: 'center' }}>View Note</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <InputLabel>Date</InputLabel>
                        <TextField
                            fullWidth
                            size="small"
                            type="date"
                            variant="outlined"
                            name="date"
                            value={formik.values.date}
                            onChange={formik.handleChange}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel>Time</InputLabel>
                        <TextField
                            fullWidth
                            disabled
                            size="small"
                            type="time"
                            variant="outlined"
                            name="time"
                            value={formik.values.time}
                            onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Subject of Note"
                            variant="outlined"
                            name="note_subject"
                            value={formik.values.note_subject}
                            onChange={formik.handleChange}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TextField
                            disabled
                            multiline
                            rows={4}
                            fullWidth
                            size="small"
                            label="Note"
                            variant="outlined"
                            name="note"
                            value={formik.values.note}
                            onChange={formik.handleChange}
                        />
                    </Grid>
                    {imageUrl && (
                        <Grid item xs={12} md={12}>
                            <img
                                src={imageUrl}
                                alt="Note"
                                style={{ width: '100%', height: 'auto', borderRadius: '5px', marginTop: '10px' }}
                            />
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
