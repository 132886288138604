import { MoreVertRounded } from '@mui/icons-material';
import { IconButton, Menu, MenuItem, TableBody, TableCell, TableRow, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import TableContainer from '../../../compliancemasters/partials/widgets/tables/components/TableContainer';
import TableContainerHeader from '../../../compliancemasters/partials/widgets/tables/components/TableContainerHeader';
import TableContainerHeaderTitle from '../../../compliancemasters/partials/widgets/tables/components/TableContainerHeaderTitle';
import TableHeader from '../../../compliancemasters/partials/widgets/tables/components/TableHeader';
import TableHeaderColumn from '../../../compliancemasters/partials/widgets/tables/components/TableHeaderColumn';
import TableWrapper from '../../../compliancemasters/partials/widgets/tables/components/TableWrapper';
import useCompanyInvitations from '../../../context/CompanyInvitation/CompanyInvitationContextProvider';
import { getSubscriptionPlans } from '../../../shared';
import { StripeProduct } from '../../modules/models';
import BulkUploadModal from './BulkUploadModal';

interface IMenuComponentProps {
    anchorEl: null | HTMLElement;
    open: boolean;
    handleClose: () => void;
    setUploadOpen: (value: boolean) => void;
    clearDriverList: () => void;
    handleCreateDrivers: () => void;
    disabled: boolean;
}

export interface IDriverUploadData {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    driversLicenseNumber: string;
    socialSecurityNumber: string;
    dateOfBirth: Date;
    productId: string;
}

const MenuComponent = ({
    anchorEl,
    clearDriverList,
    handleClose,
    open,
    setUploadOpen,
    disabled,
    handleCreateDrivers
}: IMenuComponentProps) => {
    return (
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            slotProps={{
                paper: {
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0
                        }
                    }
                }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItem
                disabled={disabled}
                onClick={() => {
                    handleCreateDrivers();
                    handleClose();
                }}
            >
                Create Drivers
            </MenuItem>
            <MenuItem
                onClick={() => {
                    setUploadOpen(true);
                    handleClose();
                }}
            >
                Select Driver List
            </MenuItem>
            <MenuItem
                onClick={() => {
                    clearDriverList();
                    handleClose();
                }}
            >
                Clear Driver List
            </MenuItem>
        </Menu>
    );
};

const DriverBulkUpload = () => {
    const [uploadOpen, setUploadOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [driverList, setDriverList] = useState<IDriverUploadData[]>([]);
    const { createBulkDrivers } = useCompanyInvitations();
    const { data } = useQuery<{ data: StripeProduct[] }>('companyPackages', async () => getSubscriptionPlans());
    const open = Boolean(anchorEl);
    let { id } = useParams();
    const clearDriverList = () => {
        setDriverList([]);
        // window.location.reload()
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCreateDrivers = () => {
        createBulkDrivers(driverList, Number(id))
            .then(() => {
                toast.success('Drivers created successfully');
            })
            .finally(() => {
                // setTimeout(() => {
                //     clearDriverList()
                // }, 3000)
            });
    };

    return (
        <>
            <div className="row gy-5 g-xl-8">
                <div className="col-xl-12">
                    <TableWrapper>
                        <TableContainerHeader>
                            <TableContainerHeaderTitle title="Driver Bulk Upload" />
                            <Tooltip title="More Options">
                                <IconButton onClick={handleClick}>
                                    <MoreVertRounded />
                                </IconButton>
                            </Tooltip>
                        </TableContainerHeader>
                        <TableContainer>
                            <TableHeader>
                                <TableHeaderColumn>First Name</TableHeaderColumn>
                                <TableHeaderColumn>Last Name</TableHeaderColumn>
                                <TableHeaderColumn>Email Address</TableHeaderColumn>
                                <TableHeaderColumn>Phone No.</TableHeaderColumn>
                                <TableHeaderColumn>Drivers License No.</TableHeaderColumn>
                                <TableHeaderColumn>Social Security No.</TableHeaderColumn>
                                <TableHeaderColumn>Date of Birth</TableHeaderColumn>
                                <TableHeaderColumn>Package Selected</TableHeaderColumn>
                            </TableHeader>
                            <TableBody>
                                {driverList.map((driver) => (
                                    <TableRow key={driver.id} hover sx={{ cursor: 'pointer' }}>
                                        <TableCell>{driver.firstName}</TableCell>
                                        <TableCell>{driver.lastName}</TableCell>
                                        <TableCell>{driver.email}</TableCell>
                                        <TableCell>{driver.phoneNumber}</TableCell>
                                        <TableCell>{driver.driversLicenseNumber}</TableCell>
                                        <TableCell>{driver.socialSecurityNumber}</TableCell>
                                        <TableCell>{driver.dateOfBirth.toISOString().split('T')[0]}</TableCell>
                                        <TableCell>
                                            {
                                                data?.data.find((p: StripeProduct) => p.product_id === driver.productId)
                                                    ?.name
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </TableContainer>
                    </TableWrapper>
                </div>
            </div>
            <MenuComponent
                disabled={driverList.length === 0}
                anchorEl={anchorEl}
                clearDriverList={clearDriverList}
                handleClose={handleClose}
                open={open}
                setUploadOpen={setUploadOpen}
                handleCreateDrivers={handleCreateDrivers}
            />
            <BulkUploadModal
                clearDriverList={clearDriverList}
                open={uploadOpen}
                setOpen={setUploadOpen}
                setUploadList={setDriverList}
                invitationId={Number(id)}
            />
        </>
    );
};

export default DriverBulkUpload;
