import { forwardRef, FunctionComponent, ReactElement, Ref, useEffect, useState } from 'react';
import { IMaintenanceVehicle } from '../../../../store/interfaces/PreventableMaintenance/maintenance.vehicles';
import { TransitionProps } from '@mui/material/transitions';
import { Button, Dialog, DialogContent, DialogTitle, Grid, Slide, TextField } from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { INotesAndLinks, NotesAndLinksType } from '../../../../store/interfaces/PreventableMaintenance/notes.and.links';
import {
    useCreateNotesAndLinksMutation,
    useUpdateNotesAndLinksMutation
} from '../../../../store/reducers/PreventableMaintenance/notes.links';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import axios from 'axios';
import { completeVehicleNoteUpload } from '../../../../shared/client/manager-notes';
import FileInput from '../../../../compliancemasters/partials/modals/new-file/components/FileInput';

interface IProps {
    open: boolean;
    setOpen: (val: boolean) => void;
    vehicle?: IMaintenanceVehicle;
    note?: INotesAndLinks;
    setNote?: (val?: INotesAndLinks) => void;
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement<any, any>;
    },
    ref: Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const initialValues: Partial<INotesAndLinks> = {
    maintenance_vehicle: undefined,
    note: '',
    note_subject: '',
    type: NotesAndLinksType.NOTE
};

export const AddNoteModal: FunctionComponent<IProps> = ({ open, setOpen, vehicle, note, setNote }) => {
    const [file, setFile] = useState<File>();
    const [loading, setLoading] = useState(false);
    const [createVehicleNote, { isLoading }] = useCreateNotesAndLinksMutation();
    const [updateVehicleNote, { isLoading: updateLoading }] = useUpdateNotesAndLinksMutation();
    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({
            note: Yup.string().required('Note is required'),
            note_subject: Yup.string().required('Note Subject is required')
        }),
        onSubmit: (values) => {
            if (note) {
                updateVehicleNote({
                    ...note,
                    ...values
                }).then(() => {
                    setOpen(false);
                    formik.resetForm();
                    setNote?.(undefined);
                });
            } else {
                console.log(file);
                createVehicleNote({
                    ...values,
                    maintenance_vehicle: vehicle,
                    file_name: file?.name ?? '',
                    file_type: file?.type ?? ''
                }).then(
                    (res: {
                        data?: { url: string; id: string } | INotesAndLinks;
                        error?: FetchBaseQueryError | SerializedError;
                    }) => {
                        if (res.error) {
                            setOpen(false);
                            formik.resetForm();
                        }
                        if (res.data && 'id' in res.data && 'url' in res.data) {
                            setLoading(true);
                            const data = res.data as { url: string; id: string };

                            const options = {
                                headers: {
                                    'Content-Type': file?.type ?? ''
                                }
                            };

                            const instance = axios.create();

                            instance.put(data.url, file, options).then(() => {
                                completeVehicleNoteUpload(Number(data.id)).then(() => {
                                    updateVehicleNote({ id: Number(data.id) });
                                    setOpen(false);
                                    formik.resetForm();
                                    setFile(undefined);
                                    setLoading(false);
                                });
                            });
                        }
                    }
                );
            }
        }
    });

    useEffect(() => {
        if (note && open) {
            formik.setValues({
                note: note.note,
                note_subject: note.note_subject
            });
        }
        // eslint-disable-next-line
    }, [note, open]);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
                setOpen(false);
                formik.resetForm();
                setNote?.(undefined);
                setFile(undefined);
            }}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle
                sx={{
                    textAlign: 'center',
                    backgroundColor: '#F5F5F5',
                    color: '#000000',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    padding: '20px 20px 20px 20px'
                }}
            >
                {note ? 'Update Note' : 'Add Note'}
            </DialogTitle>
            <DialogContent>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2} p={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="note_subject"
                                name="note_subject"
                                label="Note Subject"
                                size="small"
                                value={formik.values.note_subject}
                                onChange={formik.handleChange}
                                error={formik.touched.note_subject && Boolean(formik.errors.note_subject)}
                                helperText={formik.touched.note_subject && formik.errors.note_subject}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="note"
                                name="note"
                                label="Note"
                                size="small"
                                multiline
                                rows={4}
                                value={formik.values.note}
                                onChange={formik.handleChange}
                                error={formik.touched.note && Boolean(formik.errors.note)}
                                helperText={formik.touched.note && formik.errors.note}
                            />
                        </Grid>
                        <Grid item sm={12} lg={12}>
                            <FileInput
                                onChange={(e) => {
                                    setFile(e.target.files?.[0]);
                                }}
                                onClear={() => setFile(undefined)}
                                value={file}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                disabled={isLoading || loading || updateLoading}
                                type="submit"
                                variant="contained"
                                size="small"
                                fullWidth
                            >
                                {note ? 'Update Note' : 'Add Note'}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </Dialog>
    );
};
