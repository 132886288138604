import { CircularProgress, Container, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import WrittenPolicy from './WrittenPolicy';
import MemberTable from './MemberTable';
import { PageTitle } from '../../../compliancemasters/layout/core';
import { toast } from 'react-toastify';
import { getCompanySubscription } from '../../../shared';
import { useIntl } from 'react-intl';
import { StripeProduct } from '../../modules/models';
import { useCompany } from '../../../context/CompanyContext';

const DrugAlcoholWrittenPolicy = () => {
    const { currentCompany } = useCompany();
    const [companySub, setCompanySub] = useState<StripeProduct[]>([]);
    const [loading, setLoading] = useState(true);
    const intl = useIntl();

    const getCompanySub = async () => {
        setLoading(true);
        const sub = await getCompanySubscription();
        if (sub) {
            const uniqueProducts = sub.filter(
                (function () {
                    const seenIds = new Set<string>();
                    return (product: StripeProduct) => {
                        if (!seenIds.has(product.name)) {
                            seenIds.add(product.name);
                            return true;
                        }
                        return false;
                    };
                })()
            );
            setCompanySub(uniqueProducts);
            setLoading(false);
        } else {
            setLoading(false);
        }
    };

    useEffect(() => {
        getCompanySub();
    }, []);

    useEffect(() => {
        if (!loading && companySub.length !== currentCompany?.package?.length) {
            // Redirect to Subscription and Payment Page
            toast.error('Please subscribe to all your selected packages to continue');
            setTimeout(() => {
                window.location.assign('/subscription/plan');
            }, 4000);
        }
    }, [loading, companySub, currentCompany]);
    return (
        <Container maxWidth="md">
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
            {loading ? (
                <div className="row gy-5 g-xl-8">
                    <div className="col-xl-12">
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                height: '100vh'
                            }}
                        >
                            <CircularProgress />
                        </div>
                    </div>
                </div>
            ) : companySub.length === currentCompany?.package.length && !loading ? (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <WrittenPolicy />
                    </Grid>
                    <Grid item xs={12}>
                        <MemberTable />
                    </Grid>
                </Grid>
            ) : (
                <div className="row gy-5 g-xl-8">
                    <div className="col-xl-12">
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                height: '100vh'
                            }}
                        >
                            <div className="alert alert-danger text-center" role="alert">
                                You have not subscribed to all your selected packages. Please subscribe to all your
                                selected packages to continue.
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Container>
    );
};

export default DrugAlcoholWrittenPolicy;
