import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {IServiceOrders} from '../../interfaces/PreventableMaintenance/service.orders';

export const serviceOrdersApi = createApi({
  reducerPath: 'pm_service_orders',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/auth/api/preventable-maintenance/service-orders`,
  }),
  tagTypes: ['ServiceOrders'],
  keepUnusedDataFor: 60 * 10, // 10 minutes
  endpoints: (builder) => ({
    createServiceOrder: builder.mutation<{url: string; id: string}, Partial<IServiceOrders>>({
      query: (body) => ({
        url: '/upload/start/',
        method: 'POST',
        body: {
          ...body,
          maintenance_vehicle: body?.maintenance_vehicle?.id,
          service_alert: body?.service_alert?.id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'ServiceOrders', id: 'ServiceOrders'}],
    }),
    updateServiceOrder: builder.mutation<IServiceOrders, Partial<IServiceOrders>>({
      query: (body) => ({
        url: `/upload/finish/`,
        method: 'POST',
        body: {
          ...body,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'ServiceOrders', id: 'ServiceOrders'}],
    }),
    deleteServiceOrder: builder.mutation<void, number>({
      query: (id) => ({
        url: `/${id}/`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'ServiceOrders', id: 'ServiceOrders'}],
    }),
    getServiceOrder: builder.query<IServiceOrders, number | void>({
      query: (id) => ({
        url: `/${id}/`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'ServiceOrders', id: 'ServiceOrders'}],
    }),
    getServiceOrderByVehicle: builder.query<IServiceOrders[], number | void>({
      query: (vehicleId) => ({
        url: `/get-by-vehicle?vehicle_id=${vehicleId}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'ServiceOrders', id: 'ServiceOrders'}],
    }),
  }),
});

export const {
  useCreateServiceOrderMutation,
  useUpdateServiceOrderMutation,
  useDeleteServiceOrderMutation,
  useGetServiceOrderQuery,
  useGetServiceOrderByVehicleQuery,
} = serviceOrdersApi;
