import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IMember } from '../../../models';
import { getCompanyMember } from '../../../shared';
import { Box, Button, Container, Paper } from '@mui/material';
import FormHeaderCard from '../HeaderCard';
import ActionButtons from '../ActionButtons';
import {
    useGetDriverReleaseFormProgressQuery,
    useGetMemberDriverReleaseFormProgressQuery
} from '../../../store/reducers/DriverReleaseForm/driver-release-form';
import DriverReleaseForm from './DriverReleaseForm';
import { useThemeMode } from '../../partials';
import { useAuth } from '../../../app/modules/auth';

const DriversReleaseForm = () => {
    const { id } = useParams();
    const { mode } = useThemeMode();
    const { currentUser } = useAuth();
    const [member, setMember] = useState<IMember>();
    const [value, setValue] = useState(0);
    const { data: progress } = useGetDriverReleaseFormProgressQuery(Number(member?.id)!, {
        skip: !member?.id,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    });
    const { data: memberProgress } = useGetMemberDriverReleaseFormProgressQuery(Number(member?.id)!, {
        skip: !member?.id,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    });

    useEffect(() => {
        if (id) {
            getCompanyMember(id).then((data) => {
                setMember(data);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Container style={{ userSelect: 'none' }}>
            <Box
                sx={{
                    pl: 3,
                    pr: 3,
                    pt: 3,
                    m: 3,
                    borderRadius: 2,
                    backgroundColor: mode === 'dark' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    width: {
                        lg: '47vw',
                        md: '70vw',
                        sm: '80vw'
                    },
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }}
                component={Paper}
            >
                <FormHeaderCard
                    headerTitle="Drivers Release Form for Driving Records"
                    status={
                        progress && progress?.progress > 0 && progress?.progress < 100
                            ? 'In Progress'
                            : progress && progress.progress > 99
                            ? 'Completed'
                            : 'Not Started'
                    }
                    sectionCount={1}
                    itemCount={3}
                    totalProgress={progress ? progress.progress : 0}
                    memberProgress={memberProgress ? memberProgress.progress : 0}
                    nextButton={{ title: '', path: '' }}
                    member={member}
                    setValue={setValue}
                    value={value}
                />
            </Box>
            <Box
                sx={{
                    pl: 3,
                    pr: 3,
                    pt: 3,
                    m: 3,
                    borderRadius: 2,
                    backgroundColor: mode === 'dark' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    width: {
                        lg: '47vw',
                        md: '70vw',
                        sm: '80vw'
                    },
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }}
                component={Paper}
            >
                <DriverReleaseForm member={member} />
            </Box>
            <ActionButtons
                member={member}
                nextButton={{
                    title: 'Drivers Release Form for Background Checks',
                    path: 'driver-release-form-background-checks'
                }}
            />
            <div
                style={{
                    display: 'flex',
                    width: '47vw',
                    justifyContent: 'center',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: 10
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        if (currentUser?.role === 'Member') {
                            window.location.assign(`/dashboard/overview`);
                        } else {
                            if (member?.id) {
                                window.location.assign(`/member/${member?.id}/profile/driver-documents`);
                            }
                        }
                    }}
                    fullWidth
                >
                    Back to Forms Overview
                </Button>
            </div>
        </Container>
    );
};

export default DriversReleaseForm;
