/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { IPreEmploymentInvestigation } from '../../../../../models';
import { useNavigation } from '../../../../../shared';
import { updatePreEmploymentInvestigation } from '../../../../../shared/client/pre-employment';
import { toAbsoluteUrl } from '../../../../helpers';

interface IProps {
    investigation: IPreEmploymentInvestigation;
}

const PreEmploymentRow = ({ investigation }: IProps) => {
    const { navigateToCompanyMemberProfile } = useNavigation();
    const [dateStarted, setDateStarted] = useState<string>('');
    const [dateCompleted, setDateCompleted] = useState<string>('');

    const queryClient = useQueryClient();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const preEmploymentMutation = useMutation(updatePreEmploymentInvestigation, {
        onSuccess: (data) => {
            queryClient.invalidateQueries('pre-employment-investigations');
        }
    });

    function goToProfile() {
        navigateToCompanyMemberProfile(investigation.member.id);
    }

    useEffect(() => {
        const utcDate = new Date(investigation.date_started!);
        const offsetMinutes = utcDate.getTimezoneOffset();
        utcDate.setMinutes(utcDate.getMinutes() + offsetMinutes);
        const year = utcDate.getFullYear();
        const month = utcDate.getMonth();
        const day = utcDate.getDate();
        const formattedStartDate = `${month}-${day}-${year}`;

        if (investigation.date_completed) {
            const utcDateCompleted = new Date(investigation.date_completed!);
            const offsetMinutesCompleted = utcDateCompleted.getTimezoneOffset();
            utcDateCompleted.setMinutes(utcDateCompleted.getMinutes() + offsetMinutesCompleted);
            const adjustedYear = utcDateCompleted.getFullYear();
            const adjustedMonth = utcDateCompleted.getMonth();
            const adjustedDay = utcDateCompleted.getDate();

            // @ts-ignore
            const formattedDateTime = `${adjustedMonth}-${adjustedDay}-${adjustedYear}`;
            setDateCompleted(formattedDateTime);
        } else {
            setDateCompleted('Pending');
        }
        setDateStarted(formattedStartDate);
    }, [investigation]);

    return (
        <tr>
            <td>
                <div className="d-flex align-items-center">
                    <div className="symbol symbol-45px me-5">
                        <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt="" />
                    </div>
                    <div className="d-flex justify-content-start flex-column" onClick={goToProfile}>
                        <a href="#" className="text-dark fw-bold text-hover-primary fs-6">
                            {investigation.member.user.first_name} {investigation.member.user.last_name}
                        </a>
                    </div>
                </div>
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">{investigation.member.company.name}</div>
                </div>
            </td>

            <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">
                        {investigation.screened ? 'Screened' : 'Pending'}
                    </div>
                </div>
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">{investigation.investigation_type}</div>
                </div>
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">{dateStarted}</div>
                </div>
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">{dateCompleted}</div>
                </div>
            </td>
            {/* <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">
                        <Button
                            className="btn btn-sm btn-light btn-active-light-primary"
                            onClick={() => {
                                preEmploymentMutation.mutate({
                                    id: investigation.id,
                                    screened: !investigation.screened,
                                    // @ts-ignore
                                    member: investigation.member.id,
                                })
                            }}
                        >
                            Set as Screened
                        </Button>
                    </div>
                </div>
            </td> */}
        </tr>
    );
};

export default PreEmploymentRow;
