import DemosTable from '../../../compliancemasters/partials/widgets/tables/MemberTable/DemosTable';

const RequestedDemos = () => {
    return (
        <div className="row gy-5 g-xl-8">
            <div className="col-xl-12">
                <DemosTable />
            </div>
        </div>
    );
};

export default RequestedDemos;
