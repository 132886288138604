import { Button, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { useAuth } from '../../../app/modules/auth';
import { IMember } from '../../../models';
import {
    ITerminationOfEmploymentDto,
    ReasonForLeaving
} from '../../../store/interfaces/DriversApplicationForEmployment/termination.of.employment';
import {
    useCreateTerminationOfEmploymentMutation,
    useDeleteTerminationOfEmploymentMutation,
    useGetTerminationOfEmploymentByUserIdQuery,
    useUpdateTerminationOfEmploymentMutation
} from '../../../store/reducers/DriversApplicationForEmployment/termination-of-employment';
import { useThemeMode } from '../../partials';

const initialValues: Partial<ITerminationOfEmploymentDto> = {
    date_terminated: '',
    department_released_from: '',
    reason_for_leaving: ReasonForLeaving.OTHER,
    supervisor: ''
};

const validationSchema = Yup.object({
    date_terminated: Yup.string().required('Required'),
    department_released_from: Yup.string().required('Required').min(2, 'Department released from is required!'),
    reason_for_leaving: Yup.string().required('Required').min(2, 'Reason for leaving is required!'),
    supervisor: Yup.string().required('Required').min(2, 'Supervisor is required!')
});

const TerminationOfEmployment = ({ member, isDriverFile }: { member?: IMember; isDriverFile?: boolean }) => {
    const { mode } = useThemeMode();
    const { currentUser } = useAuth();
    const { data: termination } = useGetTerminationOfEmploymentByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    });

    const [createTerminationOfEmployment, { isLoading: isCreating }] = useCreateTerminationOfEmploymentMutation();
    const [updateTerminationOfEmployment, { isLoading: isUpdating }] = useUpdateTerminationOfEmploymentMutation();
    const [deleteTerminationOfEmployment] = useDeleteTerminationOfEmploymentMutation();

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            if (termination) {
                // @ts-ignore
                updateTerminationOfEmployment({
                    // @ts-ignore
                    ...values,
                    // @ts-ignore
                    id: termination.id
                });
            } else {
                createTerminationOfEmployment({
                    ...values,
                    // @ts-ignore
                    member: member?.id,
                    // @ts-ignore
                    company: member?.company?.id
                });
            }
        },
        validateOnBlur: true,
        validate: (values) => {
            const errors: Partial<ITerminationOfEmploymentDto> = {};
            if (!values.date_terminated) {
                errors.date_terminated = 'Required';
            }
            if (!values.department_released_from) {
                errors.department_released_from = 'Required';
            }
            if (!values.supervisor) {
                errors.supervisor = 'Required';
            }
            return errors;
        }
    });

    useEffect(() => {
        if (termination) {
            formik.setValues(termination);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [termination]);

    return (
        <div
            style={{
                padding: 10,
                margin: 10
            }}
        >
            <Typography
                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: 'center' }}
                variant="h4"
                gutterBottom
                component="h4"
            >
                Termination of Employment
            </Typography>
            <Typography sx={{ color: 'red', textAlign: 'center' }} variant="h6" gutterBottom component="h6">
                This section is only completed after the driver is terminated
            </Typography>
            <Grid
                container
                spacing={3}
                p={1}
                sx={{
                    alignItems: 'center'
                }}
            >
                <Grid item xs={12} sm={6}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Date Terminated
                    </Typography>
                    <TextField
                        fullWidth
                        id="date_terminated"
                        size="small"
                        variant="outlined"
                        type="date"
                        {...formik.getFieldProps('date_terminated')}
                        className={
                            formik.touched.date_terminated && Boolean(formik.errors.date_terminated) ? 'green' : 'red'
                        }
                        error={formik.touched.date_terminated && Boolean(formik.errors.date_terminated)}
                        helperText={formik.touched.date_terminated && formik.errors.date_terminated}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.date_terminated && Boolean(formik.errors.date_terminated)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.date_terminated && Boolean(formik.errors.date_terminated)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.date_terminated && Boolean(formik.errors.date_terminated)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Department Released From
                    </Typography>
                    <TextField
                        fullWidth
                        id="department_released_from"
                        label="Department Released From"
                        placeholder="Department Released From"
                        size="small"
                        variant="outlined"
                        {...formik.getFieldProps('department_released_from')}
                        className={
                            formik.touched.department_released_from && Boolean(formik.errors.department_released_from)
                                ? 'green'
                                : 'red'
                        }
                        error={
                            formik.touched.department_released_from && Boolean(formik.errors.department_released_from)
                        }
                        helperText={formik.touched.department_released_from && formik.errors.department_released_from}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.department_released_from &&
                                        Boolean(formik.errors.department_released_from)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.department_released_from &&
                                        Boolean(formik.errors.department_released_from)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.department_released_from &&
                                        Boolean(formik.errors.department_released_from)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Reason For Leaving
                    </Typography>
                    <TextField
                        fullWidth
                        id="reason_for_leaving"
                        select
                        size="small"
                        variant="outlined"
                        {...formik.getFieldProps('reason_for_leaving')}
                        className={
                            formik.touched.reason_for_leaving && Boolean(formik.errors.reason_for_leaving)
                                ? 'green'
                                : 'red'
                        }
                        error={formik.touched.reason_for_leaving && Boolean(formik.errors.reason_for_leaving)}
                        helperText={formik.touched.reason_for_leaving && formik.errors.reason_for_leaving}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.reason_for_leaving && Boolean(formik.errors.reason_for_leaving)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.reason_for_leaving && Boolean(formik.errors.reason_for_leaving)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.reason_for_leaving && Boolean(formik.errors.reason_for_leaving)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(ReasonForLeaving).map((reason) => (
                            <MenuItem key={reason} value={reason}>
                                {reason}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Supervisor
                    </Typography>
                    <TextField
                        fullWidth
                        id="supervisor"
                        placeholder="Supervisor"
                        size="small"
                        variant="outlined"
                        {...formik.getFieldProps('supervisor')}
                        className={formik.touched.supervisor && Boolean(formik.errors.supervisor) ? 'green' : 'red'}
                        error={formik.touched.supervisor && Boolean(formik.errors.supervisor)}
                        helperText={formik.touched.supervisor && formik.errors.supervisor}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.supervisor && Boolean(formik.errors.supervisor) ? 'red' : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.supervisor && Boolean(formik.errors.supervisor) ? 'red' : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.supervisor && Boolean(formik.errors.supervisor) ? 'red' : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                {(!termination ||
                    (termination && currentUser && ['Admin', 'SuperAdmin'].includes(currentUser?.role))) &&
                !isDriverFile ? (
                    <Grid item xs={12} sm={12}>
                        <Button
                            fullWidth
                            disabled={isCreating || isUpdating}
                            onClick={() => formik.handleSubmit()}
                            variant="contained"
                            color="primary"
                        >
                            Save
                        </Button>
                        {currentUser?.role !== 'Admin' && currentUser?.role !== 'SuperAdmin' && (
                            <Typography
                                sx={{ color: 'red', textAlign: 'center', mt: 2 }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Once you click save, you will not be able to edit this form.
                            </Typography>
                        )}
                    </Grid>
                ) : null}
                {termination && currentUser?.role === 'SuperAdmin' && (
                    <Grid item xs={12} sm={12}>
                        <Button
                            fullWidth
                            disabled={!!member?.terminated_at}
                            onClick={() => {
                                const res = window.confirm('Are you sure you want to delete this form?');
                                if (res && termination) {
                                    deleteTerminationOfEmployment(termination.id);
                                }
                            }}
                            variant="contained"
                            color="primary"
                        >
                            Delete Form
                        </Button>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default TerminationOfEmployment;
