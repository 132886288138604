import { useMutation, useQuery, useQueryClient } from 'react-query';
import { z } from 'zod';
import Button from '../../../../../app/modules/buttons/Button';
import { completeDemoRequest, getRequestedDemos } from '../../../../../shared/client/memberRequest';
import TableContainer from '../components/TableContainer';
import TableContainerHeader from '../components/TableContainerHeader';
import TableContainerHeaderTitle from '../components/TableContainerHeaderTitle';
import TableHeader from '../components/TableHeader';
import TableHeaderColumn from '../components/TableHeaderColumn';
import TableWrapper from '../components/TableWrapper';

// eslint-disable-next-line
const RequestDemoSchema = z.object({
    id: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    companyName: z.string(),
    state: z.string(),
    email: z.string().email(),
    phoneNumber: z.string(),
    demoDate: z.string(),
    demoTime: z.string(),
    specificRequirements: z.string(),
    meetingPlatform: z.string().optional(),
    demoCompleted: z.string().optional()
});

type RequestDemoType = z.infer<typeof RequestDemoSchema>;

const DemosTable = () => {
    const { data } = useQuery<RequestDemoType[]>('demo-requests', async () => getRequestedDemos());
    const queryClient = useQueryClient();

    const completeDemoMutation = useMutation(completeDemoRequest, {
        onSuccess: (data) => {
            queryClient.invalidateQueries('demo-requests');
        }
    });

    return (
        <>
            <TableWrapper>
                <TableContainerHeader>
                    <TableContainerHeaderTitle title="Requested Demo's" />
                </TableContainerHeader>
                <TableContainer>
                    <TableHeader>
                        <TableHeaderColumn>First Name</TableHeaderColumn>
                        <TableHeaderColumn>Last Name</TableHeaderColumn>
                        <TableHeaderColumn>Company</TableHeaderColumn>
                        <TableHeaderColumn>State</TableHeaderColumn>
                        <TableHeaderColumn>Email</TableHeaderColumn>
                        <TableHeaderColumn>Phone Numebr</TableHeaderColumn>
                        <TableHeaderColumn>Date</TableHeaderColumn>
                        <TableHeaderColumn>Time</TableHeaderColumn>
                        {/* <TableHeaderColumn>{''}</TableHeaderColumn> */}
                        <TableHeaderColumn>{''}</TableHeaderColumn>
                    </TableHeader>
                    <tbody>
                        {data
                            ?.filter((demo) => !demo.demoCompleted)
                            ?.map((item) => (
                                <tr key={item.id}>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex justify-content-start flex-column">
                                                {item.firstName}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex justify-content-start flex-column">
                                                {item.lastName}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex justify-content-start flex-column">
                                                {item.companyName}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex justify-content-start flex-column">{item.state}</div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex justify-content-start flex-column">{item.email}</div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex justify-content-start flex-column">
                                                {item.phoneNumber}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex justify-content-start flex-column">
                                                {item.demoDate}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex justify-content-start flex-column">
                                                {item.demoTime}
                                            </div>
                                        </div>
                                    </td>
                                    {/* <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex justify-content-start flex-column">
                                                <Button>Update Demo</Button>
                                            </div>
                                        </div>
                                    </td> */}
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex justify-content-start flex-column">
                                                <Button
                                                    onClick={() => {
                                                        completeDemoMutation.mutate(Number(item.id));
                                                    }}
                                                >
                                                    Mark Complete
                                                </Button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </TableContainer>
            </TableWrapper>
        </>
    );
};

export default DemosTable;
