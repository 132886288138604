/* eslint-disable jsx-a11y/anchor-is-valid */
import { CircularProgress } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { ButtonToolbar } from 'react-bootstrap';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import FilesTable from '../../../../compliancemasters/partials/widgets/tables/FilesTable/FilesTable';
import {
    IFile,
    IMember,
    IOptionalInvestigation,
    IPreEmploymentInvestigation,
    IPreEmploymentInvestigationDto,
    InvestigationType,
    PaymentStatus
} from '../../../../models';
import {
    createCheckoutSessionPreEmployment,
    getCompanyAddOns,
    getFilesByMemberId,
    getPreEmploymentProducts,
    getSubscriptionPlans
} from '../../../../shared';
import {
    createPreEmploymentInvestigation,
    updatePreEmploymentInvestigation
} from '../../../../shared/client/pre-employment';
import { useGetPerformanceHistoryCompleteByUserIdQuery } from '../../../../store/reducers/PreviousEmployeeSafetyPerformanceHistory/driver-identification';
import { useGetPreviousEmployeeSafetyPerformanceHistoryProgressMutation } from '../../../../store/reducers/PreviousEmployeeSafetyPerformanceHistory/progress';
import MonitoringMVRTable from '../../../pages/live-mvr-monitoring/MonitoringMVRTable';
import { useAuth } from '../../auth';
import Button from '../../buttons/Button';
import { StripeProduct } from '../../models';
import NewFormTable from '../NewFormsTable/NewFormTable';
import ReleaseForms from '../NewFormsTable/ReleaseForms';
import CurrentLocationModal from './CurrentLocationModal';
import { EndInvestigationModal } from './EndInvestigation';
import EndInvestigationsButtons from './EndInvestigationsButtons';
import OptionalInvestigationModal from './OptionalInvestigationModal';
import PreEmploymentCompleteCheckbox from './PreEmploymentCompleteCheckbox';
import PreEmploymentInvestigationTable from './PreEmploymentInvestigationTable';

const payableInvestigations = Object.values(InvestigationType).filter(
    (investigation) => investigation !== InvestigationType.PRE_EMPLOYMENT_FORMS
);

export const getNextInvestigation = (
    investigations?: IPreEmploymentInvestigation[],
    memberSub?: { name: string }
): InvestigationType | undefined => {
    const currentInvestigation = investigations?.find((investigation) => investigation.is_current_investigation);

    if (!currentInvestigation) {
        return undefined;
    }

    const name = memberSub?.name;
    let investigationOrder: InvestigationType[] = [];

    switch (name) {
        case 'Driver File Package':
            investigationOrder = [
                InvestigationType.PRE_EMPLOYMENT_FORMS,
                InvestigationType.PRE_EMPLOYMENT_MVR,
                InvestigationType.PRE_EMPLOYMENT_CRIMINAL_BACKGROUND_CHECK,
                InvestigationType.PRE_EMPLOYMENT_SCREENING_PROGRAM
            ];
            break;

        case 'Drug and Alcohol Package':
            investigationOrder = [
                InvestigationType.PRE_EMPLOYMENT_FORMS,
                InvestigationType.PRE_EMPLOYMENT_FULL_DACH_QUERY,
                InvestigationType.PRE_EMPLOYMENT_DRUG_TEST
            ];
            break;
        case 'Master Package':
        case 'Deluxe Package':
        case 'Deluxe Mini Package':
        default:
            // Default order when no specific package is matched
            investigationOrder = [
                InvestigationType.PRE_EMPLOYMENT_FORMS,
                InvestigationType.PRE_EMPLOYMENT_FULL_DACH_QUERY,
                InvestigationType.PRE_EMPLOYMENT_MVR,
                InvestigationType.PRE_EMPLOYMENT_DRUG_TEST,
                InvestigationType.PRE_EMPLOYMENT_CRIMINAL_BACKGROUND_CHECK,
                InvestigationType.PRE_EMPLOYMENT_SCREENING_PROGRAM
            ];
            break;
    }

    const currentIndex = investigationOrder.indexOf(currentInvestigation.type);

    if (currentIndex === -1) {
        return undefined;
    }

    const nextIndex = currentIndex + 1;
    return investigationOrder[nextIndex] || undefined;
};

const getCurrentInvestigation = (member: IMember) =>
    member?.pre_employment_investigation?.find((item) => item.is_current_investigation);

interface IEndInvestigationProps {
    options: StripeProduct[];
    member?: IMember;
    disabled?: boolean;
    subscription?: StripeProduct;
    disableContinue: boolean;
}

const CloseInvestigationCard = ({
    member,
    disabled,
    subscription,
    options,
    disableContinue
}: IEndInvestigationProps) => {
    const [selectedPlan, setSelectedPlan] = useState<StripeProduct>();
    const [optionalInvestigationModalOpen, setOptionalInvestigationModalOpen] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    const [shouldShowEndButtons, setShowEndButtons] = useState(false);

    const queryClient = useQueryClient();

    const preEmploymentMutation = useMutation(updatePreEmploymentInvestigation, {
        onSuccess: (data) => {
            queryClient.invalidateQueries('pre-employment-investigations');
            // Refresh the page
            window.location.assign(`/dashboard`);
        }
    });

    console.log(subscription);

    const nextInvestigation = getNextInvestigation(member?.pre_employment_investigation, subscription!);

    const shouldShowInDriverFile = useCallback(() => {
        if (
            (['Master Package', 'Deluxe Package', 'Deluxe Mini Package', 'Driver File Package'].includes(
                subscription?.name!
            ) ||
                (subscription?.base_product &&
                    ['Master Package', 'Deluxe Package', 'Deluxe Mini Package', 'Driver File Package'].includes(
                        subscription?.base_product
                    ))) &&
            member?.pre_employment_investigation &&
            ((nextInvestigation &&
                [
                    InvestigationType.PRE_EMPLOYMENT_CRIMINAL_BACKGROUND_CHECK,
                    InvestigationType.PRE_EMPLOYMENT_SCREENING_PROGRAM
                ].includes(nextInvestigation)) ||
                member?.pre_employment_investigation.length > 4)
        ) {
            setShowButtons(true);
        } else if (
            subscription?.name === 'Driver File Package' &&
            ((nextInvestigation &&
                [
                    InvestigationType.PRE_EMPLOYMENT_CRIMINAL_BACKGROUND_CHECK,
                    InvestigationType.PRE_EMPLOYMENT_SCREENING_PROGRAM
                ].includes(nextInvestigation)) ||
                (member?.pre_employment_investigation && member?.pre_employment_investigation?.length > 3))
        ) {
            setShowButtons(true);
        } else if (
            subscription?.name === 'Drug and Alcohol Package' &&
            member?.pre_employment_investigation &&
            ((nextInvestigation && [InvestigationType.PRE_EMPLOYMENT_DRUG_TEST].includes(nextInvestigation)) ||
                member?.pre_employment_investigation.length > 1)
        ) {
            setShowButtons(true);
        } else {
            setShowButtons(false);
        }
    }, [subscription, member, nextInvestigation]);

    const shouldShowContinueButtons =
        (nextInvestigation &&
            [
                InvestigationType.PRE_EMPLOYMENT_CRIMINAL_BACKGROUND_CHECK,
                InvestigationType.PRE_EMPLOYMENT_SCREENING_PROGRAM
            ].includes(nextInvestigation)) ||
        member?.pre_employment_investigation.some((item) => !item.screened);

    const setShouldShowEndButtons = useCallback(() => {
        if (
            (['Master Package', 'Deluxe Package', 'Deluxe Mini Package', 'Driver File Package'].includes(
                subscription?.name!
            ) ||
                (subscription?.base_product &&
                    ['Master Package', 'Deluxe Package', 'Deluxe Mini Package', 'Driver File Package'].includes(
                        subscription?.base_product
                    ))) &&
            member?.pre_employment_investigation &&
            member?.pre_employment_investigation?.length > 4 &&
            !member?.pre_employment_investigation.some((item) => !item.screened)
        ) {
            setShowEndButtons(true);
        } else if (
            subscription?.name === 'Driver File Package' &&
            member?.pre_employment_investigation &&
            member?.pre_employment_investigation?.length > 3 &&
            !member?.pre_employment_investigation.some((item) => !item.screened)
        ) {
            setShowEndButtons(true);
        } else if (
            subscription?.name === 'Drug and Alcohol Package' &&
            member?.pre_employment_investigation &&
            member?.pre_employment_investigation?.length > 1 &&
            !member?.pre_employment_investigation.some((item) => !item.screened)
        ) {
            setShowEndButtons(true);
        } else {
            setShowEndButtons(false);
        }
        // eslint-disable-next-line
    }, [subscription, member?.pre_employment_investigation, nextInvestigation]);

    const checkout = async (id: number) => {
        if (selectedPlan) {
            let url = await createCheckoutSessionPreEmployment(
                selectedPlan.price_id,
                selectedPlan.type,
                selectedPlan.tiers_mode,
                id
            );
            window.open(url?.url, '_blank');
        }
    };

    useEffect(() => {
        if (member && options?.length && subscription) {
            if (
                !member?.optional_investigation_selected ||
                member?.optional_investigation_selected === IOptionalInvestigation.BOTH
            ) {
                const nextInvest = getNextInvestigation(member!.pre_employment_investigation, subscription);
                if (nextInvest) {
                    const nextInvestigation = options?.find((option) =>
                        option.name.toLowerCase().includes(nextInvest.toLowerCase())
                    );
                    setSelectedPlan(nextInvestigation);
                }
            } else if (
                member?.optional_investigation_selected ===
                IOptionalInvestigation.PRE_EMPLOYMENT_CRIMINAL_BACKGROUND_CHECK
            ) {
                const nextInvestigation = options?.find((option) =>
                    option.name
                        .toLowerCase()
                        .includes(InvestigationType.PRE_EMPLOYMENT_CRIMINAL_BACKGROUND_CHECK.toLowerCase())
                );
                setSelectedPlan(nextInvestigation);
            } else if (
                member?.optional_investigation_selected === IOptionalInvestigation.PRE_EMPLOYMENT_SCREENING_PROGRAM
            ) {
                const nextInvestigation = options?.find((option) =>
                    option.name.toLowerCase().includes(InvestigationType.PRE_EMPLOYMENT_SCREENING_PROGRAM.toLowerCase())
                );
                setSelectedPlan(nextInvestigation);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [member, options]);

    useEffect(() => {
        shouldShowInDriverFile();
        setShouldShowEndButtons();
    }, [shouldShowInDriverFile, setShouldShowEndButtons]);

    return (
        <>
            <OptionalInvestigationModal
                memberId={Number(member?.id)}
                setOpen={setOptionalInvestigationModalOpen}
                open={optionalInvestigationModalOpen}
            />
            {showButtons ? (
                <div className="row g-5 g-xxl-8">
                    <div className="col-xl-12">
                        <div className="card card-xxl-stretch mb-5 mb-xl-8">
                            {shouldShowContinueButtons ? (
                                <div className="card-body d-flex flex-column p-9">
                                    <div className="d-flex flex-stack flex-grow-1">
                                        <h5 className="fw-bolder my-2">
                                            All required pre-employment investigations have been completed. Would you
                                            like to continue the investigation and complete the optional items, end the
                                            investigation and hire the applicant, or end the investigation and do not
                                            hire the applicant?
                                        </h5>
                                    </div>
                                    <div className="d-flex flex-stack flex-grow-1">
                                        <ButtonToolbar>
                                            <Button
                                                className="my-3 mx-3 btn btn-success"
                                                disabled={disabled}
                                                onClick={async () => {
                                                    if (!member?.optional_investigation_selected) {
                                                        setOptionalInvestigationModalOpen(true);
                                                    } else if (
                                                        member?.optional_investigation_selected ===
                                                        IOptionalInvestigation.BOTH
                                                    ) {
                                                        if (
                                                            nextInvestigation &&
                                                            [
                                                                InvestigationType.PRE_EMPLOYMENT_SCREENING_PROGRAM,
                                                                InvestigationType.PRE_EMPLOYMENT_CRIMINAL_BACKGROUND_CHECK
                                                            ].includes(nextInvestigation) &&
                                                            !disableContinue
                                                        ) {
                                                            const payload: IPreEmploymentInvestigationDto = {
                                                                investigation_type: nextInvestigation,
                                                                is_current_investigation: true,
                                                                member: Number(member?.id!),
                                                                requires_payment:
                                                                    payableInvestigations.includes(nextInvestigation),
                                                                payment: payableInvestigations.includes(
                                                                    nextInvestigation
                                                                )
                                                                    ? PaymentStatus.PENDING
                                                                    : PaymentStatus.NONE
                                                            };
                                                            const investigation =
                                                                await createPreEmploymentInvestigation(payload);
                                                            if (investigation && investigation.requires_payment) {
                                                                checkout(investigation.id);
                                                            }
                                                        } else if (disableContinue) {
                                                            toast.warn(
                                                                'The investigation can’t be continued until Compliance Masters has completed the Previous Employee Safety Performance History investigation. You will be notified when this has been completed and available to review'
                                                            );
                                                        }
                                                    } else if (
                                                        member?.optional_investigation_selected ===
                                                        IOptionalInvestigation.PRE_EMPLOYMENT_CRIMINAL_BACKGROUND_CHECK
                                                    ) {
                                                        if (!disableContinue) {
                                                            const payload: IPreEmploymentInvestigationDto = {
                                                                investigation_type:
                                                                    InvestigationType.PRE_EMPLOYMENT_CRIMINAL_BACKGROUND_CHECK,
                                                                is_current_investigation: true,
                                                                member: Number(member?.id!),
                                                                requires_payment: payableInvestigations.includes(
                                                                    InvestigationType.PRE_EMPLOYMENT_CRIMINAL_BACKGROUND_CHECK
                                                                ),
                                                                payment: payableInvestigations.includes(
                                                                    InvestigationType.PRE_EMPLOYMENT_CRIMINAL_BACKGROUND_CHECK
                                                                )
                                                                    ? PaymentStatus.PENDING
                                                                    : PaymentStatus.NONE
                                                            };
                                                            const investigation =
                                                                await createPreEmploymentInvestigation(payload);
                                                            if (investigation && investigation.requires_payment) {
                                                                checkout(investigation.id);
                                                            }
                                                        } else if (disableContinue) {
                                                            toast.warn(
                                                                'The investigation can’t be continued until Compliance Masters has completed the Previous Employee Safety Performance History investigation. You will be notified when this has been completed and available to review'
                                                            );
                                                        }
                                                    } else if (
                                                        member?.optional_investigation_selected ===
                                                        IOptionalInvestigation.PRE_EMPLOYMENT_SCREENING_PROGRAM
                                                    ) {
                                                        if (!disableContinue) {
                                                            const payload: IPreEmploymentInvestigationDto = {
                                                                investigation_type:
                                                                    InvestigationType.PRE_EMPLOYMENT_SCREENING_PROGRAM,
                                                                is_current_investigation: true,
                                                                member: Number(member?.id!),
                                                                requires_payment: payableInvestigations.includes(
                                                                    InvestigationType.PRE_EMPLOYMENT_SCREENING_PROGRAM
                                                                ),
                                                                payment: payableInvestigations.includes(
                                                                    InvestigationType.PRE_EMPLOYMENT_SCREENING_PROGRAM
                                                                )
                                                                    ? PaymentStatus.PENDING
                                                                    : PaymentStatus.NONE
                                                            };
                                                            const investigation =
                                                                await createPreEmploymentInvestigation(payload);
                                                            if (investigation && investigation.requires_payment) {
                                                                checkout(investigation.id);
                                                            }
                                                        } else if (disableContinue) {
                                                            toast.warn(
                                                                'The investigation can’t be continued until Compliance Masters has completed the Previous Employee Safety Performance History investigation. You will be notified when this has been completed and available to review'
                                                            );
                                                        }
                                                    }
                                                }}
                                            >
                                                {!member?.optional_investigation_selected
                                                    ? 'Select Optional Investigations'
                                                    : 'Continue Investigation to Optional'}
                                            </Button>
                                            <Button
                                                className="my-3 mx-3 btn btn-primary"
                                                disabled={disabled}
                                                onClick={() => {
                                                    preEmploymentMutation.mutate({
                                                        // @ts-ignore
                                                        member: Number(member?.id),
                                                        complete: true,
                                                        id: member?.pre_employment_investigation?.find(
                                                            (investigation) => investigation.is_current_investigation
                                                        )?.id,
                                                        screened: true
                                                    });
                                                }}
                                            >
                                                End Investigation and Hire
                                            </Button>
                                            <Button
                                                className="my-3 mx-3 btn btn-danger"
                                                dataBsToggle="modal"
                                                dataBsTarget="#end_investigation_modal"
                                            >
                                                End Investigation
                                            </Button>
                                        </ButtonToolbar>
                                    </div>
                                </div>
                            ) : shouldShowEndButtons ? (
                                <div className="card-body d-flex flex-column p-9">
                                    <div className="d-flex flex-stack flex-grow-1">
                                        <h5 className="fw-bolder my-2">
                                            All necessary and optional pre-employment investigations have been
                                            completed. Would you like to hire the driver or end the investigation?
                                        </h5>
                                    </div>
                                    <div className="d-flex flex-stack flex-grow-1">
                                        <ButtonToolbar>
                                            <Button
                                                className="my-3 mx-3 btn btn-success"
                                                disabled={disabled}
                                                onClick={() => {
                                                    preEmploymentMutation.mutate({
                                                        // @ts-ignore
                                                        member: Number(member?.id),
                                                        complete: true,
                                                        id: member?.pre_employment_investigation?.find(
                                                            (investigation) => investigation.is_current_investigation
                                                        )?.id,
                                                        screened: true
                                                    });
                                                }}
                                            >
                                                End Investigation and Hire
                                            </Button>
                                            <Button
                                                className="my-3 mx-3 btn btn-danger"
                                                dataBsToggle="modal"
                                                dataBsTarget="#end_investigation_modal"
                                            >
                                                End Investigation and Do Not Hire
                                            </Button>
                                        </ButtonToolbar>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

interface IInvestigationsProps {
    subscriptions: StripeProduct[];
    options: StripeProduct[];
    selectedPlan: StripeProduct | undefined;
    setSelectedPlan: React.Dispatch<React.SetStateAction<StripeProduct | undefined>>;
    member: IMember | undefined;
    disabled: boolean | undefined;
    subscription: StripeProduct | undefined;
    disableContinue: boolean;
}

const InvestigationCard = ({
    member,
    disabled,
    subscription,
    options,
    selectedPlan,
    setSelectedPlan,
    subscriptions,
    disableContinue
}: IInvestigationsProps) => {
    const [currentLocationModalOpen, setCurrentLocationModalOpen] = useState(false);

    const checkout = async (id: number) => {
        if (selectedPlan) {
            let url = await createCheckoutSessionPreEmployment(
                selectedPlan.price_id,
                selectedPlan.type,
                selectedPlan.tiers_mode,
                id
            );
            window.open(url?.url, '_blank');
        }
    };

    useEffect(() => {
        if (member && options?.length) {
            const nextInvest = getNextInvestigation(member.pre_employment_investigation, subscription);
            if (nextInvest) {
                const nextInvestigation = options?.find((option) =>
                    option.name.toLowerCase().includes(nextInvest.toLowerCase())
                );
                setSelectedPlan(nextInvestigation);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options]);

    return (
        <>
            {member?.pre_employment_investigation &&
            subscription &&
            getNextInvestigation(member?.pre_employment_investigation, subscription) &&
            [
                InvestigationType.PRE_EMPLOYMENT_FULL_DACH_QUERY,
                subscriptions.find((item) => item.product_id === member?.product_id)?.name !==
                    'Drug and Alcohol Package' && InvestigationType.PRE_EMPLOYMENT_MVR,
                InvestigationType.PRE_EMPLOYMENT_DRUG_TEST
            ].includes(getNextInvestigation(member?.pre_employment_investigation, subscription)!) ? (
                <div className="row g-5 g-xxl-8">
                    <div className="col-xl-12">
                        <div className="card card-xxl-stretch mb-5 mb-xl-8">
                            <div className="card-body d-flex flex-column p-9">
                                {
                                    <div className="d-flex flex-stack flex-grow-1">
                                        <h5 className="fw-bolder my-2">
                                            Would you like to continue or end this pre-employment investigation?
                                        </h5>
                                        <ButtonToolbar>
                                            <Button
                                                className="my-3 mx-3 btn btn-success"
                                                disabled={disabled}
                                                onClick={async () => {
                                                    const nextInvestigation = getNextInvestigation(
                                                        member.pre_employment_investigation,
                                                        subscription
                                                    );
                                                    if (
                                                        nextInvestigation === InvestigationType.PRE_EMPLOYMENT_DRUG_TEST
                                                    ) {
                                                        setCurrentLocationModalOpen(true);
                                                    } else if (nextInvestigation && !disableContinue) {
                                                        const payload: IPreEmploymentInvestigationDto = {
                                                            investigation_type: nextInvestigation,
                                                            is_current_investigation: true,
                                                            member: Number(member.id),
                                                            requires_payment:
                                                                payableInvestigations.includes(nextInvestigation),
                                                            payment: payableInvestigations.includes(nextInvestigation)
                                                                ? PaymentStatus.PENDING
                                                                : PaymentStatus.NONE
                                                        };
                                                        const investigation =
                                                            await createPreEmploymentInvestigation(payload);
                                                        if (investigation && investigation.requires_payment) {
                                                            if (
                                                                investigation.investigation_type ===
                                                                InvestigationType.PRE_EMPLOYMENT_FULL_DACH_QUERY
                                                            ) {
                                                                const res = window.confirm(
                                                                    'A Pre-employment Full DACH Query requires specific consent from the driver. Inform the driver to approve the consent request within the Clearinghouse. Once approved, we’re able to obtain the query and you’ll be notified when it’s been uploaded.'
                                                                );
                                                                if (res) {
                                                                    checkout(investigation.id);
                                                                }
                                                            } else {
                                                                checkout(investigation.id);
                                                            }
                                                        }
                                                    } else if (disableContinue) {
                                                        toast.warn(
                                                            'The investigation can’t be continued until Compliance Masters has completed the Previous Employee Safety Performance History investigation. You will be notified when this has been completed and available to review'
                                                        );
                                                    }
                                                }}
                                            >
                                                Continue Investigation
                                            </Button>
                                            <Button
                                                className="my-3 mx-3 btn btn-danger"
                                                dataBsToggle="modal"
                                                dataBsTarget="#end_investigation_modal"
                                            >
                                                End Investigation
                                            </Button>
                                        </ButtonToolbar>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <CurrentLocationModal
                        investigation={InvestigationType.PRE_EMPLOYMENT_DRUG_TEST}
                        open={currentLocationModalOpen}
                        setOpen={setCurrentLocationModalOpen}
                        disableContinue={disableContinue}
                        memberId={member.id}
                        payableInvestigations={payableInvestigations}
                        selectedPlan={selectedPlan!}
                    />
                </div>
            ) : null}
        </>
    );
};

interface IProps {
    subscriptions: StripeProduct[];
    member?: IMember;
    memberSubscription?: StripeProduct;
    pageLoading?: boolean;
}

export function DriverDocuments({ subscriptions, member, memberSubscription, pageLoading }: Readonly<IProps>) {
    const { currentUser } = useAuth();
    const { memberId } = useParams();
    const [files, setFiles] = useState<IFile[]>([]);
    const [addons, setAddons] = useState<StripeProduct[]>([]);
    const [options, setOptions] = useState<Array<StripeProduct>>();
    const [subscriptionss, setSubscriptionss] = useState<Array<StripeProduct>>([]);
    const [selectedPlan, setSelectedPlan] = useState<StripeProduct>();
    const [disableComplete, setDisableComplete] = useState<boolean>(false);

    const { data } = useGetPerformanceHistoryCompleteByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true
    });

    const [getProgress, { data: progress }] = useGetPreviousEmployeeSafetyPerformanceHistoryProgressMutation();

    const getPlanOptions = useCallback(async () => {
        if (memberSubscription) {
            const res = await getPreEmploymentProducts(memberSubscription.name);
            const subs = await getSubscriptionPlans();
            setOptions(res.data);
            setSubscriptionss(subs.data);
            getCompanyAddOns(
                currentUser && ['Admin', 'SuperAdmin'].includes(currentUser?.role)
                    ? Number(member?.company?.id)
                    : Number(member?.company?.id)
            ).then((res) => {
                setAddons(res);
            });
        }
    }, [currentUser, member, memberSubscription]);

    useEffect(() => {
        if (
            ((progress && progress?.progress > 97) || data?.performance_history_complete) &&
            (['Master Package', 'Deluxe Package', 'Deluxe Mini Package', 'Driver File Package'].includes(
                memberSubscription?.name!
            ) ||
                (memberSubscription?.base_product &&
                    ['Master Package', 'Deluxe Package', 'Deluxe Mini Package', 'Driver File Package'].includes(
                        memberSubscription?.base_product
                    )))
        ) {
            setDisableComplete(false);
        } else if (
            ((progress && progress?.progress < 97) || !data?.performance_history_complete) &&
            (['Master Package', 'Deluxe Package', 'Deluxe Mini Package', 'Driver File Package'].includes(
                memberSubscription?.name!
            ) ||
                (memberSubscription?.base_product &&
                    ['Master Package', 'Deluxe Package', 'Deluxe Mini Package', 'Driver File Package'].includes(
                        memberSubscription?.base_product
                    )))
        ) {
            setDisableComplete(true);
        } else if (['Drug and Alcohol Package'].includes(memberSubscription?.name!)) {
            setDisableComplete(false);
        }
    }, [progress, data, memberSubscription]);

    useEffect(() => {
        if (member) {
            getProgress(Number(member?.id));
        }
        // eslint-disable-next-line
    }, [member]);

    useEffect(() => {
        getPlanOptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getPlanOptions]);

    useEffect(() => {
        if (memberId) {
            getFiles();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getFiles() {
        getFilesByMemberId(memberId!, 'Driver').then((files) => {
            setFiles(files);
        });
    }

    console.log(member);

    return (
        <>
            <div className="d-flex flex-wrap flex-stack mb-6">
                <h3 className="fw-bolder my-2">
                    Driver File
                    <span className="fs-6 text-gray-400 fw-bold ms-1">All DQF Forms</span>
                </h3>
            </div>
            {memberSubscription && !['Driver File'].includes(memberSubscription?.name) && (
                <>
                    {!member?.company?.opted_out_of_pre_employment &&
                    (!member?.company?.packages_opted_out_of ||
                        (member?.company?.packages_opted_out_of &&
                            !member?.company?.packages_opted_out_of.includes(memberSubscription?.product_id))) ? (
                        <>
                            {!member?.terminated_at &&
                            !member?.pre_employment_screened &&
                            currentUser?.role &&
                            ['Owner', 'Admin', 'SuperAdmin'].includes(currentUser?.role) ? (
                                <PreEmploymentInvestigationTable
                                    investigations={member?.pre_employment_investigation ?? []}
                                    member={member!}
                                    subscriptions={subscriptions}
                                    memberSubscription={memberSubscription}
                                />
                            ) : null}
                            {!member?.terminated_at &&
                            !member?.pre_employment_screened &&
                            currentUser?.role &&
                            ['Owner', 'Admin', 'SuperAdmin'].includes(currentUser?.role) ? (
                                <InvestigationCard
                                    disabled={
                                        member && getCurrentInvestigation(member)?.screened
                                            ? false
                                            : member &&
                                                getCurrentInvestigation(member)?.type ===
                                                    InvestigationType.PRE_EMPLOYMENT_FORMS
                                              ? false
                                              : true
                                    }
                                    member={member!}
                                    subscription={memberSubscription}
                                    options={options!}
                                    selectedPlan={selectedPlan}
                                    setSelectedPlan={setSelectedPlan}
                                    subscriptions={subscriptionss}
                                    disableContinue={disableComplete}
                                />
                            ) : null}
                            {!member?.terminated_at &&
                            !member?.pre_employment_screened &&
                            currentUser?.role &&
                            ['Owner', 'Admin', 'SuperAdmin'].includes(currentUser?.role) ? (
                                <CloseInvestigationCard
                                    disabled={member && getCurrentInvestigation(member)?.screened ? false : true}
                                    member={member!}
                                    subscription={memberSubscription}
                                    options={options!}
                                    disableContinue={disableComplete}
                                />
                            ) : null}
                        </>
                    ) : null}
                </>
            )}
            {currentUser?.role === 'SuperAdmin' &&
            !member?.pre_employment_screened &&
            member?.company?.opted_out_of_pre_employment ? (
                <div className="row g-5 g-xxl-8 mb-6">
                    <div className="col-xl-12">
                        <PreEmploymentCompleteCheckbox member={member!} />
                    </div>
                </div>
            ) : null}
            {member?.company?.opted_out_of_pre_employment &&
                !member?.pre_employment_screened &&
                currentUser?.role &&
                ['Owner', 'Admin', 'SuperAdmin'].includes(currentUser?.role) && (
                    <EndInvestigationsButtons member={member!} files={files} />
                )}
            <div className="row g-5 g-xxl-8">
                <div className="col-xl-12">
                    {member &&
                    memberSubscription &&
                    !['Drug and Alcohol Package', 'Driver File'].includes(memberSubscription?.name) &&
                    !pageLoading ? (
                        <ReleaseForms subscription={memberSubscription} member={member} />
                    ) : null}
                </div>
            </div>
            <div className="row g-5 g-xxl-8">
                <div className="col-xl-12">
                    {member && memberSubscription && !pageLoading ? (
                        <NewFormTable subscription={memberSubscription} member={member} />
                    ) : null}
                </div>
            </div>
            <div className="row g-5 g-xxl-8">
                <div className="col-xl-12">
                    {pageLoading ? (
                        <div>
                            <CircularProgress />
                        </div>
                    ) : null}
                </div>
            </div>
            <div className="row g-5 g-xxl-8">
                <div className="col-xl-12">
                    {member && memberSubscription && !['Driver File'].includes(memberSubscription?.name) && (
                        <FilesTable
                            subscription_type={memberSubscription?.name}
                            files={files}
                            member={member}
                            memberId={memberId!}
                            onAddNewFile={getFiles}
                            location="Driver"
                        />
                    )}
                </div>
            </div>
            {currentUser && ['Admin', 'SuperAdmin'].includes(currentUser?.role) ? (
                <div className="row g-5 g-xxl-8">
                    <div className="col-xl-12">
                        {(member && memberSubscription?.name === 'Master Package') ||
                        (member && memberSubscription?.base_product === 'Master Package') ||
                        addons.some((addon) => addon.name === 'Live MVR add-on') ? (
                            <MonitoringMVRTable subscription_type={memberSubscription?.name} />
                        ) : null}
                    </div>
                </div>
            ) : null}
            {member && <EndInvestigationModal member={member} />}
        </>
    );
}
