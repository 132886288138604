import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { ISalesRep, deleteSalesRep, getSalesReps } from '../../../shared/client/sales-rep';
import { toast } from 'react-toastify';
import TableWrapper from '../../../compliancemasters/partials/widgets/tables/components/TableWrapper';
import TableContainer from '../../../compliancemasters/partials/widgets/tables/components/TableContainer';
import TableContainerHeader from '../../../compliancemasters/partials/widgets/tables/components/TableContainerHeader';
import TableContainerHeaderTitle from '../../../compliancemasters/partials/widgets/tables/components/TableContainerHeaderTitle';
import TableHeader from '../../../compliancemasters/partials/widgets/tables/components/TableHeader';
import TableHeaderColumn from '../../../compliancemasters/partials/widgets/tables/components/TableHeaderColumn';
import { IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { AdminPanelSettingsRounded, DeleteForever, MoreVert } from '@mui/icons-material';
import UpdateSalesRep from './UpdateSalesRep';
import UpdateSalesRepCompanies from './UpdateSalesRepCompanies';

function capitalizeFirstLetter(str: string): string {
    if (str.length === 0) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
}

const MenuComponent = ({
    anchorEl,
    open,
    handleClose,
    salesRep,
    getSalesReps,
    setModalOpen,
    companyModalOpen,
    setCompanyModalOpen
}: {
    anchorEl: null | HTMLElement;
    open: boolean;
    handleClose: () => void;
    getSalesReps: () => void;
    setModalOpen: (open: boolean) => void;
    salesRep?: ISalesRep;
    companyModalOpen: boolean;
    setCompanyModalOpen: (open: boolean) => void;
}) => {
    return (
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0
                    }
                }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItem
                onClick={() => {
                    setModalOpen(true);
                    handleClose();
                }}
            >
                <ListItemIcon>
                    <AdminPanelSettingsRounded fontSize="medium" />
                </ListItemIcon>
                Update Sales Rep
            </MenuItem>
            <MenuItem
                onClick={() => {
                    setCompanyModalOpen(true);
                    handleClose();
                }}
            >
                <ListItemIcon>
                    <AdminPanelSettingsRounded fontSize="medium" />
                </ListItemIcon>
                Update Companies
            </MenuItem>
            <MenuItem
                onClick={() => {
                    handleClose();
                    if (window.confirm('Are you sure you wish to change the activation status of this sales rep?')) {
                        deleteSalesRep(salesRep?.id!, 'hard')
                            .then(() => {
                                toast.success('Sales Rep deleted successfully!');
                                getSalesReps();
                            })
                            .catch((err) => {
                                toast.error(err.message);
                            });
                    }
                }}
            >
                <ListItemIcon>
                    <DeleteForever fontSize="medium" color="error" />
                </ListItemIcon>
                Delete Sales Rep
            </MenuItem>
        </Menu>
    );
};

const SalesRepTable = () => {
    const [open, setOpen] = useState<boolean>(false);
    const [salesReps, setSalesReps] = useState<ISalesRep[]>([]);
    const [salesRep, setSalesRep] = useState<ISalesRep>();
    const [companyModalOpen, setCompanyModalOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(anchorEl);
    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const getAllSalesReps = useCallback(() => {
        getSalesReps()
            .then((res: ISalesRep[]) => {
                setSalesReps(res.sort((a, b) => a.id - b.id));
            })
            .catch((err) => {
                toast.error(err.message);
            });
    }, []);

    useEffect(() => {
        getAllSalesReps();
    }, [getAllSalesReps]);

    return (
        <TableWrapper>
            <TableContainerHeader>
                <TableContainerHeaderTitle title="Sales Reps" />
            </TableContainerHeader>
            <TableContainer>
                <TableHeader>
                    <TableHeaderColumn center>Name</TableHeaderColumn>
                    <TableHeaderColumn center>Phone</TableHeaderColumn>
                    <TableHeaderColumn center>Email</TableHeaderColumn>
                    <TableHeaderColumn center>Assigned States</TableHeaderColumn>
                    <TableHeaderColumn center>Compliance Master Account</TableHeaderColumn>
                    <TableHeaderColumn center>Company</TableHeaderColumn>
                    <TableHeaderColumn center>Status</TableHeaderColumn>
                    <TableHeaderColumn center>{''}</TableHeaderColumn>
                </TableHeader>
                <tbody>
                    {salesReps.map((salesRep) => (
                        <tr
                            key={salesRep.id}
                            style={{
                                cursor: 'pointer'
                            }}
                            onClick={() => {
                                if (salesRep.is_compliance_master_account) {
                                    window.location.assign(`/compliance-sales-reps/${salesRep.id}`);
                                } else {
                                    window.location.assign(`/sales-reps/${salesRep.id}`);
                                }
                            }}
                        >
                            <td className="text-center hover-text-primary">
                                {capitalizeFirstLetter(salesRep?.user.first_name)}{' '}
                                {capitalizeFirstLetter(salesRep?.user?.last_name)}
                            </td>
                            <td className="text-center">{salesRep?.phone_number}</td>
                            <td className="text-center">{salesRep?.user?.email}</td>
                            <td className="text-center">
                                {salesRep.assigned_states
                                    ? salesRep.assigned_states.map((state) => `${state}, `)
                                    : 'Non Assigned'}
                            </td>
                            <td className="text-center">{salesRep.is_compliance_master_account ? 'Yes' : 'No'}</td>
                            <td className="text-center">
                                {salesRep?.companies.length > 0 ? salesRep?.companies.length : 'Not Assigned'}
                            </td>
                            <td className="text-center">{salesRep?.is_active ? 'Active' : 'Inactive'}</td>
                            <td className="text-center">
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setSalesRep(salesRep);
                                        handleClick(e);
                                    }}
                                >
                                    <MoreVert />
                                </IconButton>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </TableContainer>
            <MenuComponent
                getSalesReps={getAllSalesReps}
                salesRep={salesRep}
                anchorEl={anchorEl}
                open={menuOpen}
                handleClose={handleClose}
                setModalOpen={setOpen}
                companyModalOpen={companyModalOpen}
                setCompanyModalOpen={setCompanyModalOpen}
            />
            <UpdateSalesRep open={open} setOpen={setOpen} salesRep={salesRep} getSalesReps={getAllSalesReps} />
            <UpdateSalesRepCompanies
                open={companyModalOpen}
                setOpen={setCompanyModalOpen}
                salesRep={salesRep}
                getSalesReps={getAllSalesReps}
            />
        </TableWrapper>
    );
};

export default SalesRepTable;
