import TrainingTable from './TrainingTable';

const TrainingContainer = () => {
    return (
        <div className="row gy-5 g-xl-8">
            <div className="col-xl-12">
                <TrainingTable />
            </div>
        </div>
    );
};

export default TrainingContainer;
