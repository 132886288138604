import { IFile } from '../../models';
import client from '../Client';

export function getFileCategories({
    category_type,
    memberId,
    subscription
}: {
    category_type?: string;
    subscription?: string;
    memberId?: string;
}) {
    return client(
        `file/api/filecategory/${category_type ? `?category_type=${category_type}` : ''}${
            memberId ? `&memberId=${memberId}` : ''
        }${subscription ? `&subscription_type=${subscription}` : ''}`
    );
}

export function startUpload({
    fileName,
    fileType,
    title,
    expiration,
    categoryId,
    memberId,
    location,
    liveMvr,
    date,
    userId,
    description,
    quarter,
    year
}: {
    fileName: string;
    fileType: string;
    title: string;
    expiration?: string;
    categoryId?: number;
    memberId?: string | number;
    location?: string;
    date?: string | Date;
    liveMvr?: boolean;
    userId?: string | number;
    description?: string;
    quarter?: string;
    year?: string;
}) {
    return client(`file/api/file/upload/start/`, {
        body: {
            fileName,
            fileType,
            title,
            expiration,
            categoryId,
            memberId,
            location,
            liveMvr,
            date,
            userId,
            description,
            quarter,
            year
        }
    });
}

export function finishUpload(fileId: number) {
    return client(`file/api/file/upload/finish/`, {
        body: {
            fileId
        }
    });
}

export function getFilesByMemberId(memberId: string | number, location?: string) {
    return client(`file/api/file?memberId=${memberId}${location ? `&location=${location}` : ''}`);
}

export function getNonCurrentFilesByMemberId(memberId: string | number) {
    return client(`file/api/file/non-current?memberId=${memberId}`);
}

export function getExpiringFiles(companyId?: number) {
    if (companyId) {
        return client(`file/api/file/expiring?companyId=${companyId}`);
    } else {
        return client(`file/api/file/expiring`);
    }
}

export function downloadFile(fileId: number) {
    return client(`file/api/file/${fileId}/download`);
}

export function convertPdfFileToImage(url: string, fileName: string) {
    return client(`file/api/file/convert-pdf-to-image`, {
        body: {
            pdf_url: url,
            file_name: fileName
        }
    });
}

export function softDeleteFile(id: number) {
    return client(`file/api/file/soft-delete?id=${id}`, {
        method: 'DELETE'
    });
}

export function hardDeleteFile(id: number) {
    return client(`file/api/file/hard-delete?id=${id}`, {
        method: 'DELETE'
    });
}

export function getLiveMvrFiles(memberId?: string | number) {
    if (memberId) {
        return client(`file/api/file/get-live-mvr?memberId=${memberId}`);
    } else {
        return client(`file/api/file/get-live-mvr`);
    }
}

export const sendReminder = (file: IFile) => {
    return client(`file/api/file/send-expiration-reminder`, {
        body: {
            fileId: file.id
        }
    });
};
