import { Box, Chip, Grid, InputLabel, MenuItem, Paper, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { useFormik } from 'formik';
import { forwardRef, ReactElement, Ref, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { ICompany } from '../../../models';
import { useAuth } from '../../modules/auth';
import { IAdditionalPricing, StripeProduct } from '../../modules/models';

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement<any, any>;
    },
    ref: Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const initialValues: Partial<ICompany> = {
    name: '',
    id: '',
    address1: '',
    address2: '',
    city: '',
    country: '',
    state: '',
    zip_code: '',
    opted_out_of_pre_employment: false,
    packages_opted_out_of: [],
    requires_custom_pricing: false,
    custom_feature_name: '',
    custom_feature_price_ids: [],
    custom_price_added: false,
    coupon_code: ''
};

const validationSchema = Yup.object({
    id: Yup.string().required('Required'),
    name: Yup.string().required('Required'),
    address1: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    zip_code: Yup.string().required('Required'),
    opted_out_of_pre_employment: Yup.boolean().required('Required'),
    packages_opted_out_of: Yup.array().required('Required')
});

export default function UpdateCompany({
    open,
    setOpen,
    company,
    getCompanies,
    products
}: {
    open: boolean;
    setOpen: (open: boolean) => void;
    company?: ICompany;
    getCompanies: () => void;
    products: StripeProduct[];
}) {
    const { updateCompany } = useAuth();
    const [packages, setPackages] = useState<string[]>([]);
    // const [customPriceName, setCustomPriceName] = useState<string>('')
    const [pendingEmployment, setPendingEmployment] = useState<string[]>([]);
    const [customFeaturePrices, setCustomFeaturePrices] = useState<IAdditionalPricing[]>([]);
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { resetForm }) => {
            if (company) {
                await updateCompany({
                    ...values
                })
                    .then((res) => {
                        toast.success('Company updated successfully');
                        setOpen(false);
                        getCompanies();
                        resetForm();
                    })
                    .catch((err) => {
                        toast.error(err.message);
                    });
            }
            getCompanies();
            setOpen(false);
        }
    });

    const handleClose = () => {
        setOpen(false);
        setPackages([]);
        formik.resetForm();
    };

    useEffect(() => {
        if (company && open) {
            formik.setValues({
                id: company.id,
                name: company.name,
                address1: company.address1,
                address2: company.address2,
                city: company.city,
                country: company.country,
                state: company.state,
                zip_code: company.zip_code,
                opted_out_of_pre_employment: company.opted_out_of_pre_employment,
                packages_opted_out_of: company.packages_opted_out_of ? company.packages_opted_out_of : [],
                requires_custom_pricing: company.requires_custom_pricing,
                custom_feature_name: company.custom_feature_name,
                custom_feature_price_ids: company.custom_feature_price_ids ? company.custom_feature_price_ids : [],
                custom_price_added: company.custom_price_added,
                coupon_code: company.coupon_code
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, company]);

    useEffect(() => {
        if (company && company.package) {
            const pkgs = company.package.map((x) => x.id);
            setPackages(pkgs);
        }
        if (company && company?.drivers_pending_employment) {
            const pkgs = company.drivers_pending_employment.map((x) => x.id);
            setPendingEmployment(pkgs);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company]);

    useEffect(() => {
        const list: IAdditionalPricing[] = [];
        for (const product of products) {
            if (product.price_list) {
                list.push(...product?.price_list);
            }
        }
        setCustomFeaturePrices(list);
    }, [formik.values.custom_feature_price_ids, products]);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle
                sx={{
                    backgroundColor: '#1e1e2d',
                    color: 'white',
                    textAlign: 'center'
                }}
            >
                Update Company Information
            </DialogTitle>
            <DialogContent
                sx={{
                    p: 4
                }}
            >
                <Grid container spacing={2} p={2}>
                    <Grid item xs={12} sm={12}>
                        <InputLabel>Company Name</InputLabel>
                        <TextField
                            fullWidth
                            size="small"
                            id="name"
                            name="name"
                            value={formik.values.name}
                            onChange={(e) => formik.setFieldValue('name', e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <InputLabel>Address Line 1</InputLabel>
                        <TextField
                            fullWidth
                            size="small"
                            id="address1"
                            name="address1"
                            value={formik.values.address1}
                            onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <InputLabel>Address Line 2</InputLabel>
                        <TextField
                            fullWidth
                            size="small"
                            id="address2"
                            name="address2"
                            value={formik.values.address2}
                            onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <InputLabel>City</InputLabel>
                        <TextField
                            fullWidth
                            size="small"
                            id="city"
                            name="city"
                            value={formik.values.city}
                            onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <InputLabel>State</InputLabel>
                        <TextField
                            fullWidth
                            size="small"
                            id="state"
                            name="state"
                            value={formik.values.state}
                            onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <InputLabel>Zip Code</InputLabel>
                        <TextField
                            fullWidth
                            size="small"
                            id="zip_code"
                            name="zip_code"
                            value={formik.values.zip_code}
                            onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <InputLabel>Coupon Code</InputLabel>
                        <TextField
                            fullWidth
                            size="small"
                            id="coupon_code"
                            name="coupon_code"
                            value={formik.values.coupon_code}
                            onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <InputLabel>Requires Custom Pricing & Features</InputLabel>
                        <TextField
                            fullWidth
                            size="small"
                            id="requires_custom_pricing"
                            name="requires_custom_pricing"
                            value={formik.values.requires_custom_pricing ? 'Yes' : 'No'}
                            onChange={(e) =>
                                formik.setFieldValue('requires_custom_pricing', e.target.value === 'Yes' ? true : false)
                            }
                            select
                        >
                            <MenuItem value={'Yes'}>Yes</MenuItem>
                            <MenuItem value={'No'}>No</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <InputLabel>Opted out of Pre Employment</InputLabel>
                        <TextField
                            fullWidth
                            size="small"
                            id="opted_out_of_pre_employment"
                            name="opted_out_of_pre_employment"
                            value={formik.values.opted_out_of_pre_employment ? 'Yes' : 'No'}
                            onChange={(e) =>
                                formik.setFieldValue(
                                    'opted_out_of_pre_employment',
                                    e.target.value === 'Yes' ? true : false
                                )
                            }
                            select
                        >
                            <MenuItem value={'Yes'}>Yes</MenuItem>
                            <MenuItem value={'No'}>No</MenuItem>
                        </TextField>
                    </Grid>
                    {formik.values.requires_custom_pricing ? (
                        <Grid item xs={12} sm={12} md={12}>
                            <InputLabel>Custom Feature Name</InputLabel>
                            <TextField
                                fullWidth
                                size="small"
                                id="custom_feature_name"
                                name="custom_feature_name"
                                value={formik.values.custom_feature_name}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                    ) : null}
                    {formik.values.requires_custom_pricing && (
                        <Grid item xs={12} sm={12}>
                            <InputLabel>Selected Pricing to be added for each Package</InputLabel>
                            <TextField
                                fullWidth
                                size="small"
                                id="custom_feature_price_ids"
                                name="custom_feature_price_ids"
                                onChange={(e) => {
                                    if (formik.values.custom_feature_price_ids) {
                                        formik.setFieldValue('custom_feature_price_ids', [
                                            e.target.value,
                                            ...formik.values.custom_feature_price_ids
                                        ]);
                                    } else {
                                        formik.setFieldValue('custom_feature_price_ids', [e.target.value]);
                                    }
                                }}
                                select
                            >
                                {customFeaturePrices
                                    .filter(
                                        (item) =>
                                            item.price_id !==
                                            formik.values.custom_feature_price_ids?.find((prd) => item.price_id === prd)
                                    )
                                    .map((x) => {
                                        return (
                                            <MenuItem key={x.price_id} value={x.price_id}>
                                                {x.nickname} - {x?.lookup_key}
                                            </MenuItem>
                                        );
                                    })}
                            </TextField>
                        </Grid>
                    )}
                    {formik.values.opted_out_of_pre_employment && (
                        <Grid item xs={12} sm={12}>
                            <InputLabel>Selected Packages being opted out of</InputLabel>
                            <TextField
                                fullWidth
                                size="small"
                                id="packages_opted_out_of"
                                name="packages_opted_out_of"
                                onChange={(e) => {
                                    if (formik.values.packages_opted_out_of) {
                                        formik.setFieldValue('packages_opted_out_of', [
                                            e.target.value,
                                            ...formik.values.packages_opted_out_of
                                        ]);
                                    } else {
                                        formik.setFieldValue('packages_opted_out_of', [e.target.value]);
                                    }
                                }}
                                select
                            >
                                {[...packages, ...pendingEmployment]
                                    .filter(
                                        (item) =>
                                            item !== formik.values.packages_opted_out_of?.find((pkg) => item === pkg)
                                    )
                                    ?.map((x) => {
                                        const product = products.find((prd) => prd.product_id === x);
                                        return (
                                            <MenuItem key={x} value={x}>
                                                {product?.name}
                                            </MenuItem>
                                        );
                                    })}
                            </TextField>
                        </Grid>
                    )}
                    {formik.values.requires_custom_pricing && (
                        <Grid item xs={12} sm={12} md={12}>
                            <InputLabel>Custom Feature Completed?</InputLabel>
                            <TextField
                                fullWidth
                                size="small"
                                id="custom_price_added"
                                name="custom_price_added"
                                value={formik.values.custom_price_added ? 'Yes' : 'No'}
                                onChange={(e) =>
                                    formik.setFieldValue('custom_price_added', e.target.value === 'Yes' ? true : false)
                                }
                                select
                            >
                                <MenuItem value={'Yes'}>Yes</MenuItem>
                                <MenuItem value={'No'}>No</MenuItem>
                            </TextField>
                        </Grid>
                    )}
                    {formik.values.custom_feature_price_ids && formik.values.custom_feature_price_ids?.length > 0 ? (
                        <Grid item xs={12} sm={12}>
                            <Grid item sm={12}>
                                <Box
                                    component={Paper}
                                    sx={{
                                        padding: 2,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexWrap: 'wrap'
                                    }}
                                >
                                    {formik.values.custom_feature_price_ids.map((name, index) => {
                                        const customProduct = customFeaturePrices?.find(
                                            (item) => item.price_id === name
                                        );
                                        return (
                                            <Chip
                                                sx={{ m: 1 }}
                                                label={`${customProduct?.nickname} ${customProduct?.lookup_key}`}
                                                variant="outlined"
                                                onDelete={() => {
                                                    formik.setFieldValue(
                                                        'custom_feature_price_ids',
                                                        formik.values.custom_feature_price_ids?.filter(
                                                            (x) => x !== customProduct?.price_id
                                                        )
                                                    );
                                                }}
                                                key={name}
                                            />
                                        );
                                    })}
                                </Box>
                            </Grid>
                        </Grid>
                    ) : null}
                    {formik.values.packages_opted_out_of && formik.values.packages_opted_out_of?.length > 0 ? (
                        <Grid item xs={12} sm={12}>
                            <Grid item sm={12}>
                                <Box
                                    component={Paper}
                                    sx={{
                                        padding: 3,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexWrap: 'wrap'
                                    }}
                                >
                                    {formik.values.packages_opted_out_of.map((prd, index) => {
                                        const product = products.find((prod) => prod.product_id === prd);
                                        return (
                                            <Chip
                                                sx={{ m: 1 }}
                                                label={product?.name}
                                                variant="outlined"
                                                onDelete={() => {
                                                    formik.setFieldValue(
                                                        'packages_opted_out_of',
                                                        formik.values.packages_opted_out_of?.filter((x) => x !== prd)
                                                    );
                                                }}
                                                key={prd}
                                            />
                                        );
                                    })}
                                </Box>
                            </Grid>
                        </Grid>
                    ) : null}
                    <Grid item xs={12} sm={12}>
                        <Button
                            fullWidth
                            variant="contained"
                            sx={{
                                backgroundColor: '#1e1e2d'
                            }}
                            color="primary"
                            onClick={() => {
                                formik.handleSubmit();
                            }}
                        >
                            Update Company
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
