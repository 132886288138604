import { Box, Button, Grid, MenuItem, Paper, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { useAuth } from '../../../app/modules/auth';
import { IMember } from '../../../models';
import { changeStringLast4 } from '../../../shared';
import {
    DatTextChoices,
    IHoursWorked,
    IHoursWorkedDto,
    ITotalHours,
    ITotalHoursDto
} from '../../../store/interfaces/DriversStatementDutyHours/application-info-and-hours';
import {
    useCreateHoursWorkedMutation,
    useCreateTotalHoursMutation,
    useDeleteTotalHoursMutation,
    useGetHoursWorkedByUserIdQuery,
    useGetTotalHoursByUserIdQuery,
    useUpdateTotalHoursMutation
} from '../../../store/reducers/DriverStatementDutyHours/applicant-info-and-hours';
import { useThemeMode } from '../../partials';
import HoursWorkedCard from './HoursWorkedCard';

const totalHoursInitialValues: Partial<ITotalHoursDto> = {
    total_hours: 0,
    record_of_duty_status: false,
    drivers_license_number: '',
    drivers_name: ''
};

const hoursWorked: Partial<IHoursWorkedDto> = {
    date: '',
    hours_worked: '',
    day: '' as DatTextChoices
};

const totalHoursValidationSchema = Yup.object({
    total_hours: Yup.number(),
    record_of_duty_status: Yup.boolean().required(),
    drivers_license_number: Yup.string().required(),
    drivers_name: Yup.string().required()
});

const hoursWorkedValidationSchema = Yup.object({
    date: Yup.string().required(),
    hours_worked: Yup.number().required(),
    day: Yup.string().required()
});

const setFormikValues = (data: ITotalHours, formik: any, member?: IMember, role?: string) => {
    formik.setValues({
        ...data,
        drivers_name: data?.drivers_name
            ? data?.drivers_name
            : `${member?.user?.first_name} ${member?.user?.last_name}`,
        drivers_license_number: data?.drivers_license_number
            ? ['Member', 'Owner'].includes(role!)
                ? changeStringLast4(data?.drivers_license_number, '*')!
                : data?.drivers_license_number
            : ['Member', 'Owner'].includes(role!)
            ? changeStringLast4(String(member?.drivers_license_number), '*')!
            : member?.drivers_license_number
    });
};

const setHoursWorkedFormikDate = (hoursWorkedData: IHoursWorked[], hoursWorkedFormik: any) => {
    const mostRecentDate = hoursWorkedData.reduce((prev: IHoursWorked, current: IHoursWorked) =>
        prev.date < current.date ? prev : current
    );
    if (mostRecentDate) {
        //  Add 1 day to the most recent date
        // const utcDate = new Date(mostRecentDate.date)
        // const offsetMinutes = utcDate.getTimezoneOffset();
        // utcDate.setMinutes(utcDate.getMinutes() + offsetMinutes);

        const utcDate = new Date(mostRecentDate.date);
        utcDate.setDate(utcDate.getDate() - 1); // this will also handle month and year underflows

        // Adjust for timezone offset
        const offsetMinutes = utcDate.getTimezoneOffset();
        utcDate.setMinutes(utcDate.getMinutes() + offsetMinutes);

        // Format the date to "YYYY-MM-DD"
        const adjustedYear = utcDate.getFullYear();
        const adjustedMonth = String(utcDate.getMonth() + 1).padStart(2, '0');
        const adjustedDay = String(utcDate.getDate()).padStart(2, '0');
        const formattedDate = `${adjustedYear}-${adjustedMonth}-${adjustedDay}`;
        hoursWorkedFormik.setFieldValue('date', formattedDate);

        switch (mostRecentDate.day) {
            case DatTextChoices.DAY_ONE:
                hoursWorkedFormik.setFieldValue('day', DatTextChoices.DAY_TWO);
                break;
            case DatTextChoices.DAY_TWO:
                hoursWorkedFormik.setFieldValue('day', DatTextChoices.DAY_THREE);
                break;
            case DatTextChoices.DAY_THREE:
                hoursWorkedFormik.setFieldValue('day', DatTextChoices.DAY_FOUR);
                break;
            case DatTextChoices.DAY_FOUR:
                hoursWorkedFormik.setFieldValue('day', DatTextChoices.DAY_FIVE);
                break;
            case DatTextChoices.DAY_FIVE:
                hoursWorkedFormik.setFieldValue('day', DatTextChoices.DAY_SIX);
                break;
            case DatTextChoices.DAY_SIX:
                hoursWorkedFormik.setFieldValue('day', DatTextChoices.DAY_SEVEN);
                break;
            case DatTextChoices.DAY_SEVEN:
                hoursWorkedFormik.setFieldValue('day', DatTextChoices.DAY_ONE);
                break;
            default:
                hoursWorkedFormik.setFieldValue('day', DatTextChoices.DAY_ONE);
                break;
        }
    }
};

function determineDateMessage(member: IMember, hoursWorkedFormik: any) {
    const isPreEmploymentScreened = member?.pre_employment_screened;
    const isDayOne = hoursWorkedFormik.values.day === DatTextChoices.DAY_ONE;

    if (isPreEmploymentScreened && isDayOne && member?.pre_employment_investigation.length < 1) {
        return '(Day before your hire date)';
    } else if (isPreEmploymentScreened && member?.pre_employment_investigation.length > 0 && isDayOne) {
        return '(Yesterdays Date)';
    } else {
        return '';
    }
}

const ApplicantInfoAndHours = ({ member, isDriverFile }: { member?: IMember; isDriverFile?: boolean }) => {
    const { mode } = useThemeMode();
    const { currentUser } = useAuth();
    const { data: hoursWorkedData = [] } = useGetHoursWorkedByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true
    });
    const { data, refetch, isSuccess } = useGetTotalHoursByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true
    });

    const [createHoursWorked, { isLoading: isCreatingHoursWorked }] = useCreateHoursWorkedMutation();
    const [createTotalHoursWorked, { isLoading: isCreating }] = useCreateTotalHoursMutation();
    const [updateTotalHoursWorked, { isLoading: isUpdating }] = useUpdateTotalHoursMutation();
    const [deleteHoursWorked] = useDeleteTotalHoursMutation();

    const formik = useFormik({
        initialValues: totalHoursInitialValues,
        validationSchema: totalHoursValidationSchema,
        onSubmit: async (values, { resetForm }) => {
            if (data) {
                // @ts-ignore
                updateTotalHoursWorked({
                    // @ts-ignore
                    ...values,
                    // @ts-ignore
                    id: data?.id,
                    // @ts-ignore
                    company: member?.company?.id,
                    // @ts-ignore
                    member: member?.id
                });
            } else {
                createTotalHoursWorked({
                    ...values,
                    total_hours: Number(values?.total_hours),
                    // @ts-ignore
                    record_of_duty_status: values?.record_of_duty_status,
                    // @ts-ignore
                    company: member?.company?.id,
                    // @ts-ignore
                    member: member?.id
                });
            }
            resetForm();
        },
        validateOnBlur: true,
        validateOnChange: true
    });

    const hoursWorkedFormik = useFormik({
        initialValues: hoursWorked,
        validationSchema: hoursWorkedValidationSchema,
        onSubmit: async (values, { resetForm }) => {
            if (data && values) {
                createHoursWorked({
                    ...values,
                    hours_worked: Number(values.hours_worked),
                    // @ts-ignore
                    company: member?.company?.id,
                    // @ts-ignore
                    member: member?.id,
                    // @ts-ignore
                    total_hours_worked: data?.id
                }).then(() => {
                    resetForm();
                    // Refresh the page
                    window.location.reload();
                });
            }
        },
        validateOnBlur: true,
        validateOnChange: true,
        validate: (values) => {
            const errors: Partial<IHoursWorkedDto> = {};
            if (!values.date) {
                errors.date = 'Required';
            }
            if (!values.day) {
                // @ts-ignore
                errors.day = 'Required';
            }
            if (!values.hours_worked) {
                // @ts-ignore
                errors.hours_worked = 'Required';
            }
            return errors;
        }
    });

    const daysAlreadyWorked = new Set(hoursWorkedData.map((item) => item.day));

    useEffect(() => {
        if (data) {
            setFormikValues(data, formik, member, currentUser?.role);
            if (hoursWorkedData.length > 0) {
                setHoursWorkedFormikDate(hoursWorkedData, hoursWorkedFormik);
            } else {
                hoursWorkedFormik.setFieldValue('day', DatTextChoices.DAY_ONE);
            }
        } else if (!data && member) {
            formik.setValues({
                ...totalHoursInitialValues,
                drivers_name: `${member?.user?.first_name} ${member?.user?.last_name}`,
                drivers_license_number: member?.drivers_license_number
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, member, hoursWorkedData]);

    useEffect(() => {
        if (data && isSuccess) {
            refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hoursWorkedData]);

    return (
        <>
            <Box
                sx={{
                    pl: 3,
                    pr: 3,
                    Radius: 2,
                    backgroundColor: mode === 'dark' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    width: {
                        lg: '47vw',
                        md: '55vw',
                        sm: '60vw'
                    },
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: 3
                }}
                component={Paper}
            >
                <div
                    style={{
                        padding: 10,
                        margin: 10
                    }}
                >
                    <Typography
                        sx={{
                            textAlign: 'center',
                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                        }}
                        variant="h4"
                        gutterBottom
                        component="h4"
                    >
                        Applicant Info And Hours
                    </Typography>
                    <Typography
                        sx={{
                            textAlign: 'center',
                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                        }}
                        variant="h6"
                        gutterBottom
                        component="h6"
                    >
                        Drivers Statement of on Duty Hours
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Typography
                                sx={{
                                    textAlign: 'justify',
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Section 395.8(j) (2) For newly hired drivers, motor carriers must obtain a signed
                                statement from the driver that gives their total time of on-duty hours during the
                                previous 7 days, and the time at which the driver was last relieved from duty prior to
                                starting work for the carrier.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography
                                sx={{
                                    textAlign: 'justify',
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                NOTE Hours for any work during the preceding 7 days, including any compensated work for
                                a non-motor carrier, must be recorded on this form. This form should be completed on the
                                day the driver is scheduled to begin driving a commercial motor vehicle and must be kept
                                on file for at least 6 months.
                            </Typography>
                            {member?.pre_employment_screened && member?.pre_employment_investigation.length === 0 ? (
                                <Typography
                                    sx={{ textAlign: 'justify' }}
                                    color="green"
                                    variant="body1"
                                    gutterBottom
                                    component="p"
                                >
                                    Note: As an existing driver, you will be backdating this form. To the best of your
                                    ability please provide your hours worked for the 7 days previous to your hire date
                                    with this company.
                                </Typography>
                            ) : null}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography
                                sx={{
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                    textAlign: 'justify'
                                }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Employee's Name
                            </Typography>
                            <TextField
                                fullWidth
                                id="drivers_name"
                                placeholder="Employee's Name"
                                size="small"
                                variant="outlined"
                                value={formik.values.drivers_name}
                                // onChange={(e) => {
                                //     formik.setFieldValue('drivers_name', e.target.value)
                                // }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.drivers_name && Boolean(formik.errors.drivers_name)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&:hover fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.drivers_name && Boolean(formik.errors.drivers_name)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&.Mui-focused fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.drivers_name && Boolean(formik.errors.drivers_name)
                                                    ? 'red'
                                                    : 'green'
                                        }
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                                disabled={
                                    member?.terminated_at ||
                                    (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                        ? true
                                        : false
                                }
                                InputProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography
                                sx={{
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                    textAlign: 'justify'
                                }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Drivers License Number
                            </Typography>
                            <TextField
                                fullWidth
                                id="drivers_license_number"
                                placeholder="Drivers License Number"
                                size="small"
                                variant="outlined"
                                value={formik.values.drivers_license_number}
                                onChange={(e) => {
                                    formik.setFieldValue('drivers_license_number', e.target.value);
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.drivers_license_number &&
                                                Boolean(formik.errors.drivers_license_number)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&:hover fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.drivers_license_number &&
                                                Boolean(formik.errors.drivers_license_number)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&.Mui-focused fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.drivers_license_number &&
                                                Boolean(formik.errors.drivers_license_number)
                                                    ? 'red'
                                                    : 'green'
                                        }
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                                disabled={
                                    member?.terminated_at ||
                                    (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                        ? true
                                        : false
                                }
                                InputProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography
                                sx={{
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                    textAlign: 'justify'
                                }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Total hours work for the last seven days
                            </Typography>
                            <TextField
                                fullWidth
                                id="total_hours"
                                placeholder="Total hours work for the last seven days"
                                size="small"
                                variant="outlined"
                                value={formik.values.total_hours}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.total_hours && Boolean(formik.errors.total_hours)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&:hover fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.total_hours && Boolean(formik.errors.total_hours)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&.Mui-focused fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.total_hours && Boolean(formik.errors.total_hours)
                                                    ? 'red'
                                                    : 'green'
                                        }
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                                disabled={
                                    member?.terminated_at ||
                                    (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                        ? true
                                        : false
                                }
                                InputProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                            />
                            <Typography
                                sx={{ textAlign: 'justify' }}
                                color="green"
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                You don’t need to type in this box. This number will be automatically generated based on
                                the hours you provide below for each of the 7 days
                            </Typography>
                            <Typography
                                sx={{ textAlign: 'justify' }}
                                color="red"
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Answer the question below and then continue to save this section in order to begin
                                entering your hours worked.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography
                                sx={{
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                    textAlign: 'justify'
                                }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Were you required to use a record of duty status (driver's log/ELD) on 8 or more days
                                within the past 30 consecutive days?
                            </Typography>
                            <TextField
                                fullWidth
                                id="record_of_duty_status"
                                select
                                size="small"
                                variant="outlined"
                                value={formik.values.record_of_duty_status ? 'Yes' : 'No'}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        'record_of_duty_status',
                                        e.target.value === 'Yes' ? true : false
                                    );
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.record_of_duty_status &&
                                                Boolean(formik.errors.record_of_duty_status)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&:hover fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.record_of_duty_status &&
                                                Boolean(formik.errors.record_of_duty_status)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&.Mui-focused fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.record_of_duty_status &&
                                                Boolean(formik.errors.record_of_duty_status)
                                                    ? 'red'
                                                    : 'green'
                                        }
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                                disabled={
                                    member?.terminated_at ||
                                    (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                        ? true
                                        : false
                                }
                                InputProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                            >
                                <MenuItem value="Yes">Yes</MenuItem>
                                <MenuItem value="No">No</MenuItem>
                            </TextField>
                        </Grid>
                        {(!data || (data && currentUser && ['Admin', 'SuperAdmin'].includes(currentUser?.role))) &&
                        !isDriverFile ? (
                            <Grid item xs={12} sm={12}>
                                <Button
                                    fullWidth
                                    disabled={isCreating || isUpdating || !!member?.terminated_at}
                                    onClick={() => formik.handleSubmit()}
                                    variant="contained"
                                    color="primary"
                                >
                                    Save
                                </Button>
                                {currentUser?.role !== 'Admin' && currentUser?.role !== 'SuperAdmin' && (
                                    <Typography
                                        sx={{ color: 'red', textAlign: 'center', mt: 2 }}
                                        variant="body1"
                                        gutterBottom
                                        component="p"
                                    >
                                        Once you click save, you will not be able to edit this form.
                                    </Typography>
                                )}
                            </Grid>
                        ) : null}
                    </Grid>
                </div>
            </Box>
            {data && hoursWorkedData?.length < 7 ? (
                <Box
                    sx={{
                        pl: 3,
                        pr: 3,
                        Radius: 2,
                        backgroundColor: mode === 'dark' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                        width: {
                            lg: '47vw',
                            md: '55vw',
                            sm: '60vw'
                        },
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginBottom: 3
                    }}
                    component={Paper}
                >
                    <div
                        style={{
                            padding: 10,
                            margin: 10
                        }}
                    >
                        <Typography
                            sx={{
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                textAlign: 'center'
                            }}
                            variant="h4"
                            gutterBottom
                            component="h4"
                        >
                            Hours Worked
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <Typography
                                    sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                                    variant="body1"
                                    gutterBottom
                                    component="p"
                                >
                                    Day
                                </Typography>
                                <TextField
                                    fullWidth
                                    id="day"
                                    size="small"
                                    variant="outlined"
                                    placeholder="Day"
                                    value={hoursWorkedFormik.values.day}
                                    select
                                    type="day"
                                    onChange={(e) => {
                                        hoursWorkedFormik.setFieldValue('day', e.target.value);
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                // Check Validation
                                                borderColor:
                                                    hoursWorkedFormik.touched.day &&
                                                    Boolean(hoursWorkedFormik.errors.day)
                                                        ? 'red'
                                                        : 'green'
                                            },
                                            '&:hover fieldset': {
                                                // Check Validation
                                                borderColor:
                                                    hoursWorkedFormik.touched.day &&
                                                    Boolean(hoursWorkedFormik.errors.day)
                                                        ? 'red'
                                                        : 'green'
                                            },
                                            '&.Mui-focused fieldset': {
                                                // Check Validation
                                                borderColor:
                                                    hoursWorkedFormik.touched.day &&
                                                    Boolean(hoursWorkedFormik.errors.day)
                                                        ? 'red'
                                                        : 'green'
                                            }
                                        }
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                        }
                                    }}
                                    disabled={true}
                                    InputProps={{
                                        style: {
                                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                        }
                                    }}
                                >
                                    {Object.values(DatTextChoices).map(
                                        (day) =>
                                            !daysAlreadyWorked.has(day) && (
                                                <MenuItem key={day} value={day}>
                                                    {day}
                                                </MenuItem>
                                            )
                                    )}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography
                                    sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                                    variant="body1"
                                    gutterBottom
                                    component="p"
                                >
                                    Date {member && determineDateMessage(member, hoursWorkedFormik)}
                                    {/* {member?.pre_employment_screened && hoursWorkedFormik.values.day === DatTextChoices.DAY_ONE && member?.pre_employment_investigation.length < 1 ? "(Day before your hire date)" : ((member?.pre_employment_screened && member?.pre_employment_investigation.length > 0 && hoursWorkedFormik.values.day === DatTextChoices.DAY_ONE) || (!member?.pre_employment_screened && hoursWorkedFormik.values.day === DatTextChoices.DAY_ONE)) ? "(Yesterdays Date)" : ""} */}
                                </Typography>
                                <TextField
                                    fullWidth
                                    id="date"
                                    size="small"
                                    variant="outlined"
                                    value={hoursWorkedFormik.values.date}
                                    type="date"
                                    onChange={(e) => {
                                        hoursWorkedFormik.setFieldValue('date', e.target.value);
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                // Check Validation
                                                borderColor:
                                                    hoursWorkedFormik.touched.date &&
                                                    Boolean(hoursWorkedFormik.errors.date)
                                                        ? 'red'
                                                        : 'green'
                                            },
                                            '&:hover fieldset': {
                                                // Check Validation
                                                borderColor:
                                                    hoursWorkedFormik.touched.date &&
                                                    Boolean(hoursWorkedFormik.errors.date)
                                                        ? 'red'
                                                        : 'green'
                                            },
                                            '&.Mui-focused fieldset': {
                                                // Check Validation
                                                borderColor:
                                                    hoursWorkedFormik.touched.date &&
                                                    Boolean(hoursWorkedFormik.errors.date)
                                                        ? 'red'
                                                        : 'green'
                                            }
                                        }
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                        }
                                    }}
                                    disabled={!!member?.terminated_at}
                                    InputProps={{
                                        style: {
                                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography
                                    sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                                    variant="body1"
                                    gutterBottom
                                    component="p"
                                >
                                    Hours Worked
                                </Typography>
                                <TextField
                                    fullWidth
                                    id="hours_worked"
                                    size="small"
                                    variant="outlined"
                                    placeholder="Hours Worked"
                                    value={hoursWorkedFormik.values.hours_worked}
                                    onChange={(e) => {
                                        hoursWorkedFormik.setFieldValue('hours_worked', e.target.value);
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                // Check Validation
                                                borderColor:
                                                    hoursWorkedFormik.touched.hours_worked &&
                                                    Boolean(hoursWorkedFormik.errors.hours_worked)
                                                        ? 'red'
                                                        : 'green'
                                            },
                                            '&:hover fieldset': {
                                                // Check Validation
                                                borderColor:
                                                    hoursWorkedFormik.touched.hours_worked &&
                                                    Boolean(hoursWorkedFormik.errors.hours_worked)
                                                        ? 'red'
                                                        : 'green'
                                            },
                                            '&.Mui-focused fieldset': {
                                                // Check Validation
                                                borderColor:
                                                    hoursWorkedFormik.touched.hours_worked &&
                                                    Boolean(hoursWorkedFormik.errors.hours_worked)
                                                        ? 'red'
                                                        : 'green'
                                            }
                                        }
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                        }
                                    }}
                                    disabled={!!member?.terminated_at}
                                    InputProps={{
                                        style: {
                                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                        }
                                    }}
                                />
                            </Grid>
                            {!isDriverFile && (
                                <Grid item xs={12} sm={12}>
                                    <Button
                                        fullWidth
                                        disabled={isCreatingHoursWorked || !!member?.terminated_at}
                                        onClick={() => hoursWorkedFormik.handleSubmit()}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            )}
                            {data && currentUser?.role === 'SuperAdmin' && (
                                <Grid item xs={12} sm={12}>
                                    <Button
                                        fullWidth
                                        disabled={!!member?.terminated_at}
                                        onClick={() => {
                                            const res = window.confirm('Are you sure you want to delete this form?');
                                            if (res && data) {
                                                deleteHoursWorked(data.id);
                                            }
                                        }}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Delete Form
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </div>
                </Box>
            ) : null}
            {hoursWorkedData.map((item) => (
                <HoursWorkedCard member={member} hoursWorkedItem={item} key={item.id} />
            ))}
        </>
    );
};

export default ApplicantInfoAndHours;
