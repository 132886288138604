import { Grid, InputLabel, MenuItem, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef, ReactElement, Ref, useState } from 'react';
import { toast } from 'react-toastify';
import { InvestigationType, IOptionalInvestigation } from '../../../../models';
import { updateCompanyMember } from '../../../../shared';

interface IProps {
    open: boolean;
    setOpen: (val: boolean) => void;
    memberId: number;
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement<any, any>;
    },
    ref: Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function OptionalInvestigationModal({ setOpen, open, memberId }: Readonly<IProps>) {
    const [selectedInvestigation, setSelectedInvestigation] = useState<IOptionalInvestigation>(
        IOptionalInvestigation.PRE_EMPLOYMENT_CRIMINAL_BACKGROUND_CHECK
    );
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle
                sx={{
                    textAlign: 'center'
                }}
            >
                Select Optional Investigations
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} p={2}>
                    <Grid item xs={12} sm={12}>
                        <InputLabel>Select Optional Investigation</InputLabel>
                        <TextField
                            select
                            size="small"
                            variant="outlined"
                            fullWidth
                            onChange={(e) => {
                                setSelectedInvestigation(e.target.value as IOptionalInvestigation);
                            }}
                        >
                            <MenuItem value={InvestigationType.PRE_EMPLOYMENT_CRIMINAL_BACKGROUND_CHECK}>
                                Pre-Employment Criminal Background Check
                            </MenuItem>
                            <MenuItem value={InvestigationType.PRE_EMPLOYMENT_SCREENING_PROGRAM}>
                                Pre-Employment Screening Program
                            </MenuItem>
                            <MenuItem value={'Both'}>Both</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => {
                                updateCompanyMember(String(memberId), {
                                    optional_investigation_selected: selectedInvestigation
                                }).then(() => {
                                    toast.success('Optional Investigation Updated');
                                    setOpen(false);
                                });
                            }}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
