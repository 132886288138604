import {driverSignatureApi} from './driver-signature';
import {requestForCheckOfDrivingRecordsProgress} from './progress';
import {requestBySignatureApi} from './request-by-signature';
import {requestForCheckLetterApi} from './request-for-check-letter';
import {requesterSignatureApi} from './requester-signature';

export const RequestForCheckOfDrivingRecordsReducers = {
  [driverSignatureApi.reducerPath]: driverSignatureApi.reducer,
  [requestBySignatureApi.reducerPath]: requestBySignatureApi.reducer,
  [requestForCheckLetterApi.reducerPath]: requestForCheckLetterApi.reducer,
  [requesterSignatureApi.reducerPath]: requesterSignatureApi.reducer,
  [requestForCheckOfDrivingRecordsProgress.reducerPath]:
    requestForCheckOfDrivingRecordsProgress.reducer,
};

export const RequestForCheckOfDrivingRecordsMiddleware = [
  driverSignatureApi.middleware,
  requestBySignatureApi.middleware,
  requestForCheckLetterApi.middleware,
  requesterSignatureApi.middleware,
  requestForCheckOfDrivingRecordsProgress.middleware,
];
