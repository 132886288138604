import CloseIcon from '@mui/icons-material/Close';
import { DialogContent } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef, ReactElement, Ref } from 'react';
import { useThemeMode } from '../../../../compliancemasters/partials';
import SalesRepTermsAndConditions from './SalesRepTermsAndConditions';

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement;
    },
    ref: Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

export const SalesRepTermsModal = ({ open, setOpen }: IProps) => {
    const { mode } = useThemeMode();
    return (
        <Dialog open={open} onClose={() => setOpen(false)} TransitionComponent={Transition} fullScreen>
            <AppBar
                sx={{
                    position: 'relative',
                    backgroundColor: mode === 'dark' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)'
                }}
            >
                <Toolbar>
                    <IconButton
                        sx={{
                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            fontSize: '2.5rem'
                        }}
                        size="large"
                        edge="start"
                        color="inherit"
                        onClick={() => setOpen(false)}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent
                sx={{
                    backgroundColor: mode === 'dark' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                }}
            >
                <SalesRepTermsAndConditions isModal />
            </DialogContent>
        </Dialog>
    );
};
