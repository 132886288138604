import {driversHandbookReceiptApi} from './drivers-handbook-receipt';
import {driversHandbookReceiptProgress} from './progress';

export const DriversHandbookReceiptReducers = {
  [driversHandbookReceiptApi.reducerPath]: driversHandbookReceiptApi.reducer,
  [driversHandbookReceiptProgress.reducerPath]: driversHandbookReceiptProgress.reducer,
};

export const DriversHandbookReceiptMiddleware = [
  driversHandbookReceiptApi.middleware,
  driversHandbookReceiptProgress.middleware,
];
