import { Button, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { useAuth } from '../../../app/modules/auth';
import { IMember } from '../../../models';
import { IProcessRecordDto } from '../../../store/interfaces/DriversApplicationForEmployment/process.record';
import { useGetCertificationByUserIdQuery } from '../../../store/reducers/CertificateOfComplianceWithDriverLicenseRequirements/certification';
import {
    useCreateProcessRecordMutation,
    useDeleteProcessRecordMutation,
    useGetProcessRecordByUserIdQuery
} from '../../../store/reducers/DriversApplicationForEmployment/process-record';
import { useThemeMode } from '../../partials';

const initialValues: Partial<IProcessRecordDto> = {
    applicant_hired: false,
    date_employed: '',
    department: '',
    signature_of_interviewing_officer: ''
};

const validationSchema = Yup.object({
    applicant_hired: Yup.boolean().required('Required'),
    date_employed: Yup.string().required('Required'),
    department: Yup.string().required('Required').min(2, 'Too Short!'),
    signature_of_interviewing_officer: Yup.string().required('Required').min(3, 'Too Short!')
});

const ProcessRecord = ({ member, isDriverFile }: { member?: IMember; isDriverFile?: boolean }) => {
    const { mode } = useThemeMode();
    const { currentUser } = useAuth();
    const { data } = useGetProcessRecordByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    });
    const { data: certOfCompliance } = useGetCertificationByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true
    });
    const [createProcessRecord, { isLoading: isCreating }] = useCreateProcessRecordMutation();
    const [updateProcessRecord, { isLoading: isUpdating }] = useCreateProcessRecordMutation();
    const [deleteProcessRecord, { isLoading: isDeleting }] = useDeleteProcessRecordMutation();

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            if (data) {
                updateProcessRecord({
                    ...values,
                    // @ts-ignore
                    id: data.id
                });
            } else {
                createProcessRecord({
                    ...values,
                    // @ts-ignore
                    member: member?.id,
                    // @ts-ignore
                    company: member?.company?.id
                });
            }
        },
        validateOnBlur: true,
        validate: (values) => {
            const errors: Partial<IProcessRecordDto> = {};
            if (!values.date_employed) {
                errors.date_employed = 'Required';
            }
            if (!values.department) {
                errors.department = 'Required';
            }
            if (!values.signature_of_interviewing_officer) {
                errors.signature_of_interviewing_officer = 'Required';
            }
            return errors;
        }
    });

    useEffect(() => {
        if (data) {
            formik.setValues(data);
        } else if (certOfCompliance) {
            formik.setFieldValue('date_employed', certOfCompliance.date_of_hire);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, certOfCompliance]);

    return (
        <div
            style={{
                padding: 10,
                margin: 10
            }}
        >
            <Typography
                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: 'center' }}
                variant="h4"
                gutterBottom
                component="h4"
            >
                Process Record
            </Typography>
            <Grid
                container
                spacing={3}
                p={1}
                sx={{
                    alignItems: 'center'
                }}
            >
                <Grid item xs={12} sm={6}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Applicant Hired
                    </Typography>
                    <TextField
                        fullWidth
                        id="date_employed"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.applicant_hired ? 'true' : 'false'}
                        onChange={(e) => {
                            formik.setFieldValue('applicant_hired', e.target.value === 'true');
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.date_employed && Boolean(formik.errors.date_employed)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.date_employed && Boolean(formik.errors.date_employed)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.date_employed && Boolean(formik.errors.date_employed)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Date Hired
                    </Typography>
                    <TextField
                        fullWidth
                        id="date_employed"
                        size="small"
                        variant="outlined"
                        type="date"
                        {...formik.getFieldProps('date_employed')}
                        className={
                            formik.touched.date_employed && Boolean(formik.errors.date_employed) ? 'green' : 'red'
                        }
                        error={formik.touched.date_employed && Boolean(formik.errors.date_employed)}
                        helperText={formik.touched.date_employed && formik.errors.date_employed}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.date_employed && Boolean(formik.errors.date_employed)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.date_employed && Boolean(formik.errors.date_employed)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.date_employed && Boolean(formik.errors.date_employed)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Department
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <TextField
                        fullWidth
                        id="department"
                        label="Department"
                        placeholder="Department"
                        size="small"
                        variant="outlined"
                        {...formik.getFieldProps('department')}
                        className={formik.touched.department && Boolean(formik.errors.department) ? 'green' : 'red'}
                        error={formik.touched.department && Boolean(formik.errors.department)}
                        helperText={formik.touched.department && formik.errors.department}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.department && Boolean(formik.errors.department) ? 'red' : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.department && Boolean(formik.errors.department) ? 'red' : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.department && Boolean(formik.errors.department) ? 'red' : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Signature of Interviewing Officer
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <TextField
                        fullWidth
                        id="signature_of_interviewing_officer"
                        label="Signature of Interviewing Officer"
                        placeholder="Signature of Interviewing Officer"
                        size="small"
                        variant="outlined"
                        {...formik.getFieldProps('signature_of_interviewing_officer')}
                        className={
                            formik.touched.signature_of_interviewing_officer &&
                            Boolean(formik.errors.signature_of_interviewing_officer)
                                ? 'green'
                                : 'red'
                        }
                        error={
                            formik.touched.signature_of_interviewing_officer &&
                            Boolean(formik.errors.signature_of_interviewing_officer)
                        }
                        helperText={
                            formik.touched.signature_of_interviewing_officer &&
                            formik.errors.signature_of_interviewing_officer
                        }
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.signature_of_interviewing_officer &&
                                        Boolean(formik.errors.signature_of_interviewing_officer)
                                            ? 'red'
                                            : 'green',
                                    fontStyle: 'italic'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.signature_of_interviewing_officer &&
                                        Boolean(formik.errors.signature_of_interviewing_officer)
                                            ? 'red'
                                            : 'green',
                                    fontStyle: 'italic'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.signature_of_interviewing_officer &&
                                        Boolean(formik.errors.signature_of_interviewing_officer)
                                            ? 'red'
                                            : 'green',
                                    fontStyle: 'italic'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                fontStyle: 'italic'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                fontStyle: 'italic'
                            }
                        }}
                    />
                </Grid>
                {(!data || (data && currentUser && ['Admin', 'SuperAdmin'].includes(currentUser?.role))) &&
                !isDriverFile ? (
                    <Grid item xs={12} sm={12}>
                        <Button
                            fullWidth
                            disabled={isCreating || isUpdating || !!member?.terminated_at}
                            onClick={() => formik.handleSubmit()}
                            variant="contained"
                            color="primary"
                        >
                            Save
                        </Button>
                        {currentUser?.role !== 'Admin' && currentUser?.role !== 'SuperAdmin' && (
                            <Typography
                                sx={{ color: 'red', textAlign: 'center', mt: 2 }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Once you click save, you will not be able to edit this form.
                            </Typography>
                        )}
                    </Grid>
                ) : null}
                {data && currentUser?.role === 'SuperAdmin' && (
                    <Grid item xs={12} sm={12}>
                        <Button
                            fullWidth
                            disabled={isDeleting || !!member?.terminated_at}
                            onClick={() => {
                                const res = window.confirm('Are you sure you want to delete this form?');
                                if (res && data) {
                                    deleteProcessRecord(data.id);
                                }
                            }}
                            variant="contained"
                            color="primary"
                        >
                            Delete Form
                        </Button>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default ProcessRecord;
