import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { DriverType, IMember, MemberInvestigationStatus } from '../../../models';
import { Button, Grid, InputLabel, MenuItem, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { forwardRef, ReactElement, Ref, useEffect, useRef } from 'react';
import { updateCompanyMember } from '../../../shared';
import { toast } from 'react-toastify';
interface IProps {
    open: boolean;
    setOpen: (val: boolean) => void;
    member?: IMember;
}

const initialValues: Partial<IMember> & { email?: string; first_name?: string; last_name?: string } = {
    drivers_license_number: '',
    social_security_number: '',
    date_of_birth: '',
    driver_type: '' as DriverType,
    pre_employment_investigation_status: '' as MemberInvestigationStatus,
    pre_employment_investigation_complete: false,
    pre_employment_screened: false,
    phone_number: '',
    state: '',
    email: ''
};

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement<any, any>;
    },
    ref: Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function UpdateMemberModal({ open, setOpen, member }: IProps) {
    const handleClose = () => {
        setOpen(false);
        formik.resetForm();
    };

    const formik = useFormik({
        initialValues,
        onSubmit: (values, { resetForm }) => {
            updateCompanyMember(String(member?.id), values).then(() => {
                resetForm();
                toast.success('Member Updated Successfully');
                setOpen(false);
            });
        }
    });

    const formikRef = useRef(formik);

    useEffect(() => {
        if (open && member) {
            formikRef?.current.setValues({
                drivers_license_number: member?.drivers_license_number,
                social_security_number: member?.social_security_number,
                state: member?.user?.member?.state,
                email: member?.user?.email,
                phone_number: member?.user?.member?.phone_number,
                date_of_birth: member?.date_of_birth
                    ? new Date(member?.date_of_birth).toISOString().split('T')[0]
                    : undefined,
                driver_type: member?.driver_type,
                product_id: member?.product_id,
                pre_employment_investigation_status: member?.pre_employment_investigation_status,
                pre_employment_investigation_complete: member?.pre_employment_investigation_complete,
                pre_employment_screened: member?.pre_employment_screened,
                first_name: member?.user?.first_name,
                last_name: member?.user?.last_name
            });
        }
    }, [open, member]);

    return (
        <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle
                sx={{
                    textAlign: 'center',
                    borderBottom: '1px solid #e0e0e0'
                }}
            >
                Update {member?.user?.first_name} {member?.user?.last_name}'s Profile
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Grid container spacing={2} p={3}>
                        <Grid item xs={12} md={6}>
                            <InputLabel>First Name</InputLabel>
                            <TextField
                                value={formik.values.first_name}
                                name="first_name"
                                onChange={(e) => formik.setFieldValue('first_name', e.target.value)}
                                type="text"
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputLabel>Last Name</InputLabel>
                            <TextField
                                value={formik.values.last_name}
                                name="last_name"
                                onChange={(e) => formik.setFieldValue('last_name', e.target.value)}
                                type="text"
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputLabel>Date of Birth</InputLabel>
                            <TextField
                                value={formik.values.date_of_birth}
                                name="date_of_birth"
                                onChange={(e) => formik.setFieldValue('date_of_birth', e.target.value)}
                                type="date"
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputLabel>Driver Type</InputLabel>
                            <TextField
                                value={formik.values.driver_type}
                                name="driver_type"
                                onChange={(e) => formik.setFieldValue('driver_type', e.target.value)}
                                select
                                fullWidth
                                size="small"
                            >
                                {Object.values(DriverType).map((type) => (
                                    <MenuItem key={type} value={type}>
                                        {type}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputLabel>Email</InputLabel>
                            <TextField
                                value={formik.values.email}
                                name="email"
                                onChange={(e) => formik.setFieldValue('email', e.target.value)}
                                type="email"
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputLabel>Phone Number</InputLabel>
                            <TextField
                                value={formik.values.phone_number}
                                name="phone_number"
                                onChange={(e) => formik.setFieldValue('phone_number', e.target.value)}
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputLabel>Drivers License Number</InputLabel>
                            <TextField
                                value={formik.values.drivers_license_number}
                                name="drivers_license_number"
                                onChange={(e) => formik.setFieldValue('drivers_license_number', e.target.value)}
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputLabel>Social Security Number</InputLabel>
                            <TextField
                                value={formik.values.social_security_number}
                                name="social_security_number"
                                onChange={(e) => formik.setFieldValue('social_security_number', e.target.value)}
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <InputLabel>State</InputLabel>
                            <TextField
                                value={formik.values.state}
                                name="state"
                                onChange={(e) => formik.setFieldValue('state', e.target.value)}
                                fullWidth
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button size="small" fullWidth variant="contained" onClick={() => formik.handleSubmit()}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}
