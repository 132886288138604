import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
  IDriverInformation,
  IDriverInformationDto,
} from '../../interfaces/RecordOfRoadTest/driver-information';

export const driverInformationApi = createApi({
  reducerPath: 'driver_information',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/record-of-road-test-driver-information`,
  }),
  tagTypes: ['DriverInformation'],
  keepUnusedDataFor: 60 * 10, // 10 minutes
  endpoints: (builder) => ({
    createDriverInformation: builder.mutation<IDriverInformation, IDriverInformationDto>({
      query: (body) => ({
        url: '/',
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'DriverInformation', id: 'DriverInformation'}],
    }),
    updateDriverInformation: builder.mutation<IDriverInformation, IDriverInformation>({
      query: (body) => ({
        url: `/${body.id}/`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'DriverInformation', id: 'DriverInformation'}],
    }),
    deleteDriverInformation: builder.mutation<void, number>({
      query: (id) => ({
        url: `/${id}/`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    getDriverInformation: builder.query<IDriverInformation, number | void>({
      query: (id) => ({
        url: `/get-driver-information/`,
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'DriverInformation', id: 'DriverInformation'}],
    }),
    getDriverInformationByUser: builder.query<IDriverInformation, void>({
      query: () => ({
        url: `/get-driver-information-by-user/`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'DriverInformation', id: 'DriverInformation'}],
    }),
    getDriverInformationByUserId: builder.query<IDriverInformation, number | void>({
      query: (id) => ({
        url: `/get-driver-information-by-user-id/`,
        method: 'POST',
        body: {
          userId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'DriverInformation', id: 'DriverInformation'}],
    }),
  }),
});

export const {
  useCreateDriverInformationMutation,
  useUpdateDriverInformationMutation,
  useGetDriverInformationQuery,
  useGetDriverInformationByUserQuery,
  useGetDriverInformationByUserIdQuery,
  useDeleteDriverInformationMutation,
} = driverInformationApi;
