import clsx from 'clsx';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { IMemberRequest, IRequestStatus } from '../../../../../../models';
import { getRequestStatuses, updateMemberRequest } from '../../../../../../shared/client/memberRequest';
import { KTSVG } from '../../../../../helpers';

interface IProps {
    memberRequest?: IMemberRequest;
}

const memberRequestUpdateSchema = Yup.object().shape({
    status: Yup.string().min(1).required('Request Status is required')
});

const UpdateMemberRequestStatus = ({ memberRequest }: IProps) => {
    const [loading, setLoading] = useState(false);
    const { data: statuses } = useQuery<IRequestStatus[]>('requestStatuses', async () => getRequestStatuses(), {
        refetchOnWindowFocus: false
    });
    const queryClient = useQueryClient();

    const memberRequestMutation = useMutation(updateMemberRequest, {
        onSuccess: (data) => {
            toast.success('Demo Request marked as complete');
            console.log(data);
            queryClient.invalidateQueries('member-requests');
        }
    });

    const formik = useFormik({
        initialValues: {
            status: 0
        },
        validationSchema: memberRequestUpdateSchema,
        onSubmit: async (values) => {
            setLoading(true);
            if (memberRequest?.id) {
                memberRequestMutation.mutate({
                    memberRequest: {
                        id: memberRequest?.id,
                        status: values.status,
                        member: Number(memberRequest?.member.id),
                        type: Number(memberRequest?.type.id)
                    }
                });
            }
            setLoading(false);
        }
    });

    useEffect(() => {
        if (memberRequest) {
            formik.setFieldValue('status', memberRequest?.status);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memberRequest]);

    return (
        <div className="modal fade" id="update_request_modal" role="dialog" tabIndex={-1} aria-modal="true">
            <div className="modal-dialog modal-dialog-centered mw-650px">
                <div className="modal-content">
                    <div className="modal-header">
                        {/* begin::modal title */}
                        <div></div>
                        <div className="modal-title">
                            <h2 className="fw-bolder text-center">
                                Update {memberRequest?.type.name} Request <br /> (
                                {`${memberRequest?.member.user.first_name} ${memberRequest?.member.user.last_name}`})
                            </h2>
                        </div>
                        {/* end::modal title */}

                        {/* begin::Close */}
                        <div
                            className="btn btn-icon btn-sm btn-active-icon-primary"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            style={{ cursor: 'pointer' }}
                        >
                            <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon-1" />
                        </div>
                        {/* end::Close */}
                    </div>
                    <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                        <form onSubmit={formik.handleSubmit} className="form" id="kt_modal_request_update_form">
                            <div className="fv-row mb-7">
                                <label className="form-label fw-bolder text-dark fs-6">Request Status</label>
                                <select
                                    className={clsx('form-select form-select-solid', {
                                        'is-invalid': formik.touched.status && formik.errors.status
                                    })}
                                    {...formik.getFieldProps('status')}
                                    value={formik.values.status}
                                >
                                    <option value={0}>Select</option>
                                    {statuses?.map((status) => (
                                        <option key={status.value} value={status.value}>
                                            {status.value}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="text-center">
                                <button
                                    type="submit"
                                    id="kt_modal_request_submit"
                                    className="btn btn-primary"
                                    disabled={loading}
                                    data-bs-dismiss="modal"
                                    onClick={(e) => {}}
                                >
                                    <span className="indicator-label">Submit</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateMemberRequestStatus;
