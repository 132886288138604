/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import Button from '../../../../../../app/modules/buttons/Button';
import { IMemberRequest } from '../../../../../../models';
import { useNavigation } from '../../../../../../shared';
import { toAbsoluteUrl } from '../../../../../helpers';

interface IProps {
    request: IMemberRequest;
    setMemberRequest: (val: IMemberRequest) => void;
}

/*
Right now I have commented things out that won't work because of the typescript issues.

Fix the typescript issues and uncomment the code!
*/
export default function MemberRequestRow({ request, setMemberRequest }: IProps) {
    const { navigateToCompanyMemberProfile } = useNavigation();
    const [requestedDate, setRequestedDate] = useState('');
    const [completedDate, setCompletedDate] = useState('');

    function goToProfile() {
        navigateToCompanyMemberProfile(request.member.id);
    }

    useEffect(() => {
        const utcDate = new Date(request.created_at);
        const offsetMinutes = utcDate.getTimezoneOffset();
        utcDate.setMinutes(utcDate.getMinutes() + offsetMinutes);
        const adjustedYear = utcDate.getFullYear();
        const adjustedMonth = String(utcDate.getMonth() + 1).padStart(2, '0');
        const adjustedDay = String(utcDate.getDate()).padStart(2, '0');
        const formattedDate = `${adjustedMonth}-${adjustedDay}-${adjustedYear}`;
        setRequestedDate(formattedDate);
    }, [request.created_at]);

    useEffect(() => {
        const utcDate = new Date(request.date_completed);
        const offsetMinutes = utcDate.getTimezoneOffset();
        utcDate.setMinutes(utcDate.getMinutes() + offsetMinutes);
        const adjustedYear = utcDate.getFullYear();
        const adjustedMonth = String(utcDate.getMonth() + 1).padStart(2, '0');
        const adjustedDay = String(utcDate.getDate()).padStart(2, '0');
        const formattedDate = `${adjustedMonth}-${adjustedDay}-${adjustedYear}`;
        setCompletedDate(formattedDate);
    }, [request.date_completed]);

    return (
        <tr>
            <td>
                <div className="d-flex align-items-center">
                    <div className="symbol symbol-45px me-5">
                        <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt="" />
                    </div>
                    <div className="d-flex justify-content-start flex-column" onClick={goToProfile}>
                        <a href="#" className="text-dark fw-bold text-hover-primary fs-6">
                            {request.member.user.first_name} {request.member.user.last_name}
                        </a>
                    </div>
                </div>
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">{request.member.company.name}</div>
                </div>
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">{request.type.name}</div>
                </div>
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">{request.city}</div>
                </div>
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">{request.state}</div>
                </div>
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">{request.zip_code}</div>
                </div>
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">{request.status}</div>
                </div>
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">
                        {request.type.name === 'Random Drug and Alcohol Test'
                            ? request.confirm_driver_notified
                                ? 'Confirmed'
                                : 'Pending'
                            : 'N/A'}
                    </div>
                </div>
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">{requestedDate}</div>
                </div>
            </td>
            <td>
                <div className="d-flex align-items-center">
                    <div className="d-flex justify-content-start flex-column">
                        {request.date_completed ? completedDate : 'N/A'}
                    </div>
                </div>
            </td>
            <td>
                <div className="d-flex align-items-center">
                    {/* <UpdateMemberRequestStatus memberRequest={request} /> */}
                    <Button
                        className="btn btn-sm btn-light btn-active-light-primary"
                        dataBsToggle="modal"
                        onClick={() => setMemberRequest(request)}
                        dataBsTarget="#update_request_modal"
                    >
                        Update
                    </Button>
                </div>
            </td>
        </tr>
    );
}
