import {IDVIReport} from './dvir.report';
import {IMaintenanceMember} from './maintenance.members';
import {INotesAndLinks} from './notes.and.links';
import {IPmProgram} from './pm.program';
import {IServiceOrders} from './service.orders';

export enum VehicleType {
  TRUCK = 'Truck',
  TRAILER = 'Trailer',
  MOTOR_COACH = 'Motor Coach',
  SCHOOL_BUS = 'School Bus',
  MINI_BUS = 'Mini Bus',
  LIMO = 'Limo',
  VAN = 'Van',
  SEDAN = 'Sedan',
}

export interface IMaintenanceVehicle {
  vehicle_number: string;
  vehicle_make: string;
  vehicle_model: string;
  current_odometer_reading: number;
  initial_odometer_reading: number;
  vehicle_type: VehicleType;
  last_service_date?: Date;
  maintenance_member?: IMaintenanceMember;
  dvir_reports: IDVIReport[];
  dvir_report_trailer: IDVIReport[];
  pm_programs: IPmProgram[];
  pm_program: IPmProgram;
  notes_and_links: INotesAndLinks[];
  service_orders: IServiceOrders[];
  active: boolean;
  id: number;
  company: number;
  created_at: Date;
}
