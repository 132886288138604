import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {IProgress} from '../../interfaces/abstract';

export const driversApplicationProgress = createApi({
  reducerPath: 'driversApplicationProgress',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/application-for-employment-progress`,
  }),
  tagTypes: ['DriversApplicationProgress'],
  keepUnusedDataFor: 60 * 10, // 10 minutes
  endpoints: (builder) => ({
    getApplicantBasicInfoProgress: builder.mutation<IProgress, number>({
      query: (id) => ({
        url: `/applicant-basicinfo/`,
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
    }),
    getApplicantInformationProgress: builder.mutation<IProgress, number>({
      query: (id) => ({
        url: `/applicant-information/`,
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
    }),
    getApplicantSignatureProgress: builder.mutation<IProgress, number>({
      query: (id) => ({
        url: `/applicant-signature/`,
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
    }),
    getProcessRecordProgress: builder.mutation<IProgress, number>({
      query: (id) => ({
        url: `/process-record/`,
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
    }),
    getTerminationOfEmploymentProgress: builder.mutation<IProgress, number>({
      query: (id) => ({
        url: `/termination-of-employment/`,
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
    }),
    getDriverApplicationProgress: builder.mutation<IProgress, number>({
      query: (id) => ({
        url: `/total-progress/`,
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
    }),
    getDriverMemberProgress: builder.mutation<IProgress, number>({
      query: (id) => ({
        url: `/member-progress/`,
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
    }),
  }),
});

export const {
  useGetApplicantBasicInfoProgressMutation,
  useGetApplicantInformationProgressMutation,
  useGetApplicantSignatureProgressMutation,
  useGetProcessRecordProgressMutation,
  useGetTerminationOfEmploymentProgressMutation,
  useGetDriverApplicationProgressMutation,
  useGetDriverMemberProgressMutation,
} = driversApplicationProgress;
