import { useQuery } from 'react-query';
import { useAuth } from '../../../../../app/modules/auth';
import { IFileObject } from '../../../../../models';
import { getExpiringFiles } from '../../../../../shared';
import TableContainer from '../components/TableContainer';
import TableContainerHeader from '../components/TableContainerHeader';
import TableContainerHeaderTitle from '../components/TableContainerHeaderTitle';
import TableHeader from '../components/TableHeader';
import TableHeaderColumn from '../components/TableHeaderColumn';
import TableWrapper from '../components/TableWrapper';
import ExpiringFileRow from './components/ExpiringFileRow';

const ExpiringFilesTable = () => {
    const { currentUser } = useAuth();
    const { data = [] } = useQuery<IFileObject[]>('expiring-files', async () =>
        getExpiringFiles(currentUser?.role === 'Owner' ? Number(currentUser?.company?.id) : undefined)
    );

    return (
        <TableWrapper>
            <TableContainerHeader>
                <TableContainerHeaderTitle title="Expiring Files" />
            </TableContainerHeader>
            {/* begin::Body */}
            <TableContainer>
                <TableHeader>
                    <TableHeaderColumn>Employee Name</TableHeaderColumn>
                    <TableHeaderColumn>Employee Email</TableHeaderColumn>
                    <TableHeaderColumn>File Title</TableHeaderColumn>
                    <TableHeaderColumn>Expiration Date</TableHeaderColumn>
                    <TableHeaderColumn>Category</TableHeaderColumn>
                </TableHeader>
                <tbody>
                    {data
                        ?.filter((item) => ['current', 'driver', 'drug'].includes(item.location.toLowerCase()))
                        ?.map((file) => {
                            return <ExpiringFileRow file={file} key={file.id} />;
                        })}
                </tbody>
            </TableContainer>
        </TableWrapper>
    );
};

export default ExpiringFilesTable;
