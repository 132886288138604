import { Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { sendLoginOtp, useNavigation, verifyMemberAccount, verifyOtp } from '../../../../shared';
import { useAuth } from '../core/Auth';

function maskEmail(email: string): string {
    return email ? email.replace(/^(.{5})/, '*****') : '';
}

const LoginOTP = () => {
    const [channel, setChannel] = useState('email');
    const [otpRoute, setOtpRoute] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [value, setValue] = useState('');
    const [otp, setOtp] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const { setCurrentUser, getUser, currentUser } = useAuth();
    const { navigateToDashboard } = useNavigation();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setChannel((event.target as HTMLInputElement).value);
        if (event.target.value === 'email') {
            setValue('email');
            setChannel('email');
            setOtpRoute(email);
        }
        if (event.target.value === 'sms') {
            setValue('sms');
            setChannel('sms');
            setOtpRoute(phoneNumber);
        }
    };

    const verifyAccount = async (email: string) => {
        const response = await verifyMemberAccount(email, '');
        if (response) {
            setEmail(response.email);
            setPhoneNumber(response.phone_number);
        } else {
            toast.warn('The email is not associated with any account');
        }
    };

    useEffect(() => {
        if (currentUser) {
            verifyAccount(currentUser.email);
        }
    }, [currentUser]);

    return (
        <div>
            <Typography variant="h5" textAlign="center" mb={4}>
                Login OTP
            </Typography>
            {!otpSent && (
                <>
                    <Typography>
                        A one time password is required. Would you like to receive the OTP via email or SMS?
                    </Typography>
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={value}
                            onChange={handleChange}
                        >
                            {email && (
                                <FormControlLabel
                                    value="email"
                                    control={<Radio />}
                                    label={`Send email to ${maskEmail(email)}`}
                                />
                            )}
                            {phoneNumber && (
                                <FormControlLabel
                                    value="sms"
                                    control={<Radio />}
                                    label={`Send text to ${maskEmail(phoneNumber)}`}
                                />
                            )}
                        </RadioGroup>
                    </FormControl>
                    <Button
                        variant="contained"
                        fullWidth
                        size="small"
                        color="primary"
                        onClick={async () => {
                            try {
                                const res = await sendLoginOtp(otpRoute, channel);
                                if (res) {
                                    toast.success('OTP sent successfully');
                                    setOtpSent(true);
                                } else {
                                    toast.error('Failed to send OTP');
                                }
                            } catch (error: any) {
                                console.log(error);
                                toast.error(error.message);
                            }
                        }}
                    >
                        Send OTP
                    </Button>
                </>
            )}
            {otpSent && (
                <>
                    <Typography>
                        Please enter the OTP sent to your {channel === 'email' ? 'Email' : 'Phone Number'}
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField
                                fullWidth
                                id="otp"
                                type="text"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                size="small"
                                placeholder="OTP"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Button
                                variant="contained"
                                fullWidth
                                size="small"
                                color="primary"
                                onClick={async () => {
                                    const res = await verifyOtp(otpRoute, otp);
                                    if (res) {
                                        getUser().then((res) => {
                                            setCurrentUser({ ...res[0], otpConfirmed: true });
                                        });
                                        navigateToDashboard();
                                    } else {
                                        toast.error('Failed to verify OTP');
                                    }
                                }}
                            >
                                Send OTP
                            </Button>
                        </Grid>
                    </Grid>
                </>
            )}
        </div>
    );
};

export default LoginOTP;
