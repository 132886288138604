import axios from 'axios';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.response.use((response) => {
  // Check Response Status
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  } else {
    return Promise.reject(response);
  }
});
