import { Box, Button, Container, Paper } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../app/modules/auth';
import { StripeProduct } from '../../../app/modules/models';
import { useCompany } from '../../../context/CompanyContext';
import { IMember } from '../../../models';
import { getCompanyAddOns, getCompanyMember, getSubscriptionPlans } from '../../../shared';
import { } from '../../../store/reducers/DriverReleaseForm/driver-release-form';
import {
    useGetMemberPSPDisclosureProgressQuery,
    useGetPSPDisclosureProgressQuery
} from '../../../store/reducers/PSPDisclosure/psp-disclosure';
import { useThemeMode } from '../../partials';
import ActionButtons from '../ActionButtons';
import FormHeaderCard from '../HeaderCard';
import PSPDisclosureForm from './PSPDisclosureForm';

const PSPDisclosure = () => {
    const { mode } = useThemeMode();
    const { id } = useParams();
    const [member, setMember] = useState<IMember>();
    const { currentCompany } = useCompany();
    const [value, setValue] = useState(0);
    const [products, setProducts] = useState<StripeProduct[]>([]);
    const [addons, setAddons] = useState<StripeProduct[]>([]);
    const [showMvr, setShowMvr] = useState<boolean>(false);
    const { currentUser } = useAuth();
    const { data: progress } = useGetPSPDisclosureProgressQuery(Number(member?.id)!, {
        skip: !member?.id,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    });
    const { data: memberProgress } = useGetMemberPSPDisclosureProgressQuery(Number(member?.id)!, {
        skip: !member?.id,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    });

    const packageExists = (packageName: string) => {
        return products.some((pkg) => pkg.name === packageName);
    };

    const getPlanOptions = useCallback(async () => {
        getSubscriptionPlans().then((res) => {
            const optionList: StripeProduct[] = [];
            if (member) {
                for (const pkg of currentCompany?.package ?? member?.company?.package) {
                    const option = res.data.find((x: any) => x.product_id === pkg.id);
                    optionList.push(option);
                }

                setProducts(optionList);
            }
        });
        getCompanyAddOns().then((res) => {
            setAddons(res);
        });
    }, [currentCompany?.package, member]);

    useEffect(() => {
        getPlanOptions();
        // eslint-disable-next-line
    }, [getPlanOptions]);

    useEffect(() => {
        if (id) {
            getCompanyMember(id).then((data) => {
                setMember(data);
            });
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (products.length > 0) {
            if (packageExists('Master Package') || addons.some((addon) => addon.name === 'Live MVR add-on')) {
                setShowMvr(true);
            } else {
                setShowMvr(false);
            }
        }
        // eslint-disable-next-line
    }, [products, addons]);
    return (
        <Container style={{ userSelect: 'none' }}>
            <Box
                sx={{
                    pl: 3,
                    pr: 3,
                    pt: 3,
                    m: 3,
                    borderRadius: 2,
                    backgroundColor: mode === 'dark' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    width: {
                        lg: '47vw',
                        md: '70vw',
                        sm: '80vw'
                    },
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }}
                component={Paper}
            >
                <FormHeaderCard
                    headerTitle="PSP Disclosure Form"
                    status={
                        progress && progress?.progress > 0 && progress?.progress < 100
                            ? 'In Progress'
                            : progress && progress.progress > 99
                            ? 'Completed'
                            : 'Not Started'
                    }
                    sectionCount={1}
                    itemCount={1}
                    totalProgress={progress ? progress.progress : 0}
                    memberProgress={memberProgress ? memberProgress.progress : 0}
                    nextButton={{ title: '', path: '' }}
                    member={member}
                    setValue={setValue}
                    value={value}
                />
            </Box>
            <Box
                sx={{
                    pl: 3,
                    pr: 3,
                    pt: 3,
                    m: 3,
                    borderRadius: 2,
                    backgroundColor: mode === 'dark' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    width: {
                        lg: '47vw',
                        md: '70vw',
                        sm: '80vw'
                    },
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }}
                component={Paper}
            >
                <PSPDisclosureForm member={member} />
            </Box>
            <ActionButtons
                nextButton={{
                    title: showMvr ? 'Drug and Alcohol Written Policy' : "Driver's Application for Employment",
                    path: showMvr ? 'written-drug-and-alcohol-policy' : 'driver-application-for-employment'
                }}
                member={member}
                prevButton={{
                    path: 'driver-release-form-background-checks',
                    title: "Driver's Release Form for Background Checks"
                }}
            />
            <div
                style={{
                    display: 'flex',
                    width: '47vw',
                    justifyContent: 'center',
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        if (currentUser?.role === 'Member') {
                            window.location.assign(`/dashboard/overview`);
                        } else {
                            if (member?.id) {
                                window.location.assign(`/member/${member?.id}/profile/driver-documents`);
                            }
                        }
                    }}
                    fullWidth
                >
                    Back to Forms Overview
                </Button>
            </div>
        </Container>
    );
};

export default PSPDisclosure;
