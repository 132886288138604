import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
  IAccidentRecord,
  IAccidentRecordDto,
} from '../../interfaces/EmploymentHistory/accident-record';

export interface IHasAccidentRecord {
  member: number;
  company: number;
  has_accident_record: boolean;
  id: number;
}

export type IHasAccidentRecordDto = {
  memberId: number;
  companyId: number;
  has_accident_record: boolean;
};

export const accidentRecordApi = createApi({
  reducerPath: 'accident_record',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/accident-record`,
  }),
  tagTypes: ['AccidentRecord'],
  keepUnusedDataFor: 60 * 10, // 10 minutes
  endpoints: (builder) => ({
    createAccidentRecord: builder.mutation<IAccidentRecord, IAccidentRecordDto>({
      query: (body) => ({
        url: '/',
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'AccidentRecord', id: 'AccidentRecord'}],
    }),
    updateAccidentRecord: builder.mutation<IAccidentRecord, IAccidentRecord>({
      query: (body) => ({
        url: `/${body.id}/`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'AccidentRecord', id: 'AccidentRecord'}],
    }),
    getAccidentRecord: builder.query<IAccidentRecord[], number | void>({
      query: (id) => ({
        url: '/get-accident-record/',
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'AccidentRecord', id: 'AccidentRecord'}],
    }),
    getAccidentRecordByUser: builder.query<IAccidentRecord[], void>({
      query: () => ({
        url: '/get-accident-record-by-user/',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'AccidentRecord', id: 'AccidentRecord'}],
    }),
    getAccidentRecordByUserId: builder.query<IAccidentRecord[], number | void>({
      query: (id) => ({
        url: '/get-accident-record-by-user-id/',
        method: 'POST',
        body: {
          userId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'AccidentRecord', id: 'AccidentRecord'}],
    }),
    deleteAccidentRecord: builder.mutation<void, number>({
      query: (id) => ({
        url: `/delete-accident-record/`,
        method: 'POST',
        body: {
          id: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
    }),
    getHasAccidentRecord: builder.query<IHasAccidentRecord, number | undefined>({
      query: (id) => ({
        url: `/get-has-accident-record/`,
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'AccidentRecord', id: 'AccidentRecord'}],
    }),
    hasAccidentRecord: builder.mutation<IHasAccidentRecord, Partial<IHasAccidentRecord>>({
      query: (body) => ({
        url: `/has-accident-record/`,
        method: 'POST',
        body: {
          member: body.member,
          company: body.company,
          has_accident_record: body.has_accident_record,
          id: body.id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'AccidentRecord', id: 'AccidentRecord'}],
    }),
  }),
});

export const {
  useCreateAccidentRecordMutation,
  useUpdateAccidentRecordMutation,
  useGetAccidentRecordQuery,
  useGetAccidentRecordByUserQuery,
  useGetAccidentRecordByUserIdQuery,
  useDeleteAccidentRecordMutation,
  useGetHasAccidentRecordQuery,
  useHasAccidentRecordMutation,
} = accidentRecordApi;
