import { Link } from 'react-router-dom';
import { KTSVG } from '../../../../../compliancemasters/helpers';
import { IMember } from '../../../../../models';
import { getCompanyMember } from '../../../../../shared';
import { useGetNonCurrentAnnualReviewOfDrivingRecordByUserIdQuery } from '../../../../../store/reducers/AnnualReviewOfDrivingRecord/annual-review-of-driving-record';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';

const AnnualReviewOfDrivingRecords = ({ id }: { id: number }) => {
    const [member, setMember] = useState<IMember>();

    const { data: review = [] } = useGetNonCurrentAnnualReviewOfDrivingRecordByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true
    });

    useEffect(() => {
        getCompanyMember(String(id)).then((res) => {
            setMember(res);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <tr>
            <td className="mw-125px">
                <span
                    className="text-dark fw-bold text-hover-primary mb-1 fs-6 cursor-pointer"
                    onClick={() => {
                        if (review.length > 0) {
                            window.location.assign(`/member/${id}/non-current/annual-review-of-driving-record`);
                        } else {
                            return toast.warning('There is no data in this form');
                        }
                    }}
                >
                    Annual Review of Driving Record
                </span>
            </td>
            <td>
                <div className="d-flex flex-column w-75 me-2"></div>
            </td>
            <td></td>
            <td className="mw-125px">
                <span className="text-success fw-bold cursor-pointer text-hover-primary mb-1 fs-6"></span>
            </td>
            {/* {(member && member?.pre_employment_screened) || currentUser?.member ? <span className='text-success fw-bold cursor-pointer text-hover-primary mb-1 fs-6'>Owner Signature Required</span> : null} */}
            <td className="text-end">
                <Link
                    className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                    to={`/member/${id}/non-current/annual-review-of-driving-record`}
                    onClick={(e) => {
                        e.preventDefault();
                        if (review.length > 0) {
                            window.location.assign(`/member/${id}/non-current/annual-review-of-driving-record`);
                        } else {
                            return toast.warning('There is no data in this form');
                        }
                    }}
                >
                    <KTSVG path="/media/icons/duotune/arrows/arr064.svg" className="svg-icon-2" />
                </Link>
            </td>
        </tr>
    );
};

export default AnnualReviewOfDrivingRecords;
