import { useEffect, useState } from 'react';
import { Box, Button, Container, Paper, Typography } from '@mui/material';
import { StatusTag, useThemeMode } from '../../partials';
import ProgressBar from '../../partials/widgets/statistics/ProgressBar';
import { useParams } from 'react-router-dom';
import BasicApplicantInformation from './BasicApplicantInformation';
import { getCompanyMember } from '../../../shared';
import { IMember } from '../../../models';
import DriverSignature from './DriverSignature';
import ProcessRecord from './ProcessRecord';
import TerminationOfEmployment from './TerminationOfEmployment';
import ApplicantInformation from './ApplicantInformation';
import { TabRow, links, TabPanel } from '../Tabs';
import ActionButtons from '../ActionButtons';
import {
    useGetDriverApplicationProgressMutation,
    useGetDriverMemberProgressMutation
} from '../../../store/reducers/DriversApplicationForEmployment/progress';
import { useAuth } from '../../../app/modules/auth';

const DriversApplicationForEmployment = () => {
    const { mode } = useThemeMode();
    const { currentUser } = useAuth();
    const [getApplicantMemberProgress, { data }] = useGetDriverMemberProgressMutation();
    const [getApplicantInformation, { data: applicantInfoProg }] = useGetDriverApplicationProgressMutation();
    const [member, setMember] = useState<IMember>();
    const { id } = useParams();
    const [value, setValue] = useState(0);

    useEffect(() => {
        if (id) {
            getCompanyMember(id).then((data) => {
                setMember(data);
            });
            getApplicantInformation(+id);
            getApplicantMemberProgress(+id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container style={{ userSelect: 'none' }}>
            <Box
                sx={{
                    pl: 3,
                    pr: 3,
                    pt: 3,
                    m: 3,
                    borderRadius: 2,
                    backgroundColor: mode === 'dark' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    width: '47vw',
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }}
                component={Paper}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ mr: 5 }} variant="h4" gutterBottom component="h4">
                        Drivers Application For Employment
                    </Typography>
                    <Typography sx={{ mb: 2 }} variant="h4" gutterBottom component="h4">
                        <StatusTag status="In Progress" />
                    </Typography>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ mr: 5 }} variant="h6" gutterBottom component="h6">
                        5 Sections
                    </Typography>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="h6"
                        gutterBottom
                        component="h6"
                    >
                        43 Items
                    </Typography>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ mr: 5 }} variant="h6" gutterBottom component="h6">
                        Total:
                    </Typography>
                    <ProgressBar
                        progress={applicantInfoProg ? applicantInfoProg.progress : 0}
                        size={10}
                        backgroundColor="bg-secondary"
                        direction="right"
                        fontSize={7}
                    />
                </div>
                <div style={{ display: 'flex', width: '100%' }}>
                    <Typography
                        sx={{ mr: 5, color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="subtitle1"
                        gutterBottom
                        component="span"
                    >
                        {member ? member?.user?.first_name : 'Member: '}{' '}
                        {member ? ` ${member?.user?.last_name} :` : null}
                    </Typography>
                    <ProgressBar
                        progress={data ? Number(data?.progress) : 0}
                        size={10}
                        backgroundColor="bg-secondary"
                        direction="right"
                        fontSize={7}
                    />
                </div>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            if (currentUser?.role === 'Member') {
                                window.location.assign(`/dashboard/overview`);
                            } else {
                                if (member?.id) {
                                    window.location.assign(`/member/${member?.id}/profile/driver-documents`);
                                }
                            }
                        }}
                    >
                        Back to Forms Overview
                    </Button>
                </div>
                <TabRow current={value} links={links} setCurrent={setValue} />
            </Box>
            <TabPanel value={value} index={0}>
                <Box
                    sx={{
                        pl: 3,
                        pr: 3,
                        Radius: 2,
                        backgroundColor: mode === 'dark' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                        width: {
                            lg: '47vw',
                            md: '70vw',
                            sm: '80vw'
                        },
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginBottom: 3
                    }}
                    component={Paper}
                >
                    <BasicApplicantInformation member={member} />
                </Box>
                <Box
                    sx={{
                        pl: 3,
                        pr: 3,
                        Radius: 2,
                        backgroundColor: mode === 'dark' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                        width: {
                            lg: '47vw',
                            md: '70vw',
                            sm: '80vw'
                        },
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginBottom: 3
                    }}
                    component={Paper}
                >
                    <DriverSignature member={member} />
                </Box>
                <Box
                    sx={{
                        pl: 3,
                        pr: 3,
                        Radius: 2,
                        backgroundColor: mode === 'dark' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                        width: {
                            lg: '47vw',
                            md: '70vw',
                            sm: '80vw'
                        },
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginBottom: 3
                    }}
                    component={Paper}
                >
                    {currentUser && currentUser.role !== 'Member' && <ProcessRecord member={member} />}
                </Box>
                <Box
                    sx={{
                        pl: 3,
                        pr: 3,
                        Radius: 2,
                        backgroundColor: mode === 'dark' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                        width: {
                            lg: '47vw',
                            md: '70vw',
                            sm: '80vw'
                        },
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginBottom: 3
                    }}
                    component={Paper}
                >
                    {currentUser && currentUser.role !== 'Member' && <TerminationOfEmployment member={member} />}
                </Box>
                <>
                    <ApplicantInformation member={member} />
                </>
                <ActionButtons
                    member={member}
                    nextButton={{
                        title: 'Employment History',
                        path: `employment-history`
                    }}
                    prevButton={{
                        title: 'Drivers Release Form For Background Checks',
                        path: 'driver-release-form-background-checks'
                    }}
                />
            </TabPanel>
            <div
                style={{
                    display: 'flex',
                    width: '47vw',
                    justifyContent: 'center',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: 10
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        if (currentUser?.role === 'Member') {
                            window.location.assign(`/dashboard/overview`);
                        } else {
                            if (member?.id) {
                                window.location.assign(`/member/${member?.id}/profile/driver-documents`);
                            }
                        }
                    }}
                    fullWidth
                >
                    Back to Forms Overview
                </Button>
            </div>
        </Container>
    );
};

export default DriversApplicationForEmployment;
