import { Button, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { useAuth } from '../../../app/modules/auth';
import { IMember } from '../../../models';
import { InspectionTextChoices } from '../../../store/interfaces/RecordOfRoadTest/backing-and-parking';
import { ISlowingAndStoppingDto } from '../../../store/interfaces/RecordOfRoadTest/slowing-and-stopping';
import {
    useCreateSlowingAndStoppingMutation,
    useDeleteSlowingAndStoppingMutation,
    useGetSlowingAndStoppingByUserIdQuery,
    useUpdateSlowingAndStoppingMutation
} from '../../../store/reducers/RecordOfRoadTest/slowing-and-stopping';
import { useThemeMode } from '../../partials';

const initialValues: Partial<ISlowingAndStoppingDto> = {
    uses_gears_properly_ascending: InspectionTextChoices.NA,
    gears_down_properly_descending: InspectionTextChoices.NA,
    stops_and_restarts_without_rolling_back: InspectionTextChoices.NA,
    test_brakes_before_descending_grades: InspectionTextChoices.NA,
    uses_brakes_properly_on_grades: InspectionTextChoices.NA,
    uses_mirrors_to_check_traffic_to_rear: InspectionTextChoices.NA,
    signals_following_traffic: InspectionTextChoices.NA,
    avoids_sudden_stops: InspectionTextChoices.NA,
    stops_smoothly_without_excessive_fanning: InspectionTextChoices.NA,
    stops_when_coming_out_of_driveway_or_alley: InspectionTextChoices.NA,
    stops_clear_of_pedestrian_crosswalks: InspectionTextChoices.NA,
    remarks: ''
};

const validationSchema = Yup.object().shape({
    uses_gears_properly_ascending: Yup.string().required('Required'),
    gears_down_properly_descending: Yup.string().required('Required'),
    stops_and_restarts_without_rolling_back: Yup.string().required('Required'),
    test_brakes_before_descending_grades: Yup.string().required('Required'),
    uses_brakes_properly_on_grades: Yup.string().required('Required'),
    uses_mirrors_to_check_traffic_to_rear: Yup.string().required('Required'),
    signals_following_traffic: Yup.string().required('Required'),
    avoids_sudden_stops: Yup.string().required('Required'),
    stops_smoothly_without_excessive_fanning: Yup.string().required('Required'),
    stops_when_coming_out_of_driveway_or_alley: Yup.string().required('Required'),
    stops_clear_of_pedestrian_crosswalks: Yup.string().required('Required')
});

interface IProps {
    member?: IMember;
    isDriverFile?: boolean;
}

export const SlowingAndStopping: React.FC<IProps> = ({ member, isDriverFile }) => {
    const { mode } = useThemeMode();
    const { currentUser } = useAuth();
    const { data } = useGetSlowingAndStoppingByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true
    });

    const [createSlowingAndStopping, { isLoading: isCreating }] = useCreateSlowingAndStoppingMutation();
    const [updateSlowingAndStopping, { isLoading: isUpdating }] = useUpdateSlowingAndStoppingMutation();
    const [deleteSlowingAndStopping] = useDeleteSlowingAndStoppingMutation();

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (data) {
                // @ts-ignore
                updateSlowingAndStopping({
                    ...values,
                    id: data.id
                });
            } else {
                // @ts-ignore
                createSlowingAndStopping({
                    ...values,
                    // @ts-ignore
                    member: member?.id,
                    // @ts-ignore
                    company: member?.company?.id
                });
            }
            resetForm();
        },
        validateOnBlur: true,
        validateOnChange: true
    });

    useEffect(() => {
        if (data) {
            formik.setValues(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const setAllAsSatisfactory = () => {
        formik.setValues({
            avoids_sudden_stops: InspectionTextChoices.SATISFACTORY,
            gears_down_properly_descending: InspectionTextChoices.SATISFACTORY,
            signals_following_traffic: InspectionTextChoices.SATISFACTORY,
            stops_and_restarts_without_rolling_back: InspectionTextChoices.SATISFACTORY,
            stops_clear_of_pedestrian_crosswalks: InspectionTextChoices.SATISFACTORY,
            stops_smoothly_without_excessive_fanning: InspectionTextChoices.SATISFACTORY,
            stops_when_coming_out_of_driveway_or_alley: InspectionTextChoices.SATISFACTORY,
            test_brakes_before_descending_grades: InspectionTextChoices.SATISFACTORY,
            uses_brakes_properly_on_grades: InspectionTextChoices.SATISFACTORY,
            uses_gears_properly_ascending: InspectionTextChoices.SATISFACTORY,
            uses_mirrors_to_check_traffic_to_rear: InspectionTextChoices.SATISFACTORY
        });
    };

    return (
        <div
            style={{
                padding: 10,
                margin: 10
            }}
        >
            <Typography
                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: 'center' }}
                variant="h4"
                gutterBottom
                component="h4"
            >
                Slowing and Stopping
            </Typography>
            <Typography
                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: 'justify' }}
                variant="body1"
                gutterBottom
                component="p"
            >
                For the items that apply, please mark the driver's performance accordingly. Explain unsatisfactory items
                under Remarks.
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Button size="small" fullWidth variant="contained" onClick={setAllAsSatisfactory}>
                        Set all As satisfactory
                    </Button>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Uses gears properly ascending
                    </Typography>
                    <TextField
                        fullWidth
                        id="uses_gears_properly_ascending"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.uses_gears_properly_ascending}
                        onChange={(e) => {
                            formik.setFieldValue('uses_gears_properly_ascending', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.uses_gears_properly_ascending &&
                                        Boolean(formik.errors.uses_gears_properly_ascending)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.uses_gears_properly_ascending &&
                                        Boolean(formik.errors.uses_gears_properly_ascending)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.uses_gears_properly_ascending &&
                                        Boolean(formik.errors.uses_gears_properly_ascending)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Gears down properly descending
                    </Typography>
                    <TextField
                        fullWidth
                        id="gears_down_properly_descending"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.gears_down_properly_descending}
                        onChange={(e) => {
                            formik.setFieldValue('gears_down_properly_descending', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.gears_down_properly_descending &&
                                        Boolean(formik.errors.gears_down_properly_descending)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.gears_down_properly_descending &&
                                        Boolean(formik.errors.gears_down_properly_descending)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.gears_down_properly_descending &&
                                        Boolean(formik.errors.gears_down_properly_descending)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Stops and restarts without rolling back
                    </Typography>
                    <TextField
                        fullWidth
                        id="stops_and_restarts_without_rolling_back"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.stops_and_restarts_without_rolling_back}
                        onChange={(e) => {
                            formik.setFieldValue('stops_and_restarts_without_rolling_back', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.stops_and_restarts_without_rolling_back &&
                                        Boolean(formik.errors.stops_and_restarts_without_rolling_back)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.stops_and_restarts_without_rolling_back &&
                                        Boolean(formik.errors.stops_and_restarts_without_rolling_back)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.stops_and_restarts_without_rolling_back &&
                                        Boolean(formik.errors.stops_and_restarts_without_rolling_back)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Tests brakes before descending grades
                    </Typography>
                    <TextField
                        fullWidth
                        id="test_brakes_before_descending_grades"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.test_brakes_before_descending_grades}
                        onChange={(e) => {
                            formik.setFieldValue('test_brakes_before_descending_grades', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.test_brakes_before_descending_grades &&
                                        Boolean(formik.errors.test_brakes_before_descending_grades)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.test_brakes_before_descending_grades &&
                                        Boolean(formik.errors.test_brakes_before_descending_grades)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.test_brakes_before_descending_grades &&
                                        Boolean(formik.errors.test_brakes_before_descending_grades)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Uses brakes properly on grades
                    </Typography>
                    <TextField
                        fullWidth
                        id="uses_brakes_properly_on_grades"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.uses_brakes_properly_on_grades}
                        onChange={(e) => {
                            formik.setFieldValue('uses_brakes_properly_on_grades', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.uses_brakes_properly_on_grades &&
                                        Boolean(formik.errors.uses_brakes_properly_on_grades)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.uses_brakes_properly_on_grades &&
                                        Boolean(formik.errors.uses_brakes_properly_on_grades)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.uses_brakes_properly_on_grades &&
                                        Boolean(formik.errors.uses_brakes_properly_on_grades)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Uses mirrors to check traffic to rear
                    </Typography>
                    <TextField
                        fullWidth
                        id="uses_mirrors_to_check_traffic_to_rear"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.uses_mirrors_to_check_traffic_to_rear}
                        onChange={(e) => {
                            formik.setFieldValue('uses_mirrors_to_check_traffic_to_rear', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.uses_mirrors_to_check_traffic_to_rear &&
                                        Boolean(formik.errors.uses_mirrors_to_check_traffic_to_rear)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.uses_mirrors_to_check_traffic_to_rear &&
                                        Boolean(formik.errors.uses_mirrors_to_check_traffic_to_rear)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.uses_mirrors_to_check_traffic_to_rear &&
                                        Boolean(formik.errors.uses_mirrors_to_check_traffic_to_rear)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Signals following traffic
                    </Typography>
                    <TextField
                        fullWidth
                        id="signals_following_traffic"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.signals_following_traffic}
                        onChange={(e) => {
                            formik.setFieldValue('signals_following_traffic', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.signals_following_traffic &&
                                        Boolean(formik.errors.signals_following_traffic)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.signals_following_traffic &&
                                        Boolean(formik.errors.signals_following_traffic)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.signals_following_traffic &&
                                        Boolean(formik.errors.signals_following_traffic)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Avoids sudden stops
                    </Typography>
                    <TextField
                        fullWidth
                        id="avoids_sudden_stops"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.avoids_sudden_stops}
                        onChange={(e) => {
                            formik.setFieldValue('avoids_sudden_stops', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.avoids_sudden_stops && Boolean(formik.errors.avoids_sudden_stops)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.avoids_sudden_stops && Boolean(formik.errors.avoids_sudden_stops)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.avoids_sudden_stops && Boolean(formik.errors.avoids_sudden_stops)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Stops smoothly without excessive fanning
                    </Typography>
                    <TextField
                        fullWidth
                        id="stops_smoothly_without_excessive_fanning"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.stops_smoothly_without_excessive_fanning}
                        onChange={(e) => {
                            formik.setFieldValue('stops_smoothly_without_excessive_fanning', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.stops_smoothly_without_excessive_fanning &&
                                        Boolean(formik.errors.stops_smoothly_without_excessive_fanning)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.stops_smoothly_without_excessive_fanning &&
                                        Boolean(formik.errors.stops_smoothly_without_excessive_fanning)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.stops_smoothly_without_excessive_fanning &&
                                        Boolean(formik.errors.stops_smoothly_without_excessive_fanning)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Stops before crossing sidewalk when coming out of driveway or alley
                    </Typography>
                    <TextField
                        fullWidth
                        id="stops_when_coming_out_of_driveway_or_alley"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.stops_when_coming_out_of_driveway_or_alley}
                        onChange={(e) => {
                            formik.setFieldValue('stops_when_coming_out_of_driveway_or_alley', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.stops_when_coming_out_of_driveway_or_alley &&
                                        Boolean(formik.errors.stops_when_coming_out_of_driveway_or_alley)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.stops_when_coming_out_of_driveway_or_alley &&
                                        Boolean(formik.errors.stops_when_coming_out_of_driveway_or_alley)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.stops_when_coming_out_of_driveway_or_alley &&
                                        Boolean(formik.errors.stops_when_coming_out_of_driveway_or_alley)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Stops clear of pedestrian crosswalks
                    </Typography>
                    <TextField
                        fullWidth
                        id="stops_clear_of_pedestrian_crosswalks"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.stops_clear_of_pedestrian_crosswalks}
                        onChange={(e) => {
                            formik.setFieldValue('stops_clear_of_pedestrian_crosswalks', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.stops_clear_of_pedestrian_crosswalks &&
                                        Boolean(formik.errors.stops_clear_of_pedestrian_crosswalks)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.stops_clear_of_pedestrian_crosswalks &&
                                        Boolean(formik.errors.stops_clear_of_pedestrian_crosswalks)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.stops_clear_of_pedestrian_crosswalks &&
                                        Boolean(formik.errors.stops_clear_of_pedestrian_crosswalks)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Remarks
                    </Typography>
                    <TextField
                        fullWidth
                        id="remarks"
                        size="small"
                        placeholder="Remarks"
                        multiline
                        rows={4}
                        variant="outlined"
                        value={formik.values.remarks}
                        onChange={(e) => {
                            formik.setFieldValue('remarks', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.remarks && Boolean(formik.errors.remarks) ? 'red' : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.remarks && Boolean(formik.errors.remarks) ? 'red' : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.remarks && Boolean(formik.errors.remarks) ? 'red' : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                {(!data || (data && currentUser && ['Admin', 'SuperAdmin'].includes(currentUser?.role))) &&
                !isDriverFile ? (
                    <Grid item xs={12} sm={12}>
                        <Button
                            fullWidth
                            disabled={isCreating || isUpdating || !!member?.terminated_at}
                            onClick={() => formik.handleSubmit()}
                            variant="contained"
                            color="primary"
                        >
                            Save
                        </Button>
                        {currentUser?.role !== 'Admin' && currentUser?.role !== 'SuperAdmin' && (
                            <Typography
                                sx={{ color: 'red', textAlign: 'center', mt: 2 }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Once you click save, you will not be able to edit this form.
                            </Typography>
                        )}
                    </Grid>
                ) : null}
                {data && currentUser?.role === 'SuperAdmin' && (
                    <Grid item xs={12} sm={12}>
                        <Button
                            fullWidth
                            disabled={!!member?.terminated_at}
                            onClick={() => {
                                const res = window.confirm('Are you sure you want to delete this form?');
                                if (res && data) {
                                    deleteSlowingAndStopping(data.id);
                                }
                            }}
                            variant="contained"
                            color="primary"
                        >
                            Delete Form
                        </Button>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};
