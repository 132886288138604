import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { KTSVG } from '../../../../compliancemasters/helpers';
import { StatusTag } from '../../../../compliancemasters/partials';
import ProgressBar from '../../../../compliancemasters/partials/widgets/statistics/ProgressBar';
import { DriverType, IMember } from '../../../../models';
import { getCompanyMember } from '../../../../shared';
import {
    useGetAnnualReviewOfDrivingRecordProgressMutation,
    useGetMemberAnnualReviewOfDrivingRecordProgressMutation
} from '../../../../store/reducers/AnnualReviewOfDrivingRecord/progress';
import { useAuth } from '../../auth';

const AnnualReviewOfDrivingRecords = ({ id }: { id: number }) => {
    const [progress, setProgress] = useState<number>(0);
    const [getProgress, { data }] = useGetAnnualReviewOfDrivingRecordProgressMutation();
    const [getMemberProgress, { data: pg }] = useGetMemberAnnualReviewOfDrivingRecordProgressMutation();
    const [member, setMember] = useState<IMember>();
    const { currentUser } = useAuth();

    useEffect(() => {
        getProgress(id);
        getMemberProgress(id);
        getCompanyMember(String(id)).then((res) => {
            setMember(res);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    console.log(data);

    useEffect(() => {
        if (currentUser && ['Admin', 'Owner', 'SuperAdmin'].includes(currentUser?.role) && data) {
            setProgress(data?.progress);
        } else if (pg) {
            setProgress(pg?.progress);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, pg]);

    return (
        <tr>
            <td className="mw-125px">
                <span
                    className="text-dark fw-bold text-hover-primary mb-1 fs-6 cursor-pointer"
                    onClick={() => window.location.assign(`/member/${id}/re-form/annual-review-of-driving-record`)}
                >
                    Annual Review of Driving Record
                </span>
            </td>
            <td>
                <div className="d-flex flex-column w-75 me-2">
                    <ProgressBar progress={progress ? progress : 0} size={6} />
                </div>
            </td>
            <td>
                <StatusTag
                    status={
                        progress && progress > 0 && progress < 100
                            ? 'In Progress'
                            : progress && progress > 99
                              ? 'Completed'
                              : 'Not Started'
                    }
                />
            </td>
            <td className="mw-125px">
                {((member && member?.driver_type === DriverType.EXISTING_DRIVER) ||
                    (member && member?.pre_employment_screened) ||
                    currentUser?.member) &&
                (!data || data?.progress !== 100) &&
                currentUser?.role !== 'Member' ? (
                    <span className="text-success fw-bold cursor-pointer text-hover-primary mb-1 fs-6">
                        Owner Signature Required
                    </span>
                ) : null}
            </td>
            <td className="text-end">
                <Link
                    className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                    to={`/member/${id}/re-form/annual-review-of-driving-record`}
                >
                    <KTSVG path="/media/icons/duotune/arrows/arr064.svg" className="svg-icon-2" />
                </Link>
            </td>
        </tr>
    );
};

export default AnnualReviewOfDrivingRecords;
