import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
  IAttemptsMade,
  IAttemptsMadeDto,
} from '../../interfaces/PreviousEmployeeSafetyPerformanceHistory/attempts-made';

export const attemptsMadeApi = createApi({
  reducerPath: 'attempts_made',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/prev-employee-attempts-made-and-signature`,
  }),
  tagTypes: ['AttemptsMade'],
  keepUnusedDataFor: 60 * 10, // 10 minutes
  endpoints: (builder) => ({
    createAttemptMade: builder.mutation<IAttemptsMade, IAttemptsMadeDto>({
      query: (body) => ({
        url: '/',
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'AttemptsMade', id: 'AttemptsMade'}],
    }),
    updateAttemptMade: builder.mutation<IAttemptsMade, IAttemptsMade>({
      query: (body) => ({
        url: `/${body.id}/`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
    }),
    deleteAttemptMade: builder.mutation<void, number>({
      query: (id) => ({
        url: `/${id}/`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'AttemptsMade', id: 'AttemptsMade'}],
    }),
    getAttemptMade: builder.query<IAttemptsMade, number | void>({
      query: (id) => ({
        url: `/get-attempts-made/`,
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'AttemptsMade', id: 'AttemptsMade'}],
    }),
    getAttemptMadeByUser: builder.query<IAttemptsMade, void>({
      query: () => ({
        url: '/get-attempts-made-by-user/',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'AttemptsMade', id: 'AttemptsMade'}],
    }),
    getAttemptMadeByUserId: builder.query<IAttemptsMade, number | void>({
      query: (id) => ({
        url: '/get-attempts-made-by-user-id/',
        method: 'POST',
        body: {
          userId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'AttemptsMade', id: 'AttemptsMade'}],
    }),
  }),
});

export const {
  useCreateAttemptMadeMutation,
  useUpdateAttemptMadeMutation,
  useGetAttemptMadeQuery,
  useGetAttemptMadeByUserQuery,
  useGetAttemptMadeByUserIdQuery,
  useDeleteAttemptMadeMutation,
} = attemptsMadeApi;
