import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { KTSVG } from '../../../../compliancemasters/helpers';
import { StatusTag } from '../../../../compliancemasters/partials';
import { IMember } from '../../../../models';
import { getCompanyMember } from '../../../../shared';
import { useGetDocsOfActualKnowledgeProgressMutation } from '../../../../store/reducers/DocumentationofActualKnowledgeofDOTDAndAViolation/progress';
import { useGetAffidavitByUserIdQuery } from '../../../../store/reducers/DocumentationofActualKnowledgeofDOTDAndAViolation/witness-and-owner-signature';
import { useAuth } from '../../auth';

const DocsOfActualKnowledge = ({ id }: { id: number }) => {
    const [member, setMember] = useState<IMember>();
    const { currentUser } = useAuth();
    const [getProgress, { data }] = useGetDocsOfActualKnowledgeProgressMutation();
    const { data: sign } = useGetAffidavitByUserIdQuery(member?.user?.id!, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true
    });

    useEffect(() => {
        getProgress(id);
        getCompanyMember(String(id)).then((res) => {
            setMember(res);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <tr>
            <td className="mw-125px">
                <span
                    className="text-dark fw-bold text-hover-primary mb-1 fs-6 cursor-pointer"
                    onClick={() =>
                        window.location.assign(
                            `/member/${id}/re-form/documentation-of-actual-knowledge-of-dot-d-and-a-violation`
                        )
                    }
                >
                    Documentation of Actual Knowledge of DOT D&A Violation
                </span>
            </td>
            <td>
                <div className="d-flex flex-column w-75 me-2">
                    {data && data?.progress > 98 && (
                        <StatusTag
                            status={
                                data?.progress && data?.progress > 0 && data?.progress < 100
                                    ? 'In Progress'
                                    : data?.progress && data?.progress > 99
                                      ? 'Completed'
                                      : 'Not Started'
                            }
                        />
                    )}
                </div>
            </td>
            <td></td>
            <td className="mw-125px">
                {/* {((member && member?.pre_employment_screened) || currentUser?.member) &&
                !sign &&
                currentUser?.role !== 'Member' ? (
                    <span className="text-success fw-bold cursor-pointer text-hover-primary mb-1 fs-6">
                        Owner Signature Required
                    </span>
                ) : null} */}
            </td>
            <td className="text-end">
                <Link
                    className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                    to={`/member/${id}/re-form/documentation-of-actual-knowledge-of-dot-d-and-a-violation`}
                >
                    <KTSVG path="/media/icons/duotune/arrows/arr064.svg" className="svg-icon-2" />
                </Link>
            </td>
        </tr>
    );
};

export default DocsOfActualKnowledge;
