import { AdminPanelSettingsRounded, MoreVertRounded } from '@mui/icons-material';
import {
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from '@mui/material';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import TableContainerHeader from '../../../compliancemasters/partials/widgets/tables/components/TableContainerHeader';
import TableContainerHeaderTitle from '../../../compliancemasters/partials/widgets/tables/components/TableContainerHeaderTitle';
import TableWrapper from '../../../compliancemasters/partials/widgets/tables/components/TableWrapper';
import { DriverType, ICompany } from '../../../models';
import { useSelector } from '../../../store';
import { StripeActions } from '../../../store/reducers/StripeProducts/StripeActions';
import { useAuth } from '../../modules/auth';
import ViewCompanyDetail from './CompanyDetailView';
import CompanyInvitationModal from './CompanyInvitationModal';
import CreateCompanyModal from './CreateCompanyModal';
import UpdateCompany from './UpdateCompany';

const MenuComponent = ({
    anchorEl,
    open,
    handleClose,
    company,
    getCompanies,
    setModalOpen,
    companyModalOpen,
    setCompanyModalOpen
}: {
    anchorEl: null | HTMLElement;
    open: boolean;
    handleClose: () => void;
    getCompanies: () => void;
    setModalOpen: (open: boolean) => void;
    company?: ICompany;
    companyModalOpen: boolean;
    setCompanyModalOpen: (open: boolean) => void;
}) => {
    return (
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            slotProps={{
                paper: {
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0
                        }
                    }
                }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItem
                onClick={() => {
                    setModalOpen(true);
                    handleClose();
                }}
            >
                <ListItemIcon>
                    <AdminPanelSettingsRounded fontSize="medium" />
                </ListItemIcon>
                Update Company
            </MenuItem>
            <MenuItem
                onClick={() => {
                    handleClose();
                    window.location.assign(`/add-drivers/${company?.id}`);
                }}
            >
                <ListItemIcon>
                    <AdminPanelSettingsRounded fontSize="medium" />
                </ListItemIcon>
                Add Drivers (Bulk)
            </MenuItem>
            <MenuItem
                onClick={() => {
                    setCompanyModalOpen(true);
                    handleClose();
                }}
            >
                <ListItemIcon>
                    <AdminPanelSettingsRounded fontSize="medium" />
                </ListItemIcon>
                View Company
            </MenuItem>
        </Menu>
    );
};
const CompanyMenuComponent = ({
    anchorEl,
    open,
    handleClose,
    setCompanyInvitationOpen,
    setCreateCompanyOpen
}: {
    anchorEl: null | HTMLElement;
    open: boolean;
    handleClose: () => void;
    setCompanyInvitationOpen: (open: boolean) => void;
    setCreateCompanyOpen: (open: boolean) => void;
}) => {
    return (
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            slotProps={{
                paper: {
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0
                        }
                    }
                }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItem
                onClick={() => {
                    setCompanyInvitationOpen(true);
                    handleClose();
                }}
            >
                Invite Company
            </MenuItem>
            <MenuItem
                onClick={() => {
                    setCreateCompanyOpen(true);
                    handleClose();
                }}
            >
                Create Company
            </MenuItem>
        </Menu>
    );
};

const CompanyList = () => {
    const { getCompanies } = useAuth();
    const [open, setOpen] = useState<boolean>(false);
    const [companies, setCompanies] = useState<ICompany[]>([]);
    const [company, setCompany] = useState<ICompany | undefined>(undefined);
    const [companyModalOpen, setCompanyModalOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [companyAnchorEl, setCompanyAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(anchorEl);
    const companyMenuOpen = Boolean(companyAnchorEl);
    const [companyInvitationOpen, setCompanyInvitationOpen] = useState<boolean>(false);
    const [createCompanyOpen, setCreateCompanyOpen] = useState<boolean>(false);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const { products } = useSelector((state) => state.stripeReducer.product);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const fetchCompanies = () => {
        getCompanies().then((res: ICompany[]) => {
            setCompanies(res);
        });
    };

    useEffect(() => {
        StripeActions.getProducts();
        StripeActions.getCompanySubscriptions();
        fetchCompanies();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    console.log('companies', companies);

    return (
        <div>
            <TableWrapper>
                <TableContainerHeader>
                    <TableContainerHeaderTitle title="Companies" />
                    <Tooltip title="Company Options">
                        <IconButton
                            onClick={(e) => {
                                setCompanyAnchorEl(e.currentTarget);
                            }}
                        >
                            <MoreVertRounded />
                        </IconButton>
                    </Tooltip>
                </TableContainerHeader>
                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell className="text-muted fw-bold fs-6" align="center">
                                    Company Name
                                </TableCell>
                                <TableCell className="text-muted fw-bold fs-6" align="center">
                                    Email
                                </TableCell>
                                <TableCell className="text-muted fw-bold fs-6" align="center">
                                    Opted Out Pre Employment
                                </TableCell>
                                <TableCell className="text-muted fw-bold fs-6" align="center">
                                    City
                                </TableCell>
                                {/* <TableCell className="text-muted fw-bold fs-6" align="center">
                                    Drivers Started With
                                </TableCell> */}
                                <TableCell className="text-muted fw-bold fs-6" align="center">
                                    Current Total
                                </TableCell>
                                <TableCell className="text-muted fw-bold fs-6" align="center">
                                    Drivers Hired
                                </TableCell>
                                <TableCell className="text-muted fw-bold fs-6" align="center">
                                    Drivers Terminated
                                </TableCell>
                                <TableCell className="text-muted fw-bold fs-6" align="center">
                                    Packages
                                </TableCell>
                                <TableCell className="text-muted fw-bold fs-6" align="center"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {companies?.map((company) => {
                                const terminatedDriversCount = company.members.filter((item) => item.terminated_at);

                                const activeDriversCount = company.members.filter(
                                    (item) =>
                                        !item.terminated_at &&
                                        (item.driver_type === DriverType.EXISTING_DRIVER ||
                                            (item.driver_type === DriverType.NEW_DRIVER &&
                                                item.pre_employment_screened))
                                ).length;

                                console.log('Active Drivers Count', activeDriversCount);
                                console.log('Terminated Drivers Count', terminatedDriversCount.length);
                                return (
                                    <TableRow
                                        key={company.id}
                                        className={clsx('text-hover-primary cursor-pointer')}
                                        onClick={() => {
                                            window.location.assign(`/company/${company.id}`);
                                        }}
                                    >
                                        <TableCell className="text-dark fw-bold fs-6" align="center">
                                            {company.name}
                                        </TableCell>
                                        <TableCell className="text-dark fw-bold fs-6" align="center">
                                            {company.user?.email}
                                        </TableCell>
                                        <TableCell className="text-dark fw-bold fs-6" align="center">
                                            {company.opted_out_of_pre_employment ? 'Yes' : 'No'}
                                        </TableCell>
                                        <TableCell className="text-dark fw-bold fs-6" align="center">
                                            {company.city}
                                        </TableCell>
                                        <TableCell className="text-dark fw-bold fs-6" align="center">
                                            {company?.members?.length}
                                        </TableCell>
                                        <TableCell className="text-dark fw-bold fs-6" align="center">
                                            {activeDriversCount}
                                        </TableCell>
                                        <TableCell className="text-dark fw-bold fs-6" align="center">
                                            {terminatedDriversCount.length}
                                        </TableCell>
                                        <TableCell className="text-dark fw-bold fs-6" align="center">
                                            {company.package?.length}
                                        </TableCell>
                                        <TableCell className="text-dark fw-bold fs-6" align="center">
                                            <IconButton
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setCompany(company);
                                                    handleClick(e);
                                                }}
                                            >
                                                <MoreVertRounded />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </TableWrapper>
            <MenuComponent
                getCompanies={fetchCompanies}
                companyModalOpen={companyModalOpen}
                setCompanyModalOpen={setCompanyModalOpen}
                setModalOpen={setOpen}
                company={company}
                anchorEl={anchorEl}
                open={menuOpen}
                handleClose={handleClose}
            />
            <CompanyMenuComponent
                setCompanyInvitationOpen={setCompanyInvitationOpen}
                setCreateCompanyOpen={setCreateCompanyOpen}
                anchorEl={companyAnchorEl}
                open={companyMenuOpen}
                handleClose={() => setCompanyAnchorEl(null)}
            />
            <UpdateCompany
                open={open}
                setOpen={setOpen}
                company={company}
                getCompanies={fetchCompanies}
                products={products}
            />
            {company && <ViewCompanyDetail company={company} open={companyModalOpen} setOpen={setCompanyModalOpen} />}
            <CompanyInvitationModal open={companyInvitationOpen} setOpen={setCompanyInvitationOpen} />
            <CreateCompanyModal open={createCompanyOpen} setOpen={setCreateCompanyOpen} />
        </div>
    );
};

export default CompanyList;
