import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {IProgress} from '../../interfaces/abstract';

export const driverMedicalExaminerRegistryVerificationProgress = createApi({
  reducerPath: 'driverMedicalExaminerRegistryVerificationProgress',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/driver-medical-examiner-verification-progress`,
  }),
  tagTypes: ['DriverMedicalExaminerRegistryVerificationProgress'],
  keepUnusedDataFor: 60 * 10, // 10 minutes
  endpoints: (builder) => ({
    getDriverMedicalVerificationProgress: builder.mutation<IProgress, number>({
      query: (id) => ({
        url: `/total-progress/`,
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
    }),
    getMemberDriverMedicalVerificationProgress: builder.mutation<IProgress, number>({
      query: (id) => ({
        url: `/member-progress/`,
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
    }),
  }),
});

export const {
  useGetDriverMedicalVerificationProgressMutation,
  useGetMemberDriverMedicalVerificationProgressMutation,
} = driverMedicalExaminerRegistryVerificationProgress;
