import { useEffect, useState } from 'react';
import { IMember } from '../../../../../models';
import { useAuth } from '../../../../../app/modules/auth';

interface FileCategory {
    id: number;
    title: string;
}

interface IProps {
    value: number;
    onChange: (value: number) => void;
    member?: IMember;
    categories?: FileCategory[];
}

export default function FileCategoryDropdown({ value, onChange, member, categories }: IProps) {
    const [categoryList, setCategoryList] = useState<FileCategory[]>([]);
    const { currentUser } = useAuth();

    useEffect(() => {
        if (currentUser && currentUser?.role === 'Owner' && categories) {
            setCategoryList(
                categories?.filter((item) => item.id !== 23 || item?.title.toLowerCase().includes('pre-employment'))
            );
        } else if (currentUser && currentUser?.role !== 'Owner' && categories) {
            setCategoryList(categories);
        }
    }, [categories, currentUser, member]);

    return (
        <select
            className="form-select form-select-solid"
            aria-label="Select example"
            value={value}
            onChange={(e) => onChange(Number(e.target.value))}
        >
            <option>Open this select menu</option>
            {categoryList /* .filter((item) => item.id !== 23)? */
                .map((category) => (
                    <option key={category.id} value={category.id}>
                        {category.title}
                    </option>
                ))}
        </select>
    );
}
