import { useEffect, useCallback, useState, forwardRef } from 'react';
import Button from '@mui/material/Button';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Box, Checkbox, Chip, FormControlLabel, Grid, InputLabel, MenuItem, Paper, TextField } from '@mui/material';
import { useMutation, useQuery } from 'react-query';
import { IBulkMemberRequestInput, IMember, IRequest } from '../../../../../models';
import { createBulkMemberRequest, getRequestTypes } from '../../../../../shared/client/memberRequest';
import { createCheckoutSessionMemberRequest, getCompanyMembers, getMemberRequestProducts } from '../../../../../shared';
import { useAuth } from '../../../../../app/modules/auth';
import { toast } from 'react-toastify';
import { StripeProduct } from '../../../../../app/modules/models';

interface IProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    memberSubscriptions?: StripeProduct[];
}

const initialValues: IBulkMemberRequestInput = {
    members: [],
    type: 0,
    requires_payment: true,
    all_drivers: false
};

const memberRequestSchema = Yup.object().shape({
    members: Yup.array().min(1).required('Members are required'),
    type: Yup.number().min(1).required('Request Type is required')
});

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function NewRequestModal({ open, setOpen }: Readonly<IProps>) {
    const [allSelected, setAllSelected] = useState<boolean>(false);
    const [selectedPlan, setSelectedPlan] = useState<StripeProduct>();
    const [subscriptions, setSubscriptions] = useState<Array<StripeProduct>>([]);
    // const [requestTypes, setRequestTypes] = useState<IRequest[]>([]);
    const { currentUser } = useAuth();

    const { data: types = [] } = useQuery<IRequest[]>('requestTypes', async () => getRequestTypes(), {
        refetchOnWindowFocus: false
    });

    const { data: memberList = [] } = useQuery<IMember[]>(
        'members',
        async () => getCompanyMembers(String(currentUser?.company?.id)),
        {
            refetchOnWindowFocus: false,
            enabled: !!currentUser?.company?.id
        }
    );

    const handleClose = () => {
        setAllSelected(false);
        formik.resetForm();
        setOpen(false);
    };

    const checkout = async (id: number) => {
        if (selectedPlan) {
            const url = await createCheckoutSessionMemberRequest(
                selectedPlan.price_id,
                selectedPlan.type,
                selectedPlan.tiers_mode,
                id,
                formik.values.members.length,
                true
            );
            window.open(url.url, '_self');
        }
    };

    const getPlanOptions = useCallback(async () => {
        try {
            const res = await getMemberRequestProducts();
            const lookupKeyItems = res.data.filter((item: any) => item.lookup_key);
            const itemsWithOutLookupKey: any[] = [];
            for (const item of res.data as any) {
                if (!item.lookup_key) {
                    const exists = lookupKeyItems.find((lookup: any) => lookup.name === item.name);
                    if (!exists) {
                        itemsWithOutLookupKey.push(item);
                    }
                }
            }
            const itemsList = [...lookupKeyItems, ...itemsWithOutLookupKey];
            setSubscriptions(itemsList);
        } catch (error) {
            console.log(error);
        }
    }, []);

    const memberRequestMutation = useMutation(createBulkMemberRequest, {
        onSuccess: (data: any) => {
            if (formik.values.requires_payment) {
                checkout(data.id).then(() => {
                    toast.success('Requests created successfully');
                    handleClose();
                });
            } else {
                toast.success('Requests created successfully');
                handleClose();
            }
        }
    });

    const formik = useFormik({
        initialValues,
        validationSchema: memberRequestSchema,
        onSubmit: async (values) => {
            memberRequestMutation.mutate({
                ...values,
                all_drivers: values.members.length === memberList.length
            });
        }
    });

    useEffect(() => {
        if (allSelected) {
            formik.setFieldValue(
                'members',
                memberList.map((x) => x.id)
            );
            formik.setFieldValue('all_drivers', true);
        } else {
            formik.setFieldValue('members', []);
            formik.setFieldValue('all_drivers', false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allSelected]);

    useEffect(() => {
        getPlanOptions();
    }, [getPlanOptions]);

    // useEffect(() => {
    //     // Add filter
    // }, [types]);

    return (
        <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle sx={{ textAlign: 'center' }}>New Bulk Request</DialogTitle>
            <DialogContent>
                <Grid container spacing={3} p={2}>
                    <Grid item xs={12}>
                        <InputLabel>Select Request Type</InputLabel>
                        <TextField
                            select
                            fullWidth
                            size="small"
                            value={formik.values.type}
                            onChange={(e) => {
                                const item = types.find((x) => x.id === Number(e.target.value));
                                if (item) {
                                    formik.setFieldValue('type', item.id);
                                    formik.setFieldValue('requires_payment', item.requires_payment);
                                    setSelectedPlan(
                                        subscriptions?.find(
                                            (item) =>
                                                item.name ===
                                                types?.find((item) => item.id === Number(e.target.value))?.name
                                        )
                                    );
                                }
                            }}
                        >
                            {types
                                .filter((item) =>
                                    ['Motor Vehicle Record', 'DACH Query', 'Background Check'].includes(item.name)
                                )
                                .map((reqType) => (
                                    <MenuItem key={reqType.id} value={reqType.id}>
                                        {reqType?.name}
                                    </MenuItem>
                                ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel>Select Members</InputLabel>
                        <TextField
                            select
                            fullWidth
                            size="small"
                            onChange={(e) => {
                                const members = formik.values.members ?? [];
                                formik.setFieldValue('members', [...members, Number(e.target.value)]);
                            }}
                        >
                            {memberList
                                .filter((x) => !formik.values.members?.includes(Number(x.id)))
                                .map((member) => (
                                    <MenuItem key={member.id} value={member.id}>
                                        {member?.user.first_name} {member?.user.last_name}
                                    </MenuItem>
                                ))}
                        </TextField>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <FormControlLabel
                            control={<Checkbox onClick={() => setAllSelected(!allSelected)} checked={allSelected} />}
                            label="Select All"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box
                            component={Paper}
                            p={2}
                            elevation={2}
                            boxShadow={2}
                            sx={{
                                maxHeight: '200px',
                                height: '200px',
                                overflowY: 'auto'
                            }}
                        >
                            {formik.values.members?.map((memberId) => {
                                const member = memberList.find((x) => Number(x.id) === memberId);
                                return (
                                    <Chip
                                        key={member?.id}
                                        label={`${member?.user.first_name} ${member?.user.last_name}`}
                                        onDelete={() => {
                                            const members = formik.values.members ?? [];
                                            formik.setFieldValue(
                                                'members',
                                                members.filter((x) => x !== memberId)
                                            );
                                            setAllSelected(false);
                                        }}
                                        sx={{ margin: '5px' }}
                                    />
                                );
                            })}
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Button onClick={formik.submitForm} variant="contained" color="primary" fullWidth>
                            Submit
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button onClick={handleClose} variant="contained" color="error" fullWidth>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
