import {INotification} from '../../../models/INotification';
import {markAsClicked} from '../../client/notifications';

interface IProps {
  notification: INotification;
  navigate: (path: string) => void;
}
export function handleAction({notification}: IProps) {
  markAsClicked(notification.id);
  // eslint-disable-next-line array-callback-return
  notification.actions.map((action) => {
    if (action.action === 'navigate') {
      window.location.assign(action.path);
    }
  });
}
