import { Button, Grid, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { useAuth } from '../../../app/modules/auth';
import { IMember } from '../../../models';
import { changeStringLast4 } from '../../../shared';
import { ICertificateOfRoadTestDto } from '../../../store/interfaces/CertificateOfRoadTest/certificate-of-road-test';
import {
    useCreateCertificateOfRoadTestMutation,
    useGetCertificateOfRoadTestByUserIdQuery,
    useUpdateCertificateOfRoadTestMutation
} from '../../../store/reducers/CertificateOfRoadTest/certificate-of-road-test';
import { useGetDriverInformationByUserIdQuery } from '../../../store/reducers/RecordOfRoadTest/driver-information';
import { useThemeMode } from '../../partials';

const initialValues: Partial<ICertificateOfRoadTestDto> = {
    drivers_name: '',
    date_of_road_test: '',
    type_of_power_unit: '',
    social_security_number: '',
    type_of_trailers: '',
    operators_or_chauffeurs_license_number: '',
    state: '',
    type_of_bus: '',
    date: '',
    miles_driven: '',
    signature_of_examiner: '',
    organization: '',
    title: '',
    address_of_examiner: ''
};

const validationSchema = Yup.object({
    drivers_name: Yup.string().required('Required'),
    date_of_road_test: Yup.string().required('Required'),
    type_of_power_unit: Yup.string().required('Required'),
    social_security_number: Yup.string().required('Required'),
    type_of_trailers: Yup.string().required('Required'),
    operators_or_chauffeurs_license_number: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    type_of_bus: Yup.string(),
    date: Yup.string().required('Required'),
    miles_driven: Yup.string().required('Required'),
    signature_of_examiner: Yup.string().required('Required'),
    organization: Yup.string().required('Required'),
    title: Yup.string().required('Required'),
    address_of_examiner: Yup.string().required('Required')
});

interface IProps {
    member?: IMember;
    isDriverFile?: boolean;
}

const CertificateOfRoadTestComponent = ({ member, isDriverFile }: IProps) => {
    const { mode } = useThemeMode();
    const { currentUser } = useAuth();
    const { data } = useGetCertificateOfRoadTestByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true
    });

    const { data: recordOfRoadTest } = useGetDriverInformationByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    });

    const [createCertificateOfRoadTest, { isLoading: isCreating }] = useCreateCertificateOfRoadTestMutation();
    const [updateCertificateOfRoadTest, { isLoading: isUpdating }] = useUpdateCertificateOfRoadTestMutation();

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (data) {
                // @ts-ignore
                updateCertificateOfRoadTest({
                    ...values,
                    id: data.id
                });
            } else {
                createCertificateOfRoadTest({
                    ...values,
                    // @ts-ignore
                    member: member?.id,
                    // @ts-ignore
                    company: member?.company?.id
                });
            }
            resetForm();
        },
        validateOnBlur: true,
        validateOnChange: true
    });

    useEffect(() => {
        if (data) {
            formik.setValues({
                ...data,
                operators_or_chauffeurs_license_number: data?.operators_or_chauffeurs_license_number,
                social_security_number: data?.social_security_number
            });
        } else {
            formik.setValues({
                ...initialValues,
                drivers_name: `${member?.user?.first_name} ${member?.user?.last_name}`,
                social_security_number: member?.social_security_number,
                state: member?.state,
                operators_or_chauffeurs_license_number: member?.company?.user?.drivers_license_number
            });
        }
        if (recordOfRoadTest) {
            formik.setFieldValue('date_of_road_test', recordOfRoadTest.date_of_road_test);
            formik.setFieldValue('date', recordOfRoadTest.date_of_road_test);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, member, recordOfRoadTest]);

    return (
        <div
            style={{
                padding: 10,
                margin: 10,
                userSelect: 'none'
            }}
        >
            <Typography
                sx={{ textAlign: 'center', color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)' }}
                variant="h4"
                gutterBottom
                component="h4"
            >
                Certification of Road Test
            </Typography>
            <Typography
                sx={{ textAlign: 'justify', color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)' }}
                variant="body1"
                gutterBottom
                component="p"
            >
                NOTE for Motor Carrier: Section 391.31 - Once the road test is successfully completed, the examiner must
                complete the Certificate of Driver's Road Test. The original form of the road test will be placed into
                the driver qualification file and the certification or card is given to the driver who was examined.
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Employee's Name
                    </Typography>
                    <TextField
                        fullWidth
                        id="drivers_name"
                        size="small"
                        placeholder="Employee's Name"
                        variant="outlined"
                        value={formik.values.drivers_name}
                        // onChange={(e) => {
                        //     formik.setFieldValue('drivers_name', e.target.value)
                        // }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.drivers_name && Boolean(formik.errors.drivers_name)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.drivers_name && Boolean(formik.errors.drivers_name)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.drivers_name && Boolean(formik.errors.drivers_name)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Date of Road Test
                    </Typography>
                    <TextField
                        fullWidth
                        id="date_of_road_test"
                        size="small"
                        placeholder="Date of Road Test"
                        type="date"
                        variant="outlined"
                        value={formik.values.date_of_road_test}
                        onChange={(e) => {
                            formik.setFieldValue('date_of_road_test', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.date_of_road_test && Boolean(formik.errors.date_of_road_test)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.date_of_road_test && Boolean(formik.errors.date_of_road_test)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.date_of_road_test && Boolean(formik.errors.date_of_road_test)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Type of Power Unit (If passenger carrier, type none)
                    </Typography>
                    <TextField
                        fullWidth
                        id="type_of_power_unit"
                        size="small"
                        placeholder="Type of Power Unit"
                        variant="outlined"
                        value={formik.values.type_of_power_unit}
                        onChange={(e) => {
                            formik.setFieldValue('type_of_power_unit', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.type_of_power_unit && Boolean(formik.errors.type_of_power_unit)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.type_of_power_unit && Boolean(formik.errors.type_of_power_unit)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.type_of_power_unit && Boolean(formik.errors.type_of_power_unit)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Social Security Number
                    </Typography>
                    <TextField
                        fullWidth
                        id="social_security_number"
                        size="small"
                        placeholder="Social Security Number"
                        variant="outlined"
                        value={
                            ['Member', 'Owner'].includes(currentUser?.role!)
                                ? changeStringLast4(String(formik?.values?.social_security_number), '*')!
                                : data?.social_security_number
                        }
                        onChange={(e) => {
                            formik.setFieldValue('social_security_number', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.social_security_number &&
                                        Boolean(formik.errors.social_security_number)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.social_security_number &&
                                        Boolean(formik.errors.social_security_number)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.social_security_number &&
                                        Boolean(formik.errors.social_security_number)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Type of Trailer(s)
                    </Typography>
                    <TextField
                        fullWidth
                        id="type_of_trailers"
                        size="small"
                        placeholder="Type of Trailer(s)"
                        variant="outlined"
                        value={formik.values.type_of_trailers}
                        onChange={(e) => {
                            formik.setFieldValue('type_of_trailers', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.type_of_trailers && Boolean(formik.errors.type_of_trailers)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.type_of_trailers && Boolean(formik.errors.type_of_trailers)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.type_of_trailers && Boolean(formik.errors.type_of_trailers)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Operator's or Chauffeur's License Number`
                    </Typography>
                    <TextField
                        fullWidth
                        id="operators_or_chauffeurs_license_number"
                        size="small"
                        placeholder={`Operator's or Chauffeur's License Number`}
                        variant="outlined"
                        value={
                            ['Member', 'Owner'].includes(currentUser?.role!)
                                ? changeStringLast4(String(formik.values.operators_or_chauffeurs_license_number), '*')!
                                : formik.values.operators_or_chauffeurs_license_number
                        }
                        onChange={(e) => {
                            formik.setFieldValue('operators_or_chauffeurs_license_number', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.operators_or_chauffeurs_license_number &&
                                        Boolean(formik.errors.operators_or_chauffeurs_license_number)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.operators_or_chauffeurs_license_number &&
                                        Boolean(formik.errors.operators_or_chauffeurs_license_number)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.operators_or_chauffeurs_license_number &&
                                        Boolean(formik.errors.operators_or_chauffeurs_license_number)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        State
                    </Typography>
                    <TextField
                        fullWidth
                        id="state"
                        size="small"
                        placeholder={`State`}
                        variant="outlined"
                        value={formik.values.state}
                        onChange={(e) => {
                            formik.setFieldValue('state', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.state && Boolean(formik.errors.state) ? 'red' : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.state && Boolean(formik.errors.state) ? 'red' : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.state && Boolean(formik.errors.state) ? 'red' : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        If Passenger Carrier, Type of Bus
                    </Typography>
                    <TextField
                        fullWidth
                        id="type_of_bus"
                        size="small"
                        placeholder={`If Passenger Carrier, Type of Bus`}
                        variant="outlined"
                        value={formik.values.type_of_bus}
                        onChange={(e) => {
                            formik.setFieldValue('type_of_bus', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.type_of_bus && Boolean(formik.errors.type_of_bus)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.type_of_bus && Boolean(formik.errors.type_of_bus)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.type_of_bus && Boolean(formik.errors.type_of_bus)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        This is to certify that the driver named above was given a road test under my supervision on
                        Date
                    </Typography>
                    <TextField
                        fullWidth
                        id="date"
                        size="small"
                        variant="outlined"
                        value={formik.values.date}
                        type="date"
                        onChange={(e) => {
                            formik.setFieldValue('date', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? 'red' : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? 'red' : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? 'red' : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Consisting of approximately this many miles of driving
                    </Typography>
                    <TextField
                        fullWidth
                        id="miles_driven"
                        placeholder="Consiting of approximately this many miles of driving"
                        size="small"
                        variant="outlined"
                        value={formik.values.miles_driven}
                        onChange={(e) => {
                            formik.setFieldValue('miles_driven', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.miles_driven && Boolean(formik.errors.miles_driven)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.miles_driven && Boolean(formik.errors.miles_driven)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.miles_driven && Boolean(formik.errors.miles_driven)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        It is my considered opinion that this driver possesses sufficient driving skill to operate
                        safely the type of commercial motor vehicle listed above.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Signature of Examiner
                    </Typography>
                    <TextField
                        fullWidth
                        id="signature_of_examiner"
                        size="small"
                        variant="outlined"
                        value={formik.values.signature_of_examiner}
                        placeholder="Signature of Examiner"
                        onChange={(e) => {
                            formik.setFieldValue('signature_of_examiner', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.signature_of_examiner &&
                                        Boolean(formik.errors.signature_of_examiner)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.signature_of_examiner &&
                                        Boolean(formik.errors.signature_of_examiner)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.signature_of_examiner &&
                                        Boolean(formik.errors.signature_of_examiner)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                fontStyle: 'italic'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Organization
                    </Typography>
                    <TextField
                        fullWidth
                        id="organization"
                        size="small"
                        variant="outlined"
                        value={formik.values.organization}
                        placeholder="Organization"
                        onChange={(e) => {
                            formik.setFieldValue('organization', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.organization && Boolean(formik.errors.organization)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.organization && Boolean(formik.errors.organization)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.organization && Boolean(formik.errors.organization)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Title
                    </Typography>
                    <TextField
                        fullWidth
                        id="title"
                        size="small"
                        variant="outlined"
                        value={formik.values.title}
                        placeholder="Title"
                        onChange={(e) => {
                            formik.setFieldValue('title', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.title && Boolean(formik.errors.title) ? 'red' : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.title && Boolean(formik.errors.title) ? 'red' : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.title && Boolean(formik.errors.title) ? 'red' : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Address of Examiner
                    </Typography>
                    <TextField
                        fullWidth
                        id="address_of_examiner"
                        size="small"
                        variant="outlined"
                        value={formik.values.address_of_examiner}
                        placeholder="Address of Examiner"
                        onChange={(e) => {
                            formik.setFieldValue('address_of_examiner', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.address_of_examiner && Boolean(formik.errors.address_of_examiner)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.address_of_examiner && Boolean(formik.errors.address_of_examiner)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.address_of_examiner && Boolean(formik.errors.address_of_examiner)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                {(!data || (data && currentUser && ['Admin', 'SuperAdmin'].includes(currentUser?.role))) &&
                !isDriverFile ? (
                    <Grid item xs={12} sm={12}>
                        <Button
                            fullWidth
                            disabled={isCreating || isUpdating || !!member?.terminated_at}
                            onClick={() => formik.handleSubmit()}
                            variant="contained"
                            color="primary"
                        >
                            Save
                        </Button>
                        {currentUser?.role !== 'Admin' && currentUser?.role !== 'SuperAdmin' && (
                            <Typography
                                sx={{ color: 'red', textAlign: 'center', mt: 2 }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Once you click save, you will not be able to edit this form.
                            </Typography>
                        )}
                    </Grid>
                ) : null}
            </Grid>
        </div>
    );
};

export default CertificateOfRoadTestComponent;
