import { Button, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { useAuth } from '../../../app/modules/auth';
import { IMember } from '../../../models';
import { InspectionTextChoices } from '../../../store/interfaces/RecordOfRoadTest/backing-and-parking';
import { OperatingInTrafficPassingAndTurningDto } from '../../../store/interfaces/RecordOfRoadTest/operating-in-traffic-passing-and-turning';
import {
    useCreateOperatingInTrafficPassingAndTurningMutation,
    useDeleteOperatingInTrafficPassingAndTurningMutation,
    useGetOperatingInTrafficPassingAndTurningByUserIdQuery,
    useUpdateOperatingInTrafficPassingAndTurningMutation
} from '../../../store/reducers/RecordOfRoadTest/operating-in-traffic-passing-turning';
import { useThemeMode } from '../../partials';

const initialValues: Partial<OperatingInTrafficPassingAndTurningDto> = {
    signals_intention_to_turn_well_in_advance: InspectionTextChoices.NA,
    gets_into_proper_lane_before_turning: InspectionTextChoices.NA,
    checks_traffic_before_turning: InspectionTextChoices.NA,
    restricts_traffic_from_passing_on_right_when_turning_right: InspectionTextChoices.NA,
    approaches_signal_prepared_to_stop: InspectionTextChoices.NA,
    obeys_traffic_signals: InspectionTextChoices.NA,
    uses_good_judgement_on_yellow_light: InspectionTextChoices.NA,
    starts_smoothly_on_green: InspectionTextChoices.NA,
    noties_and_heeds_traffic_signs: InspectionTextChoices.NA,
    obeys_stop_signs: InspectionTextChoices.NA,
    adjusts_speed_to_permit_stopping: InspectionTextChoices.NA,
    checks_for_cross_traffic_regardless_of_signals: InspectionTextChoices.NA,
    yields_right_of_way_for_safety: InspectionTextChoices.NA,
    adjusts_speed_to_conditions: InspectionTextChoices.NA,
    makes_safe_stop_if_required: InspectionTextChoices.NA,
    selects_proper_gear_and_does_not_shift_gears_while_crossing: InspectionTextChoices.NA,
    knows_and_understands_rules_governing_grade_crossing: InspectionTextChoices.NA,
    passes_with_sufficient_clear_space_ahead: InspectionTextChoices.NA,
    does_not_pass_in_an_unsafe_location: InspectionTextChoices.NA,
    signals_change_of_lanes: InspectionTextChoices.NA,
    warns_driver_being_passed: InspectionTextChoices.NA,
    pulls_out_and_back_with_certainty: InspectionTextChoices.NA,
    does_not_tailgate: InspectionTextChoices.NA,
    does_not_block_traffic_with_slow_pass: InspectionTextChoices.NA,
    allows_enough_room_when_returning_to_right_lane: InspectionTextChoices.NA,
    speed_consistent_with_basic_ability: InspectionTextChoices.NA,
    adjusts_speed_properly_legal_limits: InspectionTextChoices.NA,
    slows_down_for_rough_roads: InspectionTextChoices.NA,
    slows_down_in_advance_of_curves_intersections_etc: InspectionTextChoices.NA,
    maintains_consistent_speed: InspectionTextChoices.NA,
    uses_defensive_driving_techniques: InspectionTextChoices.NA,
    yields_right_of_way_for_safety_courtesy: InspectionTextChoices.NA,
    goes_ahead_when_given_right_of_way_by_others: InspectionTextChoices.NA,
    does_not_crowd_other_drivers_or_force_way_through_traffic: InspectionTextChoices.NA,
    allows_faster_traffic_to_pass: InspectionTextChoices.NA,
    keeps_right_and_in_own_lane: InspectionTextChoices.NA,
    uses_horn_only_when_necessary: InspectionTextChoices.NA,
    generally_courteous_and_uses_proper_conduct: InspectionTextChoices.NA,
    remarks: ''
};

const validationSchema = Yup.object({
    signals_intention_to_turn_well_in_advance: Yup.string().required('Required'),
    gets_into_proper_lane_before_turning: Yup.string().required('Required'),
    checks_traffic_before_turning: Yup.string().required('Required'),
    restricts_traffic_from_passing_on_right_when_turning_right: Yup.string().required('Required'),
    approaches_signal_prepared_to_stop: Yup.string().required('Required'),
    obeys_traffic_signals: Yup.string().required('Required'),
    uses_good_judgement_on_yellow_light: Yup.string().required('Required'),
    starts_smoothly_on_green: Yup.string().required('Required'),
    noties_and_heeds_traffic_signs: Yup.string().required('Required'),
    obeys_stop_signs: Yup.string().required('Required'),
    adjusts_speed_to_permit_stopping: Yup.string().required('Required'),
    checks_for_cross_traffic_regardless_of_signals: Yup.string().required('Required'),
    yields_right_of_way_for_safety: Yup.string().required('Required'),
    adjusts_speed_to_conditions: Yup.string().required('Required'),
    makes_safe_stop_if_required: Yup.string().required('Required'),
    selects_proper_gear_and_does_not_shift_gears_while_crossing: Yup.string().required('Required'),
    knows_and_understands_rules_governing_grade_crossing: Yup.string().required('Required'),
    passes_with_sufficient_clear_space_ahead: Yup.string().required('Required'),
    does_not_pass_in_an_unsafe_location: Yup.string().required('Required'),
    signals_change_of_lanes: Yup.string().required('Required'),
    warns_driver_being_passed: Yup.string().required('Required'),
    pulls_out_and_back_with_certainty: Yup.string().required('Required'),
    does_not_tailgate: Yup.string().required('Required'),
    does_not_block_traffic_with_slow_pass: Yup.string().required('Required'),
    allows_enough_room_when_returning_to_right_lane: Yup.string().required('Required'),
    speed_consistent_with_basic_ability: Yup.string().required('Required'),
    adjusts_speed_properly_legal_limits: Yup.string().required('Required'),
    slows_down_for_rough_roads: Yup.string().required('Required'),
    slows_down_in_advance_of_curves_intersections_etc: Yup.string().required('Required'),
    maintains_consistent_speed: Yup.string().required('Required'),
    uses_defensive_driving_techniques: Yup.string().required('Required'),
    yields_right_of_way_for_safety_courtesy: Yup.string().required('Required'),
    goes_ahead_when_given_right_of_way_by_others: Yup.string().required('Required'),
    does_not_crowd_other_drivers_or_force_way_through_traffic: Yup.string().required('Required'),
    allows_faster_traffic_to_pass: Yup.string().required('Required'),
    keeps_right_and_in_own_lane: Yup.string().required('Required'),
    uses_horn_only_when_necessary: Yup.string().required('Required'),
    generally_courteous_and_uses_proper_conduct: Yup.string().required('Required')
});

interface Props {
    member?: IMember;
    isDriverFile?: boolean;
}

export const OperatingInTrafficPassingAndTurning: React.FC<Props> = ({ member, isDriverFile }) => {
    const { mode } = useThemeMode();
    const { currentUser } = useAuth();
    const { data } = useGetOperatingInTrafficPassingAndTurningByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true
    });

    const [createOperatingInTrafficPassingAndTurning, { isLoading: isCreating }] =
        useCreateOperatingInTrafficPassingAndTurningMutation();

    const [updateOperatingInTrafficPassingAndTurning, { isLoading: isUpdating }] =
        useUpdateOperatingInTrafficPassingAndTurningMutation();

    const [deleteOperatingInTrafficPassingAndTurning] = useDeleteOperatingInTrafficPassingAndTurningMutation();

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (data) {
                // @ts-ignore
                updateOperatingInTrafficPassingAndTurning({ id: data.id, ...values });
            } else {
                createOperatingInTrafficPassingAndTurning({
                    ...values,
                    // @ts-ignore
                    member: member?.id,
                    // @ts-ignore
                    company: member?.company?.id
                });
            }
            resetForm();
        },
        validateOnBlur: true,
        validateOnChange: true
    });

    useEffect(() => {
        if (data) {
            formik.setValues(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const setAllAsSatisfactory = () => {
        formik.setValues({
            adjusts_speed_properly_legal_limits: InspectionTextChoices.SATISFACTORY,
            adjusts_speed_to_conditions: InspectionTextChoices.SATISFACTORY,
            adjusts_speed_to_permit_stopping: InspectionTextChoices.SATISFACTORY,
            allows_enough_room_when_returning_to_right_lane: InspectionTextChoices.SATISFACTORY,
            allows_faster_traffic_to_pass: InspectionTextChoices.SATISFACTORY,
            approaches_signal_prepared_to_stop: InspectionTextChoices.SATISFACTORY,
            checks_for_cross_traffic_regardless_of_signals: InspectionTextChoices.SATISFACTORY,
            checks_traffic_before_turning: InspectionTextChoices.SATISFACTORY,
            completes_turn_and_does_not_impede_other_traffic: InspectionTextChoices.SATISFACTORY,
            does_not_block_traffic_with_slow_pass: InspectionTextChoices.SATISFACTORY,
            does_not_crowd_other_drivers_or_force_way_through_traffic: InspectionTextChoices.SATISFACTORY,
            does_not_pass_in_an_unsafe_location: InspectionTextChoices.SATISFACTORY,
            does_not_tailgate: InspectionTextChoices.SATISFACTORY,
            generally_courteous_and_uses_proper_conduct: InspectionTextChoices.SATISFACTORY,
            gets_into_proper_lane_before_turning: InspectionTextChoices.SATISFACTORY,
            goes_ahead_when_given_right_of_way_by_others: InspectionTextChoices.SATISFACTORY,
            keeps_right_and_in_own_lane: InspectionTextChoices.SATISFACTORY,
            knows_and_understands_rules_governing_grade_crossing: InspectionTextChoices.SATISFACTORY,
            maintains_consistent_speed: InspectionTextChoices.SATISFACTORY,
            makes_safe_stop_if_required: InspectionTextChoices.SATISFACTORY,
            noties_and_heeds_traffic_signs: InspectionTextChoices.SATISFACTORY,
            obeys_stop_signs: InspectionTextChoices.SATISFACTORY,
            obeys_traffic_signals: InspectionTextChoices.SATISFACTORY,
            passes_with_sufficient_clear_space_ahead: InspectionTextChoices.SATISFACTORY,
            pulls_out_and_back_with_certainty: InspectionTextChoices.SATISFACTORY,
            restricts_traffic_from_passing_on_right_when_turning_right: InspectionTextChoices.SATISFACTORY,
            selects_proper_gear_and_does_not_shift_gears_while_crossing: InspectionTextChoices.SATISFACTORY,
            signals_change_of_lanes: InspectionTextChoices.SATISFACTORY,
            signals_intention_to_turn_well_in_advance: InspectionTextChoices.SATISFACTORY,
            slows_down_for_rough_roads: InspectionTextChoices.SATISFACTORY,
            slows_down_in_advance_of_curves_intersections_etc: InspectionTextChoices.SATISFACTORY,
            speed_consistent_with_basic_ability: InspectionTextChoices.SATISFACTORY,
            starts_smoothly_on_green: InspectionTextChoices.SATISFACTORY,
            uses_defensive_driving_techniques: InspectionTextChoices.SATISFACTORY,
            uses_good_judgement_on_yellow_light: InspectionTextChoices.SATISFACTORY,
            uses_horn_only_when_necessary: InspectionTextChoices.SATISFACTORY,
            warns_driver_being_passed: InspectionTextChoices.SATISFACTORY,
            yields_right_of_way_for_safety: InspectionTextChoices.SATISFACTORY,
            yields_right_of_way_for_safety_courtesy: InspectionTextChoices.SATISFACTORY
        });
    };

    return (
        <div
            style={{
                padding: 10,
                margin: 10
            }}
        >
            <Typography
                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: 'center' }}
                variant="h4"
                gutterBottom
                component="h4"
            >
                Operating in Traffic Passing and Turning
            </Typography>
            <Typography
                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: 'justify' }}
                variant="body1"
                gutterBottom
                component="p"
            >
                For the items that apply, please mark the driver's performance accordingly. Explain unsatisfactory items
                under Remarks.
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Button size="small" fullWidth variant="contained" onClick={setAllAsSatisfactory}>
                        Set all As satisfactory
                    </Button>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        A. Turning
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Signals intention to turn well in advance
                    </Typography>
                    <TextField
                        fullWidth
                        id="signals_intention_to_turn_well_in_advance"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.signals_intention_to_turn_well_in_advance}
                        onChange={(e) => {
                            formik.setFieldValue('signals_intention_to_turn_well_in_advance', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.signals_intention_to_turn_well_in_advance &&
                                        Boolean(formik.errors.signals_intention_to_turn_well_in_advance)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.signals_intention_to_turn_well_in_advance &&
                                        Boolean(formik.errors.signals_intention_to_turn_well_in_advance)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.signals_intention_to_turn_well_in_advance &&
                                        Boolean(formik.errors.signals_intention_to_turn_well_in_advance)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Gets into proper lane well in advance of turn
                    </Typography>
                    <TextField
                        fullWidth
                        id="gets_into_proper_lane_before_turning"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.gets_into_proper_lane_before_turning}
                        onChange={(e) => {
                            formik.setFieldValue('gets_into_proper_lane_before_turning', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.gets_into_proper_lane_before_turning &&
                                        Boolean(formik.errors.gets_into_proper_lane_before_turning)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.gets_into_proper_lane_before_turning &&
                                        Boolean(formik.errors.gets_into_proper_lane_before_turning)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.gets_into_proper_lane_before_turning &&
                                        Boolean(formik.errors.gets_into_proper_lane_before_turning)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Checks traffic conditions and turns only when intersection is clear
                    </Typography>
                    <TextField
                        fullWidth
                        id="checks_traffic_before_turning"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.checks_traffic_before_turning}
                        onChange={(e) => {
                            formik.setFieldValue('checks_traffic_before_turning', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.checks_traffic_before_turning &&
                                        Boolean(formik.errors.checks_traffic_before_turning)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.checks_traffic_before_turning &&
                                        Boolean(formik.errors.checks_traffic_before_turning)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.checks_traffic_before_turning &&
                                        Boolean(formik.errors.checks_traffic_before_turning)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Restricts traffic from passing on right when preparing to complete right-hand turn
                    </Typography>
                    <TextField
                        fullWidth
                        id="restricts_traffic_from_passing_on_right_when_turning_right"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.restricts_traffic_from_passing_on_right_when_turning_right}
                        onChange={(e) => {
                            formik.setFieldValue(
                                'restricts_traffic_from_passing_on_right_when_turning_right',
                                e.target.value
                            );
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.restricts_traffic_from_passing_on_right_when_turning_right &&
                                        Boolean(
                                            formik.errors.restricts_traffic_from_passing_on_right_when_turning_right
                                        )
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.restricts_traffic_from_passing_on_right_when_turning_right &&
                                        Boolean(
                                            formik.errors.restricts_traffic_from_passing_on_right_when_turning_right
                                        )
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.restricts_traffic_from_passing_on_right_when_turning_right &&
                                        Boolean(
                                            formik.errors.restricts_traffic_from_passing_on_right_when_turning_right
                                        )
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Completes turn promptly and safely and does not impede other traffic
                    </Typography>
                    <TextField
                        fullWidth
                        id="completes_turn_and_does_not_impede_other_traffic"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.completes_turn_and_does_not_impede_other_traffic}
                        onChange={(e) => {
                            formik.setFieldValue('completes_turn_and_does_not_impede_other_traffic', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.completes_turn_and_does_not_impede_other_traffic &&
                                        Boolean(formik.errors.completes_turn_and_does_not_impede_other_traffic)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.completes_turn_and_does_not_impede_other_traffic &&
                                        Boolean(formik.errors.completes_turn_and_does_not_impede_other_traffic)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.completes_turn_and_does_not_impede_other_traffic &&
                                        Boolean(formik.errors.completes_turn_and_does_not_impede_other_traffic)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        B. Traffic Signs and Signals
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Approaches signal prepared to stop if necessary
                    </Typography>
                    <TextField
                        fullWidth
                        id="approaches_signal_prepared_to_stop"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.approaches_signal_prepared_to_stop}
                        onChange={(e) => {
                            formik.setFieldValue('approaches_signal_prepared_to_stop', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.approaches_signal_prepared_to_stop &&
                                        Boolean(formik.errors.approaches_signal_prepared_to_stop)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.approaches_signal_prepared_to_stop &&
                                        Boolean(formik.errors.approaches_signal_prepared_to_stop)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.approaches_signal_prepared_to_stop &&
                                        Boolean(formik.errors.approaches_signal_prepared_to_stop)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Obeys traffic signal
                    </Typography>
                    <TextField
                        fullWidth
                        id="obeys_traffic_signals"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.obeys_traffic_signals}
                        onChange={(e) => {
                            formik.setFieldValue('obeys_traffic_signals', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.obeys_traffic_signals &&
                                        Boolean(formik.errors.obeys_traffic_signals)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.obeys_traffic_signals &&
                                        Boolean(formik.errors.obeys_traffic_signals)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.obeys_traffic_signals &&
                                        Boolean(formik.errors.obeys_traffic_signals)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Uses good judgment on yellow light
                    </Typography>
                    <TextField
                        fullWidth
                        id="uses_good_judgement_on_yellow_light"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.uses_good_judgement_on_yellow_light}
                        onChange={(e) => {
                            formik.setFieldValue('uses_good_judgement_on_yellow_light', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.uses_good_judgement_on_yellow_light &&
                                        Boolean(formik.errors.uses_good_judgement_on_yellow_light)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.uses_good_judgement_on_yellow_light &&
                                        Boolean(formik.errors.uses_good_judgement_on_yellow_light)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.uses_good_judgement_on_yellow_light &&
                                        Boolean(formik.errors.uses_good_judgement_on_yellow_light)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Starts smoothly on green
                    </Typography>
                    <TextField
                        fullWidth
                        id="starts_smoothly_on_green"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.starts_smoothly_on_green}
                        onChange={(e) => {
                            formik.setFieldValue('starts_smoothly_on_green', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.starts_smoothly_on_green &&
                                        Boolean(formik.errors.starts_smoothly_on_green)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.starts_smoothly_on_green &&
                                        Boolean(formik.errors.starts_smoothly_on_green)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.starts_smoothly_on_green &&
                                        Boolean(formik.errors.starts_smoothly_on_green)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Notices and heeds traffic signs
                    </Typography>
                    <TextField
                        fullWidth
                        id="noties_and_heeds_traffic_signs"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.noties_and_heeds_traffic_signs}
                        onChange={(e) => {
                            formik.setFieldValue('noties_and_heeds_traffic_signs', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.noties_and_heeds_traffic_signs &&
                                        Boolean(formik.errors.noties_and_heeds_traffic_signs)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.noties_and_heeds_traffic_signs &&
                                        Boolean(formik.errors.noties_and_heeds_traffic_signs)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.noties_and_heeds_traffic_signs &&
                                        Boolean(formik.errors.noties_and_heeds_traffic_signs)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Obeys Stop signs
                    </Typography>
                    <TextField
                        fullWidth
                        id="obeys_stop_signs"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.obeys_stop_signs}
                        onChange={(e) => {
                            formik.setFieldValue('obeys_stop_signs', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.obeys_stop_signs && Boolean(formik.errors.obeys_stop_signs)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.obeys_stop_signs && Boolean(formik.errors.obeys_stop_signs)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.obeys_stop_signs && Boolean(formik.errors.obeys_stop_signs)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        C. Intersections
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Adjusts speed to permit stopping if necessary
                    </Typography>
                    <TextField
                        fullWidth
                        id="adjusts_speed_to_permit_stopping"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.adjusts_speed_to_permit_stopping}
                        onChange={(e) => {
                            formik.setFieldValue('adjusts_speed_to_permit_stopping', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.adjusts_speed_to_permit_stopping &&
                                        Boolean(formik.errors.adjusts_speed_to_permit_stopping)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.adjusts_speed_to_permit_stopping &&
                                        Boolean(formik.errors.adjusts_speed_to_permit_stopping)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.adjusts_speed_to_permit_stopping &&
                                        Boolean(formik.errors.adjusts_speed_to_permit_stopping)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Checks for cross traffic regardless of traffic controls
                    </Typography>
                    <TextField
                        fullWidth
                        id="checks_for_cross_traffic_regardless_of_signals"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.checks_for_cross_traffic_regardless_of_signals}
                        onChange={(e) => {
                            formik.setFieldValue('checks_for_cross_traffic_regardless_of_signals', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.checks_for_cross_traffic_regardless_of_signals &&
                                        Boolean(formik.errors.checks_for_cross_traffic_regardless_of_signals)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.checks_for_cross_traffic_regardless_of_signals &&
                                        Boolean(formik.errors.checks_for_cross_traffic_regardless_of_signals)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.checks_for_cross_traffic_regardless_of_signals &&
                                        Boolean(formik.errors.checks_for_cross_traffic_regardless_of_signals)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Yields right of way for safety
                    </Typography>
                    <TextField
                        fullWidth
                        id="yields_right_of_way_for_safety"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.yields_right_of_way_for_safety}
                        onChange={(e) => {
                            formik.setFieldValue('yields_right_of_way_for_safety', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.yields_right_of_way_for_safety &&
                                        Boolean(formik.errors.yields_right_of_way_for_safety)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.yields_right_of_way_for_safety &&
                                        Boolean(formik.errors.yields_right_of_way_for_safety)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.yields_right_of_way_for_safety &&
                                        Boolean(formik.errors.yields_right_of_way_for_safety)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        D. Grade Crossings
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Adjusts speed to conditions
                    </Typography>
                    <TextField
                        fullWidth
                        id="adjusts_speed_to_conditions"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.adjusts_speed_to_conditions}
                        onChange={(e) => {
                            formik.setFieldValue('adjusts_speed_to_conditions', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.adjusts_speed_to_conditions &&
                                        Boolean(formik.errors.adjusts_speed_to_conditions)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.adjusts_speed_to_conditions &&
                                        Boolean(formik.errors.adjusts_speed_to_conditions)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.adjusts_speed_to_conditions &&
                                        Boolean(formik.errors.adjusts_speed_to_conditions)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Makes safe stop, if required
                    </Typography>
                    <TextField
                        fullWidth
                        id="makes_safe_stop_if_required"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.makes_safe_stop_if_required}
                        onChange={(e) => {
                            formik.setFieldValue('makes_safe_stop_if_required', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.makes_safe_stop_if_required &&
                                        Boolean(formik.errors.makes_safe_stop_if_required)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.makes_safe_stop_if_required &&
                                        Boolean(formik.errors.makes_safe_stop_if_required)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.makes_safe_stop_if_required &&
                                        Boolean(formik.errors.makes_safe_stop_if_required)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Selects proper gear and does not shift gears while crossing
                    </Typography>
                    <TextField
                        fullWidth
                        id="selects_proper_gear_and_does_not_shift_gears_while_crossing"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.selects_proper_gear_and_does_not_shift_gears_while_crossing}
                        onChange={(e) => {
                            formik.setFieldValue(
                                'selects_proper_gear_and_does_not_shift_gears_while_crossing',
                                e.target.value
                            );
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.selects_proper_gear_and_does_not_shift_gears_while_crossing &&
                                        Boolean(
                                            formik.errors.selects_proper_gear_and_does_not_shift_gears_while_crossing
                                        )
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.selects_proper_gear_and_does_not_shift_gears_while_crossing &&
                                        Boolean(
                                            formik.errors.selects_proper_gear_and_does_not_shift_gears_while_crossing
                                        )
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.selects_proper_gear_and_does_not_shift_gears_while_crossing &&
                                        Boolean(
                                            formik.errors.selects_proper_gear_and_does_not_shift_gears_while_crossing
                                        )
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Knows and understands federal and state rules governing grade crossing
                    </Typography>
                    <TextField
                        fullWidth
                        id="knows_and_understands_rules_governing_grade_crossing"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.knows_and_understands_rules_governing_grade_crossing}
                        onChange={(e) => {
                            formik.setFieldValue(
                                'knows_and_understands_rules_governing_grade_crossing',
                                e.target.value
                            );
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.knows_and_understands_rules_governing_grade_crossing &&
                                        Boolean(formik.errors.knows_and_understands_rules_governing_grade_crossing)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.knows_and_understands_rules_governing_grade_crossing &&
                                        Boolean(formik.errors.knows_and_understands_rules_governing_grade_crossing)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.knows_and_understands_rules_governing_grade_crossing &&
                                        Boolean(formik.errors.knows_and_understands_rules_governing_grade_crossing)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        E. Passing
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Passes with sufficient clear space ahead
                    </Typography>
                    <TextField
                        fullWidth
                        id="passes_with_sufficient_clear_space_ahead"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.passes_with_sufficient_clear_space_ahead}
                        onChange={(e) => {
                            formik.setFieldValue('passes_with_sufficient_clear_space_ahead', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.passes_with_sufficient_clear_space_ahead &&
                                        Boolean(formik.errors.passes_with_sufficient_clear_space_ahead)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.passes_with_sufficient_clear_space_ahead &&
                                        Boolean(formik.errors.passes_with_sufficient_clear_space_ahead)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.passes_with_sufficient_clear_space_ahead &&
                                        Boolean(formik.errors.passes_with_sufficient_clear_space_ahead)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Does not pass in an unsafe location: hill, curve, intersection
                    </Typography>
                    <TextField
                        fullWidth
                        id="does_not_pass_in_an_unsafe_location"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.does_not_pass_in_an_unsafe_location}
                        onChange={(e) => {
                            formik.setFieldValue('does_not_pass_in_an_unsafe_location', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.does_not_pass_in_an_unsafe_location &&
                                        Boolean(formik.errors.does_not_pass_in_an_unsafe_location)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.does_not_pass_in_an_unsafe_location &&
                                        Boolean(formik.errors.does_not_pass_in_an_unsafe_location)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.does_not_pass_in_an_unsafe_location &&
                                        Boolean(formik.errors.does_not_pass_in_an_unsafe_location)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Signals change of lanes
                    </Typography>
                    <TextField
                        fullWidth
                        id="signals_change_of_lanes"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.signals_change_of_lanes}
                        onChange={(e) => {
                            formik.setFieldValue('signals_change_of_lanes', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.signals_change_of_lanes &&
                                        Boolean(formik.errors.signals_change_of_lanes)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.signals_change_of_lanes &&
                                        Boolean(formik.errors.signals_change_of_lanes)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.signals_change_of_lanes &&
                                        Boolean(formik.errors.signals_change_of_lanes)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Warns driver being passed
                    </Typography>
                    <TextField
                        fullWidth
                        id="warns_driver_being_passed"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.warns_driver_being_passed}
                        onChange={(e) => {
                            formik.setFieldValue('warns_driver_being_passed', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.warns_driver_being_passed &&
                                        Boolean(formik.errors.warns_driver_being_passed)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.warns_driver_being_passed &&
                                        Boolean(formik.errors.warns_driver_being_passed)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.warns_driver_being_passed &&
                                        Boolean(formik.errors.warns_driver_being_passed)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Pulls out and back with certainty
                    </Typography>
                    <TextField
                        fullWidth
                        id="pulls_out_and_back_with_certainty"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.pulls_out_and_back_with_certainty}
                        onChange={(e) => {
                            formik.setFieldValue('pulls_out_and_back_with_certainty', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.pulls_out_and_back_with_certainty &&
                                        Boolean(formik.errors.pulls_out_and_back_with_certainty)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.pulls_out_and_back_with_certainty &&
                                        Boolean(formik.errors.pulls_out_and_back_with_certainty)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.pulls_out_and_back_with_certainty &&
                                        Boolean(formik.errors.pulls_out_and_back_with_certainty)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Does not tailgate
                    </Typography>
                    <TextField
                        fullWidth
                        id="does_not_tailgate"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.does_not_tailgate}
                        onChange={(e) => {
                            formik.setFieldValue('does_not_tailgate', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.does_not_tailgate && Boolean(formik.errors.does_not_tailgate)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.does_not_tailgate && Boolean(formik.errors.does_not_tailgate)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.does_not_tailgate && Boolean(formik.errors.does_not_tailgate)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Does not block traffic with slow pass
                    </Typography>
                    <TextField
                        fullWidth
                        id="does_not_block_traffic_with_slow_pass"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.does_not_block_traffic_with_slow_pass}
                        onChange={(e) => {
                            formik.setFieldValue('does_not_block_traffic_with_slow_pass', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.does_not_block_traffic_with_slow_pass &&
                                        Boolean(formik.errors.does_not_block_traffic_with_slow_pass)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.does_not_block_traffic_with_slow_pass &&
                                        Boolean(formik.errors.does_not_block_traffic_with_slow_pass)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.does_not_block_traffic_with_slow_pass &&
                                        Boolean(formik.errors.does_not_block_traffic_with_slow_pass)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Allows enough room when returning to right lane
                    </Typography>
                    <TextField
                        fullWidth
                        id="allows_enough_room_when_returning_to_right_lane"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.allows_enough_room_when_returning_to_right_lane}
                        onChange={(e) => {
                            formik.setFieldValue('allows_enough_room_when_returning_to_right_lane', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.allows_enough_room_when_returning_to_right_lane &&
                                        Boolean(formik.errors.allows_enough_room_when_returning_to_right_lane)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.allows_enough_room_when_returning_to_right_lane &&
                                        Boolean(formik.errors.allows_enough_room_when_returning_to_right_lane)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.allows_enough_room_when_returning_to_right_lane &&
                                        Boolean(formik.errors.allows_enough_room_when_returning_to_right_lane)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        F. Speed
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Speed consistent with basic ability
                    </Typography>
                    <TextField
                        fullWidth
                        id="speed_consistent_with_basic_ability"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.speed_consistent_with_basic_ability}
                        onChange={(e) => {
                            formik.setFieldValue('speed_consistent_with_basic_ability', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.speed_consistent_with_basic_ability &&
                                        Boolean(formik.errors.speed_consistent_with_basic_ability)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.speed_consistent_with_basic_ability &&
                                        Boolean(formik.errors.speed_consistent_with_basic_ability)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.speed_consistent_with_basic_ability &&
                                        Boolean(formik.errors.speed_consistent_with_basic_ability)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Adjusts speed properly to road, weather, traffic conditions, legal limits
                    </Typography>
                    <TextField
                        fullWidth
                        id="adjusts_speed_properly_legal_limits"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.adjusts_speed_properly_legal_limits}
                        onChange={(e) => {
                            formik.setFieldValue('adjusts_speed_properly_legal_limits', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.adjusts_speed_properly_legal_limits &&
                                        Boolean(formik.errors.adjusts_speed_properly_legal_limits)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.adjusts_speed_properly_legal_limits &&
                                        Boolean(formik.errors.adjusts_speed_properly_legal_limits)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.adjusts_speed_properly_legal_limits &&
                                        Boolean(formik.errors.adjusts_speed_properly_legal_limits)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Slows down for rough roads
                    </Typography>
                    <TextField
                        fullWidth
                        id="slows_down_for_rough_roads"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.slows_down_for_rough_roads}
                        onChange={(e) => {
                            formik.setFieldValue('slows_down_for_rough_roads', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.slows_down_for_rough_roads &&
                                        Boolean(formik.errors.slows_down_for_rough_roads)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.slows_down_for_rough_roads &&
                                        Boolean(formik.errors.slows_down_for_rough_roads)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.slows_down_for_rough_roads &&
                                        Boolean(formik.errors.slows_down_for_rough_roads)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Slows down in advance of curves, intersections, etc
                    </Typography>
                    <TextField
                        fullWidth
                        id="slows_down_in_advance_of_curves_intersections_etc"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.slows_down_in_advance_of_curves_intersections_etc}
                        onChange={(e) => {
                            formik.setFieldValue('slows_down_in_advance_of_curves_intersections_etc', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.slows_down_in_advance_of_curves_intersections_etc &&
                                        Boolean(formik.errors.slows_down_in_advance_of_curves_intersections_etc)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.slows_down_in_advance_of_curves_intersections_etc &&
                                        Boolean(formik.errors.slows_down_in_advance_of_curves_intersections_etc)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.slows_down_in_advance_of_curves_intersections_etc &&
                                        Boolean(formik.errors.slows_down_in_advance_of_curves_intersections_etc)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Maintains consistent speed
                    </Typography>
                    <TextField
                        fullWidth
                        id="maintains_consistent_speed"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.maintains_consistent_speed}
                        onChange={(e) => {
                            formik.setFieldValue('maintains_consistent_speed', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.maintains_consistent_speed &&
                                        Boolean(formik.errors.maintains_consistent_speed)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.maintains_consistent_speed &&
                                        Boolean(formik.errors.maintains_consistent_speed)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.maintains_consistent_speed &&
                                        Boolean(formik.errors.maintains_consistent_speed)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        G. Courtesy and Safety
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Uses defensive driving techniques
                    </Typography>
                    <TextField
                        fullWidth
                        id="uses_defensive_driving_techniques"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.uses_defensive_driving_techniques}
                        onChange={(e) => {
                            formik.setFieldValue('uses_defensive_driving_techniques', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.uses_defensive_driving_techniques &&
                                        Boolean(formik.errors.uses_defensive_driving_techniques)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.uses_defensive_driving_techniques &&
                                        Boolean(formik.errors.uses_defensive_driving_techniques)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.uses_defensive_driving_techniques &&
                                        Boolean(formik.errors.uses_defensive_driving_techniques)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Yields right of way for safety
                    </Typography>
                    <TextField
                        fullWidth
                        id="yields_right_of_way_for_safety_courtesy"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.yields_right_of_way_for_safety_courtesy}
                        onChange={(e) => {
                            formik.setFieldValue('yields_right_of_way_for_safety_courtesy', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.yields_right_of_way_for_safety_courtesy &&
                                        Boolean(formik.errors.yields_right_of_way_for_safety_courtesy)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.yields_right_of_way_for_safety_courtesy &&
                                        Boolean(formik.errors.yields_right_of_way_for_safety_courtesy)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.yields_right_of_way_for_safety_courtesy &&
                                        Boolean(formik.errors.yields_right_of_way_for_safety_courtesy)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Goes ahead when given right of way by others
                    </Typography>
                    <TextField
                        fullWidth
                        id="goes_ahead_when_given_right_of_way_by_others"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.goes_ahead_when_given_right_of_way_by_others}
                        onChange={(e) => {
                            formik.setFieldValue('goes_ahead_when_given_right_of_way_by_others', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.goes_ahead_when_given_right_of_way_by_others &&
                                        Boolean(formik.errors.goes_ahead_when_given_right_of_way_by_others)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.goes_ahead_when_given_right_of_way_by_others &&
                                        Boolean(formik.errors.goes_ahead_when_given_right_of_way_by_others)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.goes_ahead_when_given_right_of_way_by_others &&
                                        Boolean(formik.errors.goes_ahead_when_given_right_of_way_by_others)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Does not crowd other drivers or force way through traffic
                    </Typography>
                    <TextField
                        fullWidth
                        id="does_not_crowd_other_drivers_or_force_way_through_traffic"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.does_not_crowd_other_drivers_or_force_way_through_traffic}
                        onChange={(e) => {
                            formik.setFieldValue(
                                'does_not_crowd_other_drivers_or_force_way_through_traffic',
                                e.target.value
                            );
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.does_not_crowd_other_drivers_or_force_way_through_traffic &&
                                        Boolean(formik.errors.does_not_crowd_other_drivers_or_force_way_through_traffic)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.does_not_crowd_other_drivers_or_force_way_through_traffic &&
                                        Boolean(formik.errors.does_not_crowd_other_drivers_or_force_way_through_traffic)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.does_not_crowd_other_drivers_or_force_way_through_traffic &&
                                        Boolean(formik.errors.does_not_crowd_other_drivers_or_force_way_through_traffic)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Allows faster traffic to pass
                    </Typography>
                    <TextField
                        fullWidth
                        id="allows_faster_traffic_to_pass"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.allows_faster_traffic_to_pass}
                        onChange={(e) => {
                            formik.setFieldValue('allows_faster_traffic_to_pass', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.allows_faster_traffic_to_pass &&
                                        Boolean(formik.errors.allows_faster_traffic_to_pass)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.allows_faster_traffic_to_pass &&
                                        Boolean(formik.errors.allows_faster_traffic_to_pass)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.allows_faster_traffic_to_pass &&
                                        Boolean(formik.errors.allows_faster_traffic_to_pass)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Keeps right and in own lane
                    </Typography>
                    <TextField
                        fullWidth
                        id="keeps_right_and_in_own_lane"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.keeps_right_and_in_own_lane}
                        onChange={(e) => {
                            formik.setFieldValue('keeps_right_and_in_own_lane', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.keeps_right_and_in_own_lane &&
                                        Boolean(formik.errors.keeps_right_and_in_own_lane)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.keeps_right_and_in_own_lane &&
                                        Boolean(formik.errors.keeps_right_and_in_own_lane)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.keeps_right_and_in_own_lane &&
                                        Boolean(formik.errors.keeps_right_and_in_own_lane)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Uses horn only when necessary
                    </Typography>
                    <TextField
                        fullWidth
                        id="uses_horn_only_when_necessary"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.uses_horn_only_when_necessary}
                        onChange={(e) => {
                            formik.setFieldValue('uses_horn_only_when_necessary', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.uses_horn_only_when_necessary &&
                                        Boolean(formik.errors.uses_horn_only_when_necessary)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.uses_horn_only_when_necessary &&
                                        Boolean(formik.errors.uses_horn_only_when_necessary)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.uses_horn_only_when_necessary &&
                                        Boolean(formik.errors.uses_horn_only_when_necessary)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Generally courteous and uses proper conduct
                    </Typography>
                    <TextField
                        fullWidth
                        id="generally_courteous_and_uses_proper_conduct"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.generally_courteous_and_uses_proper_conduct}
                        onChange={(e) => {
                            formik.setFieldValue('generally_courteous_and_uses_proper_conduct', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.generally_courteous_and_uses_proper_conduct &&
                                        Boolean(formik.errors.generally_courteous_and_uses_proper_conduct)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.generally_courteous_and_uses_proper_conduct &&
                                        Boolean(formik.errors.generally_courteous_and_uses_proper_conduct)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.generally_courteous_and_uses_proper_conduct &&
                                        Boolean(formik.errors.generally_courteous_and_uses_proper_conduct)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Remarks
                    </Typography>
                    <TextField
                        fullWidth
                        id="remarks"
                        size="small"
                        placeholder="Remarks"
                        multiline
                        rows={4}
                        variant="outlined"
                        value={formik.values.remarks}
                        onChange={(e) => {
                            formik.setFieldValue('remarks', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.remarks && Boolean(formik.errors.remarks) ? 'red' : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.remarks && Boolean(formik.errors.remarks) ? 'red' : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.remarks && Boolean(formik.errors.remarks) ? 'red' : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                {(!data || (data && currentUser && ['Admin', 'SuperAdmin'].includes(currentUser?.role))) &&
                !isDriverFile ? (
                    <Grid item xs={12} sm={12}>
                        <Button
                            fullWidth
                            disabled={isCreating || isUpdating || !!member?.terminated_at}
                            onClick={() => formik.handleSubmit()}
                            variant="contained"
                            color="primary"
                        >
                            Save
                        </Button>
                        {currentUser?.role !== 'Admin' && currentUser?.role !== 'SuperAdmin' && (
                            <Typography
                                sx={{ color: 'red', textAlign: 'center', mt: 2 }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Once you click save, you will not be able to edit this form.
                            </Typography>
                        )}
                    </Grid>
                ) : null}
                {data && currentUser?.role === 'SuperAdmin' && (
                    <Grid item xs={12} sm={12}>
                        <Button
                            fullWidth
                            disabled={!!member?.terminated_at}
                            onClick={() => {
                                const res = window.confirm('Are you sure you want to delete this form?');
                                if (res && data) {
                                    deleteOperatingInTrafficPassingAndTurning(data.id);
                                }
                            }}
                            variant="contained"
                            color="primary"
                        >
                            Delete Form
                        </Button>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};
