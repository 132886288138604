import { useEffect, useState } from 'react';
import ProgressBar from '../../../../compliancemasters/partials/widgets/statistics/ProgressBar';
import { StatusTag } from '../../../../compliancemasters/partials';
import { Link } from 'react-router-dom';
import { KTSVG } from '../../../../compliancemasters/helpers';
import {
    useGetRequestForCheckOfDrivingRecordsProgressMutation,
    useGetMemberRequestForCheckOfDrivingRecordsProgressMutation
} from '../../../../store/reducers/RequestForCheckOfDrivingRecords/progress';
import { IMember } from '../../../../models';
import { useAuth } from '../../auth';
import { getCompanyMember } from '../../../../shared';
import { useGetRequestbySignatureByUserIdQuery } from '../../../../store/reducers/RequestForCheckOfDrivingRecords/request-by-signature';
import { useGetRequestBySignatureByUserIdQuery } from '../../../../store/reducers/RequestForCheckOfDrivingRecords/requester-signature';

const RequestForCheckDrivingRecords = ({ id }: { id: number }) => {
    const [progress, setProgress] = useState<number>(0);
    const [getProgress, { data }] = useGetRequestForCheckOfDrivingRecordsProgressMutation();
    const [getMemberProgress, { data: pg }] = useGetMemberRequestForCheckOfDrivingRecordsProgressMutation();
    const [member, setMember] = useState<IMember>();
    const { currentUser } = useAuth();

    const { data: req } = useGetRequestbySignatureByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true
    });

    const { data: request } = useGetRequestBySignatureByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true
    });

    useEffect(() => {
        getProgress(id);
        getMemberProgress(id);
        getCompanyMember(String(id)).then((res) => {
            setMember(res);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (currentUser && ['Admin', 'Owner', 'SuperAdmin'].includes(currentUser?.role) && data) {
            setProgress(data?.progress);
        } else if (pg) {
            setProgress(pg?.progress);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, pg]);

    return (
        <tr>
            <td className="mw-125px">
                <span
                    className="text-dark fw-bold text-hover-primary mb-1 fs-6 cursor-pointer"
                    onClick={() => window.location.assign(`/member/${id}/re-form/request-for-check-of-driving-record`)}
                >
                    Request For Check Of Driving Records
                </span>
            </td>
            <td>
                <div className="d-flex flex-column w-75 me-2">
                    <ProgressBar progress={progress ? progress : 0} size={6} />
                </div>
            </td>
            <td>
                <StatusTag
                    status={
                        progress && progress > 0 && progress < 100
                            ? 'In Progress'
                            : progress && progress > 99
                            ? 'Completed'
                            : 'Not Started'
                    }
                />
            </td>
            <td className="mw-125px">
                {((member && member?.pre_employment_screened) || currentUser?.member) &&
                (!req || !request) &&
                currentUser?.role !== 'Member' ? (
                    <span className="text-success fw-bold cursor-pointer text-hover-primary mb-1 fs-6">
                        Owner Signature Required
                    </span>
                ) : null}
            </td>
            <td className="text-end">
                <Link
                    className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                    to={`/member/${id}/re-form/request-for-check-of-driving-record`}
                >
                    <KTSVG path="/media/icons/duotune/arrows/arr064.svg" className="svg-icon-2" />
                </Link>
            </td>
        </tr>
    );
};

export default RequestForCheckDrivingRecords;
