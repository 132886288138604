import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
  IViolationAndActualKnowledge,
  IViolationAndActualKnowledgeDto,
} from '../../interfaces/DocumentationofActualKnowledgeofDOTDAndAViolation/violation-of-actual-knowledge';

export const violationAndActualKnowledgeApi = createApi({
  reducerPath: 'violation_and_actual_knowledge',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/violation-and-actual-knowledge`,
  }),
  tagTypes: ['ViolationAndActualKnowledge'],
  keepUnusedDataFor: 60 * 10, // 10 minutes
  endpoints: (builder) => ({
    createViolationAndActualKnowledge: builder.mutation<
      IViolationAndActualKnowledge,
      IViolationAndActualKnowledgeDto
    >({
      query: (body) => ({
        url: '/',
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'ViolationAndActualKnowledge', id: 'ViolationAndActualKnowledge'}],
    }),
    updateViolationAndActualKnowledge: builder.mutation<
      IViolationAndActualKnowledge,
      IViolationAndActualKnowledge
    >({
      query: (body) => ({
        url: `/${body.id}/`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'ViolationAndActualKnowledge', id: 'ViolationAndActualKnowledge'}],
    }),
    deleteViolationAndActualKnowledge: builder.mutation<void, number>({
      query: (id) => ({
        url: `/${id}/`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
    }),
    getViolationAndActualKnowledge: builder.mutation<IViolationAndActualKnowledge, number | void>({
      query: (id) => ({
        url: '/get-violation-and-actual-knowledge/',
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
    }),
    getViolationAndActualKnowledgeByUser: builder.query<IViolationAndActualKnowledge, void>({
      query: () => ({
        url: '/get-violation-and-actual-knowledge-by-user/',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'ViolationAndActualKnowledge', id: 'ViolationAndActualKnowledge'}],
    }),
    getViolationAndActualKnowledgeByUserId: builder.query<
      IViolationAndActualKnowledge,
      number | void
    >({
      query: (id) => ({
        url: '/get-violation-and-actual-knowledge-by-user-id/',
        method: 'POST',
        body: {
          userId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'ViolationAndActualKnowledge', id: 'ViolationAndActualKnowledge'}],
    }),
  }),
});

export const {
  useCreateViolationAndActualKnowledgeMutation,
  useUpdateViolationAndActualKnowledgeMutation,
  useGetViolationAndActualKnowledgeMutation,
  useGetViolationAndActualKnowledgeByUserQuery,
  useGetViolationAndActualKnowledgeByUserIdQuery,
  useDeleteViolationAndActualKnowledgeMutation,
} = violationAndActualKnowledgeApi;
