import {ICompany, IMember} from '../../../models';
import {IAbstract} from '../abstract';

export enum Options {
  MEETS_MINIMUM_REQUIREMENTS = 'Meets Minimum Requirements for safe driving',
  DOES_NOT_ADEQUATELY_MEET = 'Does not adequately meet satisfactory safe driving performance',
  IS_DISQUALIFIED_TO_DRIVE = 'Is disqualified to drive a motor vehicle pursuant to Section 391.25',
}

export interface IAnnualReviewOfDrivingRecordDto {
  name_of_driver: string;

  he_she: Options;

  action_taken_with_driver: string;

  reviewed_by_signature: string;

  date_of_mvr_review: string;

  print: string;

  title: string;

  motor_carrier_name: string;

  motor_carrier_address: string;

  city: string;

  state_zip: string;

  company: ICompany;

  member: IMember;
}

export interface IAnnualReviewOfDrivingRecord extends IAnnualReviewOfDrivingRecordDto, IAbstract {
  is_current: boolean;
  is_original: boolean;
}

export interface IReminder {
  id: number;
  company: ICompany;
  member: IMember;
  is_active: boolean;
}
