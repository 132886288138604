import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IMember } from '../../../models';
import { getCompanyMember } from '../../../shared';
import { Box, Button, Container, Paper } from '@mui/material';
import FormHeaderCard from '../HeaderCard';
import ActionButtons from '../ActionButtons';
import DriverHandbookReceipt from './DriversSignature';
import {
    useGetDriversHandbookReceiptProgressMutation,
    useGetMemberDriversHandbookReceiptProgressMutation
} from '../../../store/reducers/DriversHandbookReceipt/progress';
import { useAuth } from '../../../app/modules/auth';
import { useThemeMode } from '../../partials';

const DriversHandbookReceipt = () => {
    const { currentUser } = useAuth();
    const { mode } = useThemeMode();
    const [getProgress, { data: progress }] = useGetDriversHandbookReceiptProgressMutation();
    const [getMemberProgress, { data: memberProgress }] = useGetMemberDriversHandbookReceiptProgressMutation();
    const [member, setMember] = useState<IMember>();
    const { id } = useParams();
    const [value, setValue] = useState(0);

    useEffect(() => {
        if (id) {
            getCompanyMember(id).then((data) => {
                setMember(data);
            });
            getProgress(Number(id));
            getMemberProgress(Number(id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Container style={{ userSelect: 'none' }}>
            <Box
                sx={{
                    pl: 3,
                    pr: 3,
                    pt: 3,
                    m: 3,
                    borderRadius: 2,
                    backgroundColor: mode === 'dark' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    width: {
                        lg: '47vw',
                        md: '70vw',
                        sm: '80vw'
                    },
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }}
                component={Paper}
            >
                <FormHeaderCard
                    headerTitle="Drivers Handbook Receipt"
                    status={
                        progress && progress?.progress > 0 && progress?.progress < 100
                            ? 'In Progress'
                            : progress && progress.progress > 99
                            ? 'Completed'
                            : 'Not Started'
                    }
                    sectionCount={4}
                    itemCount={29}
                    totalProgress={progress ? progress.progress : 0}
                    memberProgress={memberProgress ? memberProgress.progress : 0}
                    nextButton={{ title: 'Record of Road Test', path: '' }}
                    member={member}
                    setValue={setValue}
                    value={value}
                />
            </Box>
            <>
                <DriverHandbookReceipt member={member} />
            </>
            <ActionButtons
                member={member}
                nextButton={{
                    title: currentUser?.role === 'Member' ? 'Consent for FMCSA Limited Queries' : 'Record of Road Test',
                    path:
                        currentUser?.role === 'Member'
                            ? 'consent-for-limited-queries-of-the-fmcsa-drug-and-alcohol-clearinghouse'
                            : 'record-of-road-test'
                }}
                prevButton={{
                    title: 'Medical Examiner Verification',
                    path: 'medical-examiner-national-registry-verification'
                }}
            />
            <div
                style={{
                    display: 'flex',
                    width: '47vw',
                    justifyContent: 'center',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: 10
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        if (currentUser?.role === 'Member') {
                            window.location.assign(`/dashboard/overview`);
                        } else {
                            if (member?.id) {
                                window.location.assign(`/member/${member?.id}/profile/driver-documents`);
                            }
                        }
                    }}
                    fullWidth
                >
                    Back to Forms Overview
                </Button>
            </div>
        </Container>
    );
};

export default DriversHandbookReceipt;
