import {store} from '../..';
import {StripeProduct} from '../../../app/modules/models';
import {ICompanyProduct} from '../../../app/modules/subscription';
import {
  createCustomProduct,
  getCompanyAddOns,
  getCompanySubscription,
  getCustomProducts,
  getMemberRequestProducts,
  getOneTimeProducts,
  getPreEmploymentProducts,
  getSubscriptionPlans,
} from '../../../shared';
import {ICustomProducts} from '../../interfaces/PreventableMaintenance/stripe';
import {
  setCompanyAddons,
  setCompanyAddonsLoading,
  setMemberRequestProductsLoading,
  setOneTimeProducts,
  setOneTimeProductsLoading,
  setPreEmploymentProducts,
  setPreEmploymentProductsLoading,
  setProducts,
  setProductsLoading,
  setSubscriptions,
  setSubscriptionsLoading,
} from './StripeReducer';

export class StripeActions {
  public static setProductsLoading = (payload: boolean) => {
    store.dispatch(setProductsLoading(payload));
  };

  public static setOneTimeProductsLoading = (payload: boolean) => {
    store.dispatch(setOneTimeProductsLoading(payload));
  };

  public static setSubscriptionsLoading = (payload: boolean) => {
    store.dispatch(setSubscriptionsLoading(payload));
  };

  public static setCompanyAddonsLoading = (payload: boolean) => {
    store.dispatch(setCompanyAddonsLoading(payload));
  };

  public static setPreEmpoymentProductsLoading = (payload: boolean) => {
    store.dispatch(setPreEmploymentProductsLoading(payload));
  };

  public static setMemberRequestProductsLoading = (payload: boolean) => {
    store.dispatch(setMemberRequestProductsLoading(payload));
  };

  public static getProducts = async () => {
    try {
      this.setProductsLoading(true);

      const response = (await getSubscriptionPlans()) as {data?: StripeProduct[]};

      store.dispatch(setProducts(response.data || []));
    } catch (error) {
      console.log(error);
      this.setProductsLoading(false);
    }
  };

  public static getOneTimeProducts = async () => {
    try {
      this.setOneTimeProductsLoading(true);

      const res = (await getOneTimeProducts()) as {data?: StripeProduct[]};

      store.dispatch(setOneTimeProducts(res.data || []));
    } catch (error) {
      console.log(error);
      this.setOneTimeProductsLoading(false);
    }
  };

  public static getCompanySubscriptions = async () => {
    try {
      //   this.setSubscriptionsLoading(true)

      const response = (await getCompanySubscription()) as ICompanyProduct[];

      store.dispatch(setSubscriptions(response || []));
    } catch (error) {
      console.log(error);
      this.setSubscriptionsLoading(false);
    }
  };

  public static getCompanyAddons = async (companyId?: number) => {
    try {
      this.setCompanyAddonsLoading(true);

      const response = (await getCompanyAddOns(companyId)) as ICompanyProduct[];

      store.dispatch(setCompanyAddons(response || []));
    } catch (error) {
      console.log(error);
      this.setCompanyAddonsLoading(false);
    }
  };

  public static getPreEmploymentProducts = async (subscription: string) => {
    try {
      this.setPreEmpoymentProductsLoading(true);

      const response = (await getPreEmploymentProducts(subscription)) as {
        data?: StripeProduct[];
      };

      store.dispatch(setPreEmploymentProducts(response.data || []));
    } catch (error) {
      console.log(error);
      this.setPreEmpoymentProductsLoading(false);
    }
  };

  public static getMemberRequestProducts = async (memberSubscription: string) => {
    try {
      this.setMemberRequestProductsLoading(true);

      const response = (await getMemberRequestProducts(memberSubscription)) as {
        data?: StripeProduct[];
      };

      store.dispatch(setPreEmploymentProducts(response.data || []));
    } catch (error) {
      console.log(error);
      this.setMemberRequestProductsLoading(false);
    }
  };

  public static createCustomProduct = async (product: ICustomProducts) => {
    try {
      this.setProductsLoading(true);
      await createCustomProduct(product);

      this.setProductsLoading(false);
    } catch (error) {
      console.log(error);
      this.setProductsLoading(false);
    }
  };

  public static getCustomProducts = async () => {
    try {
      this.setProductsLoading(true);

      const response = (await getCustomProducts()) as {data?: StripeProduct[]};

      store.dispatch(setProducts(response.data || []));
    } catch (error) {
      console.log(error);
      this.setProductsLoading(false);
    }
  };
}
