import client from '../Client';

export interface IRole {
  id: number;
  name: string;
}

export function getRoles() {
  return client(`auth/api/role/`);
}

export function getRole(roleId: number) {
  return client(`auth/api/role/${roleId}/`);
}

export function createRole(name: string) {
  return client(`auth/api/role/`, {
    body: {
      name,
    },
  });
}

export function updateRole(roleId: number, name: string) {
  return client(`auth/api/role/${roleId}/`, {
    body: {
      name,
    },
    method: 'PUT',
  });
}

export function deleteRole(roleId: number) {
  return client(`auth/api/role/${roleId}/`, {
    method: 'DELETE',
  });
}
