import { forwardRef, FunctionComponent, ReactElement, Ref, useEffect } from 'react';
import { IMaintenanceVehicle } from '../../../../store/interfaces/PreventableMaintenance/maintenance.vehicles';
import { TransitionProps } from '@mui/material/transitions';
import { Button, Dialog, DialogContent, DialogTitle, Grid, Slide, TextField } from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { INotesAndLinks, NotesAndLinksType } from '../../../../store/interfaces/PreventableMaintenance/notes.and.links';
import {
    useCreateNotesAndLinksMutation,
    useUpdateNotesAndLinksMutation
} from '../../../../store/reducers/PreventableMaintenance/notes.links';

interface IProps {
    open: boolean;
    setOpen: (val: boolean) => void;
    vehicle?: IMaintenanceVehicle;
    link?: INotesAndLinks;
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement<any, any>;
    },
    ref: Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const initialValues: Partial<INotesAndLinks> = {
    maintenance_vehicle: undefined,
    link: '',
    link_description: '',
    type: NotesAndLinksType.LINK
};

export const AddLinkModal: FunctionComponent<IProps> = ({ open, setOpen, vehicle, link }) => {
    const [createVehicleNote] = useCreateNotesAndLinksMutation();
    const [updateVehicleNote] = useUpdateNotesAndLinksMutation();
    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object().shape({
            link: Yup.string().required('Link is required'),
            link_description: Yup.string().required('Link Description is required')
        }),
        onSubmit: (values) => {
            if (link) {
                updateVehicleNote({
                    ...link,
                    ...values
                }).then(() => {
                    setOpen(false);
                    formik.resetForm();
                });
            } else {
                createVehicleNote({
                    ...values,
                    maintenance_vehicle: vehicle
                }).then(() => {
                    setOpen(false);
                    formik.resetForm();
                });
            }
        }
    });

    useEffect(() => {
        if (!open) {
            formik.resetForm();
        }
        if (open && link) {
            formik.setValues({
                link: link.link,
                link_description: link.link_description
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [link, open]);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setOpen(false)}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle
                sx={{
                    textAlign: 'center',
                    backgroundColor: '#F5F5F5',
                    color: '#000000',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    padding: '20px 20px 20px 20px'
                }}
            >
                Add Link
            </DialogTitle>
            <DialogContent>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2} p={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="link"
                                name="link"
                                label="Link"
                                size="small"
                                value={formik.values.link}
                                onChange={formik.handleChange}
                                error={formik.touched.link && Boolean(formik.errors.link)}
                                helperText={formik.touched.link && formik.errors.link}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="link_description"
                                name="link_description"
                                label="Link Description"
                                size="small"
                                multiline
                                rows={4}
                                value={formik.values.link_description}
                                onChange={formik.handleChange}
                                error={formik.touched.link_description && Boolean(formik.errors.link_description)}
                                helperText={formik.touched.link_description && formik.errors.link_description}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button type="submit" variant="contained" size="small" fullWidth>
                                {link ? 'Update Link' : 'Add Link'}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </Dialog>
    );
};
