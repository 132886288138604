import { KTSVG } from '../../../compliancemasters/helpers';

interface IProps {
    setOpen: (open: boolean) => void;
}

const NewUserButton = ({ setOpen }: IProps) => {
    return (
        <div className="btn btn-sm btn-light-primary d-inline-flex align-items-center" onClick={() => setOpen(true)}>
            <KTSVG path="media/icons/duotune/arrows/arr075.svg" className="svg-icon-3" />
            Add New User
        </div>
    );
};

export default NewUserButton;
