import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {IProgress} from '../../interfaces/abstract';

export const driversHandbookReceiptProgress = createApi({
  reducerPath: 'driversHandbookReceiptProgress',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/drivers-handbook-receipt-progress`,
  }),
  tagTypes: ['DriversHandbookReceiptProgress'],
  keepUnusedDataFor: 60 * 10, // 10 minutes
  endpoints: (builder) => ({
    getDriversHandbookReceiptProgress: builder.mutation<IProgress, number>({
      query: (id) => ({
        url: `/total-progress/`,
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
    }),
    getMemberDriversHandbookReceiptProgress: builder.mutation<IProgress, number>({
      query: (id) => ({
        url: `/member-progress/`,
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
    }),
  }),
});

export const {
  useGetDriversHandbookReceiptProgressMutation,
  useGetMemberDriversHandbookReceiptProgressMutation,
} = driversHandbookReceiptProgress;
