import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
  IDriverAuthorizationAndSignature,
  IDriverAuthorizationAndSignatureDto,
} from '../../interfaces/LimitedQueriesDrugAndAlcoholClearinghouse/DriverAuthorizationAndSignature';

export const driverAuthorizationAndSignatureApi = createApi({
  reducerPath: 'driver_authorization_and_signature',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/driver-authorization-and-signature`,
  }),
  tagTypes: ['DriverAuthorizationAndSignature'],
  keepUnusedDataFor: 60 * 10, // 10 minutes
  endpoints: (builder) => ({
    createDriverAuthorizationAndSignature: builder.mutation<
      IDriverAuthorizationAndSignature,
      IDriverAuthorizationAndSignatureDto
    >({
      query: (body) => ({
        url: '/',
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [
        {type: 'DriverAuthorizationAndSignature', id: 'DriverAuthorizationAndSignature'},
      ],
    }),
    updateDriverAuthorizationAndSignature: builder.mutation<
      IDriverAuthorizationAndSignature,
      IDriverAuthorizationAndSignature
    >({
      query: (body) => ({
        url: `/${body.id}/`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [
        {type: 'DriverAuthorizationAndSignature', id: 'DriverAuthorizationAndSignature'},
      ],
    }),
    deleteDriverAuthorizationAndSignature: builder.mutation<void, number>({
      query: (id) => ({
        url: `/${id}/`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [
        {type: 'DriverAuthorizationAndSignature', id: 'DriverAuthorizationAndSignature'},
      ],
    }),
    getDriverAuthorizationAndSignature: builder.query<
      IDriverAuthorizationAndSignature,
      number | void
    >({
      query: (id) => ({
        url: `/get-driver-authorization-and-signature/`,
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [
        {type: 'DriverAuthorizationAndSignature', id: 'DriverAuthorizationAndSignature'},
      ],
    }),
    getDriverAuthorizationAndSignatureByUser: builder.query<IDriverAuthorizationAndSignature, void>(
      {
        query: () => ({
          url: `/get-driver-authorization-and-signature-by-user/`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
          },
        }),
        providesTags: [
          {type: 'DriverAuthorizationAndSignature', id: 'DriverAuthorizationAndSignature'},
        ],
      },
    ),
    getDriverAuthorizationAndSignatureByUserId: builder.query<
      IDriverAuthorizationAndSignature,
      number | void
    >({
      query: (id) => ({
        url: `/get-driver-authorization-and-signature-by-user-id/`,
        method: 'POST',
        body: {
          userId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [
        {type: 'DriverAuthorizationAndSignature', id: 'DriverAuthorizationAndSignature'},
      ],
    }),
  }),
});

export const {
  useCreateDriverAuthorizationAndSignatureMutation,
  useUpdateDriverAuthorizationAndSignatureMutation,
  useGetDriverAuthorizationAndSignatureQuery,
  useGetDriverAuthorizationAndSignatureByUserQuery,
  useGetDriverAuthorizationAndSignatureByUserIdQuery,
  useDeleteDriverAuthorizationAndSignatureMutation,
} = driverAuthorizationAndSignatureApi;
