import { Button, Checkbox, Divider, FormControlLabel, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useAuth } from '../../../app/modules/auth';
import { IMember } from '../../../models';
import { FTACompanyDto } from '../../../store/interfaces/DriversApplicationForEmployment/applicant.information';
import {
    IEmploymentInformation,
    IEmploymentInformationDto
} from '../../../store/interfaces/EmploymentHistory/employer-information';
import { useGetApplicantInformationByUserIdQuery } from '../../../store/reducers/DriversApplicationForEmployment/applicant-information';
import {
    useCreateEmploymentInformationMutation,
    useDeleteEmployerInformationMutation,
    useGetEmployerInformationByUserIdQuery,
    useUpdateEmploymentInformationMutation
} from '../../../store/reducers/EmploymentHistory/employer-information';
import { useThemeMode } from '../../partials';
import AccidentRecord from '../PreviousEmployeeSafetyPerformanceHistory/AccidentRecord';
import EmploymentVerification from '../PreviousEmployeeSafetyPerformanceHistory/EmploymentVerification';
import HasAccidentHistory from '../PreviousEmployeeSafetyPerformanceHistory/HasAccidentHistory';
import FtaDachInfo from './FtaDachInfo';

const initialValues: Partial<IEmploymentInformationDto> = {
    company_name: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    contact_person: '',
    contact_phone: '',
    start_date: new Date().toLocaleDateString(),
    end_date: new Date().toLocaleDateString(),
    position: '',
    reason_for_leaving: '',
    subject_to_fmcsrs: false,
    safety_sensitive_function: false,
    agency_under_dot: false
};

const validationSchema = Yup.object({
    company_name: Yup.string().required('Required'),
    address: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    zip: Yup.string().required('Required'),
    contact_person: Yup.string().required('Required'),
    contact_phone: Yup.string().required('Required'),
    start_date: Yup.string().required('Required'),
    end_date: Yup.string().required('Required'),
    position: Yup.string().required('Required'),
    reason_for_leaving: Yup.string().required('Required')
});

const EmployerHistoryItem = ({
    item,
    member,
    showFtaQuestion
}: {
    item: IEmploymentInformation;
    member?: IMember;
    showFtaQuestion?: boolean;
}) => {
    const { mode } = useThemeMode();
    const { currentUser } = useAuth();
    const params = useParams<{ '*': string; id: string }>();
    const [updateEmploymentInformaiton, { isLoading: isUpdating }] = useUpdateEmploymentInformationMutation();
    const [deleteEmploymentInformation, { isLoading: isDeleting }] = useDeleteEmployerInformationMutation();

    const formik = useFormik({
        initialValues: item,
        validationSchema,
        onSubmit: (values) => {
            updateEmploymentInformaiton(values);
            formik.resetForm();
        },
        validateOnBlur: true,
        validateOnChange: true,
        validate: (values) => {
            const errors: Partial<IEmploymentInformationDto> = {};
            if (!values.company_name) {
                errors.company_name = 'Required';
            }
            if (!values.address) {
                errors.address = 'Required';
            }
            if (!values.city) {
                errors.city = 'Required';
            }
            if (!values.state) {
                errors.state = 'Required';
            }
            if (!values.zip) {
                errors.zip = 'Required';
            }
            if (!values.contact_person) {
                errors.contact_person = 'Required';
            }
            if (!values.contact_phone) {
                errors.contact_phone = 'Required';
            }
            if (!values.start_date) {
                errors.start_date = 'Required';
            }
            if (!values.end_date) {
                errors.end_date = 'Required';
            }
            if (!values.position) {
                errors.position = 'Required';
            }
            if (!values.reason_for_leaving) {
                errors.reason_for_leaving = 'Required';
            }
            return errors;
        }
    });

    return (
        <>
            <Grid container spacing={3} sx={{ marginBottom: 5 }}>
                {showFtaQuestion &&
                (!member?.pre_employment_screened ||
                    (member?.pre_employment_screened && member?.pre_employment_investigation.length > 0)) ? (
                    <Grid item xs={12} sm={12}>
                        <Typography
                            sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                            variant="body1"
                            gutterBottom
                            component="p"
                        >
                            Is this previous employer an agency under the Department of Transportation (DOT), other than
                            the FMCSA?
                        </Typography>
                        <TextField
                            fullWidth
                            id="agency_under_dot"
                            size="small"
                            variant="outlined"
                            value={formik.values.agency_under_dot ? 'Yes' : 'No'}
                            select
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.agency_under_dot && Boolean(formik.errors.agency_under_dot)
                                                ? 'red'
                                                : 'green'
                                    },
                                    '&:hover fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.agency_under_dot && Boolean(formik.errors.agency_under_dot)
                                                ? 'red'
                                                : 'green'
                                    },
                                    '&.Mui-focused fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.agency_under_dot && Boolean(formik.errors.agency_under_dot)
                                                ? 'red'
                                                : 'green'
                                    }
                                }
                            }}
                            InputLabelProps={{
                                style: {
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                            disabled={!!member?.terminated_at}
                            InputProps={{
                                style: {
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                        >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                        </TextField>
                    </Grid>
                ) : null}
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Company
                    </Typography>
                    <TextField
                        fullWidth
                        id="company_name"
                        placeholder="Company"
                        size="small"
                        variant="outlined"
                        value={formik.values.company_name}
                        className={formik.touched.company_name && Boolean(formik.errors.company_name) ? 'green' : 'red'}
                        error={formik.touched.company_name && Boolean(formik.errors.company_name)}
                        helperText={formik.touched.company_name && formik.errors.company_name}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.company_name && Boolean(formik.errors.company_name)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.company_name && Boolean(formik.errors.company_name)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.company_name && Boolean(formik.errors.company_name)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={!!member?.terminated_at}
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Address
                    </Typography>
                    <TextField
                        fullWidth
                        id="address"
                        placeholder="Address"
                        size="small"
                        variant="outlined"
                        {...formik.getFieldProps('address')}
                        className={formik.touched.address && Boolean(formik.errors.address) ? 'green' : 'red'}
                        error={formik.touched.address && Boolean(formik.errors.address)}
                        helperText={formik.touched.address && formik.errors.address}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.address && Boolean(formik.errors.address) ? 'red' : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.address && Boolean(formik.errors.address) ? 'red' : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.address && Boolean(formik.errors.address) ? 'red' : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={!!member?.terminated_at}
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        City
                    </Typography>
                    <TextField
                        fullWidth
                        id="city"
                        placeholder="City"
                        size="small"
                        variant="outlined"
                        {...formik.getFieldProps('city')}
                        className={formik.touched.city && Boolean(formik.errors.city) ? 'green' : 'red'}
                        error={formik.touched.city && Boolean(formik.errors.city)}
                        helperText={formik.touched.city && formik.errors.city}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.city && Boolean(formik.errors.city) ? 'red' : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.city && Boolean(formik.errors.city) ? 'red' : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.city && Boolean(formik.errors.city) ? 'red' : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={!!member?.terminated_at}
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        State
                    </Typography>
                    <TextField
                        fullWidth
                        id="state"
                        placeholder="State"
                        size="small"
                        variant="outlined"
                        {...formik.getFieldProps('state')}
                        className={formik.touched.state && Boolean(formik.errors.state) ? 'green' : 'red'}
                        error={formik.touched.state && Boolean(formik.errors.state)}
                        helperText={formik.touched.state && formik.errors.state}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.state && Boolean(formik.errors.state) ? 'red' : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.state && Boolean(formik.errors.state) ? 'red' : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.state && Boolean(formik.errors.state) ? 'red' : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={!!member?.terminated_at}
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Zip
                    </Typography>
                    <TextField
                        fullWidth
                        id="zip"
                        placeholder="Zip"
                        size="small"
                        variant="outlined"
                        {...formik.getFieldProps('zip')}
                        className={formik.touched.zip && Boolean(formik.errors.zip) ? 'green' : 'red'}
                        error={formik.touched.zip && Boolean(formik.errors.zip)}
                        helperText={formik.touched.zip && formik.errors.zip}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.zip && Boolean(formik.errors.zip) ? 'red' : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.zip && Boolean(formik.errors.zip) ? 'red' : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.zip && Boolean(formik.errors.zip) ? 'red' : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={!!member?.terminated_at}
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Contact Person
                    </Typography>
                    <TextField
                        fullWidth
                        id="contact_person"
                        placeholder="Contact Person"
                        size="small"
                        variant="outlined"
                        {...formik.getFieldProps('contact_person')}
                        className={
                            formik.touched.contact_person && Boolean(formik.errors.contact_person) ? 'green' : 'red'
                        }
                        error={formik.touched.contact_person && Boolean(formik.errors.contact_person)}
                        helperText={formik.touched.contact_person && formik.errors.contact_person}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.contact_person && Boolean(formik.errors.contact_person)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.contact_person && Boolean(formik.errors.contact_person)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.contact_person && Boolean(formik.errors.contact_person)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={!!member?.terminated_at}
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Contact Phone
                    </Typography>
                    <TextField
                        fullWidth
                        id="contact_phone"
                        placeholder="Contact Phone"
                        size="small"
                        variant="outlined"
                        {...formik.getFieldProps('contact_phone')}
                        className={
                            formik.touched.contact_phone && Boolean(formik.errors.contact_phone) ? 'green' : 'red'
                        }
                        error={formik.touched.contact_phone && Boolean(formik.errors.contact_phone)}
                        helperText={formik.touched.contact_phone && formik.errors.contact_phone}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.contact_phone && Boolean(formik.errors.contact_phone)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.contact_phone && Boolean(formik.errors.contact_phone)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.contact_phone && Boolean(formik.errors.contact_phone)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={!!member?.terminated_at}
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Date Employed From
                    </Typography>
                    <TextField
                        fullWidth
                        id="start_date"
                        placeholder="Date Employed From"
                        size="small"
                        variant="outlined"
                        type="date"
                        value={formik.values.start_date}
                        onChange={(e) => {
                            formik.setFieldValue('start_date', e.target.value);
                        }}
                        className={formik.touched.start_date && Boolean(formik.errors.start_date) ? 'green' : 'red'}
                        error={formik.touched.start_date && Boolean(formik.errors.start_date)}
                        helperText={formik.touched.start_date && formik.errors.start_date}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.start_date && Boolean(formik.errors.start_date) ? 'red' : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.start_date && Boolean(formik.errors.start_date) ? 'red' : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.start_date && Boolean(formik.errors.start_date) ? 'red' : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={!!member?.terminated_at}
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Date Employed To
                    </Typography>
                    <TextField
                        fullWidth
                        id="end_date"
                        placeholder="Date Employed To"
                        size="small"
                        variant="outlined"
                        value={formik.values.end_date}
                        onChange={(e) => {
                            formik.setFieldValue('end_date', e.target.value);
                        }}
                        type="date"
                        className={formik.touched.end_date && Boolean(formik.errors.end_date) ? 'green' : 'red'}
                        error={formik.touched.end_date && Boolean(formik.errors.end_date)}
                        helperText={formik.touched.end_date && formik.errors.end_date}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.end_date && Boolean(formik.errors.end_date) ? 'red' : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.end_date && Boolean(formik.errors.end_date) ? 'red' : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.end_date && Boolean(formik.errors.end_date) ? 'red' : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={!!member?.terminated_at}
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Position Held
                    </Typography>
                    <TextField
                        fullWidth
                        id="position"
                        placeholder="Position Held"
                        size="small"
                        variant="outlined"
                        {...formik.getFieldProps('position')}
                        className={formik.touched.position && Boolean(formik.errors.position) ? 'green' : 'red'}
                        error={formik.touched.position && Boolean(formik.errors.position)}
                        helperText={formik.touched.position && formik.errors.position}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.position && Boolean(formik.errors.position) ? 'red' : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.position && Boolean(formik.errors.position) ? 'red' : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.position && Boolean(formik.errors.position) ? 'red' : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={!!member?.terminated_at}
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Reason for Leaving
                    </Typography>
                    <TextField
                        fullWidth
                        id="reason_for_leaving"
                        placeholder="Reason for Leaving"
                        size="small"
                        variant="outlined"
                        {...formik.getFieldProps('reason_for_leaving')}
                        className={
                            formik.touched.reason_for_leaving && Boolean(formik.errors.reason_for_leaving)
                                ? 'green'
                                : 'red'
                        }
                        error={formik.touched.reason_for_leaving && Boolean(formik.errors.reason_for_leaving)}
                        helperText={formik.touched.reason_for_leaving && formik.errors.reason_for_leaving}
                        multiline
                        rows={3}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.reason_for_leaving && Boolean(formik.errors.reason_for_leaving)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.reason_for_leaving && Boolean(formik.errors.reason_for_leaving)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.reason_for_leaving && Boolean(formik.errors.reason_for_leaving)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={!!member?.terminated_at}
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Were you subject to FMCSR's while employed?
                    </Typography>
                    <TextField
                        fullWidth
                        id="subject_to_fmcsrs"
                        size="small"
                        variant="outlined"
                        value={formik.values.subject_to_fmcsrs ? 'Yes' : 'No'}
                        onChange={(e) => {
                            formik.setFieldValue('subject_to_fmcsrs', e.target.value === 'Yes');
                        }}
                        select
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.subject_to_fmcsrs && Boolean(formik.errors.subject_to_fmcsrs)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.subject_to_fmcsrs && Boolean(formik.errors.subject_to_fmcsrs)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.subject_to_fmcsrs && Boolean(formik.errors.subject_to_fmcsrs)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={!!member?.terminated_at}
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Was your job designated as safety sensitive function in any DOT-regulated mode subject to the
                        drug and alcohol testing requirements of 49 CFR Part 40?
                    </Typography>
                    <TextField
                        fullWidth
                        id="safety_sensitive_function"
                        size="small"
                        variant="outlined"
                        value={formik.values.safety_sensitive_function ? 'Yes' : 'No'}
                        onChange={(e) => {
                            formik.setFieldValue('safety_sensitive_function', e.target.value === 'Yes');
                        }}
                        select
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.safety_sensitive_function &&
                                        Boolean(formik.errors.safety_sensitive_function)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.safety_sensitive_function &&
                                        Boolean(formik.errors.safety_sensitive_function)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.safety_sensitive_function &&
                                        Boolean(formik.errors.safety_sensitive_function)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={!!member?.terminated_at}
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                    </TextField>
                </Grid>
                {!item || (item && currentUser && ['Admin', 'SuperAdmin'].includes(currentUser?.role)) ? (
                    <>
                        <Grid item xs={12} sm={6}>
                            <Button
                                fullWidth
                                disabled={isUpdating || !!member?.terminated_at}
                                onClick={() => formik.handleSubmit()}
                                variant="contained"
                                color="primary"
                            >
                                Update
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button
                                fullWidth
                                disabled={isDeleting || !!member?.terminated_at}
                                onClick={() => {
                                    const res = window.confirm('Are you sure you want to delete this item?');
                                    if (res) {
                                        deleteEmploymentInformation(item.id);
                                    }
                                }}
                                variant="contained"
                                color="error"
                            >
                                Delete
                            </Button>
                        </Grid>
                    </>
                ) : null}
            </Grid>
            {currentUser &&
            ['Admin', 'SuperAdmin', 'Owner'].includes(currentUser?.role) &&
            !params['*']?.includes('employment-history') &&
            (!member?.pre_employment_screened ||
                (member?.pre_employment_screened && member?.pre_employment_investigation.length > 0)) ? (
                <>
                    <Divider sx={{ borderWidth: 3, marginBottom: 5 }} />
                    <EmploymentVerification
                        history={item}
                        employment_verification={item.employment_verification}
                        member={member}
                        id={item.id}
                    />
                    <Divider sx={{ borderWidth: 3, marginBottom: 5 }} />
                    <HasAccidentHistory
                        has_accident_history={item.driver_accident_history}
                        member={member}
                        id={item.id}
                    />
                    {item?.driver_accident_history?.has_accident_record ? (
                        <>
                            <Divider sx={{ borderWidth: 3, marginBottom: 5 }} />
                            <AccidentRecord
                                has_acident_record={item?.driver_accident_history}
                                member={member}
                                id={item?.driver_accident_history?.id ?? 1}
                            />
                        </>
                    ) : null}
                    {(!member?.pre_employment_screened ||
                        (member?.pre_employment_screened && member.pre_employment_investigation.length > 2)) &&
                        item.agency_under_dot && (
                            <>
                                <Divider sx={{ borderWidth: 3, marginBottom: 5 }} />
                                <FtaDachInfo
                                    data={item.fta_dach_info}
                                    // @ts-ignore
                                    employer_information={item?.id}
                                    member={member}
                                />
                            </>
                        )}
                </>
            ) : null}
            <Grid item xs={12} sm={12}>
                <Divider
                    style={{
                        marginTop: 5,
                        marginBottom: 5,
                        borderTopWidth: 1,
                        borderTopStyle: 'solid',
                        borderTopColor: 'gray'
                    }}
                />
            </Grid>
        </>
    );
};

const EmployerHistory = ({ member, isDriverFile }: { member?: IMember; isDriverFile?: boolean }) => {
    const { mode } = useThemeMode();
    const { currentUser } = useAuth();
    const [showFtaQuestion, setShowFtaQuestion] = useState(false);
    const { data = [] } = useGetEmployerInformationByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    });
    const [createEmploymentInformaiton, { isLoading: isCreating }] = useCreateEmploymentInformationMutation();
    const [agency, setAgency] = useState<FTACompanyDto>();

    const params = useParams<{ '*': string; id: string }>();

    const { data: applicant } = useGetApplicantInformationByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    });

    const [addNewItem, setAddNewItem] = useState(true);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            if (member) {
                createEmploymentInformaiton({
                    ...values,
                    // @ts-ignore
                    member: member.id,
                    // @ts-ignore
                    company: member.company.id
                }).then(() => {
                    //  Refresh the page
                    window.location.reload();
                });
            }
        },
        validateOnBlur: true,
        validateOnChange: true
    });

    useEffect(() => {
        if (member) {
            formik.setValues({
                // @ts-ignore
                company: member?.company.id,
                // @ts-ignore
                member: member?.id
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [member]);

    useEffect(() => {
        if (data.length > 0) {
            setAddNewItem(false);
        } else {
            setAddNewItem(true);
        }
    }, [data]);

    useEffect(() => {
        if (applicant) {
            const ftaCompanies = applicant?.fta_companies;
            const ftaCompanyCount = data?.filter((x) => x.agency_under_dot);
            if (ftaCompanies.length === ftaCompanyCount.length || member?.pre_employment_screened) {
                setShowFtaQuestion(false);
            } else if (ftaCompanyCount.length < ftaCompanies.length && !member?.pre_employment_screened) {
                setShowFtaQuestion(true);
            }
        }
    }, [applicant, data, member?.pre_employment_screened]);

    useEffect(() => {
        if (formik.values.agency_under_dot && agency) {
            formik.setValues({
                ...formik.values,
                company_name: agency?.company_name,
                contact_person: agency?.contact_name,
                contact_phone: agency?.phone_number
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agency, formik.values.agency_under_dot]);

    return (
        <div
            style={{
                padding: 10,
                margin: 10
            }}
        >
            <Typography
                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: 'center' }}
                variant="h4"
                gutterBottom
                component="h4"
            >
                Employer History
            </Typography>
            <Typography
                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: 'center' }}
                variant="body1"
                gutterBottom
                component="p"
            >
                NOTE: All driver applicants that drive in interstate commerce must provide the following information on
                all previous employers of the last 3 years. Applicants who drive a commercial motor vehicle in
                intrastate or interstate commerce must also provide an additional 7 years of information on previous
                employers for whom the applicant operated such vehicle.
            </Typography>
            {!params['*']?.includes('employment-history') ? (
                <Typography
                    sx={{ color: 'green', textAlign: 'center', mt: 3 }}
                    variant="body1"
                    gutterBottom
                    component="p"
                >
                    This section is a repeat of the previous employers you provided on the Employment History form.
                    Please continue to the bottom of the page and click Done. Compliance Masters will perform the safety
                    performance investigation on these previous employers.
                </Typography>
            ) : null}
            {params['*']?.includes('employment-history') ? (
                <Typography
                    sx={{ color: 'green', textAlign: 'center', mt: 3 }}
                    variant="body1"
                    gutterBottom
                    component="p"
                >
                    List previous employers in order starting with the most recent
                </Typography>
            ) : null}
            <Typography
                sx={{
                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                    textAlign: 'center',
                    mt: 2,
                    mb: 2
                }}
                variant="h5"
                gutterBottom
                component="h5"
            >
                Previous Employer Information
            </Typography>
            {data.map((item) => {
                return (
                    <EmployerHistoryItem showFtaQuestion={showFtaQuestion} member={member} key={item.id} item={item} />
                );
            })}
            <Grid container spacing={3}>
                {data.length > 0 && params['*']?.includes('employment-history') ? (
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <FormControlLabel
                            sx={{
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }}
                            control={
                                <Checkbox
                                    sx={{
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }}
                                    checked={addNewItem}
                                />
                            }
                            onChange={() => setAddNewItem(!addNewItem)}
                            label="Add Another Previous Employer"
                        />
                    </Grid>
                ) : null}
                {addNewItem && (
                    <>
                        {showFtaQuestion && (
                            <>
                                <Grid item xs={12} sm={12}>
                                    <Typography
                                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                                        variant="body1"
                                        gutterBottom
                                        component="p"
                                    >
                                        Is this previous employer an agency under the Department of Transportation
                                        (DOT), other than the FMCSA?
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        id="agency_under_dot"
                                        size="small"
                                        variant="outlined"
                                        value={formik.values.agency_under_dot ? 'Yes' : 'No'}
                                        onChange={(e) => {
                                            formik.setFieldValue('agency_under_dot', e.target.value === 'Yes');
                                        }}
                                        select
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    // Check Validation
                                                    borderColor:
                                                        formik.touched.agency_under_dot &&
                                                        Boolean(formik.errors.agency_under_dot)
                                                            ? 'red'
                                                            : 'green'
                                                },
                                                '&:hover fieldset': {
                                                    // Check Validation
                                                    borderColor:
                                                        formik.touched.agency_under_dot &&
                                                        Boolean(formik.errors.agency_under_dot)
                                                            ? 'red'
                                                            : 'green'
                                                },
                                                '&.Mui-focused fieldset': {
                                                    // Check Validation
                                                    borderColor:
                                                        formik.touched.agency_under_dot &&
                                                        Boolean(formik.errors.agency_under_dot)
                                                            ? 'red'
                                                            : 'green'
                                                }
                                            }
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                            }
                                        }}
                                        disabled={!!member?.terminated_at}
                                        InputProps={{
                                            style: {
                                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                            }
                                        }}
                                    >
                                        <MenuItem value="Yes">Yes</MenuItem>
                                        <MenuItem value="No">No</MenuItem>
                                    </TextField>
                                </Grid>
                                {formik.values.agency_under_dot ? (
                                    <Grid item xs={12} sm={12}>
                                        <Typography
                                            sx={{
                                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                            }}
                                            variant="body1"
                                            gutterBottom
                                            component="p"
                                        >
                                            Select Company
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            id="agency_under_dot"
                                            size="small"
                                            variant="outlined"
                                            value={agency?.id}
                                            onChange={(e) => {
                                                const agency = applicant?.fta_companies.find(
                                                    (item) => Number(item.id) === Number(e.target.value)
                                                );
                                                if (agency) {
                                                    setAgency(agency);
                                                }
                                            }}
                                            select
                                            InputLabelProps={{
                                                style: {
                                                    color:
                                                        mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                                }
                                            }}
                                            disabled={!!member?.terminated_at}
                                            InputProps={{
                                                style: {
                                                    color:
                                                        mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                                }
                                            }}
                                        >
                                            {applicant?.fta_companies
                                                .filter(
                                                    (fta) =>
                                                        fta.company_name !==
                                                        data?.find((x) => x.company_name)?.company_name
                                                )
                                                .map((item) => (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        {item.company_name}
                                                    </MenuItem>
                                                ))}
                                        </TextField>
                                    </Grid>
                                ) : null}
                            </>
                        )}
                        <Grid item xs={12} sm={12}>
                            <Typography
                                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Company
                            </Typography>
                            <TextField
                                fullWidth
                                id="company_name"
                                placeholder="Company"
                                size="small"
                                variant="outlined"
                                {...formik.getFieldProps('company_name')}
                                className={
                                    formik.touched.company_name && Boolean(formik.errors.company_name) ? 'green' : 'red'
                                }
                                error={formik.touched.company_name && Boolean(formik.errors.company_name)}
                                helperText={formik.touched.company_name && formik.errors.company_name}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.company_name && Boolean(formik.errors.company_name)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&:hover fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.company_name && Boolean(formik.errors.company_name)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&.Mui-focused fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.company_name && Boolean(formik.errors.company_name)
                                                    ? 'red'
                                                    : 'green'
                                        }
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                                disabled={!!member?.terminated_at}
                                InputProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography
                                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Address
                            </Typography>
                            <TextField
                                fullWidth
                                id="address"
                                placeholder="Address"
                                autoComplete="off"
                                size="small"
                                variant="outlined"
                                {...formik.getFieldProps('address')}
                                className={formik.touched.address && Boolean(formik.errors.address) ? 'green' : 'red'}
                                error={formik.touched.address && Boolean(formik.errors.address)}
                                helperText={formik.touched.address && formik.errors.address}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.address && Boolean(formik.errors.address)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&:hover fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.address && Boolean(formik.errors.address)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&.Mui-focused fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.address && Boolean(formik.errors.address)
                                                    ? 'red'
                                                    : 'green'
                                        }
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                                disabled={!!member?.terminated_at}
                                InputProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography
                                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                City
                            </Typography>
                            <TextField
                                fullWidth
                                id="city"
                                placeholder="City"
                                size="small"
                                variant="outlined"
                                autoComplete="off"
                                {...formik.getFieldProps('city')}
                                className={formik.touched.city && Boolean(formik.errors.city) ? 'green' : 'red'}
                                error={formik.touched.city && Boolean(formik.errors.city)}
                                helperText={formik.touched.city && formik.errors.city}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.city && Boolean(formik.errors.city) ? 'red' : 'green'
                                        },
                                        '&:hover fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.city && Boolean(formik.errors.city) ? 'red' : 'green'
                                        },
                                        '&.Mui-focused fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.city && Boolean(formik.errors.city) ? 'red' : 'green'
                                        }
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                                disabled={!!member?.terminated_at}
                                InputProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography
                                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                State
                            </Typography>
                            <TextField
                                fullWidth
                                id="state"
                                placeholder="State"
                                autoComplete="off"
                                size="small"
                                variant="outlined"
                                {...formik.getFieldProps('state')}
                                className={formik.touched.state && Boolean(formik.errors.state) ? 'green' : 'red'}
                                error={formik.touched.state && Boolean(formik.errors.state)}
                                helperText={formik.touched.state && formik.errors.state}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.state && Boolean(formik.errors.state) ? 'red' : 'green'
                                        },
                                        '&:hover fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.state && Boolean(formik.errors.state) ? 'red' : 'green'
                                        },
                                        '&.Mui-focused fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.state && Boolean(formik.errors.state) ? 'red' : 'green'
                                        }
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                                disabled={!!member?.terminated_at}
                                InputProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography
                                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Zip
                            </Typography>
                            <TextField
                                fullWidth
                                autoComplete="off"
                                id="zip"
                                placeholder="Zip"
                                size="small"
                                variant="outlined"
                                {...formik.getFieldProps('zip')}
                                className={formik.touched.zip && Boolean(formik.errors.zip) ? 'green' : 'red'}
                                error={formik.touched.zip && Boolean(formik.errors.zip)}
                                helperText={formik.touched.zip && formik.errors.zip}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.zip && Boolean(formik.errors.zip) ? 'red' : 'green'
                                        },
                                        '&:hover fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.zip && Boolean(formik.errors.zip) ? 'red' : 'green'
                                        },
                                        '&.Mui-focused fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.zip && Boolean(formik.errors.zip) ? 'red' : 'green'
                                        }
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                                disabled={!!member?.terminated_at}
                                InputProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography
                                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Contact Person
                            </Typography>
                            <TextField
                                fullWidth
                                autoComplete="off"
                                id="contact_person"
                                placeholder="Contact Person"
                                size="small"
                                variant="outlined"
                                {...formik.getFieldProps('contact_person')}
                                className={
                                    formik.touched.contact_person && Boolean(formik.errors.contact_person)
                                        ? 'green'
                                        : 'red'
                                }
                                error={formik.touched.contact_person && Boolean(formik.errors.contact_person)}
                                helperText={formik.touched.contact_person && formik.errors.contact_person}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.contact_person && Boolean(formik.errors.contact_person)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&:hover fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.contact_person && Boolean(formik.errors.contact_person)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&.Mui-focused fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.contact_person && Boolean(formik.errors.contact_person)
                                                    ? 'red'
                                                    : 'green'
                                        }
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                                disabled={!!member?.terminated_at}
                                InputProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography
                                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Contact Phone
                            </Typography>
                            <TextField
                                fullWidth
                                autoComplete="off"
                                id="contact_phone"
                                placeholder="Contact Phone"
                                size="small"
                                variant="outlined"
                                {...formik.getFieldProps('contact_phone')}
                                className={
                                    formik.touched.contact_phone && Boolean(formik.errors.contact_phone)
                                        ? 'green'
                                        : 'red'
                                }
                                error={formik.touched.contact_phone && Boolean(formik.errors.contact_phone)}
                                helperText={formik.touched.contact_phone && formik.errors.contact_phone}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.contact_phone && Boolean(formik.errors.contact_phone)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&:hover fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.contact_phone && Boolean(formik.errors.contact_phone)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&.Mui-focused fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.contact_phone && Boolean(formik.errors.contact_phone)
                                                    ? 'red'
                                                    : 'green'
                                        }
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                                disabled={!!member?.terminated_at}
                                InputProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography
                                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Date Employed From
                            </Typography>
                            <TextField
                                fullWidth
                                id="start_date"
                                placeholder="Date Employed From"
                                size="small"
                                variant="outlined"
                                type="date"
                                {...formik.getFieldProps('start_date')}
                                className={
                                    formik.touched.start_date && Boolean(formik.errors.start_date) ? 'green' : 'red'
                                }
                                error={formik.touched.start_date && Boolean(formik.errors.start_date)}
                                helperText={formik.touched.start_date && formik.errors.start_date}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.start_date && Boolean(formik.errors.start_date)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&:hover fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.start_date && Boolean(formik.errors.start_date)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&.Mui-focused fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.start_date && Boolean(formik.errors.start_date)
                                                    ? 'red'
                                                    : 'green'
                                        }
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                                disabled={!!member?.terminated_at}
                                InputProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography
                                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Date Employed To
                            </Typography>
                            <TextField
                                fullWidth
                                id="end_date"
                                placeholder="Date Employed To"
                                size="small"
                                variant="outlined"
                                {...formik.getFieldProps('end_date')}
                                type="date"
                                className={formik.touched.end_date && Boolean(formik.errors.end_date) ? 'green' : 'red'}
                                error={formik.touched.end_date && Boolean(formik.errors.end_date)}
                                helperText={formik.touched.end_date && formik.errors.end_date}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.end_date && Boolean(formik.errors.end_date)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&:hover fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.end_date && Boolean(formik.errors.end_date)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&.Mui-focused fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.end_date && Boolean(formik.errors.end_date)
                                                    ? 'red'
                                                    : 'green'
                                        }
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                                disabled={!!member?.terminated_at}
                                InputProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography
                                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Position Held
                            </Typography>
                            <TextField
                                fullWidth
                                id="position"
                                placeholder="Position Held"
                                size="small"
                                variant="outlined"
                                {...formik.getFieldProps('position')}
                                className={formik.touched.position && Boolean(formik.errors.position) ? 'green' : 'red'}
                                error={formik.touched.position && Boolean(formik.errors.position)}
                                helperText={formik.touched.position && formik.errors.position}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.position && Boolean(formik.errors.position)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&:hover fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.position && Boolean(formik.errors.position)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&.Mui-focused fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.position && Boolean(formik.errors.position)
                                                    ? 'red'
                                                    : 'green'
                                        }
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                                disabled={!!member?.terminated_at}
                                InputProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography
                                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Reason for Leaving
                            </Typography>
                            <TextField
                                fullWidth
                                id="reason_for_leaving"
                                placeholder="Reason for Leaving"
                                size="small"
                                variant="outlined"
                                {...formik.getFieldProps('reason_for_leaving')}
                                className={
                                    formik.touched.reason_for_leaving && Boolean(formik.errors.reason_for_leaving)
                                        ? 'green'
                                        : 'red'
                                }
                                error={formik.touched.reason_for_leaving && Boolean(formik.errors.reason_for_leaving)}
                                helperText={formik.touched.reason_for_leaving && formik.errors.reason_for_leaving}
                                multiline
                                rows={3}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.reason_for_leaving &&
                                                Boolean(formik.errors.reason_for_leaving)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&:hover fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.reason_for_leaving &&
                                                Boolean(formik.errors.reason_for_leaving)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&.Mui-focused fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.reason_for_leaving &&
                                                Boolean(formik.errors.reason_for_leaving)
                                                    ? 'red'
                                                    : 'green'
                                        }
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                                disabled={!!member?.terminated_at}
                                InputProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography
                                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Were you subject to FMCSR's while employed?
                            </Typography>
                            <TextField
                                fullWidth
                                id="subject_to_fmcsrs"
                                size="small"
                                variant="outlined"
                                value={formik.values.subject_to_fmcsrs ? 'Yes' : 'No'}
                                onChange={(e) => {
                                    formik.setFieldValue('subject_to_fmcsrs', e.target.value === 'Yes');
                                }}
                                select
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.subject_to_fmcsrs &&
                                                Boolean(formik.errors.subject_to_fmcsrs)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&:hover fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.subject_to_fmcsrs &&
                                                Boolean(formik.errors.subject_to_fmcsrs)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&.Mui-focused fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.subject_to_fmcsrs &&
                                                Boolean(formik.errors.subject_to_fmcsrs)
                                                    ? 'red'
                                                    : 'green'
                                        }
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                                disabled={!!member?.terminated_at}
                                InputProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                            >
                                <MenuItem value="Yes">Yes</MenuItem>
                                <MenuItem value="No">No</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography
                                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Was your job designated as safety sensitive function in any DOT-regulated mode subject
                                to the drug and alcohol testing requirements of 49 CFR Part 40?
                            </Typography>
                            <TextField
                                fullWidth
                                id="safety_sensitive_function"
                                size="small"
                                variant="outlined"
                                value={formik.values.safety_sensitive_function ? 'Yes' : 'No'}
                                onChange={(e) => {
                                    formik.setFieldValue('safety_sensitive_function', e.target.value === 'Yes');
                                }}
                                select
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.safety_sensitive_function &&
                                                Boolean(formik.errors.safety_sensitive_function)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&:hover fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.safety_sensitive_function &&
                                                Boolean(formik.errors.safety_sensitive_function)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&.Mui-focused fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.safety_sensitive_function &&
                                                Boolean(formik.errors.safety_sensitive_function)
                                                    ? 'red'
                                                    : 'green'
                                        }
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                                disabled={!!member?.terminated_at}
                                InputProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                            >
                                <MenuItem value="Yes">Yes</MenuItem>
                                <MenuItem value="No">No</MenuItem>
                            </TextField>
                        </Grid>
                        {!isDriverFile && (
                            <Grid item xs={12} sm={12}>
                                <Button
                                    fullWidth
                                    disabled={isCreating || !!member?.terminated_at}
                                    onClick={() => formik.handleSubmit()}
                                    variant="contained"
                                    color="primary"
                                >
                                    Save
                                </Button>
                                {currentUser?.role !== 'Admin' && currentUser?.role !== 'SuperAdmin' && (
                                    <Typography
                                        sx={{ color: 'red', textAlign: 'center', mt: 2 }}
                                        variant="body1"
                                        gutterBottom
                                        component="p"
                                    >
                                        Once you click save, you will not be able to edit this form.
                                    </Typography>
                                )}
                            </Grid>
                        )}
                    </>
                )}
            </Grid>
        </div>
    );
};

export default EmployerHistory;
