import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { toAbsoluteUrl } from '../../../../compliancemasters/helpers';
import { IMaintenanceVehicle } from '../../../../store/interfaces/PreventableMaintenance/maintenance.vehicles';
import { useAuth } from '../../../modules/auth';
import LinkRow from '../../../modules/profile/ProfileHeader/components/LinkRow';

interface ILinkRow {
    name: string;
    path: string;
    show?: boolean;
}

interface IProps {
    vehicle?: IMaintenanceVehicle;
}

const links: ILinkRow[] = [
    {
        name: 'PM Program',
        path: 'pm-program',
        show: true
    },
    {
        name: 'Driver Vehicle Inspection Report (DVIR)',
        path: 'driver-vehicle-inspection-report',
        show: true
    },
    {
        name: 'Service Orders',
        path: 'service-orders',
        show: true
    },
    {
        name: 'Notes and Links',
        path: 'notes-and-links',
        show: true
    }
];

const VehicleProfile: React.FunctionComponent<IProps> = ({ vehicle }) => {
    const { currentUser } = useAuth();
    return (
        <>
            {currentUser?.role === 'Owner' && (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => window.location.assign('/preventable-maintenance')}
                    sx={{ borderRadius: 2, width: 200, height: 40, mt: 4 }}
                >
                    Back to Vehicle List
                </Button>
            )}
            <Box component={Paper} mt={4} elevation={2} boxShadow={3} px={3} sx={{ borderRadius: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={4} sm={4} md={4} lg={3}>
                        <Box justifyContent="center">
                            <img
                                style={{ borderRadius: 15, width: 150 }}
                                src={toAbsoluteUrl('/media/avatars/blank.png')}
                                alt="vehicle"
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={8} sm={8} md={8} lg={9}>
                        <Grid container spacing={2} p={3}>
                            <Grid item xs={12}>
                                <div>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'gray' }}>
                                        Vehicle Number:
                                        <span style={{ color: 'gray', fontWeight: 'normal' }}>
                                            {' '}
                                            {vehicle?.vehicle_number}
                                        </span>
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'gray' }}>
                                        Vehicle Make:
                                        <span style={{ color: 'gray', fontWeight: 'normal' }}>
                                            {' '}
                                            {vehicle?.vehicle_make}
                                        </span>
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'gray' }}>
                                        Vehicle Model:
                                        <span style={{ color: 'gray', fontWeight: 'normal' }}>
                                            {' '}
                                            {vehicle?.vehicle_model}
                                        </span>
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'gray' }}>
                                        Initial Odometer Reading:
                                        <span style={{ color: 'gray', fontWeight: 'normal' }}>
                                            {' '}
                                            {vehicle?.initial_odometer_reading
                                                ? Intl.NumberFormat('en-US', {
                                                      compactDisplay: 'short'
                                                  }).format(vehicle?.initial_odometer_reading)
                                                : 'Pending...'}
                                        </span>
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'gray' }}>
                                        Current Odometer Reading:
                                        <span style={{ color: 'gray', fontWeight: 'normal' }}>
                                            {' '}
                                            {vehicle?.current_odometer_reading
                                                ? Intl.NumberFormat('en-US', {
                                                      compactDisplay: 'short'
                                                  }).format(vehicle?.current_odometer_reading)
                                                : 'Pending...'}
                                        </span>
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <LinkRow links={links} />
            </Box>
        </>
    );
};

export default VehicleProfile;
