import { useEffect, useState } from 'react';
import { getConnectedPaymentMethods, getSubscriptionPlans, useNavigation } from '../../../shared';
import { ISubscription } from '../../../models/IPayments';
import Button from '../../modules/buttons/Button';
import { useCompany } from '../../../context/CompanyContext';

const Subscriptions = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loading, setLoading] = useState(true);
    const [subscriptions, setSubscriptions] = useState<ISubscription[]>([]);
    const { navigateToPayment } = useNavigation();
    const { paymentInfo, setPaymentInfo } = useCompany();

    const getPaymentInfo = async () => {
        let payments = await getConnectedPaymentMethods();
        if (payments.length > 0 && setPaymentInfo) {
            setPaymentInfo(payments);
        }
    };

    const getSubs = async () => {
        const subs = await getSubscriptionPlans();
        if (subs) {
            setLoading(false);
            setSubscriptions(subs.data as ISubscription[]);
        } else {
            setLoading(false);
        }
    };

    useEffect(() => {
        getSubs();
        getPaymentInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="py-20 landing-dark-bg">
            <div className="container">
                {paymentInfo && paymentInfo.length ? (
                    <div className="d-flex flex-column container pt-lg-20">
                        <div className="text-center d-flex flex-row justify-content-between" id="kt_pricing">
                            {subscriptions
                                ? subscriptions.map((plan, index) => (
                                      <div className="col-xl-3" key={index}>
                                          <div className="d-flex h-100 align-items-center">
                                              <div className="w-100 d-flex flex-column flex-center rounded-3 bg-body py-15 px-10">
                                                  <div className="mb-7 text-center">
                                                      <h1 className="text-dark mb-5 fw-boldest">{plan.name}</h1>
                                                      <div className="text-center">
                                                          <span className="mb-2 text-primary">$</span>
                                                          <span
                                                              className="fs-3x fw-bold text-primary"
                                                              data-kt-plan-price-month={99}
                                                              data-kt-plan-price-annual={999}
                                                          >
                                                              {plan?.tiers[0].unit_amount &&
                                                                  plan?.tiers[0].unit_amount / 100}
                                                          </span>
                                                          <span
                                                              className="fs-7 fw-semibold opacity-50"
                                                              data-kt-plan-price-month="Mon"
                                                              data-kt-plan-price-annual="Ann"
                                                          >
                                                              / Month per driver
                                                          </span>
                                                      </div>
                                                  </div>
                                                  <div className="d-flex flex-stack mb-5 justify-content-center">
                                                      <Button
                                                          onClick={() => {
                                                              // window.location.assign(`/subscriptions/${plan.id}`)
                                                          }}
                                                      >
                                                          Subscribe
                                                      </Button>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  ))
                                : null}
                        </div>
                    </div>
                ) : (
                    <div className="card align-items-center">
                        <div className="card-header">
                            <h1 className="card-title">Connect payment before selecting subscription plan</h1>
                        </div>
                        <div className="card-body text-center col-lg-10">
                            <button
                                id="kt_sign_up_submit"
                                className="btn btn-lg btn-primary w-100 mb-10"
                                data-bs-dismiss="modal"
                                onClick={() => navigateToPayment()}
                            >
                                <span className="indicator-label">Add payment</span>
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Subscriptions;
