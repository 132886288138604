import { Button, Grid, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { useAuth } from '../../../app/modules/auth';
import { IMember } from '../../../models';
import { IAuthorizedEmployerRepresentativeSignatureDto } from '../../../store/interfaces/DrugandAlcoholEmployeesCertifiedReceipt/authorized-employer-representative-signature';
import { useGetCertificationByUserIdQuery } from '../../../store/reducers/CertificateOfComplianceWithDriverLicenseRequirements/certification';
import { useGetProcessRecordByUserIdQuery } from '../../../store/reducers/DriversApplicationForEmployment/process-record';
import {
    useCreateAuthorizedEmployerRepresentativeSignatureMutation,
    useGetAuthorizedEmployerRepresentativeSignatureByUserIdQuery,
    useUpdateAuthorizedEmployerRepresentativeSignatureMutation
} from '../../../store/reducers/DrugandAlcoholEmployeesCertifiedReceipt/authorized-employer-representative-signature';
import { useThemeMode } from '../../partials';

const initialValues: Partial<IAuthorizedEmployerRepresentativeSignatureDto> = {
    authorized_employer_representative_signature: '',
    date: ''
};

const validationSchema = Yup.object({
    authorized_employer_representative_signature: Yup.string().required('Required'),
    date: Yup.string().required('Required')
});

interface IProps {
    member?: IMember;
    isDriverFile?: boolean;
}

export const AuthorizedEmployerRepresentativeSignature = ({ member, isDriverFile }: IProps) => {
    const { mode } = useThemeMode();
    const { currentUser } = useAuth();
    const { data } = useGetAuthorizedEmployerRepresentativeSignatureByUserIdQuery(member?.user?.id!, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true
    });

    const { data: cert } = useGetCertificationByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true
    });

    const { data: processRecord } = useGetProcessRecordByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    });

    const [createAuthorizedEmployerRepresentativeSignature, { isLoading: isCreating }] =
        useCreateAuthorizedEmployerRepresentativeSignatureMutation();
    const [updateAuthorizedEmployerRepresentativeSignature, { isLoading: isUpdating }] =
        useUpdateAuthorizedEmployerRepresentativeSignatureMutation();

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (data) {
                updateAuthorizedEmployerRepresentativeSignature({
                    ...data,
                    ...values
                });
            } else {
                createAuthorizedEmployerRepresentativeSignature({
                    ...values,
                    // @ts-ignore
                    company: member?.company?.id,
                    // @ts-ignore
                    member: member?.id
                });
            }
        },
        validateOnBlur: true,
        validateOnChange: true,
        validate(values) {
            const errors: any = {};
            if (!values.authorized_employer_representative_signature) {
                errors.authorized_employer_representative_signature = 'Required';
            }
            if (!values.date) {
                errors.date = 'Required';
            }
            return errors;
        }
    });

    useEffect(() => {
        if (data) {
            formik.setValues(data);
        } else if (processRecord) {
            formik.setFieldValue('date', processRecord?.date_employed);
        } else if (cert) {
            formik.setFieldValue('date', cert?.date_of_hire);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, cert, processRecord]);

    return (
        <div
            style={{
                padding: 10,
                margin: 10
            }}
        >
            <Typography
                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: 'center' }}
                variant="h4"
                gutterBottom
                component="h4"
            >
                Authorized Employer Representative Signature
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Authorized Employer Representative:
                    </Typography>
                    <TextField
                        fullWidth
                        id="authorized_employer_representative_signature"
                        placeholder="Authorized Employer Representative Signature"
                        size="small"
                        variant="outlined"
                        value={formik.values.authorized_employer_representative_signature}
                        onChange={(e) => {
                            formik.setFieldValue('authorized_employer_representative_signature', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.authorized_employer_representative_signature &&
                                        Boolean(formik.errors.authorized_employer_representative_signature)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.authorized_employer_representative_signature &&
                                        Boolean(formik.errors.authorized_employer_representative_signature)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.authorized_employer_representative_signature &&
                                        Boolean(formik.errors.authorized_employer_representative_signature)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                fontStyle: 'italic'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Date of Hire
                    </Typography>
                    <TextField
                        fullWidth
                        id="date"
                        type="date"
                        size="small"
                        variant="outlined"
                        value={formik.values.date}
                        onChange={(e) => {
                            formik.setFieldValue('date', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? 'red' : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? 'red' : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? 'red' : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                {(!data || (data && currentUser && ['Admin', 'SuperAdmin'].includes(currentUser?.role))) &&
                !isDriverFile ? (
                    <Grid item xs={12} sm={12}>
                        <Button
                            fullWidth
                            disabled={isCreating || isUpdating || !!member?.terminated_at}
                            onClick={() => formik.handleSubmit()}
                            variant="contained"
                            color="primary"
                        >
                            Save
                        </Button>
                        {currentUser?.role !== 'Admin' && currentUser?.role !== 'SuperAdmin' && (
                            <Typography
                                sx={{ color: 'red', textAlign: 'center', mt: 2 }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Once you click save, you will not be able to edit this form.
                            </Typography>
                        )}
                    </Grid>
                ) : null}
            </Grid>
        </div>
    );
};
