import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
  IDriverEducation,
  IDriverEducationDto,
  IDriverSignature,
  IDriverSignatureDto,
} from '../../interfaces/EmploymentHistory/driver-education';

export const driverEducationApi = createApi({
  reducerPath: 'driver_education',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/drivers-education`,
  }),
  tagTypes: ['DriverEducation'],
  keepUnusedDataFor: 60 * 10, // 10 minutes
  endpoints: (builder) => ({
    createDriverEducation: builder.mutation<IDriverEducation, IDriverEducationDto>({
      query: (body) => ({
        url: '/',
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'DriverEducation', id: 'DriverEducation'}],
    }),
    updateDriverEducation: builder.mutation<IDriverEducation, IDriverEducation>({
      query: (body) => ({
        url: `/${body.id}/`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'DriverEducation', id: 'DriverEducation'}],
    }),
    deleteDriversEducation: builder.mutation<void, number>({
      query: (id) => ({
        url: `/${id}/`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'DriverEducation', id: 'DriverEducation'}],
    }),
    getDriverEducation: builder.query<IDriverEducation, number | void>({
      query: (id) => ({
        url: '/get-drivers-education/',
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'DriverEducation', id: 'DriverEducation'}],
    }),
    getDriverEducationByUser: builder.query<IDriverEducation, void>({
      query: () => ({
        url: '/get-drivers-education-by-user/',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'DriverEducation', id: 'DriverEducation'}],
    }),
    getDriverEducationByUserId: builder.query<IDriverEducation, number | void>({
      query: (id) => ({
        url: '/get-drivers-education-by-user-id/',
        method: 'POST',
        body: {
          userId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'DriverEducation', id: 'DriverEducation'}],
    }),
  }),
});

export const employmentHistoryDriverSignatureApi = createApi({
  reducerPath: 'employment_history_driver_signature',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/employment-history-drivers-signature`,
  }),
  tagTypes: ['EmploymentHistoryDriverSignature'],
  endpoints: (builder) => ({
    createEmploymentHistoryDriverSignature: builder.mutation<IDriverSignature, IDriverSignatureDto>(
      {
        query: (body) => ({
          url: '/',
          method: 'POST',
          body,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
          },
        }),
        invalidatesTags: [
          {type: 'EmploymentHistoryDriverSignature', id: 'EmploymentHistoryDriverSignature'},
        ],
      },
    ),
    updateEmploymentHistoryDriverSignature: builder.mutation<IDriverSignature, IDriverSignature>({
      query: (body) => ({
        url: `/${body.id}/`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [
        {type: 'EmploymentHistoryDriverSignature', id: 'EmploymentHistoryDriverSignature'},
      ],
    }),
    deleteEmploymentHistoryDriverSignature: builder.mutation<void, number>({
      query: (id) => ({
        url: `/${id}/`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [
        {type: 'EmploymentHistoryDriverSignature', id: 'EmploymentHistoryDriverSignature'},
      ],
    }),
    getEmploymentHistoryDriverSignature: builder.query<IDriverSignature, number | void>({
      query: (id) => ({
        url: '/get-drivers-signature/',
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [
        {type: 'EmploymentHistoryDriverSignature', id: 'EmploymentHistoryDriverSignature'},
      ],
    }),
    getEmploymentHistoryDriverSignatureByUser: builder.query<IDriverSignature, void>({
      query: () => ({
        url: '/get-drivers-signature-by-user/',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [
        {type: 'EmploymentHistoryDriverSignature', id: 'EmploymentHistoryDriverSignature'},
      ],
    }),
    getEmploymentHistoryDriverSignatureByUserId: builder.query<IDriverSignature, number | void>({
      query: (id) => ({
        url: '/get-drivers-signature-by-user-id/',
        method: 'POST',
        body: {
          userId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [
        {
          type: 'EmploymentHistoryDriverSignature',
          id: 'EmploymentHistoryDriverSignature',
        },
      ],
    }),
  }),
});

export const {
  useCreateDriverEducationMutation,
  useUpdateDriverEducationMutation,
  useGetDriverEducationQuery,
  useGetDriverEducationByUserQuery,
  useGetDriverEducationByUserIdQuery,
  useDeleteDriversEducationMutation,
} = driverEducationApi;
export const {
  useCreateEmploymentHistoryDriverSignatureMutation,
  useUpdateEmploymentHistoryDriverSignatureMutation,
  useGetEmploymentHistoryDriverSignatureQuery,
  useGetEmploymentHistoryDriverSignatureByUserQuery,
  useGetEmploymentHistoryDriverSignatureByUserIdQuery,
  useDeleteEmploymentHistoryDriverSignatureMutation,
} = employmentHistoryDriverSignatureApi;
