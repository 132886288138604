import {accidentRecordApi} from './accident-record';
import {applicantQuestionsApi} from './applicant-questions';
import {driverEducationApi, employmentHistoryDriverSignatureApi} from './drivers-education';
import {drivingExperienceApi} from './driving-experience';
import {employmentInformationApi} from './employer-information';
import {experienceAndQualificationsApi} from './experience-and-qualification';
import {employmentHistoryProgress} from './progress';
import {trafficConvictionsApi} from './traffic-convictions';

export const EmploymentHistoryReducers = {
  [accidentRecordApi.reducerPath]: accidentRecordApi.reducer,
  [applicantQuestionsApi.reducerPath]: applicantQuestionsApi.reducer,
  [driverEducationApi.reducerPath]: driverEducationApi.reducer,
  [drivingExperienceApi.reducerPath]: drivingExperienceApi.reducer,
  [employmentInformationApi.reducerPath]: employmentInformationApi.reducer,
  [experienceAndQualificationsApi.reducerPath]: experienceAndQualificationsApi.reducer,
  [trafficConvictionsApi.reducerPath]: trafficConvictionsApi.reducer,
  [employmentHistoryDriverSignatureApi.reducerPath]: employmentHistoryDriverSignatureApi.reducer,
  [employmentHistoryProgress.reducerPath]: employmentHistoryProgress.reducer,
};

export const EmploymentHistoryMiddleware = [
  accidentRecordApi.middleware,
  applicantQuestionsApi.middleware,
  driverEducationApi.middleware,
  drivingExperienceApi.middleware,
  employmentInformationApi.middleware,
  experienceAndQualificationsApi.middleware,
  trafficConvictionsApi.middleware,
  employmentHistoryDriverSignatureApi.middleware,
  employmentHistoryProgress.middleware,
];
