/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react';
import { useLayout } from '../core';
import ContactModal from './ContactModal';

const Footer: FC = () => {
    const [open, setOpen] = useState(false);
    const { classes } = useLayout();
    return (
        <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
            {/* begin::Container */}
            <div
                className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
            >
                {/* begin::Copyright */}
                {/* end::Copyright */}

                {/* begin::Nav */}
                <ul className="menu menu-gray-600 menu-hover-primary fw-bold order-1">
                    <li className="menu-item" onClick={() => setOpen(true)}>
                        <a href="#" className="menu-link pe-0 pe-2">
                            Contact
                        </a>
                    </li>
                </ul>
                {/* end::Nav */}
            </div>
            {/* end::Container */}
            <ContactModal open={open} setOpen={setOpen} />
        </div>
    );
};

export { Footer };
