import { Link } from 'react-router-dom';
import {
    useGetLimitedQueriesDachProgressMutation,
    useGetMemberLimitedQueriesDachProgressMutation
} from '../../../../../store/reducers/LimitedQueriesDrugAndAlcoholClearinghouse/progress';
import { KTSVG } from '../../../../../compliancemasters/helpers';
import { useState, useEffect } from 'react';

const DachClearingHouseQueries = ({ id }: { id: number }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [progress, setProgress] = useState<number>(0);
    const [getProgress, { data }] = useGetLimitedQueriesDachProgressMutation();
    const [getMemberProgress, { data: pg }] = useGetMemberLimitedQueriesDachProgressMutation();

    useEffect(() => {
        getProgress(id);
        getMemberProgress(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (data) {
            setProgress(data?.progress);
        } else if (pg) {
            setProgress(pg?.progress);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, pg]);

    return (
        <tr>
            <td className="mw-125px">
                <span
                    className="text-dark fw-bold text-hover-primary mb-1 fs-6 cursor-pointer"
                    onClick={() =>
                        window.location.assign(
                            `/member/${id}/drug-file/consent-for-limited-queries-of-the-fmcsa-drug-and-alcohol-clearinghouse`
                        )
                    }
                >
                    Consent for Limited Queries Of The FMCSA Drug and Alcohol Clearinghouse
                </span>
            </td>
            <td>
                <div className="d-flex flex-column w-75 me-2">
                    {/* <ProgressBar progress={progress ? progress : 0} size={6} /> */}
                </div>
            </td>
            <td>
                {/* <StatusTag status={progress && (progress > 0 && progress < 100) ? "In Progress" : progress && progress > 99 ? "Completed" : "Not Started"} /> */}
            </td>
            <td className="mw-125px">
                <span className="text-success fw-bold cursor-pointer text-hover-primary mb-1 fs-6">
                    {/* Owner Signature Required */}
                </span>
            </td>
            <td className="text-end">
                <Link
                    className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                    to={`/member/${id}/drug-file/consent-for-limited-queries-of-the-fmcsa-drug-and-alcohol-clearinghouse`}
                >
                    <KTSVG path="/media/icons/duotune/arrows/arr064.svg" className="svg-icon-2" />
                </Link>
            </td>
        </tr>
    );
};

export default DachClearingHouseQueries;
