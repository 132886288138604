import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
  ICompanyDriverInformation,
  ICompanyDriverInformationDto,
} from '../../interfaces/DocumentationofActualKnowledgeofDOTDAndAViolation/company-driver-information';

export const companyDriverInformationApi = createApi({
  reducerPath: 'company_driver_information',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/company-driver-information`,
  }),
  keepUnusedDataFor: 60 * 10, // 10 minutes
  tagTypes: ['CompanyDriverInformation'],
  endpoints: (builder) => ({
    createCompanyDriverInformation: builder.mutation<
      ICompanyDriverInformation,
      ICompanyDriverInformationDto
    >({
      query: (body) => ({
        url: '/',
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'CompanyDriverInformation', id: 'CompanyDriverInformation'}],
    }),
    updateCompanyDriverInformation: builder.mutation<
      ICompanyDriverInformation,
      ICompanyDriverInformation
    >({
      query: (body) => ({
        url: `/${body.id}/`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'CompanyDriverInformation', id: 'CompanyDriverInformation'}],
    }),
    deleteCompanyDriverInformation: builder.mutation<void, number>({
      query: (id) => ({
        url: `/${id}/`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      // invalidatesTags: [{ type: 'CompanyDriverInformation', id: 'CompanyDriverInformation' }]
    }),
    getCompanyDriverInformation: builder.mutation<ICompanyDriverInformation, number | void>({
      query: (id) => ({
        url: '/get-company-driver-information/',
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
    }),
    getCompanyDriverInformationByUser: builder.query<ICompanyDriverInformation, void>({
      query: () => ({
        url: '/get-company-driver-information-by-user/',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'CompanyDriverInformation', id: 'CompanyDriverInformation'}],
    }),
    getCompanyDriverInformationByUserId: builder.query<ICompanyDriverInformation, number>({
      query: (id) => ({
        url: '/get-company-driver-information-by-user-id/',
        method: 'POST',
        body: {
          userId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'CompanyDriverInformation', id: 'CompanyDriverInformation'}],
    }),
  }),
});

export const {
  useCreateCompanyDriverInformationMutation,
  useUpdateCompanyDriverInformationMutation,
  useGetCompanyDriverInformationMutation,
  useGetCompanyDriverInformationByUserQuery,
  useGetCompanyDriverInformationByUserIdQuery,
  useDeleteCompanyDriverInformationMutation,
} = companyDriverInformationApi;
