import { Box, SxProps, Theme } from '@mui/material';

export default function TableWrapper({
    children,
    sx
}: {
    children: React.ReactNode[] | React.ReactNode;
    sx?: SxProps<Theme>;
}) {
    return (
        <Box sx={sx} className={`card card-xxl-stretch mb-5 mb-xl-8`}>
            {children}
        </Box>
    );
}
