import { Button, Grid, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { useAuth } from '../../../app/modules/auth';
import { IMember } from '../../../models';
import {
    IWitnessAndOwnerSignature,
    IWitnessAndOwnerSignatureDto
} from '../../../store/interfaces/DocumentationofActualKnowledgeofDOTDAndAViolation/witness-and-owner-signature';
import {
    useDeleteWitnessAndOwnerSignatureMutation,
    useUpdateWitnessAndOwnerSignatureMutation
} from '../../../store/reducers/DocumentationofActualKnowledgeofDOTDAndAViolation/witness-and-owner-signature';
import { useThemeMode } from '../../partials';

const initialValues: Partial<IWitnessAndOwnerSignatureDto> = {
    name: '',
    contact_information: ''
};

const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    contact_information: Yup.string().required('Required')
});

interface IProps {
    data: IWitnessAndOwnerSignature;
    member?: IMember;
}

const UpdateWitnessAndActualSignature = ({ data, member }: IProps) => {
    const { currentUser } = useAuth();
    const [updateWitnessAndActualSignature, { isLoading: isUpdating }] = useUpdateWitnessAndOwnerSignatureMutation();
    const [deleteWitnessAndActualSignature, { isLoading: isDeleting }] = useDeleteWitnessAndOwnerSignatureMutation();
    const { mode } = useThemeMode();

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            updateWitnessAndActualSignature({
                ...data,
                ...values
            });
            resetForm();
        },
        validateOnBlur: true,
        validateOnChange: true,
        validate(values) {
            const errors: Partial<IWitnessAndOwnerSignatureDto> = {};
            if (!values.name) {
                errors.name = 'Required';
            }
            if (!values.contact_information) {
                errors.contact_information = 'Required';
            }
            return errors;
        }
    });

    useEffect(() => {
        if (data) {
            formik.setValues(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <>
            <Grid item xs={12} sm={12}>
                <Typography
                    sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                    variant="body1"
                    gutterBottom
                    component="p"
                >
                    Name
                </Typography>
                <TextField
                    fullWidth
                    id="name"
                    placeholder="Name"
                    size="small"
                    variant="outlined"
                    value={formik.values.name}
                    onChange={(e) => {
                        formik.setFieldValue('name', e.target.value);
                    }}
                    disabled={!!member?.terminated_at}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                // Check Validation
                                borderColor: formik.touched.name && Boolean(formik.errors.name) ? 'red' : 'green'
                            },
                            '&:hover fieldset': {
                                // Check Validation
                                borderColor: formik.touched.name && Boolean(formik.errors.name) ? 'red' : 'green'
                            },
                            '&.Mui-focused fieldset': {
                                // Check Validation
                                borderColor: formik.touched.name && Boolean(formik.errors.name) ? 'red' : 'green'
                            }
                        }
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                        }
                    }}
                    InputProps={{
                        style: {
                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                        }
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography
                    sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                    variant="body1"
                    gutterBottom
                    component="p"
                >
                    Contact Information
                </Typography>
                <TextField
                    fullWidth
                    id="contact_information"
                    placeholder="Contact Information"
                    size="small"
                    variant="outlined"
                    value={formik.values.contact_information}
                    onChange={(e) => {
                        formik.setFieldValue('contact_information', e.target.value);
                    }}
                    disabled={!!member?.terminated_at}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                // Check Validation
                                borderColor:
                                    formik.touched.contact_information && Boolean(formik.errors.contact_information)
                                        ? 'red'
                                        : 'green'
                            },
                            '&:hover fieldset': {
                                // Check Validation
                                borderColor:
                                    formik.touched.contact_information && Boolean(formik.errors.contact_information)
                                        ? 'red'
                                        : 'green'
                            },
                            '&.Mui-focused fieldset': {
                                // Check Validation
                                borderColor:
                                    formik.touched.contact_information && Boolean(formik.errors.contact_information)
                                        ? 'red'
                                        : 'green'
                            }
                        }
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                        }
                    }}
                    InputProps={{
                        style: {
                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                        }
                    }}
                />
            </Grid>
            {!data || (data && currentUser && ['Admin', 'SuperAdmin'].includes(currentUser?.role)) ? (
                <>
                    <Grid item xs={12} sm={6}>
                        <Button
                            fullWidth
                            disabled={isUpdating || !!member?.terminated_at}
                            onClick={() => formik.handleSubmit()}
                            variant="contained"
                            color="primary"
                        >
                            Update
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            fullWidth
                            disabled={isDeleting || !!member?.terminated_at}
                            onClick={() => {
                                if (data.id && window.confirm('Are you sure you want to delete this item?')) {
                                    deleteWitnessAndActualSignature(data.id);
                                }
                            }}
                            variant="contained"
                            color="error"
                        >
                            Delete
                        </Button>
                    </Grid>
                </>
            ) : null}
        </>
    );
};

export default UpdateWitnessAndActualSignature;
