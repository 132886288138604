import { Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef, ReactElement, Ref, useEffect, useState } from 'react';
import { ICompany } from '../../../models';
import { getSubscriptionPlans } from '../../../shared';
import { StripeProduct } from '../../modules/models';

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement<any, any>;
    },
    ref: Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    company: ICompany;
}

export default function ViewCompanyDetail({ company, open, setOpen }: IProps) {
    const [companySubscriptions, setCompanySubscriptions] = useState<StripeProduct[]>([]);

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (open) {
            getSubscriptionPlans().then((res) => {
                setCompanySubscriptions(res?.data);
            });
        }
    }, [open]);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle
                sx={{
                    backgroundColor: '#f5f5f5',
                    padding: '1rem 1.5rem',
                    textAlign: 'center'
                }}
            >
                {company?.name} Details
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <DialogContentText>
                            <strong>Company Name:</strong>
                        </DialogContentText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DialogContentText>{company?.name}</DialogContentText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DialogContentText>
                            <strong>Company Owners Name:</strong>
                        </DialogContentText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DialogContentText>
                            {company?.user?.first_name} {company?.user?.last_name}
                        </DialogContentText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DialogContentText>
                            <strong>Phone Number:</strong>
                        </DialogContentText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DialogContentText>{company?.phone_number}</DialogContentText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DialogContentText>
                            <strong>DOT Number:</strong>
                        </DialogContentText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DialogContentText>{company?.dot_number}</DialogContentText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DialogContentText>
                            <strong>Address Line 1:</strong>
                        </DialogContentText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DialogContentText>{company?.address1}</DialogContentText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DialogContentText>
                            <strong>Address Line 2:</strong>
                        </DialogContentText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DialogContentText>{company?.address2}</DialogContentText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DialogContentText>
                            <strong>City:</strong>
                        </DialogContentText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DialogContentText>{company?.city}</DialogContentText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DialogContentText>
                            <strong>State:</strong>
                        </DialogContentText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DialogContentText>{company?.state}</DialogContentText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DialogContentText>
                            <strong>Zip Code:</strong>
                        </DialogContentText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DialogContentText>{company?.zip_code}</DialogContentText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DialogContentText>
                            <strong>Date Account Added:</strong>
                        </DialogContentText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DialogContentText>
                            {company?.created_at ? new Date(company?.created_at).toLocaleDateString() : ''}
                        </DialogContentText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DialogContentText>
                            <strong>Driver Count:</strong>
                        </DialogContentText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DialogContentText>{company?.members?.length}</DialogContentText>
                    </Grid>
                    {companySubscriptions?.length > 0 ? (
                        <>
                            <Grid item xs={12} sm={6}>
                                <DialogContentText>
                                    <strong>Packages Subscribed To:</strong>
                                </DialogContentText>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <DialogContentText>
                                    {company?.package?.map((x) => {
                                        const pkg = companySubscriptions?.find((item) => item.product_id === x.id);
                                        return (
                                            <div key={x.id}>
                                                <strong>{pkg?.name}:</strong>{' '}
                                                {x?.numberOfDrivers === 1
                                                    ? `${x?.numberOfDrivers} Driver`
                                                    : `${x?.numberOfDrivers} Drivers`}
                                            </div>
                                        );
                                    })}
                                </DialogContentText>
                            </Grid>
                        </>
                    ) : null}
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
