import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
    FTACompanyDto,
    IApplicationInformation
} from '../../../store/interfaces/DriversApplicationForEmployment/applicant.information';
import { useThemeMode } from '../../partials';
import { Box, Button, Checkbox, FormControlLabel, Grid, Paper, TextField, Typography } from '@mui/material';
import { IMember } from '../../../models';
import { useCreateFtaCompanyMutation } from '../../../store/reducers/DriversApplicationForEmployment/applicant-information';

const ftaCompany: Partial<FTACompanyDto> = {
    applicant: 0,
    company_name: '',
    contact_name: '',
    phone_number: ''
};

const ftaCompanyValidationSchema = Yup.object({
    company_name: Yup.string().required('Required').min(3, 'Must be at least 3 characters'),
    contact_name: Yup.string().required('Required').min(3, 'Must be at least 3 characters'),
    phone_number: Yup.string().required('Required').min(3, 'Must be at least 3 characters')
});

interface IProps {
    member?: IMember;
    applicant?: IApplicationInformation;
}

export const FTADetailItem = ({ ftaData, member }: { ftaData?: FTACompanyDto; member?: IMember }) => {
    const { mode } = useThemeMode();

    const ftaFormik = useFormik({
        initialValues: ftaCompany,
        validationSchema: ftaCompanyValidationSchema,
        onSubmit: (values) => {}
    });

    useEffect(() => {
        if (ftaData) {
            ftaFormik.setFieldValue('applicant', ftaData.applicant);
            ftaFormik.setFieldValue('company_name', ftaData.company_name);
            ftaFormik.setFieldValue('contact_name', ftaData.contact_name);
            ftaFormik.setFieldValue('phone_number', ftaData.phone_number);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ftaData]);

    return (
        <div
            style={{
                padding: 10,
                margin: 10
            }}
        >
            <Grid
                container
                spacing={3}
                p={1}
                sx={{
                    alignItems: 'center'
                }}
            >
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Company Name
                    </Typography>
                    <TextField
                        fullWidth
                        id="company_name"
                        placeholder="Company Name"
                        size="small"
                        variant="outlined"
                        {...ftaFormik.getFieldProps('company_name')}
                        className={
                            ftaFormik.touched.company_name && Boolean(ftaFormik.errors.company_name) ? 'green' : 'red'
                        }
                        error={ftaFormik.touched.company_name && Boolean(ftaFormik.errors.company_name)}
                        helperText={ftaFormik.touched.company_name && ftaFormik.errors.company_name}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        ftaFormik.touched.company_name && Boolean(ftaFormik.errors.company_name)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        ftaFormik.touched.company_name && Boolean(ftaFormik.errors.company_name)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        ftaFormik.touched.company_name && Boolean(ftaFormik.errors.company_name)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={!!member?.terminated_at}
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Contact Name
                    </Typography>
                    <TextField
                        fullWidth
                        id="contact_name"
                        placeholder="Contact Name"
                        size="small"
                        variant="outlined"
                        {...ftaFormik.getFieldProps('contact_name')}
                        className={
                            ftaFormik.touched.contact_name && Boolean(ftaFormik.errors.contact_name) ? 'green' : 'red'
                        }
                        error={ftaFormik.touched.contact_name && Boolean(ftaFormik.errors.contact_name)}
                        helperText={ftaFormik.touched.contact_name && ftaFormik.errors.contact_name}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        ftaFormik.touched.contact_name && Boolean(ftaFormik.errors.contact_name)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        ftaFormik.touched.contact_name && Boolean(ftaFormik.errors.contact_name)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        ftaFormik.touched.contact_name && Boolean(ftaFormik.errors.contact_name)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={!!member?.terminated_at}
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Phone Number
                    </Typography>
                    <TextField
                        fullWidth
                        id="phone_number"
                        placeholder="Phone Number"
                        size="small"
                        variant="outlined"
                        {...ftaFormik.getFieldProps('phone_number')}
                        className={
                            ftaFormik.touched.phone_number && Boolean(ftaFormik.errors.phone_number) ? 'green' : 'red'
                        }
                        error={ftaFormik.touched.phone_number && Boolean(ftaFormik.errors.phone_number)}
                        helperText={ftaFormik.touched.phone_number && ftaFormik.errors.phone_number}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        ftaFormik.touched.phone_number && Boolean(ftaFormik.errors.phone_number)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        ftaFormik.touched.phone_number && Boolean(ftaFormik.errors.phone_number)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        ftaFormik.touched.phone_number && Boolean(ftaFormik.errors.phone_number)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={!!member?.terminated_at}
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

const FTADetails = ({ applicant, member }: IProps) => {
    const { mode } = useThemeMode();
    const [addNewItem, setAddNewItem] = useState(true);
    const [createFtaCompany, { isLoading }] = useCreateFtaCompanyMutation();

    const ftaFormik = useFormik({
        initialValues: ftaCompany,
        validationSchema: ftaCompanyValidationSchema,
        onSubmit: (values, { resetForm }) => {
            createFtaCompany(values).then((res) => {
                resetForm();
            });
        }
    });

    useEffect(() => {
        if (applicant) {
            ftaFormik.setFieldValue('applicant', applicant.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicant]);

    useEffect(() => {
        if (applicant && applicant?.fta_companies?.length > 0) {
            setAddNewItem(false);
        } else {
            setAddNewItem(true);
        }
    }, [applicant]);

    return (
        <div
            style={{
                padding: 10,
                margin: 10
            }}
        >
            <Box
                sx={{
                    pl: 3,
                    pr: 3,
                    Radius: 2,
                    backgroundColor: mode === 'dark' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    width: {
                        lg: '47vw',
                        md: '70vw',
                        sm: '80vw'
                    },
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: 3
                }}
                component={Paper}
            >
                <Typography
                    sx={{
                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        textAlign: 'center'
                    }}
                    variant="h4"
                    gutterBottom
                    component="h4"
                >
                    Non-FMCSA Previous Employers
                </Typography>
                <Typography
                    sx={{
                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        textAlign: 'center'
                    }}
                    variant="h6"
                    gutterBottom
                    component="h4"
                >
                    Please provide the previous employers that are not under the FMCSA.
                </Typography>
                {applicant?.fta_companies?.map((company) => (
                    <FTADetailItem ftaData={company} member={member} key={company.id} />
                ))}
                <Grid container spacing={3} p={1}>
                    {applicant && applicant?.fta_companies.length > 0 ? (
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <FormControlLabel
                                sx={{
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }}
                                control={
                                    <Checkbox
                                        sx={{
                                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                        }}
                                        checked={addNewItem}
                                    />
                                }
                                onChange={() => setAddNewItem(!addNewItem)}
                                label="Add Another Company"
                            />
                        </Grid>
                    ) : null}
                    {addNewItem && (
                        <>
                            <Grid item xs={12} sm={12}>
                                <Typography
                                    sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                                    variant="body1"
                                    gutterBottom
                                    component="p"
                                >
                                    Company Name
                                </Typography>
                                <TextField
                                    fullWidth
                                    id="company_name"
                                    placeholder="Company Name"
                                    size="small"
                                    variant="outlined"
                                    {...ftaFormik.getFieldProps('company_name')}
                                    className={
                                        ftaFormik.touched.company_name && Boolean(ftaFormik.errors.company_name)
                                            ? 'green'
                                            : 'red'
                                    }
                                    error={ftaFormik.touched.company_name && Boolean(ftaFormik.errors.company_name)}
                                    helperText={ftaFormik.touched.company_name && ftaFormik.errors.company_name}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                // Check Validation
                                                borderColor:
                                                    ftaFormik.touched.company_name &&
                                                    Boolean(ftaFormik.errors.company_name)
                                                        ? 'red'
                                                        : 'green'
                                            },
                                            '&:hover fieldset': {
                                                // Check Validation
                                                borderColor:
                                                    ftaFormik.touched.company_name &&
                                                    Boolean(ftaFormik.errors.company_name)
                                                        ? 'red'
                                                        : 'green'
                                            },
                                            '&.Mui-focused fieldset': {
                                                // Check Validation
                                                borderColor:
                                                    ftaFormik.touched.company_name &&
                                                    Boolean(ftaFormik.errors.company_name)
                                                        ? 'red'
                                                        : 'green'
                                            }
                                        }
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                        }
                                    }}
                                    disabled={!!member?.terminated_at}
                                    InputProps={{
                                        style: {
                                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography
                                    sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                                    variant="body1"
                                    gutterBottom
                                    component="p"
                                >
                                    Contact Name
                                </Typography>
                                <TextField
                                    fullWidth
                                    id="contact_name"
                                    placeholder="Contact Name"
                                    size="small"
                                    variant="outlined"
                                    {...ftaFormik.getFieldProps('contact_name')}
                                    className={
                                        ftaFormik.touched.contact_name && Boolean(ftaFormik.errors.contact_name)
                                            ? 'green'
                                            : 'red'
                                    }
                                    error={ftaFormik.touched.contact_name && Boolean(ftaFormik.errors.contact_name)}
                                    helperText={ftaFormik.touched.contact_name && ftaFormik.errors.contact_name}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                // Check Validation
                                                borderColor:
                                                    ftaFormik.touched.contact_name &&
                                                    Boolean(ftaFormik.errors.contact_name)
                                                        ? 'red'
                                                        : 'green'
                                            },
                                            '&:hover fieldset': {
                                                // Check Validation
                                                borderColor:
                                                    ftaFormik.touched.contact_name &&
                                                    Boolean(ftaFormik.errors.contact_name)
                                                        ? 'red'
                                                        : 'green'
                                            },
                                            '&.Mui-focused fieldset': {
                                                // Check Validation
                                                borderColor:
                                                    ftaFormik.touched.contact_name &&
                                                    Boolean(ftaFormik.errors.contact_name)
                                                        ? 'red'
                                                        : 'green'
                                            }
                                        }
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                        }
                                    }}
                                    disabled={!!member?.terminated_at}
                                    InputProps={{
                                        style: {
                                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography
                                    sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                                    variant="body1"
                                    gutterBottom
                                    component="p"
                                >
                                    Phone Number
                                </Typography>
                                <TextField
                                    fullWidth
                                    id="phone_number"
                                    placeholder="Phone Number"
                                    size="small"
                                    variant="outlined"
                                    {...ftaFormik.getFieldProps('phone_number')}
                                    className={
                                        ftaFormik.touched.phone_number && Boolean(ftaFormik.errors.phone_number)
                                            ? 'green'
                                            : 'red'
                                    }
                                    error={ftaFormik.touched.phone_number && Boolean(ftaFormik.errors.phone_number)}
                                    helperText={ftaFormik.touched.phone_number && ftaFormik.errors.phone_number}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                // Check Validation
                                                borderColor:
                                                    ftaFormik.touched.phone_number &&
                                                    Boolean(ftaFormik.errors.phone_number)
                                                        ? 'red'
                                                        : 'green'
                                            },
                                            '&:hover fieldset': {
                                                // Check Validation
                                                borderColor:
                                                    ftaFormik.touched.phone_number &&
                                                    Boolean(ftaFormik.errors.phone_number)
                                                        ? 'red'
                                                        : 'green'
                                            },
                                            '&.Mui-focused fieldset': {
                                                // Check Validation
                                                borderColor:
                                                    ftaFormik.touched.phone_number &&
                                                    Boolean(ftaFormik.errors.phone_number)
                                                        ? 'red'
                                                        : 'green'
                                            }
                                        }
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                        }
                                    }}
                                    disabled={!!member?.terminated_at}
                                    InputProps={{
                                        style: {
                                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Button
                                    fullWidth
                                    disabled={isLoading || !!member?.terminated_at}
                                    onClick={() => ftaFormik.handleSubmit()}
                                    variant="contained"
                                    color="primary"
                                >
                                    Save
                                </Button>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Box>
        </div>
    );
};

export default FTADetails;
