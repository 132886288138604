import { Link } from 'react-router-dom';
import { KTSVG } from '../../../../../compliancemasters/helpers';
import { useGetNonCurrentDriverMedicalExaminerInformationAndOwnerSignatureByUserIdQuery } from '../../../../../store/reducers/DriverMedicalExaminerRegistryVerification/driver-medical-examiner-information-and-owner-signature';
import { IMember } from '../../../../../models';
import { toast } from 'react-toastify';

const MedicalExaminerRegistryVerification = ({ member }: { member?: IMember }) => {
    const { data = [] } = useGetNonCurrentDriverMedicalExaminerInformationAndOwnerSignatureByUserIdQuery(
        member?.user?.id,
        {
            skip: !member?.user?.id,
            refetchOnMountOrArgChange: true,
            refetchOnFocus: true
        }
    );

    return (
        <tr>
            <td className="mw-125px">
                <span
                    className="text-dark fw-bold text-hover-primary mb-1 fs-6 cursor-pointer"
                    onClick={() => {
                        if (data.length > 0) {
                            window.location.assign(
                                `/member/${member?.id}/non-current/medical-examiner-national-registry-verification`
                            );
                        } else {
                            return toast.warning('There is no data in this form');
                        }
                    }}
                >
                    Medical Examiner National Registry Verification
                </span>
            </td>
            <td>
                <div className="d-flex flex-column w-75 me-2"></div>
            </td>
            <td></td>
            <td className="mw-125px">
                <span className="text-success fw-bold cursor-pointer text-hover-primary mb-1 fs-6"></span>
            </td>
            <td className="text-end">
                <Link
                    className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                    onClick={(e) => {
                        e.preventDefault();
                        if (data.length > 0) {
                            window.location.assign(
                                `/member/${member?.id}/non-current/medical-examiner-national-registry-verification`
                            );
                        } else {
                            return toast.warning('There is no data in this form');
                        }
                    }}
                    to={`/member/${member?.id}/non-current/medical-examiner-national-registry-verification`}
                >
                    <KTSVG path="/media/icons/duotune/arrows/arr064.svg" className="svg-icon-2" />
                </Link>
            </td>
        </tr>
    );
};

export default MedicalExaminerRegistryVerification;
