import React, { forwardRef, ReactElement, Ref, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Box, Button, Grid, InputLabel, MenuItem, Paper, TextField } from '@mui/material';
import {
    CompanyInvitation,
    CompanyInvitationDto,
    CompanyPaymentStatus,
    ICompanyPackage
} from '../../../context/CompanyInvitation/company.invitation.types';
import { useQuery } from 'react-query';
import { getSubscriptionPlans } from '../../../shared';
import { StripeProduct } from '../../modules/models';
import useCompanyInvitations from '../../../context/CompanyInvitation/CompanyInvitationContextProvider';
import { toast } from 'react-toastify';

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement<any, any>;
    },
    ref: Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    companyInvitation?: CompanyInvitation;
}

const initialValues: CompanyInvitationDto = {
    email: '',
    first_name: '',
    last_name: '',
    payment_status: CompanyPaymentStatus.PENDING,
    company_package: [],
    email_sent: false
};

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string().required('Last name is required'),
    company_package: Yup.array().min(1, 'Company package is required')
});

export default function CompanyInvitationModal({ open, setOpen, companyInvitation }: Readonly<IProps>) {
    const { createInvitation, updateInvitation, getInvitations } = useCompanyInvitations();
    const [selectedPackage, setSelectedPackage] = useState<StripeProduct | undefined>();
    const [selectedPackageQuantity, setSelectedPackageQuantity] = useState<number>(0);
    const handleClose = () => {
        setOpen(false);
    };

    const { data } = useQuery<{ data: StripeProduct[] }>('companyPackages', async () => getSubscriptionPlans());

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            if (companyInvitation) {
                updateInvitation({
                    ...companyInvitation,
                    ...values
                })
                    .then(() => {
                        toast.success('Invitation updated');
                        getInvitations();
                        setOpen(false);
                    })
                    .catch((error) => {
                        toast.error('Error updating invitation');
                    });
            } else {
                createInvitation(values)
                    .then(() => {
                        toast.success('Invitation created');
                        getInvitations();
                        setOpen(false);
                    })
                    .catch((error) => {
                        toast.error('Error creating invitation');
                    });
            }
        }
    });

    const formikRef = React.useRef(formik);

    useEffect(() => {
        if (companyInvitation && open) {
            formikRef.current.setValues({
                ...companyInvitation,
                company_package: companyInvitation?.company_package ? companyInvitation.company_package : []
            });
        }
    }, [companyInvitation, open]);

    return (
        <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle sx={{ textAlign: 'center' }}>
                {companyInvitation ? 'Update Company Invitation' : 'Create Company Invitation'}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} p={2}>
                    <Grid item xs={12}>
                        <InputLabel>First Name</InputLabel>
                        <TextField
                            size="small"
                            fullWidth
                            name="first_name"
                            value={formik.values.first_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel>Last Name</InputLabel>
                        <TextField
                            size="small"
                            fullWidth
                            name="last_name"
                            value={formik.values.last_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel>Email</InputLabel>
                        <TextField
                            size="small"
                            fullWidth
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel>Company Package</InputLabel>
                        <TextField
                            size="small"
                            fullWidth
                            name="company_package"
                            value={selectedPackage?.price_id ?? ''}
                            onChange={(e) => {
                                setSelectedPackage(data?.data.find((item) => item.price_id === e.target.value));
                            }}
                            select
                            disabled={data?.data.length === 0 || formik.values.company_package.length > 1}
                        >
                            <MenuItem value="">Select a Package</MenuItem>
                            {data?.data
                                .filter(
                                    (item) =>
                                        formik.values.company_package.find((i) => i.product_id === item.product_id)
                                            ?.product_id !== item.product_id
                                )
                                .map((packageItem) => (
                                    <MenuItem key={packageItem.price_id} value={packageItem.price_id}>
                                        {packageItem.name}
                                    </MenuItem>
                                ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel>Number of Drivers for Package</InputLabel>
                        <TextField
                            size="small"
                            fullWidth
                            name="company_package_quantity"
                            value={selectedPackageQuantity}
                            disabled={data?.data.length === 0 || formik.values.company_package.length > 1}
                            onChange={(e) => {
                                setSelectedPackageQuantity(Number(e.target.value));
                            }}
                        />
                    </Grid>
                    {selectedPackage && selectedPackageQuantity !== 0 ? (
                        <Grid item xs={12}>
                            <Button
                                size="small"
                                disabled={!selectedPackage || selectedPackageQuantity === 0}
                                fullWidth
                                variant="contained"
                                onClick={() => {
                                    formik.setValues({
                                        ...formik.values,
                                        company_package: [
                                            ...formik.values.company_package,
                                            {
                                                product_id: selectedPackage?.product_id!,
                                                numberOfDrivers: selectedPackageQuantity
                                            }
                                        ]
                                    });
                                    // Clear Temp Fields
                                    setSelectedPackage(undefined);
                                    setSelectedPackageQuantity(0);
                                }}
                            >
                                Add Package
                            </Button>
                        </Grid>
                    ) : null}
                    {formik.values.company_package.length > 0 ? (
                        <Grid item xs={12}>
                            <Box
                                component={Paper}
                                elevation={1}
                                boxShadow={1}
                                sx={{
                                    height: '250px',
                                    padding: '10px',
                                    overflowY: 'auto'
                                }}
                            >
                                {formik.values.company_package.map((packageItem: ICompanyPackage) => (
                                    <Box
                                        key={packageItem.product_id}
                                        boxShadow={3}
                                        component={Paper}
                                        elevation={3}
                                        p={1}
                                        m={1}
                                        bgcolor="background.paper"
                                        borderRadius="5px"
                                    >
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <InputLabel>Package Name</InputLabel>
                                                <TextField
                                                    size="small"
                                                    fullWidth
                                                    value={
                                                        data?.data.find(
                                                            (item) => item.product_id === packageItem.product_id
                                                        )?.name
                                                    }
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <InputLabel>Number of Drivers</InputLabel>
                                                <TextField
                                                    size="small"
                                                    fullWidth
                                                    value={packageItem.numberOfDrivers}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button
                                                    size="small"
                                                    variant="contained"
                                                    color="error"
                                                    onClick={() => {
                                                        formik.setValues({
                                                            ...formik.values,
                                                            company_package: formik.values.company_package.filter(
                                                                (item: ICompanyPackage) =>
                                                                    item.product_id !== packageItem.product_id
                                                            )
                                                        });
                                                    }}
                                                >
                                                    Remove Package
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                ))}
                            </Box>
                        </Grid>
                    ) : null}
                    <Grid item xs={12}>
                        <Button size="small" fullWidth variant="contained" onClick={() => formik.handleSubmit()}>
                            {companyInvitation ? 'Update Invitation' : 'Create Invitation'}
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
