import {IManagerNotes, IManagerNotesDto} from '../../models/IMaanagerNotes';
import client from '../Client';

export async function getManagerNotesByUser(id: number) {
  return await client(`form-revamp/manager-notes/get-manager-notes-by-user-id/`, {
    body: {
      userId: id,
    },
    method: 'POST',
  });
}

export async function createManagerNotes(managerNotes: IManagerNotesDto) {
  return await client(`form-revamp/manager-notes/`, {
    body: managerNotes,
    method: 'POST',
  });
}

export async function updateManagerNotes(managerNotes: Partial<IManagerNotes>) {
  return await client(`form-revamp/manager-notes/${managerNotes.id}/`, {
    body: managerNotes,
    method: 'PUT',
  });
}

export async function completeManagerNoteUpload(id: number) {
  return await client(`form-revamp/manager-notes/finish-upload/`, {
    method: 'POST',
    body: {
      id,
    },
  });
}

export async function downloadFile(id: number) {
  return await client(`form-revamp/manager-notes/download/`, {
    method: 'POST',
    body: {
      id,
    },
  });
}

export async function deleteManagerNotes(id: number) {
  return await client(`form-revamp/manager-notes/${id}/`, {
    method: 'DELETE',
  });
}

export async function completeVehicleNoteUpload(id: number) {
  return await client(`auth/api/preventable-maintenance/notes-and-links/upload/finish`, {
    method: 'POST',
    body: {
      id,
    },
  });
}

export async function downloadVehicleFile(id: number) {
  return await client(`auth/api/preventable-maintenance/notes-and-links/download/`, {
    method: 'POST',
    body: {
      id,
    },
  });
}
