import { Grid, MenuItem, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { ChangeEvent, forwardRef, ReactElement, Ref, useEffect, useState } from 'react';
import { IMember } from '../../../../../models';
import { getCompanyMember } from '../../../../../shared';
import { IDVIReport } from '../../../../../store/interfaces/PreventableMaintenance/dvir.report';
import {
    IMaintenanceVehicle,
    VehicleType
} from '../../../../../store/interfaces/PreventableMaintenance/maintenance.vehicles';
import { useGetMaintenanceVehiclesByCompanyQuery } from '../../../../../store/reducers/PreventableMaintenance/maintenance.vehicle';
import { useAuth } from '../../../../modules/auth';

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement<any, any>;
    },
    ref: Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

const getMostRecentDVIRReport = (vehicle: IMaintenanceVehicle): IDVIReport | null => {
    if (!vehicle.dvir_reports.length) return null;

    return [...vehicle.dvir_reports].sort(
        (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    )[0];
};

const needsEndOdometerAndSignature = (report: IDVIReport, currentUserId: number) => {
    return (
        report?.member?.user?.id === currentUserId &&
        !report.condition_satisfactory &&
        (!report?.end_odometer_reading || report.end_odometer_reading === '') &&
        !report?.next_driver_signature
    );
};

const needsEndOdometer = (report: IDVIReport, currentUserId: number) => {
    return report?.member?.user?.id === currentUserId && !report?.end_odometer_reading;
};

const needsNextDriverSignature = (report: IDVIReport) => {
    return !report?.condition_satisfactory && !report.next_driver_signature;
};

const getValidationMessage = (
    vehicle: IMaintenanceVehicle,
    type: 'endOdometerAndSignature' | 'endOdometer' | 'signature'
) => {
    const messages = {
        endOdometerAndSignature: `Please enter the end odometer reading and next driver's signature on previous DVIR for Vehicle #${vehicle?.vehicle_number} by clicking continue before starting a new one.`,
        endOdometer: `Please enter the end odometer reading on previous DVIR for Vehicle #${vehicle?.vehicle_number} by clicking continue before starting a new one.`,
        signature: `Vehicle #${vehicle?.vehicle_number} has a previous DVIR that requires the next driver's signature. Please provide your signature on the previous DVIR by clicking continue and then you’ll be able to start a new DVIR…`
    };
    return messages[type] || '';
};

export default function SelectVehicleModal({ open, setOpen }: IProps) {
    const { currentUser } = useAuth();
    const [id, setId] = useState<number>();
    const [vehicleNumber, setVehicleNumber] = useState<IMaintenanceVehicle>();
    const [selectedVehicleIncomplete, setSelectedVehicleIncomplete] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const { data } = useGetMaintenanceVehiclesByCompanyQuery(id ?? 0, {
        refetchOnFocus: true,
        skip: !id
    });

    const getCompanyId = async () => {
        if (currentUser?.role === 'Member') {
            const member = (await getCompanyMember(String(currentUser?.member?.id))) as unknown as IMember;
            setId(Number(member?.company?.id));
        }
        if (currentUser?.role === 'Owner') {
            setId(Number(currentUser?.company?.id));
        }
    };

    useEffect(() => {
        if (currentUser) {
            getCompanyId();
        }
        // eslint-disable-next-line
    }, [currentUser]);

    const handleClose = () => {
        setOpen(false);
        setVehicleNumber(undefined);
    };

    const handleVehicleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const vehicleId = Number(e.target.value);
        const vehicle = data?.find((item) => item.id === vehicleId);

        if (!vehicle) {
            return;
        }

        const latest_report = getMostRecentDVIRReport(vehicle);

        if (!latest_report) {
            setSelectedVehicleIncomplete(false);
            setVehicleNumber(vehicle);
            setMessage('');
            return;
        }

        // Check validation conditions
        if (needsEndOdometerAndSignature(latest_report, currentUser?.id!)) {
            setSelectedVehicleIncomplete(true);
            setMessage(getValidationMessage(vehicle, 'endOdometerAndSignature'));
            setVehicleNumber(vehicle);
            return;
        }

        if (needsEndOdometer(latest_report, currentUser?.id!)) {
            setSelectedVehicleIncomplete(true);
            setMessage(getValidationMessage(vehicle, 'endOdometer'));
            setVehicleNumber(vehicle);
            return;
        }

        if (latest_report?.member?.user?.id !== currentUser?.id && !latest_report?.end_odometer_reading) {
            setSelectedVehicleIncomplete(false);
            setVehicleNumber(vehicle);
            setMessage('');
            return;
        }

        if (needsNextDriverSignature(latest_report)) {
            setSelectedVehicleIncomplete(true);
            setVehicleNumber(vehicle);
            setMessage(getValidationMessage(vehicle, 'signature'));
            return;
        }

        // Default case - everything is valid
        setSelectedVehicleIncomplete(false);
        setVehicleNumber(vehicle);
        setMessage('');
    };

    return (
        <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                <Typography variant="h6" textAlign="center" mb={4}>
                    Select Vehicle to Continue
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                            fullWidth
                            id="vehicle_number"
                            type="text"
                            size="small"
                            select
                            value={vehicleNumber?.id ?? 0}
                            onChange={handleVehicleChange}
                        >
                            {data
                                ?.filter((trailer) => trailer.vehicle_type !== VehicleType.TRAILER)
                                ?.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item?.vehicle_make} {item?.vehicle_model} - {item?.vehicle_number}
                                    </MenuItem>
                                ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Button
                            disabled={!vehicleNumber}
                            variant="contained"
                            fullWidth
                            size="small"
                            color="primary"
                            onClick={() => {
                                const report = getMostRecentDVIRReport(vehicleNumber!);
                                if (selectedVehicleIncomplete && report) {
                                    return window.location.assign(`/driver-inspection-report/${report.id}`);
                                }
                                return window.location.assign(`/driver-inspection-report/vehicle/${vehicleNumber?.id}`);
                            }}
                        >
                            Continue
                        </Button>
                        {selectedVehicleIncomplete && (
                            <Typography variant="caption" color="error">
                                {message}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
