import WrittenPolicy from '../../../app/pages/drug-alcohol-written-policy/WrittenPolicy';

const DrugAlcoholWrittenPolicy = () => {
    return (
        <div
            style={{
                padding: 10,
                margin: 10
            }}
        >
            <WrittenPolicy />
        </div>
    );
};

export default DrugAlcoholWrittenPolicy;
