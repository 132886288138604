import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
  FTACompanyDto,
  IAddressDto,
  IApplicantInformationDto,
  IApplicationInformation,
} from '../../interfaces/DriversApplicationForEmployment/applicant.information';

export const applicantInformationApi = createApi({
  reducerPath: 'applicant_information',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/applicant-info`,
  }),
  tagTypes: ['ApplicantInformation'],
  keepUnusedDataFor: 60 * 10, // 10 minutes
  endpoints: (builder) => ({
    createApplicantInformation: builder.mutation<IApplicationInformation, IApplicantInformationDto>(
      {
        query: (body) => ({
          url: '/',
          method: 'POST',
          body,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
          },
        }),
        invalidatesTags: [{type: 'ApplicantInformation', id: 'ApplicantInformation'}],
      },
    ),
    updateApplicantInformation: builder.mutation<IApplicationInformation, IApplicationInformation>({
      query: (body) => ({
        url: `/${body.id}/`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'ApplicantInformation', id: 'ApplicantInformation'}],
    }),
    deleteApplicantInformation: builder.mutation<void, number>({
      query: (id) => ({
        url: `/${id}/`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'ApplicantInformation', id: 'ApplicantInformation'}],
    }),
    getApplicantInformation: builder.query<IApplicationInformation, number | void>({
      query: (id) => ({
        url: `/applicant-information/`,
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'ApplicantInformation', id: 'ApplicantInformation'}],
    }),
    getApplicantInformationByUser: builder.query<IApplicationInformation, void>({
      query: () => ({
        url: `/applicant-information-by-user/`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'ApplicantInformation', id: 'ApplicantInformation'}],
    }),
    createAddress: builder.mutation<IAddressDto, IAddressDto>({
      query: (body) => ({
        url: '/create-address/',
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'ApplicantInformation', id: 'ApplicantInformation'}],
    }),
    getApplicantInformationByUserId: builder.query<IApplicationInformation, number | void>({
      query: (id) => ({
        url: `/applicant-information-by-user-id/`,
        method: 'POST',
        body: {
          userId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'ApplicantInformation', id: 'ApplicantInformation'}],
    }),
    updateAddress: builder.mutation<IAddressDto, IAddressDto>({
      query: (body) => ({
        url: '/update-address/',
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'ApplicantInformation', id: 'ApplicantInformation'}],
    }),
    deleteAddress: builder.mutation<void, number>({
      query: (id) => ({
        url: `/delete-address/`,
        method: 'POST',
        body: {
          id: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'ApplicantInformation', id: 'ApplicantInformation'}],
    }),
    getAddress: builder.query<IAddressDto[], number>({
      query: (id) => ({
        url: `/address/`,
        method: 'POST',
        body: {
          userId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'ApplicantInformation', id: 'ApplicantInformation'}],
    }),
    createFtaCompany: builder.mutation<void, Partial<FTACompanyDto>>({
      query: (body) => ({
        url: '/create-fta-company/',
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'ApplicantInformation', id: 'ApplicantInformation'}],
    }),
  }),
});

export const {
  useCreateApplicantInformationMutation,
  useUpdateApplicantInformationMutation,
  useGetApplicantInformationQuery,
  useGetApplicantInformationByUserQuery,
  useCreateAddressMutation,
  useGetApplicantInformationByUserIdQuery,
  useUpdateAddressMutation,
  useDeleteAddressMutation,
  useGetAddressQuery,
  useCreateFtaCompanyMutation,
  useDeleteApplicantInformationMutation,
} = applicantInformationApi;
