import { useCallback, useEffect, useState } from 'react';
import { ISalesRep, getSalesRep } from '../../../shared/client/sales-rep';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import { IPackage, useAuth } from '../../modules/auth';
import { getCompanyMembers, getSubscriptionPlans } from '../../../shared';
import { StripeProduct } from '../../modules/models';
import { toast } from 'react-toastify';
import TableWrapper from '../../../compliancemasters/partials/widgets/tables/components/TableWrapper';
import TableContainerHeader from '../../../compliancemasters/partials/widgets/tables/components/TableContainerHeader';
import TableContainerHeaderTitle from '../../../compliancemasters/partials/widgets/tables/components/TableContainerHeaderTitle';
import TableContainer from '../../../compliancemasters/partials/widgets/tables/components/TableContainer';
import TableHeaderColumn from '../../../compliancemasters/partials/widgets/tables/components/TableHeaderColumn';
import TableHeader from '../../../compliancemasters/partials/widgets/tables/components/TableHeader';
import { IMember } from '../../../models';
import TrainingContainer from '../sales-rep-training/TrainingContainer';

interface ICompanyPackage extends IPackage {
    name: string;
    total_price: number;
    company: string;
}

function calculatePercentage(currentpackage: ICompanyPackage[]): number {
    // Initial percentage is 40%
    let percentage = 40;

    // Calculate the number of additional percentage points
    const additionalPercentagePoints = Math.floor(currentpackage.reduce((a, b) => a + b.numberOfDrivers, 0) / 100);

    // Cap the percentage at 50%
    percentage += additionalPercentagePoints;
    return Math.min(percentage, 50); // Ensure it doesn't exceed 50%
}

function calculateSalary(packages: ICompanyPackage[]): number {
    let totalSalary = 0;

    const percentage = calculatePercentage(packages);
    const packageTotalSalary = (percentage / 100) * packages.reduce((a, b) => a + b.total_price, 0);
    totalSalary += packageTotalSalary;
    return Number(totalSalary.toFixed(2));
}

const SalesRepDetails = () => {
    const { id } = useParams<{ id: string }>();
    const { currentUser } = useAuth();
    const [salesRep, setSalesRep] = useState<ISalesRep>();
    const [loading, setLoading] = useState<boolean>(false);
    const [companyPackages, setCompanyPackages] = useState<ICompanyPackage[][]>([]);

    useEffect(() => {
        if (currentUser?.role === 'SalesRep' && currentUser?.sales_rep) {
            setLoading(true);
            getSalesRep(currentUser.sales_rep.id)
                .then((res) => {
                    setSalesRep(res);
                    setLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                });
        } else if (currentUser?.role !== 'SalesRep' && id) {
            setLoading(true);
            getSalesRep(parseInt(id))
                .then((res) => {
                    setSalesRep(res);
                    setLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                });
        }
    }, [id, currentUser]);

    const getPackages = useCallback(async () => {
        if (salesRep) {
            setLoading(true);
            getSubscriptionPlans()
                .then(async (res: { data: StripeProduct[] }) => {
                    const companyPackages: ICompanyPackage[][] = [];
                    for (const company of salesRep.companies) {
                        const members: IMember[] = await getCompanyMembers(company.id);
                        const filteredMembers = members.filter(
                            (member) => member.pre_employment_screened || !member?.terminated_at
                        );

                        const packages = company.package.map((packageId) => {
                            const numberOfDrivers = filteredMembers.filter(
                                (member) => member.product_id === packageId.id
                            ).length;
                            return {
                                ...packageId,
                                numberOfDrivers
                            };
                        });

                        const companyPackage: ICompanyPackage[] = [];
                        for (const packageId of packages as IPackage[]) {
                            const packageData = res.data.find((p) => p.product_id === packageId.id);
                            const unit_amount = packageData?.tiers.reduce((acc, curr) => acc + curr.unit_amount, 0);
                            if (packageData) {
                                companyPackage.push({
                                    name: packageData.name,
                                    total_price: unit_amount ? (unit_amount / 100) * packageId.numberOfDrivers : 0,
                                    company: company.name,
                                    id: packageId.id,
                                    numberOfDrivers: packageId.numberOfDrivers
                                });
                            }
                        }
                        companyPackages.push(companyPackage);
                    }
                    setCompanyPackages(companyPackages);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                    toast.error(err.message);
                });
        }
    }, [salesRep]);

    useEffect(() => {
        getPackages();
    }, [getPackages]);

    return (
        <>
            {loading ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%'
                    }}
                >
                    <CircularProgress />
                </div>
            ) : null}
            {!loading ? (
                <>
                    <TableWrapper>
                        <TableContainerHeader>
                            <TableContainerHeaderTitle
                                title={currentUser?.role !== 'SalesRep' ? 'Sales Rep Details' : 'Sales Details'}
                            />
                        </TableContainerHeader>
                        <TableContainer>
                            <TableHeader>
                                <TableHeaderColumn center>Company</TableHeaderColumn>
                                <TableHeaderColumn center>Packages</TableHeaderColumn>
                                <TableHeaderColumn center>No. of Drivers</TableHeaderColumn>
                                <TableHeaderColumn center>Package cost per driver</TableHeaderColumn>
                                <TableHeaderColumn center>Monthly Cost</TableHeaderColumn>
                                <TableHeaderColumn center>Sales Rep %</TableHeaderColumn>
                                <TableHeaderColumn center>Monthly Sales Rep payment</TableHeaderColumn>
                            </TableHeader>
                            <tbody>
                                {companyPackages.map((companyPackage, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="text-center">{companyPackage[0].company}</td>
                                            <td className="text-center">
                                                {companyPackage?.map((item) => `${item.name}, `)}
                                            </td>
                                            <td className="text-center">
                                                {companyPackage?.map((item) => `${item.numberOfDrivers}, `)}
                                            </td>
                                            <td className="text-center">
                                                {companyPackage?.map(
                                                    (item) =>
                                                        `$${
                                                            item.total_price
                                                                ? item.total_price / item.numberOfDrivers
                                                                : 0
                                                        }, `
                                                )}
                                            </td>
                                            <td className="text-center">
                                                {companyPackage?.map(
                                                    (item) => `$${item.total_price ? item.total_price : 0}, `
                                                )}
                                            </td>
                                            <td className="text-center">{calculatePercentage(companyPackage)}%</td>
                                            <td className="text-center">${calculateSalary(companyPackage)}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </TableContainer>
                    </TableWrapper>
                    <Box
                        sx={{
                            marginTop: '20px',
                            padding: '20px',
                            borderRadius: '5px',
                            marginBottom: '20px'
                        }}
                        component={Paper}
                    >
                        <Grid container spacing={3}>
                            <Grid item sm={6}>
                                <Typography variant="h6" align="center">
                                    Total Monthly Sales Rep payment
                                </Typography>
                            </Grid>
                            <Grid item sm={6}>
                                <Typography variant="h6" align="center">
                                    ${calculateSalary(companyPackages.flat())}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <TrainingContainer />
                </>
            ) : null}
        </>
    );
};

export default SalesRepDetails;
