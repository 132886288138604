import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {IServiceAlerts} from '../../interfaces/ServiceAlerts/ServiceAlerts.interface';

export const serviceAlertsApi = createApi({
  reducerPath: 'pm_service_alerts',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/auth/api/preventable-maintenance/service-alerts`,
  }),
  tagTypes: ['ServiceAlerts'],
  keepUnusedDataFor: 60 * 10, // 10 minutes
  endpoints: (builder) => ({
    updateServiceAlert: builder.mutation<IServiceAlerts, Partial<IServiceAlerts>>({
      query: (body) => ({
        url: `/update/${body.id}/`,
        method: 'PUT',
        body: {
          ...body,
          vehicle: body?.vehicle?.id,
          service: body?.service?.id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'ServiceAlerts', id: 'ServiceAlerts'}],
    }),
    deleteServiceAlert: builder.mutation<void, number>({
      query: (id) => ({
        url: `/delete/${id}/`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'ServiceAlerts', id: 'ServiceAlerts'}],
    }),
    getServiceAlertsByCompany: builder.query<IServiceAlerts[], number | void>({
      query: (company) => ({
        url: `/get_by_company?company_id=${company}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
    }),
  }),
});

export const {
  useUpdateServiceAlertMutation,
  useDeleteServiceAlertMutation,
  useGetServiceAlertsByCompanyQuery,
} = serviceAlertsApi;
