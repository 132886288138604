import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useState } from 'react';
import { useThemeMode } from '../../../compliancemasters/partials';
import AdminUserTable from '../../../compliancemasters/partials/widgets/tables/MemberTable/AdminUserTable';
import CompanyInvitations from './CompanyInvitations';
import CompanyList from './CompanyList';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <>{children}</>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

export default function DashboardTabs() {
    const [value, setValue] = useState(0);
    const { mode } = useThemeMode();
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs centered value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab
                        label="Companies"
                        {...a11yProps(0)}
                        sx={{
                            color: mode === 'dark' ? '#fff' : '#000'
                        }}
                    />
                    <Tab
                        sx={{
                            color: mode === 'dark' ? '#fff' : '#000'
                        }}
                        label="Users"
                        {...a11yProps(1)}
                    />
                    <Tab
                        sx={{
                            color: mode === 'dark' ? '#fff' : '#000'
                        }}
                        label="Invitations"
                        {...a11yProps(2)}
                    />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <CompanyList />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <AdminUserTable />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <CompanyInvitations />
            </CustomTabPanel>
        </Box>
    );
}
