import { Routes, Route, Outlet, useParams, Navigate } from 'react-router-dom';
import CompanyMemberPageProfileHeader from './ProfileHeader/CompanyMemberPageHeader';
import { DrugDocuments } from './components/DrugDocuments';
import { DriverDocuments } from './components/DriverDocuments';
import { NonCurrentDocuments } from './components/NonCurrentDocuments';
import React, { useCallback, useEffect, useState } from 'react';
import { StripeProduct } from '../models';
import { IMember } from '../../../models';
import { getCompanyMember, getSubscriptionPlans } from '../../../shared';
import { useQuery } from 'react-query';
import { CircularProgress } from '@mui/material';
import ManagerNotes from './components/ManagerNotes';

const ProfilePage = () => {
    const { memberId } = useParams();
    const [memberSubscription, setMemberSubscription] = useState<StripeProduct | undefined>(undefined);
    const [memberSubscriptionLoading, setMemberSubscriptionLoading] = useState<boolean>(true);
    const [subscriptions, setSubscriptions] = useState<Array<StripeProduct>>([]);

    const { data: member, isLoading } = useQuery<IMember>(['member', memberId], () => getCompanyMember(memberId!), {
        enabled: !!memberId,
        refetchOnWindowFocus: true,
        refetchInterval: 1000 * 10
    });

    const getPlanOptions = useCallback(async () => {
        setMemberSubscriptionLoading(true);
        const subs = await getSubscriptionPlans();
        setSubscriptions(subs.data);
    }, []);

    useEffect(() => {
        getPlanOptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getPlanOptions]);

    useEffect(() => {
        if (member) {
            const subscription = subscriptions.find((subscription) => subscription.product_id === member?.product_id);
            setMemberSubscription(subscription);
            setMemberSubscriptionLoading(false);
        }
    }, [member, subscriptions]);

    return (
        <Routes>
            <Route
                element={
                    <>
                        <CompanyMemberPageProfileHeader memberSubscription={memberSubscription} />
                        <Outlet />
                    </>
                }
            >
                {memberSubscription && !memberSubscriptionLoading ? (
                    <>
                        {memberSubscription ? (
                            <>
                                <Route
                                    path="driver-documents"
                                    element={
                                        <DriverDocuments
                                            member={member}
                                            subscriptions={subscriptions}
                                            memberSubscription={memberSubscription}
                                            pageLoading={isLoading || memberSubscriptionLoading}
                                        />
                                    }
                                />
                                <Route
                                    path="drug-documents"
                                    element={<DrugDocuments memberSubscription={memberSubscription} />}
                                />
                                <Route
                                    path="non-current-documents"
                                    element={
                                        <NonCurrentDocuments member={member} memberSubscription={memberSubscription} />
                                    }
                                />
                                <Route path="manager-notes" element={<ManagerNotes member={member} />} />
                            </>
                        ) : null}
                        <Route
                            index
                            element={
                                <Navigate
                                    to={
                                        memberSubscription?.name === 'Drug and Alcohol Package'
                                            ? 'drug-documents'
                                            : 'driver-documents'
                                    }
                                />
                            }
                        />
                    </>
                ) : (
                    <Route
                        index
                        element={
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '40vh'
                                }}
                            >
                                <CircularProgress />
                            </div>
                        }
                    />
                )}
            </Route>
        </Routes>
    );
};

export default ProfilePage;
