import { Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useAuth } from '../../../app/modules/auth';
import { IMember } from '../../../models';
import { IWitnessAndOwnerSignatureDto } from '../../../store/interfaces/DocumentationofActualKnowledgeofDOTDAndAViolation/witness-and-owner-signature';
import {
    useCreateWitnessAndOwnerSignatureMutation,
    useGetWitnessAndOwnerSignatureByUserIdQuery
} from '../../../store/reducers/DocumentationofActualKnowledgeofDOTDAndAViolation/witness-and-owner-signature';
import { useThemeMode } from '../../partials';
import UpdateWitnessAndActualSignature from './UpdateWitnessAndActualSignature';

const initialValues: Partial<IWitnessAndOwnerSignatureDto> = {
    name: '',
    contact_information: ''
};

const validationSchema = Yup.object({
    name: Yup.string().required('Required'),
    contact_information: Yup.string().required('Required')
});

interface IProps {
    member?: IMember;
    isDriverFile?: boolean;
}

const WitnessAndOwnerSignature = ({ member, isDriverFile }: IProps) => {
    const { currentUser } = useAuth();
    const { data } = useGetWitnessAndOwnerSignatureByUserIdQuery(member?.user?.id!, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true
    });
    const { mode } = useThemeMode();
    const [createWitnessAndOwnerSignature, { isLoading: isCreating }] = useCreateWitnessAndOwnerSignatureMutation();

    const [addNewItem, setAddNewItem] = useState(true);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            createWitnessAndOwnerSignature({
                ...values,
                // @ts-ignore
                company: member?.company?.id,
                // @ts-ignore
                member: member?.id
            });
            resetForm();
        },
        validateOnBlur: true,
        validateOnChange: true,
        validate(values) {
            const errors: Partial<IWitnessAndOwnerSignatureDto> = {};
            if (!values.name) {
                errors.name = 'Required';
            }
            if (!values.contact_information) {
                errors.contact_information = 'Required';
            }
            return errors;
        }
    });

    useEffect(() => {
        if (data && data.length > 0) {
            setAddNewItem(false);
        } else {
            setAddNewItem(true);
        }
    }, [data]);

    return (
        <div
            style={{
                padding: 10,
                margin: 10
            }}
        >
            <Typography
                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: 'center' }}
                variant="h4"
                gutterBottom
                component="h4"
            >
                Witness Information
            </Typography>
            <Typography
                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                variant="body1"
                gutterBottom
                component="p"
            >
                Witnesses to the violation
            </Typography>
            <Grid container spacing={2}>
                {data &&
                    data.map((item, index) => (
                        <UpdateWitnessAndActualSignature member={member} key={index} data={item} />
                    ))}
                {data && data.length > 0 && (
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <FormControlLabel
                            sx={{
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }}
                            control={
                                <Checkbox
                                    sx={{
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }}
                                    checked={addNewItem}
                                />
                            }
                            onChange={() => setAddNewItem(!addNewItem)}
                            label="Add Employer History Record"
                        />
                    </Grid>
                )}
                {addNewItem && (
                    <>
                        <Grid item xs={12} sm={12} mt={2}>
                            <Typography
                                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Witness Name
                            </Typography>
                            <TextField
                                fullWidth
                                id="name"
                                placeholder="Name"
                                size="small"
                                variant="outlined"
                                value={formik.values.name}
                                onChange={(e) => {
                                    formik.setFieldValue('name', e.target.value);
                                }}
                                disabled={!!member?.terminated_at}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.name && Boolean(formik.errors.name) ? 'red' : 'green'
                                        },
                                        '&:hover fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.name && Boolean(formik.errors.name) ? 'red' : 'green'
                                        },
                                        '&.Mui-focused fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.name && Boolean(formik.errors.name) ? 'red' : 'green'
                                        }
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                                InputProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography
                                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Contact Information
                            </Typography>
                            <TextField
                                fullWidth
                                id="contact_information"
                                placeholder="Contact Information"
                                size="small"
                                variant="outlined"
                                value={formik.values.contact_information}
                                onChange={(e) => {
                                    formik.setFieldValue('contact_information', e.target.value);
                                }}
                                disabled={!!member?.terminated_at}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.contact_information &&
                                                Boolean(formik.errors.contact_information)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&:hover fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.contact_information &&
                                                Boolean(formik.errors.contact_information)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&.Mui-focused fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.contact_information &&
                                                Boolean(formik.errors.contact_information)
                                                    ? 'red'
                                                    : 'green'
                                        }
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                                InputProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                            />
                        </Grid>
                        {currentUser && ['Admin', 'SuperAdmin'].includes(currentUser?.role) && !isDriverFile ? (
                            <Grid item xs={12} sm={12}>
                                <Button
                                    fullWidth
                                    disabled={isCreating || !!member?.terminated_at}
                                    onClick={() => formik.handleSubmit()}
                                    variant="contained"
                                    color="primary"
                                >
                                    Save
                                </Button>
                                {currentUser?.role !== 'Admin' && currentUser?.role !== 'SuperAdmin' && (
                                    <Typography
                                        sx={{ color: 'red', textAlign: 'center', mt: 2 }}
                                        variant="body1"
                                        gutterBottom
                                        component="p"
                                    >
                                        Once you click save, you will not be able to edit this form.
                                    </Typography>
                                )}
                            </Grid>
                        ) : null}
                    </>
                )}
            </Grid>
        </div>
    );
};

export default WitnessAndOwnerSignature;
