import { Button, Divider, Grid, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { useAuth } from '../../../app/modules/auth';
import { IMember } from '../../../models';
import { IOwnerDatesDto } from '../../../store/interfaces/PreviousEmployeeSafetyPerformanceHistory/driver-identification';
import {
    useCreateOwnerDatesMutation,
    useDeleteOwnerDatesMutation,
    useGetOwnerDatesByUserIdQuery,
    useUpdateOwnerDatesMutation
} from '../../../store/reducers/PreviousEmployeeSafetyPerformanceHistory/owner-dates';
import { useThemeMode } from '../../partials';

const initialValues: Partial<IOwnerDatesDto> = {
    date: '',
    response_date: ''
};

const validationSchema = Yup.object({
    date: Yup.string(),
    response_date: Yup.string()
});

interface IProps {
    member?: IMember;
}

const OwnerDates = ({ member }: IProps) => {
    const { currentUser } = useAuth();
    const { mode } = useThemeMode();
    const { data } = useGetOwnerDatesByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true
    });

    const [createOwnerDates, { isLoading: isCreating }] = useCreateOwnerDatesMutation();
    const [updateOwnerDates, { isLoading: isUpdating }] = useUpdateOwnerDatesMutation();
    const [deleteOwnerDates] = useDeleteOwnerDatesMutation();

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (data) {
                updateOwnerDates({
                    ...data,
                    ...values,
                    id: data.id
                }).then(() => {
                    resetForm();
                });
            } else {
                createOwnerDates({
                    // @ts-ignore
                    date: values.date ? values.date : null,
                    // @ts-ignore
                    response_date: values.response_date ? values.response_date : null,
                    // @ts-ignore
                    company: member?.company?.id,
                    // @ts-ignore
                    member: member?.id
                }).then(() => {
                    resetForm();
                });
            }
        },
        validateOnBlur: true,
        validateOnChange: true
    });

    useEffect(() => {
        if (data) {
            if (data.date) {
                formik.setFieldValue('date', data.date);
            }
            if (data.response_date) {
                formik.setFieldValue('response_date', data.response_date);
            }
        }
        // eslint-disable-next-line
    }, [data]);

    return (
        <>
            {currentUser && !['Owner', 'Member'].includes(currentUser.role) ? (
                <>
                    <Grid item xs={12} sm={12}>
                        <Typography
                            sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                            variant="body1"
                            gutterBottom
                            component="p"
                        >
                            Request for Previous Employee Safety Performance History, Dated:
                        </Typography>
                        <TextField
                            fullWidth
                            id="date"
                            size="small"
                            variant="outlined"
                            type="date"
                            value={formik.values.date}
                            onChange={(e) => {
                                formik.setFieldValue('date', e.target.value);
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.date && Boolean(formik.errors.date) ? 'red' : 'green'
                                    },
                                    '&:hover fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.date && Boolean(formik.errors.date) ? 'red' : 'green'
                                    },
                                    '&.Mui-focused fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.date && Boolean(formik.errors.date) ? 'red' : 'green'
                                    }
                                }
                            }}
                            InputLabelProps={{
                                style: {
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                            disabled={!!(member?.terminated_at ?? data?.date)}
                            InputProps={{
                                style: {
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Button
                            fullWidth
                            disabled={isCreating || isUpdating || !!member?.terminated_at}
                            onClick={() => formik.handleSubmit()}
                            variant="contained"
                            color="primary"
                        >
                            Save
                        </Button>
                        {currentUser?.role !== 'Admin' && currentUser?.role !== 'SuperAdmin' && (
                            <Typography
                                sx={{ color: 'red', textAlign: 'center', mt: 2 }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Once you click save, you will not be able to edit this form.
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Divider sx={{ mt: 3, mb: 3 }} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography
                            sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                            variant="body1"
                            gutterBottom
                            component="p"
                        >
                            Date of Response Received
                        </Typography>
                        <TextField
                            fullWidth
                            id="response_date"
                            size="small"
                            variant="outlined"
                            type="date"
                            value={formik.values.response_date}
                            onChange={(e) => {
                                formik.setFieldValue('response_date', e.target.value);
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.response_date && Boolean(formik.errors.response_date)
                                                ? 'red'
                                                : 'green'
                                    },
                                    '&:hover fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.response_date && Boolean(formik.errors.response_date)
                                                ? 'red'
                                                : 'green'
                                    },
                                    '&.Mui-focused fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.response_date && Boolean(formik.errors.response_date)
                                                ? 'red'
                                                : 'green'
                                    }
                                }
                            }}
                            InputLabelProps={{
                                style: {
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                            disabled={!!(member?.terminated_at ?? data?.response_date)}
                            InputProps={{
                                style: {
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Button
                            fullWidth
                            disabled={isCreating || isUpdating || !!member?.terminated_at}
                            onClick={() => formik.handleSubmit()}
                            variant="contained"
                            color="primary"
                        >
                            Save
                        </Button>
                        {currentUser?.role !== 'Admin' && currentUser?.role !== 'SuperAdmin' && (
                            <Typography
                                sx={{ color: 'red', textAlign: 'center', mt: 2 }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Once you click save, you will not be able to edit this form.
                            </Typography>
                        )}
                    </Grid>
                    {data && currentUser?.role === 'SuperAdmin' && (
                        <Grid item xs={12} sm={12}>
                            <Button
                                fullWidth
                                disabled={!!member?.terminated_at}
                                onClick={() => {
                                    const res = window.confirm('Are you sure you want to delete this form?');
                                    if (res && data) {
                                        deleteOwnerDates(data.id);
                                    }
                                }}
                                variant="contained"
                                color="primary"
                            >
                                Delete Form
                            </Button>
                        </Grid>
                    )}
                </>
            ) : null}
        </>
    );
};

export default OwnerDates;
