import {ICompany, IMember} from '../../../models';
import {IAbstract} from '../abstract';

export enum TimeTextChoices {
  AM = 'AM',
  PM = 'PM',
}

export interface IDutyHoursDriverSignatureDto {
  driver_signature: string;
  date_signed: string;
  date: string;
  time: string;
  time_of_day: TimeTextChoices;
  company: ICompany;
  member: IMember;
}

export interface IDutyHoursDriverSignature extends IDutyHoursDriverSignatureDto, IAbstract {}
