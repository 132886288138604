/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useCallback, useState } from 'react';
import Button from '@mui/material/Button';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Container, Grid, InputLabel, TextField } from '@mui/material';
import TableWrapper from '../components/TableWrapper';
import TableContainerHeader from '../components/TableContainerHeader';
import TableContainerHeaderTitle from '../components/TableContainerHeaderTitle';
import { useAuth } from '../../../../../app/modules/auth';
import { useThemeMode } from '../../../layout/theme-mode/ThemeModeProvider';

const initialValues = {
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    role: 'Admin' as 'Admin' | 'Member' | 'Owner' | 'SuperAdmin'
};

const validationSchema = Yup.object().shape({
    firstname: Yup.string().max(255).required('First name is required'),
    lastname: Yup.string().max(255).required('Last name is required'),
    email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
    password: Yup.string().max(255).required('Password is required')
});

export default function AddAdmin() {
    const { mode } = useThemeMode();
    const { saveAuth, register } = useAuth();

    const [loading, setLoading] = useState(false);
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            function errorHandler(error: any) {
                console.log(error);
                saveAuth(undefined);
                setStatus('The registration details is incorrect');
                completedRequest();
            }

            function completedRequest() {
                setSubmitting(false);
                setLoading(false);
            }

            setLoading(true);

            register({
                firstname: values.firstname,
                lastname: values.lastname,
                email: values.email,
                password: values.password,
                role: values.role
            })
                .then(() => {
                    completedRequest();
                    window.history.back();
                })
                .catch((error) => {
                    errorHandler(error);
                });
        }
    });
    return (
        <TableWrapper
            sx={{
                width: {
                    xs: '100%',
                    sm: '60%',
                    md: '50%',
                    lg: '50%'
                },
                marginLeft: 'auto',
                marginRight: 'auto'
            }}
        >
            <TableContainerHeader>
                <TableContainerHeaderTitle title="Add Admin" />
            </TableContainerHeader>
            <Container
                sx={{
                    width: {
                        xs: '100%',
                        sm: '90%',
                        md: '90%',
                        lg: '90%'
                    },
                    padding: 2
                }}
            >
                {formik.status && (
                    <div className="mb-lg-15 alert alert-danger">
                        <div className="alert-text font-weight-bold">{formik.status}</div>
                    </div>
                )}
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <InputLabel
                            sx={{
                                color: 'white'
                            }}
                        >
                            First Name
                        </InputLabel>
                        <TextField
                            fullWidth
                            placeholder="First Name"
                            autoComplete="off"
                            size="small"
                            name="firstname"
                            value={formik.values.firstname}
                            onChange={formik.handleChange}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.firstname && Boolean(formik.errors.firstname)
                                                ? 'red'
                                                : 'green'
                                    },
                                    '&:hover fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.firstname && Boolean(formik.errors.firstname)
                                                ? 'red'
                                                : 'green'
                                    },
                                    '&.Mui-focused fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.firstname && Boolean(formik.errors.firstname)
                                                ? 'red'
                                                : 'green'
                                    }
                                }
                            }}
                            InputLabelProps={{
                                style: {
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                            InputProps={{
                                style: {
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel
                            sx={{
                                color: 'white'
                            }}
                        >
                            Last Name
                        </InputLabel>
                        <TextField
                            fullWidth
                            placeholder="Last Name"
                            autoComplete="off"
                            size="small"
                            name="lastname"
                            value={formik.values.lastname}
                            onChange={formik.handleChange}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.lastname && Boolean(formik.errors.lastname) ? 'red' : 'green'
                                    },
                                    '&:hover fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.lastname && Boolean(formik.errors.lastname) ? 'red' : 'green'
                                    },
                                    '&.Mui-focused fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.lastname && Boolean(formik.errors.lastname) ? 'red' : 'green'
                                    }
                                }
                            }}
                            InputLabelProps={{
                                style: {
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                            InputProps={{
                                style: {
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel
                            sx={{
                                color: 'white'
                            }}
                        >
                            Email Address
                        </InputLabel>
                        <TextField
                            fullWidth
                            placeholder="Email Address"
                            autoComplete="off"
                            size="small"
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.email && Boolean(formik.errors.email) ? 'red' : 'green'
                                    },
                                    '&:hover fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.email && Boolean(formik.errors.email) ? 'red' : 'green'
                                    },
                                    '&.Mui-focused fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.email && Boolean(formik.errors.email) ? 'red' : 'green'
                                    }
                                }
                            }}
                            InputLabelProps={{
                                style: {
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                            InputProps={{
                                style: {
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel
                            sx={{
                                color: 'white'
                            }}
                        >
                            Password
                        </InputLabel>
                        <TextField
                            fullWidth
                            placeholder="Password"
                            autoComplete="off"
                            type="password"
                            size="small"
                            name="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.password && Boolean(formik.errors.password) ? 'red' : 'green'
                                    },
                                    '&:hover fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.password && Boolean(formik.errors.password) ? 'red' : 'green'
                                    },
                                    '&.Mui-focused fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.password && Boolean(formik.errors.password) ? 'red' : 'green'
                                    }
                                }
                            }}
                            InputLabelProps={{
                                style: {
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                            InputProps={{
                                style: {
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button size="small" variant="contained" fullWidth onClick={() => formik.handleSubmit()}>
                            Add Admin
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </TableWrapper>
    );
}
