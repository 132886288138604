import { MouseEvent, useEffect, useRef, useState } from 'react';
import TableWrapper from '../../../compliancemasters/partials/widgets/tables/components/TableWrapper';
import TableContainerHeader from '../../../compliancemasters/partials/widgets/tables/components/TableContainerHeader';
import TableContainerHeaderTitle from '../../../compliancemasters/partials/widgets/tables/components/TableContainerHeaderTitle';
import TableContainer from '../../../compliancemasters/partials/widgets/tables/components/TableContainer';
import { IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import useCompanyInvitations from '../../../context/CompanyInvitation/CompanyInvitationContextProvider';
import clsx from 'clsx';
import { MoreVertRounded } from '@mui/icons-material';
import CompanyInvitationModal from './CompanyInvitationModal';
import { CompanyInvitation } from '../../../context/CompanyInvitation/company.invitation.types';
import CompanyInvitationViewModal from './CompanyInvitationViewModal';

interface MenuComponentProps {
    anchorEl: null | HTMLElement;
    open: boolean;
    handleClose: () => void;
    setViewInvitationModalOpen: (open: boolean) => void;
    setUpdateInvitationModalOpen: (open: boolean) => void;
    deleteInvitation: () => void;
}

const MenuComponent = ({
    anchorEl,
    handleClose,
    open,
    setUpdateInvitationModalOpen,
    setViewInvitationModalOpen,
    deleteInvitation
}: Readonly<MenuComponentProps>) => {
    return (
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            slotProps={{
                paper: {
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0
                        }
                    }
                }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItem
                onClick={() => {
                    setUpdateInvitationModalOpen(true);
                    handleClose();
                }}
            >
                Update Company Invitation
            </MenuItem>
            <MenuItem
                onClick={() => {
                    setViewInvitationModalOpen(true);
                    handleClose();
                }}
            >
                View Company Invitation
            </MenuItem>
            <MenuItem
                onClick={() => {
                    deleteInvitation();
                    handleClose();
                }}
            >
                Delete Company Invitation
            </MenuItem>
        </Menu>
    );
};

const CompanyInvitations = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [updateInvitationModalOpen, setUpdateInvitationModalOpen] = useState<boolean>(false);
    const [viewInvitationModalOpen, setViewInvitationModalOpen] = useState<boolean>(false);
    const [invitation, setInvitation] = useState<CompanyInvitation>();
    const { getInvitations, invitations, deleteInvitation } = useCompanyInvitations();
    const getInvitationsRef = useRef(getInvitations);

    useEffect(() => {
        const interval = setInterval(() => {
            if (getInvitationsRef.current) {
                getInvitationsRef.current();
            }
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div>
            <TableWrapper>
                <TableContainerHeader>
                    <TableContainerHeaderTitle title="Company Invitations" />
                </TableContainerHeader>
                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell className="text-muted fw-bold fs-6" align="center">
                                    User Names
                                </TableCell>
                                <TableCell className="text-muted fw-bold fs-6" align="center">
                                    Email
                                </TableCell>
                                <TableCell className="text-muted fw-bold fs-6" align="center">
                                    Number of Drivers
                                </TableCell>
                                <TableCell className="text-muted fw-bold fs-6" align="center">
                                    Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {invitations.map((item) => (
                                <TableRow key={item.id} className={clsx('text-hover-primary cursor-pointer')}>
                                    <TableCell align="center">
                                        {item.first_name} {item.last_name}
                                    </TableCell>
                                    <TableCell align="center">{item.email}</TableCell>
                                    <TableCell align="center">
                                        {item?.company_package?.reduce((acc, curr) => acc + curr.numberOfDrivers, 0)}
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            onClick={(event) => {
                                                setInvitation(item);
                                                handleClick(event);
                                            }}
                                        >
                                            <MoreVertRounded />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </TableWrapper>
            <MenuComponent
                anchorEl={anchorEl}
                handleClose={handleClose}
                open={open}
                setUpdateInvitationModalOpen={setUpdateInvitationModalOpen}
                setViewInvitationModalOpen={setViewInvitationModalOpen}
                deleteInvitation={() => {
                    const res = window.confirm('Are you sure you want to delete this invitation?');
                    if (res && invitation) {
                        deleteInvitation(invitation.id);
                    }
                }}
            />
            <CompanyInvitationModal
                open={updateInvitationModalOpen}
                setOpen={setUpdateInvitationModalOpen}
                companyInvitation={invitation}
            />
            <CompanyInvitationViewModal
                invitation={invitation}
                open={viewInvitationModalOpen}
                setOpen={setViewInvitationModalOpen}
            />
        </div>
    );
};

export default CompanyInvitations;
