import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IMember } from '../../../models';
import { getCompanyMember } from '../../../shared';
import { Box, Button, Container, Paper, Typography } from '@mui/material';
import FormHeaderCard from '../HeaderCard';
import CompanyDriverInformation from './CompanyDriverInformation';
import ViolationAndActualKnowledge from './ViolationAndActualKnowledge';
import WitnessAndOwnerSignature from './WitnessAndOwnerSignature';
import Affidavit from './Affidavit';
import ActionButtons from '../ActionButtons';
import {
    useGetDocsOfActualKnowledgeProgressMutation,
    useGetMemberDocsOfActualKnowledgeProgressMutation
} from '../../../store/reducers/DocumentationofActualKnowledgeofDOTDAndAViolation/progress';
import { useThemeMode } from '../../partials';
import { useAuth } from '../../../app/modules/auth';

const DocsActualKnowledge = () => {
    const { mode } = useThemeMode();
    const { currentUser } = useAuth();
    const [getProgress, { data: progress }] = useGetDocsOfActualKnowledgeProgressMutation();
    const [getMemberProgress, { data: memberProgress }] = useGetMemberDocsOfActualKnowledgeProgressMutation();
    const [member, setMember] = useState<IMember>();
    const { id } = useParams();
    const [value, setValue] = useState(0);

    useEffect(() => {
        if (id) {
            getCompanyMember(id).then((data) => {
                setMember(data);
            });
            getProgress(Number(id));
            getMemberProgress(Number(id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Container style={{ userSelect: 'none' }}>
            <Box
                sx={{
                    pl: 3,
                    pr: 3,
                    pt: 3,
                    m: 3,
                    borderRadius: 2,
                    backgroundColor: mode === 'dark' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    width: {
                        lg: '47vw',
                        md: '70vw',
                        sm: '80vw'
                    },
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }}
                component={Paper}
            >
                <FormHeaderCard
                    headerTitle="Documentation of Actual Knowledge of DOT D&A Violation"
                    status={
                        progress && progress?.progress > 0 && progress?.progress < 100
                            ? 'In Progress'
                            : progress && progress.progress > 99
                            ? 'Completed'
                            : 'Not Started'
                    }
                    sectionCount={4}
                    itemCount={29}
                    totalProgress={progress ? progress.progress : 0}
                    memberProgress={memberProgress ? memberProgress.progress : 0}
                    nextButton={{ title: 'Annual Review of Driving Record', path: '' }}
                    member={member}
                    setValue={setValue}
                    value={value}
                    drugFile
                />
            </Box>
            <Box
                sx={{
                    pl: 3,
                    pr: 3,
                    pt: 3,
                    m: 3,
                    borderRadius: 2,
                    backgroundColor: mode === 'dark' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    width: {
                        lg: '47vw',
                        md: '70vw',
                        sm: '80vw'
                    },
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }}
                component={Paper}
            >
                <Typography
                    align="center"
                    variant="h5"
                    color="red"
                    sx={{
                        p: 1
                    }}
                >
                    Only complete this form at the time you obtain actual knowledge of a driver violating drug and
                    alcohol policies
                </Typography>
            </Box>
            <Box
                sx={{
                    pl: 3,
                    pr: 3,
                    pt: 3,
                    m: 3,
                    borderRadius: 2,
                    backgroundColor: mode === 'dark' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    width: {
                        lg: '47vw',
                        md: '70vw',
                        sm: '80vw'
                    },
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }}
                component={Paper}
            >
                <CompanyDriverInformation member={member} />
            </Box>
            <Box
                sx={{
                    pl: 3,
                    pr: 3,
                    pt: 3,
                    m: 3,
                    borderRadius: 2,
                    backgroundColor: mode === 'dark' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    width: {
                        lg: '47vw',
                        md: '70vw',
                        sm: '80vw'
                    },
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }}
                component={Paper}
            >
                <ViolationAndActualKnowledge member={member} />
            </Box>
            <Box
                sx={{
                    pl: 3,
                    pr: 3,
                    pt: 3,
                    m: 3,
                    borderRadius: 2,
                    backgroundColor: mode === 'dark' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    width: {
                        lg: '47vw',
                        md: '70vw',
                        sm: '80vw'
                    },
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }}
                component={Paper}
            >
                <WitnessAndOwnerSignature member={member} />
            </Box>
            <Box
                sx={{
                    pl: 3,
                    pr: 3,
                    pt: 3,
                    m: 3,
                    borderRadius: 2,
                    backgroundColor: mode === 'dark' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    width: {
                        lg: '47vw',
                        md: '70vw',
                        sm: '80vw'
                    },
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }}
                component={Paper}
            >
                <Affidavit member={member} />
            </Box>
            <ActionButtons
                nextButton={{ title: 'Annual Review of Driving Record', path: 'annual-review-of-driving-record' }}
                member={member}
                prevButton={{
                    path: 'drug-and-alcohol-employees-certified-receipt',
                    title: 'Drug and Alcohol Employees Certified Receipt'
                }}
            />
            <div
                style={{
                    display: 'flex',
                    width: '47vw',
                    justifyContent: 'center',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: 10
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        if (currentUser?.role === 'Member') {
                            window.location.assign(`/dashboard/overview`);
                        } else {
                            if (member?.id) {
                                window.location.assign(`/member/${member?.id}/profile/driver-documents`);
                            }
                        }
                    }}
                    fullWidth
                >
                    Back to Forms Overview
                </Button>
            </div>
        </Container>
    );
};

export default DocsActualKnowledge;
