import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import { IRandomDACHTesting, IRandomDACHTestingDto } from '../../interfaces/RandomDach/random-dach';

export const randomDachApi = createApi({
    reducerPath: 'random_dach',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/random-dach-selection`
    }),
    tagTypes: ['RandomDACH'],
    keepUnusedDataFor: 60 * 10, // 10 minutes
    endpoints: (builder) => ({
        createRandomDACHTesting: builder.mutation<IRandomDACHTesting, IRandomDACHTestingDto>({
            query: (body) => ({
                url: '/',
                method: 'POST',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'RandomDACH', id: 'RandomDACH' }]
        }),
        updateRandomDACHTesting: builder.mutation<IRandomDACHTesting, IRandomDACHTesting>({
            query: (body) => ({
                url: `/${body.id}/`,
                method: 'PUT',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'RandomDACH', id: 'RandomDACH' }]
        }),
        getRandomDACHTesting: builder.query<IRandomDACHTesting[], void>({
            query: () => ({
                url: `/`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'RandomDACH', id: 'RandomDACH' }]
        }),
        getRandomDACHTestingByCompany: builder.query<IRandomDACHTesting[], number | null | undefined>({
            query: (id) => ({
                url: `/get-by-company/`,
                method: 'POST',
                body: {
                    companyId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            providesTags: [{ type: 'RandomDACH', id: 'RandomDACH' }]
        }),
        deleteRandomDACHTesting: builder.mutation<void, number>({
            query: (id) => ({
                url: `/${id}/`,
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'RandomDACH', id: 'RandomDACH' }]
        }),
        setRandomDachTestViewed: builder.mutation<void, number>({
            query: (id) => ({
                url: `/set-request-as-viewed/`,
                method: 'POST',
                body: {
                    randomDachId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            }),
            invalidatesTags: [{ type: 'RandomDACH', id: 'RandomDACH' }]
        }),
        sendRandomTestReminder: builder.mutation<void, number>({
            query: (id) => ({
                url: `/send-reminder/`,
                method: 'POST',
                body: {
                    randomDachId: id
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authHelper.getAuth()?.api_token}`
                }
            })
        })
    })
});

export const {
    useCreateRandomDACHTestingMutation,
    useUpdateRandomDACHTestingMutation,
    useGetRandomDACHTestingQuery,
    useGetRandomDACHTestingByCompanyQuery,
    useDeleteRandomDACHTestingMutation,
    useSetRandomDachTestViewedMutation,
    useSendRandomTestReminderMutation
} = randomDachApi;
