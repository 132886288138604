import { useEffect, useState } from 'react';
import TableContainer from '../../../compliancemasters/partials/widgets/tables/components/TableContainer';
import TableContainerHeader from '../../../compliancemasters/partials/widgets/tables/components/TableContainerHeader';
import TableContainerHeaderTitle from '../../../compliancemasters/partials/widgets/tables/components/TableContainerHeaderTitle';
import TableHeader from '../../../compliancemasters/partials/widgets/tables/components/TableHeader';
import TableHeaderColumn from '../../../compliancemasters/partials/widgets/tables/components/TableHeaderColumn';
import TableWrapper from '../../../compliancemasters/partials/widgets/tables/components/TableWrapper';
import { IUser } from '../../../models';
import { useAuth } from '../../modules/auth';
import AddUserModal from './AddUserModal';
import NewUserButton from './NewUserButton';

const UserTable = () => {
    const { searchUsers } = useAuth();
    const [open, setOpen] = useState<boolean>(false);
    const [users, setUsers] = useState<IUser[]>();
    const [user, setUser] = useState<IUser>();

    useEffect(() => {
        handleSearch('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const handleSearch = (email: string) => {
        searchUsers(email).then((res: IUser[]) => {
            setUsers(res.filter((user) => ['Admin', 'Assistant', 'SuperAdmin'].includes(user.role)));
        });
    };

    return (
        <>
            <TableWrapper>
                <TableContainerHeader>
                    <TableContainerHeaderTitle title="Users" />
                    <NewUserButton setOpen={setOpen} />
                </TableContainerHeader>
                <TableContainer>
                    <TableHeader>
                        <TableHeaderColumn center>Name</TableHeaderColumn>
                        <TableHeaderColumn center>Email</TableHeaderColumn>
                        <TableHeaderColumn center>Role</TableHeaderColumn>
                    </TableHeader>
                    <tbody>
                        {users?.map((user) => (
                            <tr key={user.id}>
                                <td className="text-center">
                                    {user?.first_name} {user?.last_name}
                                </td>
                                <td className="text-center">{user.email}</td>
                                <td className="text-center">{user.role}</td>
                            </tr>
                        ))}
                    </tbody>
                </TableContainer>
            </TableWrapper>
            <AddUserModal open={open} setOpen={setOpen} user={user} setUser={setUser} />
        </>
    );
};

export default UserTable;
