import { KTSVG } from '../../helpers';

interface IProps {
    memberId: string | number;
    onAdd?: () => void;
    location?: string;
    setOpen: (val: boolean) => void;
}

const ViewDriverFileButton = ({ memberId, onAdd, location, setOpen }: IProps) => {
    return (
        <div
            className="btn btn-sm btn-light-primary d-inline-flex align-items-center"
            onClick={() => setOpen(true)}
            // onClick={() => window.location.assign(`/driver-pdf/${memberId}`)}
        >
            <KTSVG path="media/icons/duotune/arrows/arr075.svg" className="svg-icon-3" />
            View Driver File PDF
        </div>
    );
};

export default ViewDriverFileButton;
