import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {IProgress} from '../../interfaces/abstract';
import {
  IReleaseFormAuthorization,
  IReleaseFormAuthorizationDto,
} from '../../interfaces/ReleaseFormBackgroundCheck/ReleaseFormBackgroundChecks';

export const releaseFormAuthorizationApi = createApi({
  reducerPath: 'release_form_authorization_api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/release-forms-authorization`,
  }),
  tagTypes: ['ReleaseFormAuthorization'],
  keepUnusedDataFor: 60 * 10, // 10 minutes
  endpoints: (builder) => ({
    createReleaseFormAuthorization: builder.mutation<
      IReleaseFormAuthorization,
      IReleaseFormAuthorizationDto
    >({
      query: (body) => ({
        url: '/',
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'ReleaseFormAuthorization', id: 'ReleaseFormAuthorization'}],
    }),
    updateReleaseFormAuthorization: builder.mutation<
      IReleaseFormAuthorization,
      IReleaseFormAuthorization
    >({
      query: (body) => ({
        url: `/${body.id}/`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'ReleaseFormAuthorization', id: 'ReleaseFormAuthorization'}],
    }),
    deleteReleaseFormAuthorization: builder.mutation<void, number>({
      query: (id) => ({
        url: `/${id}/`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'ReleaseFormAuthorization', id: 'ReleaseFormAuthorization'}],
    }),
    getReleaseFormAuthorization: builder.query<IReleaseFormAuthorization, number>({
      query: (id) => ({
        url: `/get-release-form-authorization/`,
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'ReleaseFormAuthorization', id: 'ReleaseFormAuthorization'}],
    }),
    getReleaseFormAuthorizationByUser: builder.query<IReleaseFormAuthorization, void>({
      query: () => ({
        url: `/get-release-form-authorization-by-user/`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'ReleaseFormAuthorization', id: 'ReleaseFormAuthorization'}],
    }),
    getReleaseFormAuthorizationByUserId: builder.query<IReleaseFormAuthorization, number>({
      query: (id) => ({
        url: `/get-release-form-authorization-by-user-id/`,
        method: 'POST',
        body: {
          userId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'ReleaseFormAuthorization', id: 'ReleaseFormAuthorization'}],
    }),
    getReleaseFormAuthorizationProgress: builder.query<IProgress, number | undefined>({
      query: (id) => ({
        url: `/get-total-progress/`,
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'ReleaseFormAuthorization', id: 'ReleaseFormAuthorization'}],
    }),
    getMemberReleaseFormAuthorizationProgress: builder.query<IProgress, number | undefined>({
      query: (id) => ({
        url: `/get-member-progress/`,
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'ReleaseFormAuthorization', id: 'ReleaseFormAuthorization'}],
    }),
  }),
});

export const {
  useCreateReleaseFormAuthorizationMutation,
  useUpdateReleaseFormAuthorizationMutation,
  useGetReleaseFormAuthorizationQuery,
  useGetReleaseFormAuthorizationByUserQuery,
  useGetReleaseFormAuthorizationByUserIdQuery,
  useGetReleaseFormAuthorizationProgressQuery,
  useGetMemberReleaseFormAuthorizationProgressQuery,
  useDeleteReleaseFormAuthorizationMutation,
} = releaseFormAuthorizationApi;
