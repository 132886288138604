import {StripeProduct} from '../../app/modules/models';
import {ICompanyProduct} from '../../app/modules/subscription';
import {ICustomProducts} from '../../store/interfaces/PreventableMaintenance/stripe';
import client from '../Client';

export function getPlaidItems() {
  return client('payments/api/plaidItem');
}

export function getPlaidTempLink() {
  return client('payments/api/get-link-token', {method: 'POST'});
}

export function getCompanySubscription() {
  return client('payments/api/companySubscription/get-company-subscription/'); //  Added to reducer
}

export function getCompanyAddOns(company_id?: number) {
  return client(
    `payments/api/onetimePurchase/get-purchased-add-ons/${company_id ? `?company_id=${company_id}` : ''}`,
  ); //  Added to reducer
}

export function postCompanySubscription(planId: number) {
  return client('payments/api/companySubscription', {body: {subscription: planId}});
}

export const getSubscriptionPlans = async () => {
  return await client('payments/api/get-stripe-products/'); //  Added to reducer
};

export function getOneTimeProducts() {
  return client('payments/api/get-onetime-products/'); //  Added to reducer
}

export function getPreEmploymentProducts(packageName: string) {
  return client(`payments/api/get-pre-employment-products?subscription=${packageName}`); //  Added to reducer
}

export function getMemberRequestProducts(packageName?: string) {
  return client(
    `payments/api/get-member-request-products${packageName ? `?subscription=${packageName}` : ''}`,
  ); //  Added to reducer
}

export function createCustomProduct(customProduct: ICustomProducts) {
  return client('payments/api/create-custom-product/', {body: customProduct});
}

export function getCustomProducts() {
  return client('payments/api/get-custom-products/');
}

interface ICheckoutSession {
  priceId: string;
  type: string;
  tiersMode: string;
  product_id: string;
}

export function createCheckoutSession(checkoutSession: ICheckoutSession[], paymentType: string) {
  return client('payments/api/checkout-session/', {
    body: {
      checkoutSession,
      paymentType,
    },
  });
}

export function createAdondCheckoutSession(
  checkoutSession: {
    price_id: string;
    type: string;
    tiers_mode: string;
    product_id: string;
    members?: string[];
  },
  paymentType: string,
) {
  return client('payments/api/addon-checkout-session/', {
    body: {
      ...checkoutSession,
      paymentType: paymentType,
    },
  });
}

export async function createCheckoutSessionPreEmployment(
  priceId: string,
  type: string,
  tiersMode: string,
  investigation_id: number,
) {
  return client('payments/api/pre-employment-checkout/', {
    body: {
      price_id: priceId,
      type: type,
      tiers_mode: tiersMode,
      investigation_id: investigation_id,
    },
  });
}

export async function createCheckoutSessionMemberRequest(
  priceId: string,
  type: string,
  tiersMode: string,
  request_id: number,
  quantity?: number,
  isBulk?: boolean,
) {
  return await client('payments/api/checkout-session-member-request/', {
    body: {
      price_id: priceId,
      type: type,
      tiers_mode: tiersMode,
      request_id: request_id,
      quantity: quantity,
      isBulk: isBulk,
    },
  });
}

export function updateSubscription(
  current_subscription: ICompanyProduct,
  new_subscription: StripeProduct,
) {
  return client('payments/api/companySubscription/update-subscription/', {
    body: {
      current_subscription,
      new_subscription,
    },
  });
}

export function createSetupIntentSecret() {
  return client('payments/api/create-intent-secret/');
}

export function getConnectedPaymentMethods() {
  return client('payments/api/paymentMethod/');
}

export function getInvoices() {
  return client('payments/api/get-invoices/');
}

export function getUpcomingInvoices() {
  return client('payments/api/get-upcoming-invoices/');
}

export function deletePaymentMethod(paymentMethodId: string) {
  return client('payments/api/paymentMethod/', {method: 'DELETE', body: {paymentMethodId}});
}

export function setDefaultPaymentMethod(paymentMethodId: string) {
  return client('payments/api/paymentMethod/default', {body: {paymentMethodId}});
}
