import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
  IDriverCertificationForOtherCompensatedWork,
  IDriverCertificationForOtherCompensatedWorkDto,
} from '../../interfaces/DriversStatementDutyHours/DriverCertificationForOtherCompensatedWork';

export const driverCertificateForOtherCompensatedWorkApi = createApi({
  reducerPath: 'driver_certificate_for_other_compensated_work',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/driver-certification-for-other-compensated-work`,
  }),
  tagTypes: ['DriverCertificateForOtherCompensatedWork'],
  keepUnusedDataFor: 60 * 10, // 10 minutes
  endpoints: (builder) => ({
    createDriverCertificateForOtherCompensatedWork: builder.mutation<
      IDriverCertificationForOtherCompensatedWork,
      IDriverCertificationForOtherCompensatedWorkDto
    >({
      query: (body) => ({
        url: '/',
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [
        {
          type: 'DriverCertificateForOtherCompensatedWork',
          id: 'DriverCertificateForOtherCompensatedWork',
        },
      ],
    }),
    updateDriverCertificateForOtherCompensatedWork: builder.mutation<
      IDriverCertificationForOtherCompensatedWork,
      IDriverCertificationForOtherCompensatedWork
    >({
      query: (body) => ({
        url: `/${body.id}/`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [
        {
          type: 'DriverCertificateForOtherCompensatedWork',
          id: 'DriverCertificateForOtherCompensatedWork',
        },
      ],
    }),
    deleteDriverCertificateForOtherCompensatedWork: builder.mutation<void, number>({
      query: (id) => ({
        url: `/${id}/`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [
        {
          type: 'DriverCertificateForOtherCompensatedWork',
          id: 'DriverCertificateForOtherCompensatedWork',
        },
      ],
    }),
    getDriverCertificateForOtherCompensatedWork: builder.query<
      IDriverCertificationForOtherCompensatedWork,
      number | void
    >({
      query: (id) => ({
        url: `/get-driver-certification-for-other-compensated-work/`,
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [
        {
          type: 'DriverCertificateForOtherCompensatedWork',
          id: 'DriverCertificateForOtherCompensatedWork',
        },
      ],
    }),
    getDriverCertificateForOtherCompensatedWorkByUser: builder.query<
      IDriverCertificationForOtherCompensatedWork,
      void
    >({
      query: () => ({
        url: `/get-driver-certification-for-other-compensated-work-by-user/`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [
        {
          type: 'DriverCertificateForOtherCompensatedWork',
          id: 'DriverCertificateForOtherCompensatedWork',
        },
      ],
    }),
    getDriverCertificateForOtherCompensatedWorkByUserId: builder.query<
      IDriverCertificationForOtherCompensatedWork,
      number | void
    >({
      query: (id) => ({
        url: `/get-driver-certification-for-other-compensated-work-by-user-id/`,
        method: 'POST',
        body: {
          userId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [
        {
          type: 'DriverCertificateForOtherCompensatedWork',
          id: 'DriverCertificateForOtherCompensatedWork',
        },
      ],
    }),
  }),
});

export const {
  useCreateDriverCertificateForOtherCompensatedWorkMutation,
  useGetDriverCertificateForOtherCompensatedWorkByUserIdQuery,
  useGetDriverCertificateForOtherCompensatedWorkByUserQuery,
  useGetDriverCertificateForOtherCompensatedWorkQuery,
  useUpdateDriverCertificateForOtherCompensatedWorkMutation,
  useDeleteDriverCertificateForOtherCompensatedWorkMutation,
} = driverCertificateForOtherCompensatedWorkApi;
