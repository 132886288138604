import { Box, Button, Grid, MenuItem, Paper, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../core/Auth';
import { useGetMaintenanceVehiclesByCompanyQuery } from '../../../../store/reducers/PreventableMaintenance/maintenance.vehicle';
import { IMaintenanceVehicle } from '../../../../store/interfaces/PreventableMaintenance/maintenance.vehicles';
import { getCompanyMember } from '../../../../shared';
import { IMember } from '../../../../models';

const SelectVehicle = () => {
    const { currentUser } = useAuth();
    const [id, setId] = useState<number>();
    const [vehicleNumber, setVehicleNumber] = useState<IMaintenanceVehicle>();
    const { data } = useGetMaintenanceVehiclesByCompanyQuery(id ?? 0, {
        refetchOnFocus: true,
        skip: !id
    });

    const getCompanyId = async () => {
        if (currentUser?.role === 'Member') {
            const member = (await getCompanyMember(String(currentUser?.member?.id))) as unknown as IMember;
            setId(Number(member?.company?.id));
        }
        if (currentUser?.role === 'Owner') {
            setId(Number(currentUser?.company?.id));
        }
    };

    useEffect(() => {
        if (currentUser) {
            getCompanyId();
        }
        // eslint-disable-next-line
    }, [currentUser]);

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '90vh'
            }}
        >
            <Box
                component={Paper}
                p={2}
                m={2}
                sx={{
                    borderRadius: 2,
                    boxShadow: 3,
                    width: {
                        xs: '90%',
                        sm: '80%',
                        md: '60%',
                        lg: '40%'
                    }
                }}
            >
                <Typography variant="h6" textAlign="center" mb={4}>
                    Select Vehicle to Continue
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                            fullWidth
                            id="vehicle_number"
                            type="text"
                            size="small"
                            select
                            value={vehicleNumber?.id ?? 0}
                            onChange={(e) => {
                                const vehicle = data?.find((item) => item.id === Number(e.target.value));
                                setVehicleNumber(vehicle);
                            }}
                        >
                            {data?.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.vehicle_number}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Button
                            disabled={!vehicleNumber}
                            variant="contained"
                            fullWidth
                            size="small"
                            color="primary"
                            onClick={() => {
                                // Add the vehicle id to the url
                                window.location.assign(`/driver-inspection-report/${vehicleNumber?.id}`);
                            }}
                        >
                            Continue
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
};

export default SelectVehicle;
