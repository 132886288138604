import {IFile} from '../../../models';
import {IServiceAlerts} from '../ServiceAlerts/ServiceAlerts.interface';
import {IMaintenanceVehicle} from './maintenance.vehicles';

export enum ServiceOrderType {
  DVIR_REPORT = 'DVIR_REPORT',
  MISCELLANEOUS = 'MISCELLANEOUS',
}

export interface IServiceOrders {
  maintenance_vehicle: IMaintenanceVehicle;
  title: string;
  description: string;
  file_name: string;
  original_file_name: string;
  file_type: string;
  file: IFile;
  is_active: boolean;
  upload_finished_at: Date;
  link: string;
  service_alert?: IServiceAlerts;
  type: ServiceOrderType;
  odometer_reading?: number;
  id?: number;
}
