import { useEffect, useState } from 'react';
import TableWrapper from '../../../compliancemasters/partials/widgets/tables/components/TableWrapper';
import TableContainerHeader from '../../../compliancemasters/partials/widgets/tables/components/TableContainerHeader';
import TableContainerHeaderTitle from '../../../compliancemasters/partials/widgets/tables/components/TableContainerHeaderTitle';
import { KTSVG } from '../../../compliancemasters/helpers';
import TableContainer from '../../../compliancemasters/partials/widgets/tables/components/TableContainer';
import TableHeader from '../../../compliancemasters/partials/widgets/tables/components/TableHeader';
import TableHeaderColumn from '../../../compliancemasters/partials/widgets/tables/components/TableHeaderColumn';
import {
    Box,
    Grid,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Paper,
    TableBody,
    TableCell,
    TablePagination,
    TableRow,
    TextField
} from '@mui/material';
import { useGetMaintenanceVehiclesByCompanyQuery } from '../../../store/reducers/PreventableMaintenance/maintenance.vehicle';
import { AddVehicleModal } from './AddVehicleModal';
import { IMaintenanceVehicle } from '../../../store/interfaces/PreventableMaintenance/maintenance.vehicles';
import { AdminPanelSettingsRounded, MoreVertRounded } from '@mui/icons-material';
import { useCompany } from '../../../context/CompanyContext';
import { useAuth } from '../../modules/auth';
import { AssignPmProgramModal } from './AssignPmProgramModal';
import { DeleteVehicleModal } from './DeleteVehicleModal';
import { useParams } from 'react-router-dom';

enum Filter {
    Active = 'Active',
    Inactive = 'Inactive'
}

const MenuComponent = ({
    anchorEl,
    open,
    handleClose,
    setAddPmProgramModalOpen,
    setDeleteModalOpen
}: {
    anchorEl: HTMLElement | null;
    open: boolean;
    handleClose: () => void;
    setAddPmProgramModalOpen: (val: boolean) => void;
    setDeleteModalOpen: (val: boolean) => void;
}) => {
    return (
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            slotProps={{
                paper: {
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0
                        }
                    }
                }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItem
                onClick={() => {
                    handleClose();
                }}
            >
                <ListItemIcon>
                    <AdminPanelSettingsRounded fontSize="medium" />
                </ListItemIcon>
                View Vehicle Details
            </MenuItem>
            <MenuItem
                onClick={() => {
                    handleClose();
                    setAddPmProgramModalOpen(true);
                }}
            >
                <ListItemIcon>
                    <AdminPanelSettingsRounded fontSize="medium" />
                </ListItemIcon>
                Assign PM Program
            </MenuItem>
            <MenuItem
                onClick={() => {
                    setDeleteModalOpen(true);
                    handleClose();
                }}
            >
                <ListItemIcon>
                    <AdminPanelSettingsRounded fontSize="medium" />
                </ListItemIcon>
                Delete Vehicle
            </MenuItem>
        </Menu>
    );
};

const VehicleList = () => {
    const { id } = useParams<{ id: string }>();
    const [open, setOpen] = useState(false);
    const [filter, setFilter] = useState<Filter>(Filter.Active);
    const [vehicleFilterInput, setUserFilterInput] = useState<string>('');
    const [vehicles, setVehicles] = useState<IMaintenanceVehicle[]>([]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedVehicle, setSelectedVehicle] = useState<IMaintenanceVehicle>();
    const [addPmProgramOpen, setAddPmProgramOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const menuOpen = Boolean(anchorEl);
    const { currentCompany } = useCompany();
    const { currentUser } = useAuth();
    const { data = [] } = useGetMaintenanceVehiclesByCompanyQuery(
        Number(currentUser?.company?.id) || Number(currentCompany?.id) || Number(id),
        {
            refetchOnFocus: true,
            skip: !currentUser?.company?.id && !currentCompany?.id && !id
        }
    );

    const [page, setPage] = useState<number>(0);

    const [limit, setLimit] = useState<number>(5);

    const handlePageChange = (event: any, newPage: number): void => {
        setPage(newPage);
    };

    const handleLimitChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setLimit(parseInt(event.target.value));
    };

    useEffect(() => {
        if (filter === Filter.Active) {
            const filteredVehicles = data.filter((vehicle) => vehicle.active);
            setVehicles(filteredVehicles);
        } else {
            const filteredVehicles = data.filter((vehicle) => !vehicle.active);
            setVehicles(filteredVehicles);
        }
    }, [filter, data]);

    useEffect(() => {
        const filteredVehicles = data.filter((vehicle) => vehicle.active);
        setVehicles(filteredVehicles);
        if (vehicleFilterInput) {
            const filteredVehicles = data.filter((vehicle) => {
                return (
                    vehicle.vehicle_number.toLowerCase().includes(vehicleFilterInput.toLowerCase()) ||
                    vehicle.vehicle_make.toLowerCase().includes(vehicleFilterInput.toLowerCase()) ||
                    vehicle.vehicle_model.toLowerCase().includes(vehicleFilterInput.toLowerCase())
                );
            });
            setVehicles(filteredVehicles);
        }
    }, [vehicleFilterInput, data]);

    return (
        <Paper component={Box} mt={4}>
            <TableWrapper>
                <TableContainerHeader>
                    <TableContainerHeaderTitle title="Vehicle List" />
                    <div
                        className="btn btn-sm btn-light-primary d-inline-flex align-items-center"
                        onClick={() => setOpen(true)}
                    >
                        <KTSVG path="media/icons/duotune/arrows/arr075.svg" className="svg-icon-3" />
                        Add Vehicle
                    </div>
                </TableContainerHeader>
                <Grid container spacing={2} p={2}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                            fullWidth
                            value={filter}
                            variant="outlined"
                            size="small"
                            select
                            helperText="Filter Vehicles"
                        >
                            {Object.values(Filter).map((option: Filter) => (
                                <MenuItem key={option} value={option} onClick={() => setFilter(option)}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                            fullWidth
                            value={vehicleFilterInput}
                            onChange={(e) => setUserFilterInput(e.target.value)}
                            variant="outlined"
                            size="small"
                            placeholder="Search for Vehicles"
                        />
                    </Grid>
                </Grid>
                <TableContainer>
                    <TableHeader>
                        <TableHeaderColumn>Vehicle No.</TableHeaderColumn>
                        <TableHeaderColumn>Make</TableHeaderColumn>
                        <TableHeaderColumn>Model</TableHeaderColumn>
                        <TableHeaderColumn>Type</TableHeaderColumn>
                        <TableHeaderColumn>Odometer Reading</TableHeaderColumn>
                        <TableHeaderColumn>PM Program</TableHeaderColumn>
                        <TableHeaderColumn>Actions</TableHeaderColumn>
                    </TableHeader>
                    <TableBody>
                        {vehicles
                            .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
                            .slice(page * limit, page * limit + limit)
                            .map((vehicle, index) => (
                                <TableRow
                                    hover
                                    key={vehicle.id}
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        window.location.assign(`/maintenance-vehicle/${vehicle.id}`);
                                    }}
                                >
                                    <TableCell>{vehicle.vehicle_number}</TableCell>
                                    <TableCell>{vehicle.vehicle_make}</TableCell>
                                    <TableCell>{vehicle.vehicle_model}</TableCell>
                                    <TableCell>{vehicle.vehicle_type}</TableCell>
                                    <TableCell>{vehicle.current_odometer_reading}</TableCell>
                                    <TableCell>{vehicle.pm_program ? 'Started' : 'Not Started'}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setAnchorEl(e.currentTarget);
                                                setSelectedVehicle(vehicle);
                                            }}
                                        >
                                            <MoreVertRounded />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </TableContainer>
                <Box p={2}>
                    <TablePagination
                        component="div"
                        count={vehicles.length}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleLimitChange}
                        page={page}
                        rowsPerPage={limit}
                        rowsPerPageOptions={[5, 10, 25, 30]}
                    />
                </Box>
            </TableWrapper>
            <AddVehicleModal open={open} setOpen={setOpen} />
            <MenuComponent
                anchorEl={anchorEl}
                open={menuOpen}
                handleClose={() => setAnchorEl(null)}
                setAddPmProgramModalOpen={setAddPmProgramOpen}
                setDeleteModalOpen={setDeleteModalOpen}
            />
            <AssignPmProgramModal open={addPmProgramOpen} setOpen={setAddPmProgramOpen} vehicle={selectedVehicle} />
            <DeleteVehicleModal
                open={deleteModalOpen}
                setOpen={setDeleteModalOpen}
                vehicle={selectedVehicle}
                setVehicle={setSelectedVehicle}
            />
        </Paper>
    );
};

export default VehicleList;
