import { useParams } from 'react-router-dom';
import { useAuth } from '../../../../auth';
import { useThemeMode } from '../../../../../../compliancemasters/partials';
import { Box, Container, Paper } from '@mui/material';
import FormHeaderCard from '../../../../../../compliancemasters/reforms/HeaderCard';
import { IMember } from '../../../../../../models';
import { getCompanyMember } from '../../../../../../shared';
import MedicalExaminerNationalRegistryVerification from './MedicalExaminerNationalRegistryVerification';
import { useState, useEffect } from 'react';

const NonCurrentMedicalExaminerNationalRegistry = () => {
    const { currentUser } = useAuth();
    const { mode } = useThemeMode();
    // const [getProgress, { data: progress }] = useGetDriverMedicalVerificationProgressMutation()
    // const [getMemberProgress, { data: memberProgress }] = useGetMemberDriverMedicalVerificationProgressMutation()
    const [member, setMember] = useState<IMember>();
    const { id } = useParams();
    const [value, setValue] = useState(0);

    useEffect(() => {
        if (id) {
            getCompanyMember(id).then((data) => {
                setMember(data);
            });
            // getProgress(Number(id))
            // getMemberProgress(Number(id))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Container>
            <Box
                sx={{
                    pl: 3,
                    pr: 3,
                    pt: 3,
                    m: 3,
                    borderRadius: 2,
                    backgroundColor: mode === 'light' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                    width: {
                        lg: '47vw',
                        md: '55vw',
                        sm: '60vw'
                    },
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }}
                component={Paper}
            >
                <FormHeaderCard
                    headerTitle="Medical Examiner National Registry Verification"
                    sectionCount={4}
                    itemCount={29}
                    nextButton={{ title: 'Driver Handbook Receipt', path: '' }}
                    member={member}
                    setValue={setValue}
                    value={value}
                    nonCurrent={true}
                />
            </Box>
            <Box
                sx={{
                    pl: 3,
                    pr: 3,
                    pt: 3,
                    m: 3,
                    borderRadius: 2,
                    backgroundColor: mode === 'light' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                    width: {
                        lg: '47vw',
                        md: '55vw',
                        sm: '60vw'
                    },
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }}
                component={Paper}
            >
                {currentUser && currentUser.role !== 'Member' && (
                    <MedicalExaminerNationalRegistryVerification member={member} />
                )}
            </Box>
        </Container>
    );
};

export default NonCurrentMedicalExaminerNationalRegistry;
