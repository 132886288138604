import { Box, Paper, Typography } from '@mui/material';
import { useThemeMode } from '../../../compliancemasters/partials';

const WrittenPolicy = () => {
    const { mode } = useThemeMode();
    return (
        <Box
            component={Paper}
            sx={{
                p: 2,
                backgroundColor: mode === 'dark' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)'
            }}
            elevation={2}
            boxShadow={2}
        >
            <Typography variant="h4" component="h4" textAlign="center">
                D & A Written Policy
            </Typography>
            <Typography variant="h4" component="h4" textAlign="center">
                Compliance Masters, LLC.
            </Typography>
            <Typography variant="h4" component="h4" textAlign="center">
                Drug and Alcohol Written Policy
            </Typography>
            <div style={{ marginTop: 10 }} />
            <Typography
                variant="body1"
                component="p"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                Policy Statement: The Company is a drug-free workplace and company policy requires all CDL holders as
                well as employees in safety sensitive areas to not use alcohol eight hours before their work time until
                fifteen minutes after they have finished work. More importantly, the Company recognizes that the
                employees are the most valuable resource and wants to assist any employee who feels they may have a
                problem with chemical dependency or alcoholism. The Company will have available information on and
                access to appropriate employee assistance programs designed to help those individuals who wish to treat
                such problems. Training will be given to all employees about the effects of drug and alcohol as well as
                the details of this policy. The Company will also display and distribute informational material on this
                matter to all employees. Every employee shall be notified of the Company Policy at the time of their
                employment and during the education/training program. Training shall be provided to all supervisory
                personnel who will determine whether an employee must be drug and/or alcohol tested based on reasonable
                cause.
            </Typography>
            <Typography
                variant="body1"
                component="p"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                Policy Purpose: The Company is concerned with the safety of its employees, customers, and equipment. The
                use of drugs and alcohol compromises that safety, and thus are prohibited. Further, the goal of this
                policy is to balance our respect for individuals with the need to maintain a safe, productive and
                drug-free environment. The intent of this policy is to offer a helping hand to those who need it, while
                sending a clear message that the illegal use of drugs and the abuse of alcohol are incompatible with
                employment at the Company. This policy shall comply with the laws of the United States of America.
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                Responsible Party: Chief Executive Officer (CEO)
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                Procedure:
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                Definitions:
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                G.9.1 For the purpose of the Policy and the Company’s drug and alcohol testing program, “safety
                sensitive function” means any of the following:
            </Typography>
            <ol>
                {definitions.map((item, index) => (
                    <li
                        key={index}
                        style={{
                            listStyle: 'none'
                        }}
                    >
                        <Typography
                            variant="body1"
                            component="p"
                            textAlign="left"
                            sx={{
                                color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                                my: 2,
                                fontSize: '1.5rem'
                            }}
                        >
                            {index + 1}. {item}
                        </Typography>
                    </li>
                ))}
            </ol>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                G.9.2 For purposes of the Policy and the Company’s drug and alcohol testing program, “company business”
                includes, but is not limited to, work performed on or in Company property including a Company vehicle,
                and work performed on, or in a non-Company vehicle being used for conducting company business; the term
                also includes meal and break times.
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                G.9.3 For purposes of the Policy and the Company’s drug and alcohol testing program, “refusing to be
                tested”, means any of the following:
            </Typography>
            <ol>
                {definitions2.map((item, index) => (
                    <li
                        key={index}
                        style={{
                            listStyle: 'none'
                        }}
                    >
                        <Typography
                            variant="body1"
                            component="p"
                            textAlign="left"
                            sx={{
                                color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                                my: 2,
                                fontSize: '1.5rem'
                            }}
                        >
                            {index + 1}. {item}
                        </Typography>
                    </li>
                ))}
            </ol>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                G.9.4 For purposes of the Policy and the Company's testing program, an alcohol test will be considered
                positive when the alcohol concentration level registers 0.02 or greater.
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                G.9.5 “Under the influence” means, with respect to drugs, the presence in an employee’s system or any
                detectable amount of a drug, or its metabolites, and speech, action, or an appearance which lead a
                supervisor to reasonable suspect that the employee’s ability to perform his or her job safely and
                effectively has been impaired by drugs.
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                G.9.6 The term “drugs” includes any and all controlled substances, such as, but not limited to,
                marijuana, cocaine, amphetamines, PCP, and opiates. The term “drugs” also includes prescription and
                over-the-counter medications which are being abused.
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem',
                    fontStyle: 'italic'
                }}
            >
                Prohibitive Conduct
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                G.9.7 The following employee conduct is prohibited:
            </Typography>
            <ol>
                {definitions3.map((item, index) => (
                    <li
                        key={index}
                        style={{
                            listStyle: 'none'
                        }}
                    >
                        <Typography
                            variant="body1"
                            component="p"
                            textAlign="left"
                            sx={{
                                color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                                my: 2,
                                fontSize: '1.5rem'
                            }}
                        >
                            {index + 1}. {item}
                        </Typography>
                    </li>
                ))}
            </ol>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                G.9.8 Prescription drugs may interfere with the employees ability to perform their duties. Employees
                taking a prescription or an over-the-counter drug must be able to explain any possible side effects to
                their supervisor. CDL holders and employees in safety sensitive areas must be aware that some
                over-the-counter medications and mouthwashes contain alcohol and/or antihistamines which may impair safe
                operation of equipment. For example; Vicks NyQuil TM is 25% (50 proof) ethyl alcohol, Comtrex TM is 20%
                (40 proof), Contac Severe Cold Formula Night Strength TN is 25% (50 proof) and Listerine TM is 26.9% (50
                proof). These substances are prohibited for CDL holders and employees in safety sensitive areas.
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                G.9.9 As a condition of employment, employees must abide by the terms of this policy and must notify the
                Company in writing of any conviction of a violation of a criminal drug statute occurring in the
                workplace no later than five (5) calendar days after such conviction.
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                G.9.10 If, during the course of employment, the employee acknowledges a substance or alcohol abuse
                problem, the problem shall be treated as any other illness, subject to the following:
            </Typography>
            <ol>
                {definitions4.map((item, index) => (
                    <li
                        key={index}
                        style={{
                            listStyle: 'none'
                        }}
                    >
                        <Typography
                            variant="body1"
                            component="p"
                            textAlign="left"
                            sx={{
                                color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                                my: 2,
                                fontSize: '1.5rem'
                            }}
                        >
                            {index + 1}. {item}
                        </Typography>
                    </li>
                ))}
            </ol>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                General Procedures
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                G.9.11 It is the responsibility of the Company’s supervisors and managers to counsel employees whenever
                they see changes in performance or behavior that suggest an employee has a drug problem. Although it is
                not the supervisor’s job to diagnose personal problems, the supervisor should encourage such employees
                to seek help and advise them about available resources for getting help. Everyone shares responsibility
                for maintaining a safe work environment and co-workers should encourage anyone who has a drug problem to
                seek help.
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                G.9.12 Refusal to submit to a controlled substance and/or alcohol test will result in immediate
                termination of employment. Refusal includes, but is not limited to, refusal to sign the controlled
                substance chain of custody form or the breath alcohol test form, failure to cooperate with the
                controlled substance test collection site personnel or with the breath alcohol technician, not going the
                most direct route in a reasonable time to the collection center, and/or failure to provide an adequate
                sample of urine or breath.
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                G.9.13 The Company shall provide training to all employees on the effects of drug and alcohol abuse, as
                well as the details of this policy. The Company will also offer resource information on various means of
                employee assistance in our community, including but not limited to the names of drug and alcohol abuse
                and assistance programs, as well as assistance in enrolling in these programs. Distribution of this
                information to employees will be for their confidential use. Employees are encouraged to use this
                resource file; which is located in the Chief Financial Officer’s (CFO) office. Costs of these programs,
                not covered by insurance, shall be paid for by the employee.
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                G.9.14 Testing for breath alcohol and controlled substances may take place under the following
                conditions:
            </Typography>
            <ol>
                {definitions5.map((item, index) => (
                    <li
                        key={index}
                        style={{
                            listStyle: 'none'
                        }}
                    >
                        <Typography
                            variant="body1"
                            component="p"
                            textAlign="left"
                            sx={{
                                color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                                my: 2,
                                fontSize: '1.5rem'
                            }}
                        >
                            {index + 1}. {item}
                        </Typography>
                    </li>
                ))}
            </ol>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                G.9.14 Testing for breath alcohol and controlled substances may take place under the following
                conditions:
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem',
                    fontStyle: 'italic'
                }}
            >
                Job Applicant Drug Testing
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                G.9.15 All job applicants with the Company will undergo testing for substance abuse as a condition of
                employment. Any applicant with a confirmed positive test result will be denied employment. Applicants
                will be required to submit voluntarily to a urinalysis test at a laboratory chosen by the Company, and
                will also be required to sign a consent agreement releasing the Company from liability as a condition of
                pre-employment.
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                G.9.16 If the physician, unofficial or lab personnel has responsible suspicion to believe that the job
                applicant has tampered with the specimen, the applicant will not be considered for employment.
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                G.9.17 The Company will not discriminate against applicants from employment because of a past history of
                drug or alcohol abuse. It is the current illegal use of drugs and/or abuse of alcohol, for preventing
                employees from performing their jobs properly, that the Company wishes to prohibit.
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontStyle: 'italic',
                    fontSize: '1.5rem'
                }}
            >
                Employee Drug Testing
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                G.9.18 The company has adopted testing practices to identify employees who illegally use drugs on or off
                the job or who abuse alcohol on the job. It shall be a condition of employment for all employees to
                submit to substance-abuse testing under the following circumstances:
            </Typography>
            <ol>
                <li
                    style={{
                        color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                        fontSize: '1.5rem'
                    }}
                >
                    when there is reasonable suspicion to believe that an employee is illegally using drugs or abusing
                    alcohol. ‘Reasonable suspicion’ is based on a belief that an employee is using or has used drugs or
                    alcohol in violation of the employer’s policy drawn from specific objective and particular facts and
                    reasonable inferences drawn from those facts in light of experience. Among other things, such facts
                    and inferences may be based upon, but not limited to, the following:
                </li>
                <ol>
                    <li
                        style={{
                            color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                            listStyleType: 'lower-alpha',
                            fontSize: '1.5rem'
                        }}
                    >
                        Observable phenomena while at work, either singularly or in combination, such as the following:
                    </li>
                </ol>
            </ol>
            <ul>
                <li
                    style={{
                        color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                        fontSize: '1.5rem'
                    }}
                >
                    Abnormally dilated or constricted pupils
                </li>
                <li
                    style={{
                        color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                        fontSize: '1.5rem'
                    }}
                >
                    Glazed stare; redness of eyes
                </li>
                <li
                    style={{
                        color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                        fontSize: '1.5rem'
                    }}
                >
                    Flushed face
                </li>
                <li
                    style={{
                        color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                        fontSize: '1.5rem'
                    }}
                >
                    Change of speech (faster, slower, slurring)
                </li>
                <li
                    style={{
                        color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                        fontSize: '1.5rem'
                    }}
                >
                    Constant sniffing
                </li>
                <li
                    style={{
                        color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                        fontSize: '1.5rem'
                    }}
                >
                    Increased absences
                </li>
                <li
                    style={{
                        color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                        fontSize: '1.5rem'
                    }}
                >
                    Redness under the nose
                </li>
                <li
                    style={{
                        color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                        fontSize: '1.5rem'
                    }}
                >
                    Sudden weight loss
                </li>
                <li
                    style={{
                        color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                        fontSize: '1.5rem'
                    }}
                >
                    Needle marks
                </li>
                <li
                    style={{
                        color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                        fontSize: '1.5rem'
                    }}
                >
                    Change in personality
                </li>
                <li
                    style={{
                        color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                        fontSize: '1.5rem'
                    }}
                >
                    Increased appetite for sweets
                </li>
                <li
                    style={{
                        color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                        fontSize: '1.5rem'
                    }}
                >
                    Forgetfulness-performance faltering
                </li>
                <li
                    style={{
                        color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                        fontSize: '1.5rem'
                    }}
                >
                    Poor concentration
                </li>
                <li
                    style={{
                        color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                        fontSize: '1.5rem'
                    }}
                >
                    Constant fatigue or hyperactivity
                </li>
                <li
                    style={{
                        color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                        fontSize: '1.5rem'
                    }}
                >
                    Smell of alcohol
                </li>
                <ol>
                    <li
                        style={{
                            color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                            fontSize: '1.5rem',
                            listStyleType: 'numeric'
                        }}
                    >
                        Abnormal conduct or erratic behavior while at work or significant deterioration in work
                        performance;
                    </li>
                    <li
                        style={{
                            color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                            fontSize: '1.5rem',
                            listStyleType: 'numeric'
                        }}
                    >
                        A report of substance abuse provided by a reliable and credible source;
                    </li>
                    <li
                        style={{
                            color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                            fontSize: '1.5rem',
                            listStyleType: 'numeric'
                        }}
                    >
                        Evidence that an individual has tampered with any substance abuse test during his or her
                        employment with the current employer;
                    </li>
                    <li
                        style={{
                            color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                            fontSize: '1.5rem',
                            listStyleType: 'numeric'
                        }}
                    >
                        Information that an employee has caused or contributed to an accident while at work or;
                    </li>
                    <li
                        style={{
                            color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                            fontSize: '1.5rem',
                            listStyleType: 'numeric'
                        }}
                    >
                        Evidence that an employee has used, possessed, sold, solicited, or transferred drugs while
                        working or while on the employer’s premises or while operating the employer’s vehicle,
                        machinery, or equipment
                    </li>
                </ol>
            </ul>
            <ol>
                <li
                    style={{
                        color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                        listStyleType: 'numeric',
                        fontSize: '1.5rem'
                    }}
                >
                    when an employee has caused or contributed to an on-the-job injury that resulted in a loss of work
                    time, which means any period of time during which an employee stops performing the normal duties of
                    employment and leaves the place of employment to seek care from a licensed medical provider. An
                    employer may send employees for substance-abuse tests if they are involved in on-the-job accidents
                    or personal injury or damage to Company property occurs.
                </li>
                <li
                    style={{
                        color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                        listStyleType: 'numeric',
                        fontSize: '1.5rem'
                    }}
                >
                    as part of a follow-up program to treatment for drug abuse
                </li>
                <li
                    style={{
                        color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                        listStyleType: 'numeric',
                        fontSize: '1.5rem'
                    }}
                >
                    routine fitness-for-duty drug or alcohol testing. A covered employer must require an employee to
                    submit to a drug or alcohol test if the test is conducted as part of a routinely scheduled employee
                    fitness-for-duty medical examination where the examinations are required by; law, regulation, are
                    part of the covered employer’s established policy, or one that is scheduled routinely for all
                    members of an employment classification group.
                </li>
            </ol>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                G.9.19 Any employee reporting to work visibly impaired will be deemed unable to perform required duties
                and will not be allowed to work. If possible, the employee's supervisor will first seek another
                supervisor's opinion to confirm the employee’s status. Next the supervisor will consult privately with
                the employee to determine the cause of the observation, including whether substance abuse has occurred.
                If, in the opinion of the supervisor, the employee is considered impaired, the employee will be sent
                home or to a medical facility by taxi or other safe transportation alternative—depending on the
                determination of the observed impairment—and accompanied by the supervisor or another employee if
                necessary. A drug or alcohol test may be required. An impaired employee will not be allowed to drive.
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontStyle: 'italic',
                    fontSize: '1.5rem'
                }}
            >
                Opportunity to Contest or Explain Test Results
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                G.9.20 Employees and job applicants who have a positive confirmed drug or alcohol test results may
                explain or contest the result to the medical review officer within five (5) working days after receiving
                written notification of the test results from the medical review officer. If an employee's or job
                applicant’s explanation or challenge is unsatisfactory to the medical review officer, the medical review
                officer shall report positive test results back to the Company; a person may contest the drug test
                result pursuant to rules adopted by the state’s Department of Labor.
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontStyle: 'italic',
                    fontSize: '1.5rem'
                }}
            >
                Confidentiality
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                G.9.21 The confidentiality of any information received by the employer through a substance-abuse testing
                program shall be maintained, except as otherwise provided by law.
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontStyle: 'italic',
                    fontSize: '1.5rem'
                }}
            >
                Refusal to Submit
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                G.9.22 Failure to submit to a required substance-abuse test is misconduct and also shall be subject to
                discipline up to and including termination, especially if conducted for pre-employment drug screening.
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontStyle: 'italic',
                    fontSize: '1.5rem'
                }}
            >
                Important Information for Job Applicants and Employees
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                G.9.23 When an employee or job applicant submits to a drug and/or alcohol test, he/she will be given a
                form by the specimen collector that contains a list of common medications and substances which may alter
                or affect the outcome of a drug or alcohol test. This form will also have a space for the donor to
                provide any information that he/she considers relevant to the test, including the identification of
                currently or recently used prescription or non-prescription medication or other relevant information.
                The information form should be kept by the job applicant or employee for his/her personal use. If the
                job applicant or employee has a positive confirmed test result, a medical review officer will attempt to
                contact the individual in order to privately discuss the findings with that person. The job applicant or
                employee should keep the form as a reminder to discuss this information at that time. The medical review
                officer will take this information into account when interpreting any positive confirmed test results.
                The information provided shall be treated as confidential and will not be given to the employer.
                Employees and job applicants have the right to consult with a medical review officer for technical
                information regarding prescription and non-prescription medicine.
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                G.9.24 It is the responsibility of every employee or job applicant to notify the testing laboratory of
                any administrative or civil action brought pursuant to TCA Section 50– 9–100 et.seq, Drug-Free Workplace
                Programs.
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                G.9.25 Substance abuse testing for job applicants and employees will include a urinalysis screen for the
                following drugs: Alcohol; (not required for job applicant testing); any alcoholic beverage, all liquid
                medications containing ethyl alcohol (ethanol). Please read the label for content.
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                For example; Vicks Nyquil TM is 25% (50 proof) ethyl alcohol, Comtrex ™ is 20% (40 proof), Contac
                Severe Cold Formula Night Strength TN is 25% (50 proof) and Listerine TM is 26.9% (54 proof);
                Amphetamines: "speed," "uppers," etc. Cannabinoids: THC, marijuana, hashish, "pot," "grass," "hash,"
                etc. Cocaine: "coke," "crack," etc. Phencyclidine: PCP, "angel dust." Opiates: Narcotics, Heroin,
                Codeine, Morphine, "smack," "dope," etc.
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontStyle: 'italic',
                    fontSize: '1.5rem'
                }}
            >
                Commercial Driver's License Holders
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                G.9.26 Employees who hold a Commercial Driver's License or are otherwise in a safety sensitive position,
                will be subject to random tests for drugs and alcohol. Random testing will be conducted on an
                unannounced and ongoing basis throughout the calendar year. With each random selection, each covered
                employee has an equal chance of being chosen for testing. Random tests for alcohol are administered just
                prior to, during, or just after a Driver performs a safety sensitive function.
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                G.9.27 All CDL holders are required to provide a breath test within eight (8) hours and a urine specimen
                within 32 hours of an accident to be used for the testing of controlled substances. The CDL holder shall
                remain available for such testing, or may be deemed as having refused to be tested. If the driver is
                seriously injured, and cannot provide a specimen at the time of the accident, the driver shall provide
                the necessary authorization for obtaining hospital reports and other documents that would indicate
                whether there were any controlled substances in the driver's system.
            </Typography>
            <ol>
                <li
                    style={{
                        color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                        listStyleType: 'numeric',
                        fontSize: '1.5rem'
                    }}
                >
                    An accident is defined by the Federal Motor Safety Regulations as an accident which results in the
                    death of a human being or bodily injury to a person who, as a result of the injury, immediately
                    receives medical treatment away from the scene of the accident, or which requires one of the
                    vehicles towed from the scene of the accident. Except for a fatality accident, verification of the
                    driver's responsibility in the above scenario must be established by a traffic citation to the
                    driver.
                </li>
            </ol>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                G.9.28 The Company shall adhere to the Federal Policy for random testing for all covered CDL holders as
                follows:
            </Typography>
            <ol>
                <li
                    style={{
                        color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                        listStyleType: 'numeric',
                        fontSize: '1.5rem'
                    }}
                >
                    a company-wide selection process which removes discretion in selection by any management personnel
                    has been adopted by the Company;
                </li>
                <li
                    style={{
                        color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                        listStyleType: 'numeric',
                        fontSize: '1.5rem'
                    }}
                >
                    random testing for alcohol of at least twenty-five (25%) and for drug testing of at least fifty
                    percent (50%) of all covered CDL holders;
                </li>
                <li
                    style={{
                        color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                        listStyleType: 'numeric',
                        fontSize: '1.5rem'
                    }}
                >
                    the random testing will be reasonably spaced over any twelve (12) month period;
                </li>
                <li
                    style={{
                        color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                        listStyleType: 'numeric',
                        fontSize: '1.5rem'
                    }}
                >
                    Once notified, the CDL holder must proceed to the assigned collection site.
                </li>
            </ol>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                G.9.29 Should a CDL holder or employee in a safety-sensitive position be required to complete an
                approved treatment program, that individual shall be required to pass a return to duty test, submit to
                at least six follow-up tests, during the first year, and follow-up testing may be conducted for up to
                sixty (60) months. Failure to adhere to this condition is grounds for immediate termination.
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                G.9.30 Split sample urine specimens will be collected at a designated collection site under
                circumstances according to Department of Transportation/Federal regulations (Part 40 of Title 49)
                designed to prevent sample switching and tampering. Urine specimens will be sealed and sent to the
                designated NIDA certified laboratory for testing. Detailed records will be kept to prevent
                misidentification of samples. The following protocol will apply to all specimen collections:
            </Typography>
            <ol>
                <li
                    style={{
                        color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                        listStyleType: 'numeric',
                        fontSize: '1.5rem'
                    }}
                >
                    the applicant/employee will provide a urine sample at the assigned collection site at the appointed
                    time.
                </li>
                <li
                    style={{
                        color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                        listStyleType: 'numeric',
                        fontSize: '1.5rem'
                    }}
                >
                    the applicant/employee will participate in the chain of custody procedures in order to ensure
                    accurate collection by providing photo identification, completing and signing consent, release of
                    information and Chain of Custody forms, and following Department of Transportation/Federal urine
                    collection procedures in cooperation with the collection site.
                </li>
                <li
                    style={{
                        color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                        listStyleType: 'numeric',
                        fontSize: '1.5rem'
                    }}
                >
                    under split specimen procedures, the donor must provide 45 ml in a specimen container. The collector
                    will pour 30 ml into one bottle and seal it, the remaining sample of 15 ml will be sealed in a
                    second bottle. Both bottles will be sent to the laboratory. The bottle with the 30 ml will be the
                    primary specimen and the second bottle will be held by the laboratory and analyzed only after a
                    verified positive by the MRO and the employee requests the analysis within 72 hours of notification
                    by the MRO.
                </li>
                <li
                    style={{
                        color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                        listStyleType: 'numeric',
                        fontSize: '1.5rem'
                    }}
                >
                    if the applicant/employee refuses to provide the specimen for drug testing, the situation will be
                    considered equal to a positive test and the same consequences will apply.
                </li>
            </ol>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                G.9.31 All positive urine screens will be confirmed through GC/MS testing (Gas Chromatography/Mass
                Spectrometry) before any discipline is imposed or hiring decisions are made.
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                G.9.32 A Medical Review Officer (MRO) will review all DOT regulated drug tests performed by the
                laboratory. The MRO is to determine whether positive test results indicate illegal drug use or whether
                other medical explanations could indicate illegal drug use or whether other medical explanations could
                account for the result. The MRO will inform the employee of the findings. On all "Positive" drug screen
                test results, the MRO will make two attempts on two consecutive days to first contact the
                applicant/employee and review the findings. If the applicant/employee cannot be reached during the above
                mentioned time frame, the company management will be contacted and informed to contact the
                applicant/employee and have such persons make him or herself available to be contacted by the MRO to
                review the findings. If the applicant/employee does not make him or herself available to be contacted by
                the MRO, the consequences to the applicant/employee will be equal to that of a positive test result,
                which is immediate discipline, up to and including termination.
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                G.9.33 All alcohol tests conducted under this Policy require that the employee provide a breath
                specimen. The employee must provide either a breath or blood specimen, as directed by a law enforcement
                officer after an accident. Alcohol tests will be administered using a breath specimen, taken by a breath
                alcohol technician (BAT) using an approved breath testing device (EBT) except in cases of on-scene
                post-accident testing conducted by federal, state, or local officials. Before being tested by the
                Company, each employee will be required to present their personal identification and execute a DOT
                "Breath Alcohol Test Form" provided by the BAT. An employee who refuses to provide his/her
                identification, provides a false identification, refuses to execute the DOT "Breath Alcohol Test Form",
                or who otherwise refuses or fails to cooperate will be treated as though he/she had tested positive and
                will be subject to disciplinary action, up to and including discharge, in addition to the penalties
                imposed by the Department of Transportation. Prior to each alcohol breath test conducted by Company, the
                BAT will instruct the employee on how the test will be performed.
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                G.9.34 To protect each driver, the BAT will attach to the testing device an individually-sealed
                mouthpiece in the employee's view. The driver will then be directed to blow forcefully into the breath
                testing device until an adequate amount of breath has been maintained. In the event that an employee is
                unable to provide an adequate amount of breath for the initial or confirmatory test after several
                attempts to do so, the employee will be required to submit to an evaluation by a licensed medical
                physician to determine whether a valid medical condition exists. If the physician determines that a
                valid medical condition does exist, the test result will be reported to the company as "negative". If
                the physician determines that a valid medical condition does not exist, the result will be reported to
                the Company as "confirmed positive."
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                G.9.35 In the event that the employee provides an adequate breath specimen and the initial test
                registers as alcohol concentration level that is less than 0.02, the test result will be recorded as a
                "negative", and no additional tests will be required at that time.
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                G.9.36 In the event that the employee provides an adequate breath specimen and the initial test
                registers as alcohol concentration level of 0.02 or greater, a second confirmatory test will be
                performed. In the event that the employee provides an adequate breath specimen and the confirmatory test
                registers less than 0.02, the test result will be reported to the company as "negative".
            </Typography>
            <Typography
                variant="h6"
                component="h6"
                textAlign="left"
                sx={{
                    color: mode === 'light' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    my: 2,
                    fontSize: '1.5rem'
                }}
            >
                G.9.37 If the employee is not terminated, they must be evaluated by a Substance-Abuse Professional (SAP)
                and follow the suggestions for treatment by the SAP. Prior to returning to work the employee must submit
                to a return-to- duty test and the results must be negative.
            </Typography>
        </Box>
    );
};

export default WrittenPolicy;

const definitions = [
    'Driving',
    'The time spent waiting to be dispatched, at the garage or other property, unless the driver has been relieved from duty;',
    'inspecting, servicing, or conditioning equipment;',
    'being in or on a commercial motor vehicle;',
    'loading or unloading, including supervising or assisting in loading or unloading, attending a vehicle being loaded or unloaded, remaining in readiness to operate the vehicle, giving or receiving receipts for scheduled route;',
    'securing the vehicle and taking all of the other precautionary measures required by DOT’s regulations following an accident (49 CFR 392.40 and 392.41)',
    'repairing, obtaining assistance, or attending a disabled vehicle'
];

const definitions2 = [
    'failing to provide an adequate urine specimen for a drug test without a valid medical explanation;',
    'failing to provide adequate breath for alcohol testing without a valid medical explanation;',
    'failing to submit to a test as directed;',
    'engaging in any conduct which clearly obstructs the testing process'
];

const definitions3 = [
    'employees are prohibited from using, being under the influence of, or possessing illegal drugs;',
    'employees are prohibited from using, or being under the influence of legal drugs that are being used illegally;',
    'employees are prohibited from using or being under the influence of legal drugs whose use can adversely affect the ability of the employee to perform his or her job safely;',
    'employees are prohibited from selling, buying, soliciting to buy or sell, transporting, or possessing illegal drugs while on Company time or property;',
    'employees are prohibited from being in the possession of alcohol on Company property at any time;',
    'employees are prohibited from using alcohol in any beverage, mixture or medication for a period starting 8 hours prior to the start of work to 15 minutes after the completion of work. Employees shall not have possession of any alcoholic beverages during this time unless operating a motorcoach and it is being transported on behalf of the Company in order to sell to the passengers;',
    'the consumption or possession of alcoholic beverages on the Company‘s premises is prohibited (Company sponsored activities which may include the serving of alcoholic beverages are not included in this provision). An employee whose normal faculties are impaired due to alcoholic beverages, or whose blood alcohol level tests .10% by weight for non-safety sensitive positions, or .04% for safety sensitive positions, while on duty/Company business shall be guilty of misconduct, and shall be subject to discipline up to and including termination of employment;',
    'any CDL holders and employees in safety sensitive positions with a breath alcohol content of 0.04 or higher during this time period, face immediate disciplinary action, up to and including termination of employment. CDL holders and employees in safety sensitive areas with a breath alcohol content of 0.02 to 0.04 shall be removed from their duties for a period of 24 hours. CDL holders required to take a post-accident test under the Federal Motor Carrier Safety Regulation 382.303 shall not be in possession of, or use alcohol until the test is complete, or for 8 hours following the accident, whichever comes first. All other employees found with a breath alcohol content of 0.08 during this time period shall face immediate disciplinary action, up to and including termination of employment. Any employee found with a breath alcohol content higher than described shall contact a family member to drive him or her home, or shall allow a supervisor to drive him or her home, if a family member is not available;',
    'employees are prohibited from testing positive for drugs and/or alcohol, refusing to be tested for drugs and/or alcohol, failing to submit to a drug and/or alcohol test as described by the Company, and/or failing to stay in contact with the Company and its Medical Review Officer while awaiting the results of a drug test;',
    'employees are prohibited from violating any applicable federal and/or state requirements governing the use of drugs and alcohol or doing anything to obstruct the Company‘s goals with respect to drug and alcohol testing;',
    'a CDL holder who violates these prohibitions will be subject to disciplinary action mandated by the DOT. In addition, any CDL holder who is convicted by the judicial system of a felony from a drug or alcohol-related matter will be subject to disciplinary action up to and including termination of employment. Any employee who violates these prohibitions will also be subject to disciplinary action by the Company, up to and including discharge.'
];

const definitions4 = [
    'the decision to seek diagnosis for the abuse problem is the responsibility of the employee;',
    'the diagnosis and prescribed treatment of the employee’s condition will be determined by health care professionals designated by the Company and the employee’s physician;',
    'the employee may be placed on medical leave for the predetermined period recommended by those professionals;',
    'should the employee fail to stay drug and/or alcohol free after the treatment program, the Company may elect to terminate employment.'
];

const definitions5 = [
    'pre-employment',
    'post-accident',
    'random',
    'reasonable suspicion',
    'return to duty',
    'follow-up'
];
