import UserRoleTable from './UserRoleTable';

const UserRoles = () => {
    return (
        <div className="row gy-5 g-xl-8">
            <div className="col-xl-12">
                <UserRoleTable />
            </div>
        </div>
    );
};

export default UserRoles;
