import { AdminPanelSettingsRounded, MoreVertRounded } from '@mui/icons-material';
import {
    Box,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Paper,
    TableBody,
    TableCell,
    TablePagination,
    TableRow
} from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { KTSVG } from '../../../compliancemasters/helpers';
import TableContainer from '../../../compliancemasters/partials/widgets/tables/components/TableContainer';
import TableContainerHeader from '../../../compliancemasters/partials/widgets/tables/components/TableContainerHeader';
import TableContainerHeaderTitle from '../../../compliancemasters/partials/widgets/tables/components/TableContainerHeaderTitle';
import TableHeader from '../../../compliancemasters/partials/widgets/tables/components/TableHeader';
import TableHeaderColumn from '../../../compliancemasters/partials/widgets/tables/components/TableHeaderColumn';
import TableWrapper from '../../../compliancemasters/partials/widgets/tables/components/TableWrapper';
import { IMaintenanceMember } from '../../../store/interfaces/PreventableMaintenance/maintenance.members';
import { useGetMaintenanceMembersByCompanyQuery } from '../../../store/reducers/PreventableMaintenance/maintenance.member';
import { useAuth } from '../../modules/auth';
import { AddMaintenanceMember } from './AddMaintenanceMember';
import { DeleteMaintenanceMemberModal } from './DeleteMaintenanceMember';

const MenuComponent = ({
    anchorEl,
    open,
    handleClose,
    updateModalOpen,
    setDeleteModalOpen
}: {
    anchorEl: HTMLElement | null;
    open: boolean;
    handleClose: () => void;
    updateModalOpen: (val: boolean) => void;
    setDeleteModalOpen: (val: boolean) => void;
}) => {
    return (
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            slotProps={{
                paper: {
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0
                        }
                    }
                }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            {/* <MenuItem
                onClick={() => {
                    updateModalOpen(true);
                    handleClose();
                }}
            >
                <ListItemIcon>
                    <AdminPanelSettingsRounded fontSize="medium" />
                </ListItemIcon>
                Update Maintenance Member
            </MenuItem> */}
            <MenuItem
                onClick={() => {
                    handleClose();
                    setDeleteModalOpen(true);
                }}
            >
                <ListItemIcon>
                    <AdminPanelSettingsRounded fontSize="medium" />
                </ListItemIcon>
                Delete Maintenance Member
            </MenuItem>
        </Menu>
    );
};

const MaintenanceMemberTable = () => {
    const { id } = useParams<{ id: string }>();
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedMember, setSelectedMember] = useState<IMaintenanceMember>();
    const menuOpen = Boolean(anchorEl);
    const { currentUser } = useAuth();
    const { data: members = [] } = useGetMaintenanceMembersByCompanyQuery(
        Number(currentUser?.company?.id) || Number(id),
        {
            refetchOnFocus: true,
            skip: !currentUser?.company?.id && !id
        }
    );

    const [page, setPage] = useState<number>(0);

    const [limit, setLimit] = useState<number>(5);

    const handlePageChange = (event: any, newPage: number): void => {
        setPage(newPage);
    };

    const handleLimitChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setLimit(parseInt(event.target.value));
    };
    return (
        <Paper component={Box} mt={4}>
            <TableWrapper>
                <TableContainerHeader>
                    <TableContainerHeaderTitle title="Maintenance Members" />
                    <div
                        className="btn btn-sm btn-light-primary d-inline-flex align-items-center"
                        onClick={() => setOpen(true)}
                    >
                        <KTSVG path="media/icons/duotune/arrows/arr075.svg" className="svg-icon-3" />
                        Add Member
                    </div>
                </TableContainerHeader>
                <TableContainer>
                    <TableHeader>
                        <TableHeaderColumn>#</TableHeaderColumn>
                        <TableHeaderColumn>First Name</TableHeaderColumn>
                        <TableHeaderColumn>Last Name</TableHeaderColumn>
                        <TableHeaderColumn>Email Address</TableHeaderColumn>
                        <TableHeaderColumn>Phone Number</TableHeaderColumn>
                        <TableHeaderColumn>Actions</TableHeaderColumn>
                    </TableHeader>
                    <TableBody>
                        {members.slice(page * limit, page * limit + limit).map((member, index) => (
                            <TableRow key={member.id}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{member?.user?.first_name}</TableCell>
                                <TableCell>{member?.user?.last_name}</TableCell>
                                <TableCell>{member?.user?.email}</TableCell>
                                <TableCell>
                                    {member?.user?.member?.phone_number ?? member?.phone_number ?? 'N/A'}
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setAnchorEl(e.currentTarget);
                                            setSelectedMember(member);
                                        }}
                                    >
                                        <MoreVertRounded />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </TableContainer>
                <Box p={2}>
                    <TablePagination
                        component="div"
                        count={members.length}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleLimitChange}
                        page={page}
                        rowsPerPage={limit}
                        rowsPerPageOptions={[5, 10, 25, 30]}
                    />
                </Box>
            </TableWrapper>
            <AddMaintenanceMember open={open} setOpen={setOpen} maintenanceMember={selectedMember} />
            <MenuComponent
                anchorEl={anchorEl}
                open={menuOpen}
                handleClose={() => setAnchorEl(null)}
                updateModalOpen={setOpen}
                setDeleteModalOpen={setDeleteModal}
            />
            <DeleteMaintenanceMemberModal
                open={deleteModal}
                setOpen={setDeleteModal}
                setMember={setSelectedMember}
                member={selectedMember}
            />
        </Paper>
    );
};

export default MaintenanceMemberTable;
