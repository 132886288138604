import {NavigateFunction} from 'react-router-dom';

export default function CompanyNavigation(navigate: NavigateFunction) {
  function navigateToCompanyMemberProfile(memberId: string | number, companyId?: string | number) {
    navigate(`/member/${memberId}/profile`, {
      state: {
        companyId,
      },
    });
  }

  return {
    navigateToCompanyMemberProfile,
  };
}
