import { Button, Typography } from '@mui/material';
import { useAuth } from '../../app/modules/auth';
import { IFormStatus, IMember } from '../../models';
import { StatusTag, useThemeMode } from '../partials';
import ProgressBar from '../partials/widgets/statistics/ProgressBar';
import { TabRow, links } from './Tabs';

interface IProps {
    headerTitle: string;
    sectionCount: number;
    itemCount: number;
    totalProgress?: number;
    memberProgress?: number;
    member: IMember | undefined;
    nextButton: {
        title: string;
        path: string;
    };
    value: number;
    setValue: (val: number) => void;
    status?: IFormStatus;
    nonCurrent?: boolean;
    drugFile?: boolean;
}

const FormHeaderCard = ({
    headerTitle,
    itemCount,
    member,
    memberProgress,
    nextButton,
    sectionCount,
    setValue,
    totalProgress,
    value,
    status,
    nonCurrent,
    drugFile
}: IProps) => {
    const { mode } = useThemeMode();
    const { currentUser } = useAuth();

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                    sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', mr: 5 }}
                    variant="h4"
                    gutterBottom
                    component="h4"
                >
                    {headerTitle}
                </Typography>
                <Typography sx={{ mb: 2 }} variant="h4" gutterBottom component="h4">
                    {status ? <StatusTag status={status} /> : null}
                </Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                    sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', mr: 5 }}
                    variant="h6"
                    gutterBottom
                    component="h6"
                >
                    {`${sectionCount} ${sectionCount > 1 ? 'Sections' : 'Section'}`}
                </Typography>
                <Typography
                    sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                    variant="h6"
                    gutterBottom
                    component="h6"
                >
                    {`${itemCount} ${itemCount > 1 ? 'Items' : 'Item'}`}
                </Typography>
            </div>
            {totalProgress !== undefined ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', mr: 5 }}
                        variant="h6"
                        gutterBottom
                        component="h6"
                    >
                        Total:
                    </Typography>
                    <ProgressBar
                        progress={totalProgress}
                        size={10}
                        backgroundColor="bg-secondary"
                        direction="right"
                        fontSize={7}
                    />
                </div>
            ) : null}
            {memberProgress !== undefined ? (
                <div style={{ display: 'flex', width: '100%' }}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', mr: 5 }}
                        variant="subtitle1"
                        gutterBottom
                        component="span"
                    >
                        {member ? member?.user?.first_name : 'Member: '}{' '}
                        {member ? ` ${member?.user?.last_name} :` : null}
                    </Typography>
                    <ProgressBar
                        progress={memberProgress}
                        size={10}
                        backgroundColor="bg-secondary"
                        direction="right"
                        fontSize={7}
                    />
                </div>
            ) : null}
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                        if (currentUser?.role === 'Member') {
                            window.location.assign(`/dashboard/overview`);
                        } else {
                            if (nonCurrent && member?.id) {
                                window.location.assign(`/member/${member?.id}/profile/non-current-documents`);
                            } else if (drugFile && member?.id) {
                                window.location.assign(`/member/${member?.id}/profile/drug-documents`);
                            } else if (member?.id) {
                                window.location.assign(`/member/${member?.id}/profile/driver-documents`);
                            }
                        }
                    }}
                >
                    Back to Forms Overview
                </Button>
                {nextButton.path.length > 0 && nextButton.title.length > 0 ? (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            if (member?.id) {
                                window.location.assign(`/member/${member?.id}/re-form/${nextButton.path}`);
                            }
                        }}
                    >
                        Next: {nextButton.title}
                    </Button>
                ) : null}
            </div>
            <TabRow current={value} links={links} setCurrent={setValue} />
        </>
    );
};

export default FormHeaderCard;
