import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { paymentSuccessLink } from '../../../shared/client/memberRequest';
import { Card, Placeholder } from 'react-bootstrap';

const MemberRequestPaymentSuccess = () => {
    const { id } = useParams<{ id: string }>();

    const [queryStarted, setQueryStarted] = useState(false);
    const [queryFinished, setQueryFinished] = useState(false);
    const [message, setMessage] = useState('');

    const paymentSuccessFn = useCallback(async () => {
        try {
            if (id) {
                const queryParams = new URLSearchParams(window.location.search);

                // Get the value of a specific parameter
                const paramValue = queryParams.get('isBulk');
                setQueryStarted(true);
                if (paramValue && paramValue === 'True') {
                    const investigation = await paymentSuccessLink(Number(id), true);
                    if (investigation) {
                        setMessage('Your payment was successful. You will be notified when your Request is complete.');
                        setQueryFinished(true);
                    } else {
                        setMessage('There was an error with your payment. Please try again.');
                        setQueryFinished(true);
                    }
                } else {
                    const investigation = await paymentSuccessLink(Number(id));
                    if (investigation) {
                        setMessage('Your payment was successful. You will be notified when your Request is complete.');
                        setQueryFinished(true);
                    } else {
                        setMessage('There was an error with your payment. Please try again.');
                        setQueryFinished(true);
                    }
                }
            }
        } catch (error) {
            setMessage('There was an error with your payment. Please try again.');
            setQueryFinished(true);
            console.log(error);
        }
    }, [id]);

    useEffect(() => {
        if (!queryStarted) {
            paymentSuccessFn();
        }
    }, [paymentSuccessFn, queryStarted]);

    return (
        <div
            className="container"
            style={{
                height: '70vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '80vw'
            }}
        >
            <Card
                style={{
                    width: '40vw',
                    height: '20%'
                }}
            >
                {queryFinished && (
                    <Card.Body>
                        <Card.Title
                            style={{
                                textAlign: 'center'
                            }}
                        >
                            Payment Status
                        </Card.Title>
                        <Card.Text
                            style={{
                                textAlign: 'center'
                            }}
                        >
                            {message}
                        </Card.Text>
                    </Card.Body>
                )}
                {queryStarted && !queryFinished ? (
                    <Card.Body>
                        <Placeholder as={Card.Text} animation="glow">
                            <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} /> <Placeholder xs={6} />{' '}
                            <Placeholder xs={8} />
                        </Placeholder>
                    </Card.Body>
                ) : null}
            </Card>
        </div>
    );
};

export default MemberRequestPaymentSuccess;
