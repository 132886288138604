import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, lazy, Suspense, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';
import { getCSSVariableValue } from '../../compliancemasters/assets/ts/_utils';
import { WithChildren } from '../../compliancemasters/helpers';
import { IPayloadProps } from '../../compliancemasters/layout/components/aside/AsideMenus/OwnerAsideMenu';
import { StripeActions } from '../../store/reducers/StripeProducts/StripeActions';
import Profile from '../modules/profile/CompanyMemberProfile';
import CompanyDashboard from '../pages/dashboard/CompanyDashboard';
import DrugAlcoholWrittenPolicy from '../pages/drug-alcohol-written-policy/DrugAlcoholWrittenPolicy';
import ExpiringFilesDashboard from '../pages/expiring-files/ExpiringFilesDashboard';
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper';
import LiveMVRMonitoring from '../pages/live-mvr-monitoring/LiveMVRMonitoring';
import MemberRequestPaymentFailed from '../pages/member-request-payments/MemberRequestPaymentFailed';
import MemberRequestPaymentSuccess from '../pages/member-request-payments/MemberRequestPaymentSuccess';
import { MenuTestPage } from '../pages/MenuTestPage';
import PreEmploymentPaymentFailed from '../pages/pre-employment-payments/PreEmploymentPaymentFailed';
import PreEmploymentPaymentSuccess from '../pages/pre-employment-payments/PreEmploymentPaymentSuccess';
import MaintenanceVehicleDetails from '../pages/preventableMaintenance/MaintenanceVehicleDetails/MaintenanceVehicleDetails';
import PreventableMaintenanceContainer from '../pages/preventableMaintenance/PreventableMaintenanceContainer';
import RandomTestingSelectionDetails from '../pages/random-testing-selections/RandomTestingSelectionDetails';
import RandomTestingSelections from '../pages/random-testing-selections/RandomTestingSelections';
import Subscriptions from '../pages/subscriptions/Subscriptions';
import DvirRoutes from './DvirRoutes';

export default function CompanyPrivateRoutes() {
    /*
    Go into the other private routes and delete any of these that SHOULD NOT be accessible
    We wouldn't want a company admin to be able to access the widgets page, profile page, etc.
    */

    // const { subscriptions } = useSelector(state => state.stripeReducer.subscription)
    const { pmProgramEnabled, pmProgramEnabledUrl } = useFlags<IPayloadProps>();

    const ProfilePage = lazy(() => import('../modules/profile/CompanyMemberProfile'));
    const ParentPaymentPage = lazy(() => import('../modules/subscription/ParentPaymentPage'));

    // const packageExists = (packageName: string) => {
    //     return subscriptions.some(pkg => pkg.name === packageName)
    // }

    useEffect(() => {
        StripeActions.getCompanySubscriptions();
    }, []);

    return (
        <Routes>
            {/* Pages */}
            <Route path="dashboard" element={<CompanyDashboard />} />
            <Route path="subscriptions" element={<Subscriptions />} />
            <Route path="expiring-files" element={<ExpiringFilesDashboard />} />
            <Route path="live-mvr-monitoring" element={<LiveMVRMonitoring />} />
            <Route path="drug-alcohol-written-policy" element={<DrugAlcoholWrittenPolicy />} />
            <Route path="pre-employment/payment-success/:id" element={<PreEmploymentPaymentSuccess />} />
            <Route path="pre-employment/payment-failed/:id" element={<PreEmploymentPaymentFailed />} />
            <Route path="memberrequests/payment-success/:id" element={<MemberRequestPaymentSuccess />} />
            <Route path="memberrequests/payment-failed/:id" element={<MemberRequestPaymentFailed />} />
            <Route path="builder" element={<BuilderPageWrapper />} />
            <Route path="menu-test" element={<MenuTestPage />} />
            <Route path="random-testing-selections" element={<RandomTestingSelections />} />
            <Route path="random-testing-selections/:id" element={<RandomTestingSelectionDetails />} />
            {pmProgramEnabled && pmProgramEnabledUrl.urls.includes(process.env.REACT_APP_PORTAL_URL!) ? (
                <>
                    <Route path="preventable-maintenance" element={<PreventableMaintenanceContainer />} />
                    <Route path="driver-inspection-report/*" element={<DvirRoutes />} />
                    <Route
                        path="maintenance-vehicle/:id/*"
                        element={
                            <SuspensedView>
                                <MaintenanceVehicleDetails />
                            </SuspensedView>
                        }
                    />
                </>
            ) : null}
            {/* Lazy Modules */}
            <Route
                path="crafted/pages/profile/*"
                element={
                    <SuspensedView>
                        <ProfilePage />
                    </SuspensedView>
                }
            />
            <Route
                path="subscription/*"
                element={
                    <SuspensedView>
                        <ParentPaymentPage />
                    </SuspensedView>
                }
            />
            <Route path="member/:memberId/profile/*" element={<Profile />} />
            {/* Page Not Found */}
            <Route path="*" element={<Navigate to="/error/404" />} />
        </Routes>
    );
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
    const baseColor = getCSSVariableValue('--kt-primary');
    TopBarProgress.config({
        barColors: {
            '0': baseColor
        },
        barThickness: 1,
        shadowBlur: 5
    });
    return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};
