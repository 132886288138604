import {IFile} from '../../../models';
import {IMaintenanceVehicle} from './maintenance.vehicles';

export enum NotesAndLinksType {
  NOTE = 'NOTE',
  LINK = 'LINK',
}

export interface INotesAndLinks {
  maintenance_vehicle: IMaintenanceVehicle;
  note?: string;
  link?: string;
  note_subject?: string;
  link_description?: string;
  type?: NotesAndLinksType;
  id?: number;
  created_at?: Date;
  time?: string;
  file_name?: string;
  original_file_name?: string;
  file_type?: string;
  file?: IFile;
  upload_finished_at?: Date;
}
