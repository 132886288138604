import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
  IOwnerDates,
  IOwnerDatesDto,
} from '../../interfaces/PreviousEmployeeSafetyPerformanceHistory/driver-identification';

export const ownerDatesApi = createApi({
  reducerPath: 'owner_dates',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/prev-employee-owner-dates`,
  }),
  tagTypes: ['OwnerDates'],
  keepUnusedDataFor: 60 * 10, // 10 minutes
  endpoints: (builder) => ({
    createOwnerDates: builder.mutation<IOwnerDates, IOwnerDatesDto>({
      query: (body) => ({
        url: '/',
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'OwnerDates', id: 'OwnerDates'}],
    }),
    updateOwnerDates: builder.mutation<IOwnerDates, IOwnerDates>({
      query: (body) => ({
        url: `/${body.id}/`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'OwnerDates', id: 'OwnerDates'}],
    }),
    deleteOwnerDates: builder.mutation<void, number>({
      query: (id) => ({
        url: `/${id}/`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
    }),
    getOwnerDates: builder.query<IOwnerDates, number | void>({
      query: (id) => ({
        url: '/get-owner-dates/',
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'OwnerDates', id: 'OwnerDates'}],
    }),
    getOwnerDatesByUser: builder.query<IOwnerDates, void>({
      query: () => ({
        url: '/get-owner-dates-by-user/',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'OwnerDates', id: 'OwnerDates'}],
    }),
    getOwnerDatesByUserId: builder.query<IOwnerDates, number | void>({
      query: (id) => ({
        url: '/get-owner-dates-by-user-id/',
        method: 'POST',
        body: {
          userId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'OwnerDates', id: 'OwnerDates'}],
    }),
  }),
});

export const {
  useCreateOwnerDatesMutation,
  useUpdateOwnerDatesMutation,
  useGetOwnerDatesQuery,
  useGetOwnerDatesByUserQuery,
  useGetOwnerDatesByUserIdQuery,
  useDeleteOwnerDatesMutation,
} = ownerDatesApi;
