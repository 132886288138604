import {ICompany, IUser} from '../../models';
import client from '../Client';

export interface ISalesRep {
  id: number;
  user: IUser;
  companies: ICompany[];
  terminated_at: string | null;
  state: string;
  zip_code: string;
  phone_number: string;
  street_address: string;
  city: string;
  created_at: string;
  updated_at: string;
  is_active: boolean;
  assigned_states: string[];
  is_compliance_master_account: boolean;
}

export interface ISalesRepDto {
  zip_code: string;
  phone_number: string;
  state: string;
  street_address: string;
  city: string;
  user: number;
  is_active: boolean;
  assigned_states: string[];
  is_compliance_master_account: boolean;
}

export const getSalesReps = () => {
  return client(`auth/api/sales-rep/`);
};

export const getSalesRep = (salesRepId: number) => {
  return client(`auth/api/sales-rep/${salesRepId}/`);
};

export const updateSalesRep = (salesRepId: number, body: Partial<ISalesRepDto>) => {
  return client(`auth/api/sales-rep/${salesRepId}/`, {
    body,
    method: 'PUT',
  });
};

export const updateSalesRepCompanies = (body: {id: number; companies: number[]}) => {
  return client(`auth/api/sales-rep/update-companies/`, {
    body,
    method: 'PUT',
  });
};

export const changeActivationStatus = (salesRepId: number, body: Partial<ISalesRep>) => {
  return client(`auth/api/sales-rep/change-activation/`, {
    body,
    method: 'POST',
  });
};

export const deleteSalesRep = (salesRepId: number, delete_type: string) => {
  return client(`auth/api/sales-rep/delete-sales-rep/`, {
    body: {id: salesRepId, delete_type},
    method: 'POST',
  });
};
