import { FC, lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';
import { getCSSVariableValue } from '../../compliancemasters/assets/ts/_utils';
import { WithChildren } from '../../compliancemasters/helpers';
import Profile from '../modules/profile/CompanyMemberProfile';
import AdminDashboard from '../pages/dashboard/AdminDashboard';
import CompanyDetails from '../pages/dashboard/CompanyDetails';
import DriverBulkUpload from '../pages/drivers-bulk-upload/DriverBulkUpload';
import ExpiringFilesDashboard from '../pages/expiring-files/ExpiringFilesDashboard';
import PreEmploymentRequestDashboard from '../pages/pre-employment-requests/PreEmploymentRequestDashboard';
import RandomTestingSelectionDetails from '../pages/random-testing-selections/RandomTestingSelectionDetails';
import RandomTestingSelections from '../pages/random-testing-selections/RandomTestingSelections';
import RequestedDemos from '../pages/requested-demos/RequestedDemos';
import MemberRequestDashboard from '../pages/requests/MemberRequestDashboard';
import TrainingContainer from '../pages/sales-rep-training/TrainingContainer';
import ComplianceMasterSalesRepDetails from '../pages/sales-reps/ComlianceMasterSalesRepDetail';
import SalesRepDetails from '../pages/sales-reps/SalesRepDetails';
import SalesRepsContainer from '../pages/sales-reps/SalesRepsContainer';
import UserRoles from '../pages/user-roles/UserRoles';

export default function AdminPrivateRoutes() {
    /*
        Go into the other private routes and delete any of these that are not being used, including these
    */
    const ParentPaymentPage = lazy(() => import('../modules/subscription/ParentPaymentPage'));

    return (
        <Routes>
            {/* Pages */}
            <Route path="dashboard" element={<AdminDashboard />} />
            <Route path="memberrequests" element={<MemberRequestDashboard />} />
            <Route path="demos" element={<RequestedDemos />} />
            <Route path="company/:id" element={<CompanyDetails />} />
            <Route path="expiring-files" element={<ExpiringFilesDashboard />} />
            <Route path="preemployment-requests" element={<PreEmploymentRequestDashboard />} />
            <Route path="add-drivers/:id" element={<DriverBulkUpload />} />
            <Route path="user-roles" element={<UserRoles />} />
            <Route path="sales-reps" element={<SalesRepsContainer />} />
            <Route path="sales-rep-training" element={<TrainingContainer />} />
            <Route path="sales-reps/:id" element={<SalesRepDetails />} />
            <Route path="compliance-sales-reps/:id" element={<ComplianceMasterSalesRepDetails />} />
            <Route path="random-testing-selections" element={<RandomTestingSelections />} />
            <Route path="random-testing-selections/:id" element={<RandomTestingSelectionDetails />} />
            {/* Lazy Modules */}
            <Route
                path="subscription/*"
                element={
                    <SuspensedView>
                        <ParentPaymentPage />
                    </SuspensedView>
                }
            />
            <Route path="member/:memberId/profile/*" element={<Profile />} />
            {/* Page Not Found */}
            <Route path="*" element={<Navigate to="/error/404" />} />
        </Routes>
    );
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
    const baseColor = getCSSVariableValue('--kt-primary');
    TopBarProgress.config({
        barColors: {
            '0': baseColor
        },
        barThickness: 1,
        shadowBlur: 5
    });
    return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};
