import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {IMaintenanceVehicle} from '../../interfaces/PreventableMaintenance/maintenance.vehicles';

export const maintenanceVehicleApi = createApi({
  reducerPath: 'pm_maintenance_vehicle',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/auth/api/preventable-maintenance/maintenance-vehicles`,
  }),
  tagTypes: ['MaintenanceVehicle'],
  keepUnusedDataFor: 60 * 10, // 10 minutes
  endpoints: (builder) => ({
    createMaintenanceVehicle: builder.mutation<IMaintenanceVehicle, Partial<IMaintenanceVehicle>>({
      query: (body) => ({
        url: '/',
        method: 'POST',
        body: {
          ...body,
          company: body?.company,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'MaintenanceVehicle', id: 'MaintenanceVehicle'}],
    }),
    updateMaintenanceVehicle: builder.mutation<IMaintenanceVehicle, Partial<IMaintenanceVehicle>>({
      query: (body) => ({
        url: `/${body.id}/`,
        method: 'PUT',
        body: {
          ...body,
          pm_program: body?.pm_program?.id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'MaintenanceVehicle', id: 'MaintenanceVehicle'}],
    }),
    deleteMaintenanceVehicle: builder.mutation<void, number>({
      query: (id) => ({
        url: `/${id}/`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'MaintenanceVehicle', id: 'MaintenanceVehicle'}],
    }),
    getMaintenanceVehicle: builder.query<IMaintenanceVehicle, number | void>({
      query: (id) => ({
        url: `/${id}/`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'MaintenanceVehicle', id: 'MaintenanceVehicle'}],
    }),
    getMaintenanceVehicles: builder.query<IMaintenanceVehicle[], void>({
      query: () => ({
        url: '/',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'MaintenanceVehicle', id: 'MaintenanceVehicle'}],
    }),
    getMaintenanceVehiclesByCompany: builder.query<IMaintenanceVehicle[], number | void>({
      query: (companyId) => ({
        url: `/get-by-company?company_id=${companyId}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'MaintenanceVehicle', id: 'MaintenanceVehicle'}],
    }),
  }),
});

export const {
  useCreateMaintenanceVehicleMutation,
  useUpdateMaintenanceVehicleMutation,
  useDeleteMaintenanceVehicleMutation,
  useGetMaintenanceVehicleQuery,
  useGetMaintenanceVehiclesQuery,
  useGetMaintenanceVehiclesByCompanyQuery,
} = maintenanceVehicleApi;
