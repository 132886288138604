import { useEffect, useState } from 'react';
import ProgressBar from '../../../../compliancemasters/partials/widgets/statistics/ProgressBar';
import { StatusTag } from '../../../../compliancemasters/partials';
import { Link } from 'react-router-dom';
import { KTSVG } from '../../../../compliancemasters/helpers';
import {
    useGetEmploymentHistoryProgressMutation,
    useGetMemberEmploymentHistoryProgressMutation
} from '../../../../store/reducers/EmploymentHistory/progress';
import { useAuth } from '../../auth';

const EmploymentHistory = ({ id }: { id: number }) => {
    const { currentUser } = useAuth();
    const [getProgress, { data }] = useGetEmploymentHistoryProgressMutation();
    const [getMemberProgress, { data: pg }] = useGetMemberEmploymentHistoryProgressMutation();
    const [progress, setProgress] = useState<number>(0);

    useEffect(() => {
        getProgress(id);
        getMemberProgress(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (currentUser && ['Admin', 'Owner', 'SuperAdmin'].includes(currentUser?.role) && data) {
            setProgress(data?.progress);
        } else if (pg) {
            setProgress(pg?.progress);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, pg]);

    return (
        <tr>
            <td className="mw-125px">
                <span
                    className="text-dark fw-bold text-hover-primary mb-1 fs-6 cursor-pointer"
                    onClick={() => window.location.assign(`/member/${id}/re-form/employment-history`)}
                >
                    Employment History
                </span>
            </td>
            <td>
                <div className="d-flex flex-column w-75 me-2">
                    <ProgressBar progress={progress ? progress : 0} size={6} />
                </div>
            </td>
            <td>
                <StatusTag
                    status={
                        progress && progress > 0 && progress < 100
                            ? 'In Progress'
                            : progress && progress > 99
                            ? 'Completed'
                            : 'Not Started'
                    }
                />
            </td>
            <td className="mw-125px">
                <span className="text-success fw-bold cursor-pointer text-hover-primary mb-1 fs-6"></span>
            </td>
            <td className="text-end">
                <Link
                    className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                    to={`/member/${id}/re-form/employment-history`}
                    onClick={(e) => {
                        e.preventDefault();
                        window.location.assign(`/member/${id}/re-form/employment-history`);
                    }}
                >
                    <KTSVG path="/media/icons/duotune/arrows/arr064.svg" className="svg-icon-2" />
                </Link>
            </td>
        </tr>
    );
};

export default EmploymentHistory;
