import {annualReviewOfDrivingRecordApi} from './annual-review-of-driving-record';
import {annualReviewOfDrivingRecordProgress} from './progress';

export const AnnualReviewOfDrivingRecordReducers = {
  [annualReviewOfDrivingRecordApi.reducerPath]: annualReviewOfDrivingRecordApi.reducer,
  [annualReviewOfDrivingRecordProgress.reducerPath]: annualReviewOfDrivingRecordProgress.reducer,
};

export const AnnualReviewOfDrivingRecordMiddleware = [
  annualReviewOfDrivingRecordApi.middleware,
  annualReviewOfDrivingRecordProgress.middleware,
];
