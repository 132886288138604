import { Link } from 'react-router-dom';
import { KTSVG } from '../../../../compliancemasters/helpers';
import { useGetCertificateOfRoadTestProgressMutation } from '../../../../store/reducers/CertificateOfRoadTest/progress';
import { IMember } from '../../../../models';
import { useAuth } from '../../auth';
import { getCompanyMember } from '../../../../shared';
import { useState, useEffect } from 'react';

const CertificateOfRoadTest = ({ id }: { id: number }) => {
    // const [progress, setProgress] = useState<number>(0)
    // const [getRecordProgress, { data: recordProgress }] = useGetRecordOfRoadTestProgressMutation()
    // const [getMemberProgress, { data: pg }] = useGetMemberCertificateOfRoadTestProgressMutation()
    const [getProgress, { data }] = useGetCertificateOfRoadTestProgressMutation();
    const [member, setMember] = useState<IMember>();
    const { currentUser } = useAuth();

    useEffect(() => {
        // getRecordProgress(id)
        getProgress(id);
        // getMemberProgress(id)
        getCompanyMember(String(id)).then((res) => {
            setMember(res);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    // useEffect(() => {
    //     if (currentUser && ["Admin", "Owner", "SuperAdmin"].includes(currentUser?.role) && data) {
    //         setProgress(data?.progress)
    //     } else if (pg) {
    //         setProgress(pg?.progress)
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [data, pg])

    return (
        <tr>
            <td className="mw-125px">
                <span
                    className="text-dark fw-bold text-hover-primary mb-1 fs-6 cursor-pointer"
                    onClick={() => window.location.assign(`/member/${id}/re-form/certificate-of-road-test`)}
                >
                    Certificate of Road Test
                </span>
            </td>
            <td>
                <div className="d-flex flex-column w-75 me-2">
                    {/* <ProgressBar progress={progress ? progress : 0} size={6} /> */}
                </div>
            </td>
            <td>
                {/* {recordProgress && recordProgress?.progress > 0 && progress > 98 ? <StatusTag status={progress && (progress > 0 && progress < 100) ? "In Progress" : progress && progress > 99 ? "Completed" : "Not Started"} /> : null} */}
            </td>
            <td className="mw-125px">
                {((member && member?.pre_employment_screened) || currentUser?.member) &&
                data &&
                data?.progress < 99 &&
                currentUser?.role !== 'Member' ? (
                    <span className="text-success fw-bold cursor-pointer text-hover-primary mb-1 fs-6">
                        Owner Signature Required
                    </span>
                ) : null}
            </td>
            <td className="text-end">
                <Link
                    className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                    to={`/member/${id}/re-form/certificate-of-road-test`}
                >
                    <KTSVG path="/media/icons/duotune/arrows/arr064.svg" className="svg-icon-2" />
                </Link>
            </td>
        </tr>
    );
};

export default CertificateOfRoadTest;
