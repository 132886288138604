import Button from '../../../../app/modules/buttons/Button';
import { StripeProduct } from '../../../../app/modules/models';
import { IMember } from '../../../../models';
import RequestModal from './RequestModal';

interface IProps {
    member: IMember;
    memberSubscription?: StripeProduct;
}

const RequestButton = ({ member, memberSubscription }: IProps) => {
    return (
        <>
            <RequestModal member={member} memberSubscription={memberSubscription} />
            <Button
                className={'btn btn-sm btn-light mx-1'}
                dataBsToggle="modal"
                dataBsTarget="#request_modal"
                disabled={!!member?.terminated_at}
            >
                Make Request
            </Button>
        </>
    );
};

export default RequestButton;
