import {ICompany, IMember} from '../../../models';
import {IAbstract} from '../abstract';

export enum InspectionTextChoices {
  SATISFACTORY = 'Satisfactory',
  UNSATISFACTORY = 'Unsatisfactory',
  NA = 'N/A',
}

export interface IBackingAndParkingDto {
  gets_out_and_checks_before_backing: InspectionTextChoices;
  looks_back_as_well_as_uses_mirrors: InspectionTextChoices;
  gets_out_and_rechecks_conditions_on_long_back: InspectionTextChoices;
  avoids_backing_from_blind_side: InspectionTextChoices;
  signals_when_backing: InspectionTextChoices;
  controls_speed_and_direction_properly_while_backing: InspectionTextChoices;
  does_not_hit_nearby_vehicles_or_stationary_objects: InspectionTextChoices;
  parks_proper_distance_from_curb: InspectionTextChoices;
  sets_parking_brake_puts_in_gear_chocks_wheel_shuts_off_motor: InspectionTextChoices;
  checks_traffic_conditions_and_signals: InspectionTextChoices;
  parks_in_legal_and_safe_location: InspectionTextChoices;
  parks_off_pavement: InspectionTextChoices;
  avoids_parking_on_soft_shoulders: InspectionTextChoices;
  uses_emergency_warning_signals_when_required: InspectionTextChoices;
  secures_unit_properly: InspectionTextChoices;
  remarks: string;
  company: ICompany;
  member: IMember;
}

export interface IBackingAndParking extends IBackingAndParkingDto, IAbstract {}
