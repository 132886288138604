import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import clsx from 'clsx';
import * as Yup from 'yup';
import { PasswordMeterComponent } from '../../../../compliancemasters/assets/ts/components';
import { useAuth } from '../core/Auth';
import { toast } from 'react-toastify';
import { SalesRepTermsModal } from './SalesRepTermsModal';
import { useEffect, useState } from 'react';

interface FormValues {
    firstname: string;
    lastname: string;
    email: string;
    password: string;
    changepassword: string;
    acceptTerms: boolean;
    street_address: string;
    city: string;
    zip_code: string;
    role: string;
    state: string;
    phone_number: string;
}

const initialValues: FormValues = {
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    changepassword: '',
    acceptTerms: false,
    role: 'SalesRep',
    state: ``,
    zip_code: '',
    city: '',
    street_address: '',
    phone_number: ''
};

const validationSchema = Yup.object({
    firstname: Yup.string().required('First name is required'),
    lastname: Yup.string().required('Last name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string().required('Password is required').min(6, 'Password must be at least 6 characters long'),
    changepassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm password is required'),
    acceptTerms: Yup.boolean().oneOf([true], 'You must accept the terms and conditions'),
    role: Yup.string().required('Role is required'),
    state: Yup.string().required('State is required'),
    zip_code: Yup.string().required('Social security number is required'),
    phone_number: Yup.string().required('Phone number is required'),
    street_address: Yup.string().required('Street address is required'),
    city: Yup.string().required('City is required')
});

export const SalesRepRegistration = () => {
    const { saveAuth, register } = useAuth();
    const [open, setOpen] = useState(false);
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            function errorHandler(error: any) {
                saveAuth(undefined);
                setStatus('The registration details is incorrect');
                completedRequest();
            }

            function completedRequest() {
                setSubmitting(false);
            }

            register({
                email: values.email,
                firstname: values.firstname,
                lastname: values.lastname,
                password: values.password,
                passwordConfirmation: values.changepassword,
                state: values.state,
                zip_code: values.zip_code,
                phone_number: values.phone_number,
                role: values.role as 'SalesRep',
                city: values.city,
                street_address: values.street_address
            })
                .then(() => {
                    toast.success('Registration successful, Please wait for an admin to approve your account.');
                    setTimeout(() => {
                        window.location.assign('/auth/login');
                    }, 3000);
                })
                .catch((error) => errorHandler(error));
        },
        validateOnBlur: true,
        validateOnChange: true
    });

    useEffect(() => {
        PasswordMeterComponent.bootstrap();
    }, []);

    return (
        <form
            onSubmit={formik.handleSubmit}
            className="form w-100 p-5 fv-plugins-bootstrap5 fv-plugins-framework"
            noValidate
            id="kt_login_signup_form"
        >
            <div className="mb-10 text-center">
                {/* begin::Title */}
                <h1 className="text-dark mb-3">Create a Sales Rep Account</h1>
                {/* end::Title */}

                {/* begin::Link */}
                {window.location.href.includes('company') ? null : (
                    <div className="text-gray-400 fw-bold fs-4">
                        Already have an account?
                        <Link to="/auth/login" className="link-primary fw-bolder" style={{ marginLeft: '5px' }}>
                            Sign in here
                        </Link>
                    </div>
                )}
                {/* end::Link */}
            </div>
            {/* end::Heading */}

            {formik.status && (
                <div className="mb-lg-15 alert alert-danger">
                    <div className="alert-text font-weight-bold">{formik.status}</div>
                </div>
            )}
            <div className="form-floating mb-3">
                <input
                    type="text"
                    className={`form-control ${
                        formik.touched.firstname && formik.errors.firstname ? 'is-invalid' : ''
                    }`}
                    id="firstname"
                    placeholder="First name"
                    {...formik.getFieldProps('firstname')}
                />
                <label htmlFor="firstname">First name</label>
                <div className="invalid-feedback">{formik.errors.firstname}</div>
            </div>
            <div className="form-floating mb-3">
                <input
                    type="text"
                    className={`form-control ${formik.touched.lastname && formik.errors.lastname ? 'is-invalid' : ''}`}
                    id="lastname"
                    placeholder="Last name"
                    {...formik.getFieldProps('lastname')}
                />
                <label htmlFor="lastname">Last name</label>
                <div className="invalid-feedback">{formik.errors.lastname}</div>
            </div>
            <div className="form-floating mb-3">
                <input
                    type="email"
                    className={`form-control ${formik.touched.email && formik.errors.email ? 'is-invalid' : ''}`}
                    id="email"
                    placeholder="
                                        Email"
                    {...formik.getFieldProps('email')}
                />
                <label htmlFor="email">Email</label>
                <div className="invalid-feedback">{formik.errors.email}</div>
            </div>
            <div className="mb-10 fv-row" data-kt-password-meter="true">
                <div className="mb-1">
                    <label className="form-label fw-bolder text-dark fs-6">Password</label>
                    <div className="position-relative mb-3">
                        <input
                            type="password"
                            placeholder="Password"
                            autoComplete="off"
                            {...formik.getFieldProps('password')}
                            className={clsx(
                                'form-control form-control-lg form-control-solid',
                                {
                                    'is-invalid': formik.touched.password && formik.errors.password
                                },
                                {
                                    'is-valid': formik.touched.password && !formik.errors.password
                                }
                            )}
                        />
                        {formik.touched.password && formik.errors.password && (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                    <span role="alert">{formik.errors.password}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* begin::Meter */}
                    <div className="d-flex align-items-center mb-3" data-kt-password-meter-control="highlight">
                        <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                        <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                        <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                        <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
                    </div>
                    {/* end::Meter */}
                </div>
                <div className="text-muted">Use 8 or more characters with a mix of letters, numbers & symbols.</div>
            </div>
            <div className="form-floating mb-3">
                <input
                    type="password"
                    className={`form-control ${
                        formik.touched.changepassword && formik.errors.changepassword ? 'is-invalid' : ''
                    }`}
                    id="changepassword"
                    placeholder="Confirm password"
                    {...formik.getFieldProps('changepassword')}
                />
                <label htmlFor="changepassword">Confirm password</label>
                <div className="invalid-feedback">{formik.errors.changepassword}</div>
            </div>

            <div className="form-floating mb-3">
                <input
                    type="text"
                    className={`form-control ${
                        formik.touched.street_address && formik.errors.street_address ? 'is-invalid' : ''
                    }`}
                    id="street_address"
                    placeholder="Street Address"
                    {...formik.getFieldProps('street_address')}
                />
                <label htmlFor="street_address">Street Address</label>
                <div className="invalid-feedback">{formik.errors.street_address}</div>
            </div>
            <div className="form-floating mb-3">
                <input
                    type="text"
                    className={`form-control ${formik.touched.city && formik.errors.city ? 'is-invalid' : ''}`}
                    id="city"
                    placeholder="City"
                    {...formik.getFieldProps('city')}
                />
                <label htmlFor="city">City</label>
                <div className="invalid-feedback">{formik.errors.city}</div>
            </div>
            <div className="form-floating mb-3">
                <input
                    type="text"
                    className={`form-control ${formik.touched.state && formik.errors.state ? 'is-invalid' : ''}`}
                    id="state"
                    placeholder="State"
                    {...formik.getFieldProps('state')}
                />
                <label htmlFor="state">State</label>
                <div className="invalid-feedback">{formik.errors.state}</div>
            </div>
            <div className="form-floating mb-3">
                <input
                    type="text"
                    className={`form-control ${formik.touched.zip_code && formik.errors.zip_code ? 'is-invalid' : ''}`}
                    id="zip_code"
                    placeholder="Zip Code"
                    {...formik.getFieldProps('zip_code')}
                />
                <label htmlFor="zip_code">Zip Code</label>
                <div className="invalid-feedback">{formik.errors.zip_code}</div>
            </div>
            <div className="form-floating mb-3">
                <input
                    type="text"
                    className={`form-control ${
                        formik.touched.phone_number && formik.errors.phone_number ? 'is-invalid' : ''
                    }`}
                    id="phone_number"
                    placeholder="Phone number"
                    {...formik.getFieldProps('phone_number')}
                />
                <label htmlFor="phone_number">Phone number</label>
                <div className="invalid-feedback">{formik.errors.phone_number}</div>
            </div>
            <div className="fv-row mb-10">
                <div className="form-check form-check-custom form-check-solid">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id="kt_login_toc_agree"
                        {...formik.getFieldProps('acceptTerms')}
                    />
                    <label className="form-check-label fw-bold text-gray-700 fs-6" htmlFor="kt_login_toc_agree">
                        I Agree the{' '}
                        <Link
                            to="/auth/terms"
                            onClick={(e) => {
                                e.preventDefault();
                                setOpen(true);
                            }}
                            className="ms-1 link-primary"
                        >
                            terms and conditions
                        </Link>
                        .
                    </label>
                    {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                <span role="alert">{formik.errors.acceptTerms}</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="d-grid">
                <button
                    type="submit"
                    disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
                    className="btn btn-primary btn-login text-uppercase fw-bold"
                >
                    Register
                </button>
            </div>
            <SalesRepTermsModal open={open} setOpen={setOpen} />
        </form>
    );
};
