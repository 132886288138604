import { useEffect } from 'react';
import {
    IDriversAccidentHistory,
    IDriversAccidentHistoryDto
} from '../../../store/interfaces/PreviousEmployeeSafetyPerformanceHistory/previous-employer-information';
import { IMember } from '../../../models';
import { Button, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { useThemeMode } from '../../partials';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
    useCreateDriversAccidentHistoryMutation,
    useUpdateDriversAccidentHistoryMutation
} from '../../../store/reducers/EmploymentHistory/employer-information';
import { useAuth } from '../../../app/modules/auth';

interface IProps {
    member?: IMember;
    id?: number;
    has_accident_history?: IDriversAccidentHistory;
}

const initialValues: IDriversAccidentHistoryDto = {
    employer_information: undefined,
    has_accident_record: false
};

const validationSchema = Yup.object({});

const HasAccidentHistory = ({ has_accident_history, id, member }: IProps) => {
    const { mode } = useThemeMode();
    const { currentUser } = useAuth();

    const [createDriverAccidentHistory, { isLoading: isCreating }] = useCreateDriversAccidentHistoryMutation();
    const [updateDriverAccidentHistory] = useUpdateDriversAccidentHistoryMutation();

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            if (has_accident_history) {
                updateDriverAccidentHistory({
                    ...has_accident_history,
                    ...values
                }).then(() => {
                    // window.location.reload()
                });
            } else {
                createDriverAccidentHistory(values).then(() => {
                    // window.location.reload()
                });
            }
        }
    });

    useEffect(() => {
        if (has_accident_history) {
            formik.setValues(has_accident_history);
        } else if (id) {
            formik.setFieldValue('employer_information', id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [has_accident_history, id]);

    return (
        <div
            style={{
                padding: 10,
                margin: 10
            }}
        >
            <Typography
                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: 'center' }}
                variant="h5"
                gutterBottom
                component="h4"
            >
                Driver Accident History
            </Typography>
            <Grid container spacing={3}>
                <Grid item sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Is there an accident record to register for this position?
                    </Typography>
                    <TextField
                        fullWidth
                        id="applicant_employed"
                        size="small"
                        variant="outlined"
                        value={formik.values.has_accident_record ? 'Yes' : 'No'}
                        onChange={(e) => {
                            formik.setFieldValue('has_accident_record', e.target.value === 'Yes');
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.has_accident_record && Boolean(formik.errors.has_accident_record)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.has_accident_record && Boolean(formik.errors.has_accident_record)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.has_accident_record && Boolean(formik.errors.has_accident_record)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={!!member?.terminated_at}
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        select
                    >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                    </TextField>
                </Grid>

                {!has_accident_history ||
                (has_accident_history && currentUser && ['Admin', 'SuperAdmin'].includes(currentUser?.role)) ? (
                    <Grid item xs={12} sm={12}>
                        <Button
                            fullWidth
                            disabled={isCreating || !!member?.terminated_at}
                            onClick={() => formik.handleSubmit()}
                            variant="contained"
                            color="primary"
                        >
                            Save
                        </Button>
                    </Grid>
                ) : null}
            </Grid>
        </div>
    );
};

export default HasAccidentHistory;
