import {previousPreEmploymentDrugDriversSignatureApi} from './previous-pre-employment-drug-driver-signature';
import {previousPreEmploymentDrugWitnessSignatureApi} from './previous-pre-employment-drug-witness-singature';
import {prevPreEmploymentDrugAndAlcoholTestStatementApi} from './previous-pre-employment-test-statement';
import {prevPreEmploymentDachTestStatementProgress} from './progress';

export const PreviousPreEmploymentDrugAndAlcoholTestStatementReducers = {
  [previousPreEmploymentDrugDriversSignatureApi.reducerPath]:
    previousPreEmploymentDrugDriversSignatureApi.reducer,
  [previousPreEmploymentDrugWitnessSignatureApi.reducerPath]:
    previousPreEmploymentDrugWitnessSignatureApi.reducer,
  [prevPreEmploymentDrugAndAlcoholTestStatementApi.reducerPath]:
    prevPreEmploymentDrugAndAlcoholTestStatementApi.reducer,
  [prevPreEmploymentDachTestStatementProgress.reducerPath]:
    prevPreEmploymentDachTestStatementProgress.reducer,
};

export const PreviousPreEmploymentDrugAndAlcoholTestStatementMiddleware = [
  previousPreEmploymentDrugDriversSignatureApi.middleware,
  previousPreEmploymentDrugWitnessSignatureApi.middleware,
  prevPreEmploymentDrugAndAlcoholTestStatementApi.middleware,
  prevPreEmploymentDachTestStatementProgress.middleware,
];
