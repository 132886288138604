import { useCallback, useEffect, useState } from 'react';
import TableWrapper from '../../../compliancemasters/partials/widgets/tables/components/TableWrapper';
import TableContainer from '../../../compliancemasters/partials/widgets/tables/components/TableContainer';
import TableContainerHeader from '../../../compliancemasters/partials/widgets/tables/components/TableContainerHeader';
import TableContainerHeaderTitle from '../../../compliancemasters/partials/widgets/tables/components/TableContainerHeaderTitle';
import TableHeader from '../../../compliancemasters/partials/widgets/tables/components/TableHeader';
import TableHeaderColumn from '../../../compliancemasters/partials/widgets/tables/components/TableHeaderColumn';
import { IRole, deleteRole, getRoles } from '../../../shared/client/roles';
import { toast } from 'react-toastify';
import NewRoleButton from './NewRoleButton';
import AddRoleModal from './AddRoleModal';

const UserRoleTable = () => {
    const [roles, setRoles] = useState<IRole[]>([]);
    const [role, setRole] = useState<IRole>();
    const [open, setOpen] = useState<boolean>(false);

    const getUserRoles = useCallback(() => {
        getRoles()
            .then((res: IRole[]) => {
                setRoles(res.sort((a, b) => a.id - b.id));
            })
            .catch((err) => {
                toast.error(err.message);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    useEffect(() => {
        getUserRoles();
    }, [getUserRoles]);

    return (
        <>
            <TableWrapper>
                <TableContainerHeader>
                    <TableContainerHeaderTitle title="User Roles" />
                    <NewRoleButton setOpen={setOpen} />
                </TableContainerHeader>
                <TableContainer>
                    <TableHeader>
                        <TableHeaderColumn center>Role</TableHeaderColumn>
                        <TableHeaderColumn center>{''}</TableHeaderColumn>
                        <TableHeaderColumn center>{''}</TableHeaderColumn>
                    </TableHeader>
                    <tbody>
                        {roles.map((role) => (
                            <tr key={role.id}>
                                <td className="text-center">{role.name}</td>
                                <td className="text-center">
                                    <button
                                        className="btn btn-primary btn-sm"
                                        onClick={() => {
                                            if (['SuperAdmin', 'Admin'].includes(role.name)) {
                                                toast.error(`You cannot edit the role ${role.name}!`);
                                                return;
                                            }
                                            setRole(role);
                                            setOpen(true);
                                        }}
                                        style={{
                                            backgroundColor: '#1e1e2d',
                                            color: '#fff'
                                        }}
                                    >
                                        Edit
                                    </button>
                                </td>
                                <td className="text-center">
                                    <button
                                        className="btn btn-sm"
                                        style={{
                                            backgroundColor: '#1e1e2d',
                                            color: '#fff'
                                        }}
                                        onClick={() => {
                                            if (['SuperAdmin', 'Admin'].includes(role.name)) {
                                                toast.error(`You cannot delete the role ${role.name}!`);
                                                return;
                                            }
                                            if (window.confirm('Are you sure you wish to delete this role?')) {
                                                deleteRole(role.id).then(() => {
                                                    toast.success('Role deleted successfully');
                                                    getUserRoles();
                                                });
                                            }
                                        }}
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </TableContainer>
            </TableWrapper>
            <AddRoleModal role={role} setOpen={setOpen} open={open} setRole={setRole} />
        </>
    );
};

export default UserRoleTable;
