import { Button, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { useAuth } from '../../../app/modules/auth';
import { IMember } from '../../../models';
import {
    AlcoholChoices,
    ControlledSubstanceTextChoices,
    IViolationAndActualKnowledgeDto
} from '../../../store/interfaces/DocumentationofActualKnowledgeofDOTDAndAViolation/violation-of-actual-knowledge';
import {
    useCreateViolationAndActualKnowledgeMutation,
    useDeleteViolationAndActualKnowledgeMutation,
    useGetViolationAndActualKnowledgeByUserIdQuery,
    useUpdateViolationAndActualKnowledgeMutation
} from '../../../store/reducers/DocumentationofActualKnowledgeofDOTDAndAViolation/violation-and-actual-knowledge';
import { useThemeMode } from '../../partials';

const initialValues: Partial<IViolationAndActualKnowledgeDto> = {
    alcohol_use_within_4_hours_of_duty: '',
    alcohol_use_prior_to_post_accident_test: '',
    alcohol_use_while_on_duty: '',
    controlled_substances_use: '',
    alcohol: AlcoholChoices.DIRECT_OBSERVATION_OF_THE_VIOLATION,
    controlled_substances: ControlledSubstanceTextChoices.DIRECT_OBSERVATION_OF_THE_VIOLATION,
    date_knowledge_obtained: '',
    date_submitted_to_clearinghouse: '',
    description_of_the_violation: '',
    attached_documentation: ''
};

const validationSchema = Yup.object({
    alcohol_use_within_4_hours_of_duty: Yup.string().required('Required'),
    alcohol_use_prior_to_post_accident_test: Yup.string().required('Required'),
    alcohol_use_while_on_duty: Yup.string().required('Required'),
    controlled_substances_use: Yup.string().required('Required'),
    alcohol: Yup.string().required('Required'),
    controlled_substances: Yup.string().required('Required'),
    date_knowledge_obtained: Yup.string().required('Required'),
    date_submitted_to_clearinghouse: Yup.string().required('Required'),
    description_of_the_violation: Yup.string().required('Required'),
    attached_documentation: Yup.string().required('Required')
});

interface IProps {
    member?: IMember;
    isDriverFile?: boolean;
}

const ViolationAndActualKnowledge = ({ member, isDriverFile }: IProps) => {
    const { mode } = useThemeMode();
    const { currentUser } = useAuth();
    const { data } = useGetViolationAndActualKnowledgeByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true
    });

    const [createViolationAndActualKnowledge, { isLoading: isCreating }] =
        useCreateViolationAndActualKnowledgeMutation();
    const [updateViolationAndActualKnowledge, { isLoading: isUpdating }] =
        useUpdateViolationAndActualKnowledgeMutation();
    const [deleteViolationAndActualKnowledge, { isLoading: isDeleting }] =
        useDeleteViolationAndActualKnowledgeMutation();

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (data) {
                updateViolationAndActualKnowledge({
                    ...data,
                    ...values
                });
            } else {
                createViolationAndActualKnowledge({
                    ...values,
                    // @ts-ignore
                    company: member?.company?.id,
                    // @ts-ignore
                    member: member?.id
                });
            }
            resetForm();
        },
        validateOnBlur: true,
        validateOnChange: true,
        validate(values) {
            const errors: Partial<IViolationAndActualKnowledgeDto> = {};
            if (values.alcohol === AlcoholChoices.DIRECT_OBSERVATION_OF_THE_VIOLATION) {
                if (!values.alcohol_use_within_4_hours_of_duty) {
                    errors.alcohol_use_within_4_hours_of_duty = 'Required';
                }
                if (!values.alcohol_use_prior_to_post_accident_test) {
                    errors.alcohol_use_prior_to_post_accident_test = 'Required';
                }
                if (!values.alcohol_use_while_on_duty) {
                    errors.alcohol_use_while_on_duty = 'Required';
                }
            }
            if (values.controlled_substances === ControlledSubstanceTextChoices.DIRECT_OBSERVATION_OF_THE_VIOLATION) {
                if (!values.controlled_substances_use) {
                    errors.controlled_substances_use = 'Required';
                }
            }
            return errors;
        }
    });

    useEffect(() => {
        if (data) {
            formik.setValues(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <div
            style={{
                padding: 10,
                margin: 10
            }}
        >
            <Typography
                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: 'center' }}
                variant="h4"
                gutterBottom
                component="h4"
            >
                Violation and Actual Knowledge
            </Typography>
            <Typography
                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                variant="body1"
                gutterBottom
                component="p"
            >
                Violations
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Alcohol use within 4 hours of duty
                    </Typography>
                    <TextField
                        fullWidth
                        id="alcohol_use_within_4_hours_of_duty"
                        select
                        size="small"
                        variant="outlined"
                        value={formik.values.alcohol_use_within_4_hours_of_duty}
                        onChange={(e) => {
                            formik.setFieldValue('alcohol_use_within_4_hours_of_duty', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.alcohol_use_within_4_hours_of_duty &&
                                        Boolean(formik.errors.alcohol_use_within_4_hours_of_duty)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.alcohol_use_within_4_hours_of_duty &&
                                        Boolean(formik.errors.alcohol_use_within_4_hours_of_duty)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.alcohol_use_within_4_hours_of_duty &&
                                        Boolean(formik.errors.alcohol_use_within_4_hours_of_duty)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        <MenuItem value={''}>Select An Answer</MenuItem>
                        <MenuItem value={'Yes'}>Yes</MenuItem>
                        <MenuItem value={'No'}>No</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Alcohol use prior to post-accident test
                    </Typography>
                    <TextField
                        fullWidth
                        id="alcohol_use_prior_to_post_accident_test"
                        select
                        size="small"
                        variant="outlined"
                        value={formik.values.alcohol_use_prior_to_post_accident_test}
                        onChange={(e) => {
                            formik.setFieldValue('alcohol_use_prior_to_post_accident_test', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.alcohol_use_prior_to_post_accident_test &&
                                        Boolean(formik.errors.alcohol_use_prior_to_post_accident_test)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.alcohol_use_prior_to_post_accident_test &&
                                        Boolean(formik.errors.alcohol_use_prior_to_post_accident_test)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.alcohol_use_prior_to_post_accident_test &&
                                        Boolean(formik.errors.alcohol_use_prior_to_post_accident_test)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        <MenuItem value={'Yes'}>Yes</MenuItem>
                        <MenuItem value={'No'}>No</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Alcohol use while on duty
                    </Typography>
                    <TextField
                        fullWidth
                        id="alcohol_use_while_on_duty"
                        select
                        size="small"
                        variant="outlined"
                        value={formik.values.alcohol_use_while_on_duty}
                        onChange={(e) => {
                            formik.setFieldValue('alcohol_use_while_on_duty', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.alcohol_use_while_on_duty &&
                                        Boolean(formik.errors.alcohol_use_while_on_duty)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.alcohol_use_while_on_duty &&
                                        Boolean(formik.errors.alcohol_use_while_on_duty)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.alcohol_use_while_on_duty &&
                                        Boolean(formik.errors.alcohol_use_while_on_duty)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        <MenuItem value={'Yes'}>Yes</MenuItem>
                        <MenuItem value={'No'}>No</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Controlled substances use
                    </Typography>
                    <TextField
                        fullWidth
                        id="controlled_substances_use"
                        select
                        size="small"
                        variant="outlined"
                        value={formik.values.controlled_substances_use}
                        onChange={(e) => {
                            formik.setFieldValue('controlled_substances_use', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.controlled_substances_use &&
                                        Boolean(formik.errors.controlled_substances_use)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.controlled_substances_use &&
                                        Boolean(formik.errors.controlled_substances_use)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.controlled_substances_use &&
                                        Boolean(formik.errors.controlled_substances_use)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        <MenuItem value={'Yes'}>Yes</MenuItem>
                        <MenuItem value={'No'}>No</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Basis for "actual knowledge" as defined in Sec. 382.107
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        (Select how knowledge of the violation was obtained)
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Alcohol
                    </Typography>
                    <TextField
                        fullWidth
                        id="alcohol"
                        select
                        size="small"
                        variant="outlined"
                        value={formik.values.alcohol}
                        onChange={(e) => {
                            formik.setFieldValue('alcohol', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.alcohol && Boolean(formik.errors.alcohol) ? 'red' : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.alcohol && Boolean(formik.errors.alcohol) ? 'red' : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.alcohol && Boolean(formik.errors.alcohol) ? 'red' : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(AlcoholChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Controlled Substances
                    </Typography>
                    <TextField
                        fullWidth
                        id="controlled_substances"
                        select
                        size="small"
                        variant="outlined"
                        value={formik.values.controlled_substances}
                        onChange={(e) => {
                            formik.setFieldValue('controlled_substances', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.controlled_substances &&
                                        Boolean(formik.errors.controlled_substances)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.controlled_substances &&
                                        Boolean(formik.errors.controlled_substances)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.controlled_substances &&
                                        Boolean(formik.errors.controlled_substances)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(ControlledSubstanceTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Date actual knowledge was obtained
                    </Typography>
                    <TextField
                        fullWidth
                        id="date_knowledge_obtained"
                        type="date"
                        size="small"
                        variant="outlined"
                        value={formik.values.date_knowledge_obtained}
                        onChange={(e) => {
                            formik.setFieldValue('date_knowledge_obtained', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.date_knowledge_obtained &&
                                        Boolean(formik.errors.date_knowledge_obtained)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.date_knowledge_obtained &&
                                        Boolean(formik.errors.date_knowledge_obtained)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.date_knowledge_obtained &&
                                        Boolean(formik.errors.date_knowledge_obtained)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Date submitted to Clearinghouse
                    </Typography>
                    <TextField
                        fullWidth
                        id="date_submitted_to_clearinghouse"
                        type="date"
                        size="small"
                        variant="outlined"
                        value={formik.values.date_submitted_to_clearinghouse}
                        onChange={(e) => {
                            formik.setFieldValue('date_submitted_to_clearinghouse', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.date_submitted_to_clearinghouse &&
                                        Boolean(formik.errors.date_submitted_to_clearinghouse)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.date_submitted_to_clearinghouse &&
                                        Boolean(formik.errors.date_submitted_to_clearinghouse)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.date_submitted_to_clearinghouse &&
                                        Boolean(formik.errors.date_submitted_to_clearinghouse)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Description of the violation
                    </Typography>
                    <TextField
                        fullWidth
                        id="description_of_the_violation"
                        placeholder="Description of the violation"
                        multiline
                        rows={4}
                        size="small"
                        variant="outlined"
                        value={formik.values.description_of_the_violation}
                        onChange={(e) => {
                            formik.setFieldValue('description_of_the_violation', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.description_of_the_violation &&
                                        Boolean(formik.errors.description_of_the_violation)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.description_of_the_violation &&
                                        Boolean(formik.errors.description_of_the_violation)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.description_of_the_violation &&
                                        Boolean(formik.errors.description_of_the_violation)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        To support each alleged fact above, the following documentation is attached
                    </Typography>
                    <TextField
                        fullWidth
                        id="attached_documentation"
                        placeholder="To support each alleged fact above, the following documentation is attached"
                        multiline
                        rows={4}
                        size="small"
                        variant="outlined"
                        value={formik.values.attached_documentation}
                        onChange={(e) => {
                            formik.setFieldValue('attached_documentation', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.attached_documentation &&
                                        Boolean(formik.errors.attached_documentation)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.attached_documentation &&
                                        Boolean(formik.errors.attached_documentation)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.attached_documentation &&
                                        Boolean(formik.errors.attached_documentation)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                {(!data || (data && currentUser && ['Admin', 'SuperAdmin'].includes(currentUser?.role))) &&
                !isDriverFile ? (
                    <Grid item xs={12} sm={12}>
                        <Button
                            fullWidth
                            disabled={isCreating || isUpdating || !!member?.terminated_at}
                            onClick={() => formik.handleSubmit()}
                            variant="contained"
                            color="primary"
                        >
                            Save
                        </Button>
                        {currentUser?.role !== 'Admin' && currentUser?.role !== 'SuperAdmin' && (
                            <Typography
                                sx={{ color: 'red', textAlign: 'center', mt: 2 }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Once you click save, you will not be able to edit this form.
                            </Typography>
                        )}
                    </Grid>
                ) : null}
                {data && currentUser?.role === 'SuperAdmin' && (
                    <Grid item xs={12} sm={12}>
                        <Button
                            fullWidth
                            disabled={isDeleting || !!member?.terminated_at}
                            onClick={() => {
                                const res = window.confirm('Are you sure you want to delete this form section?');
                                if (res)
                                    deleteViolationAndActualKnowledge(data?.id).then(() => {
                                        window.location.reload();
                                    });
                            }}
                            variant="contained"
                            color="error"
                        >
                            Delete Violation and Actual Knowledge
                        </Button>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default ViolationAndActualKnowledge;
