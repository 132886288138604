import { Button, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { useAuth } from '../../../app/modules/auth';
import { IMember } from '../../../models';
import { InspectionTextChoices } from '../../../store/interfaces/RecordOfRoadTest/backing-and-parking';
import { IPlacingVehiclesInMotionAndUseOfControlsDto } from '../../../store/interfaces/RecordOfRoadTest/placing-vehicles-in-motion-and-use-of-controls';
import {
    useCreatePlacingVehiclesInMotionAndUseOfControlsMutation,
    useDeletePlacingVehiclesInMotionAndUseOfControlsMutation,
    useGetPlacingVehiclesInMotionAndUseOfControlsByUserIdQuery,
    useUpdatePlacingVehiclesInMotionAndUseOfControlsMutation
} from '../../../store/reducers/RecordOfRoadTest/placing-vehicles-in-motion-use-of-controls';
import { useThemeMode } from '../../partials';

const initialValues: Partial<IPlacingVehiclesInMotionAndUseOfControlsDto> = {
    places_transmission_in_neutral_before_starting_engine: InspectionTextChoices.NA,
    starts_engine_without_difficulty: InspectionTextChoices.NA,
    allows_proper_warm_up: InspectionTextChoices.NA,
    understands_gauges_on_instrument_panel: InspectionTextChoices.NA,
    maintains_proper_engine_speed_rpm_while_driving: InspectionTextChoices.NA,
    does_not_abuse_motor: InspectionTextChoices.NA,
    starts_loaded_unit_smoothly: InspectionTextChoices.NA,
    uses_clutch_properly: InspectionTextChoices.NA,
    times_gearshifts_properly: InspectionTextChoices.NA,
    shifts_gears_smoothly: InspectionTextChoices.NA,
    use_proper_gear_sequence: InspectionTextChoices.NA,
    knows_proper_use_of_tractor_protection_valve: InspectionTextChoices.NA,
    understands_low_air_warning: InspectionTextChoices.NA,
    tests_service_brakes: InspectionTextChoices.NA,
    build_full_air_pressure_beofre_moving: InspectionTextChoices.NA,
    controls_steering_wheel: InspectionTextChoices.NA,
    good_driving_posture_and_good_grip_on_wheel: InspectionTextChoices.NA,
    knows_lighting_regulations: InspectionTextChoices.NA,
    uses_proper_lighting_beam: InspectionTextChoices.NA,
    dim_lights_when_meeting_or_following_other_traffic: InspectionTextChoices.NA,
    proper_use_of_auxiliary_lights: InspectionTextChoices.NA,
    adjusts_speed_to_range_of_headlights: InspectionTextChoices.NA,
    remarks: ''
};

const validationSchema = Yup.object({
    places_transmission_in_neutral_before_starting_engine: Yup.string().required('Required'),
    starts_engine_without_difficulty: Yup.string().required('Required'),
    allows_proper_warm_up: Yup.string().required('Required'),
    understands_gauges_on_instrument_panel: Yup.string().required('Required'),
    maintains_proper_engine_speed_rpm_while_driving: Yup.string().required('Required'),
    does_not_abuse_motor: Yup.string().required('Required'),
    starts_loaded_unit_smoothly: Yup.string().required('Required'),
    uses_clutch_properly: Yup.string().required('Required'),
    times_gearshifts_properly: Yup.string().required('Required'),
    shifts_gears_smoothly: Yup.string().required('Required'),
    use_proper_gear_sequence: Yup.string().required('Required'),
    knows_proper_use_of_tractor_protection_valve: Yup.string().required('Required'),
    understands_low_air_warning: Yup.string().required('Required'),
    tests_service_brakes: Yup.string().required('Required'),
    build_full_air_pressure_beofre_moving: Yup.string().required('Required'),
    controls_steering_wheel: Yup.string().required('Required'),
    good_driving_posture_and_good_grip_on_wheel: Yup.string().required('Required'),
    knows_lighting_regulations: Yup.string().required('Required'),
    uses_proper_lighting_beam: Yup.string().required('Required'),
    dim_lights_when_meeting_or_following_other_traffic: Yup.string().required('Required'),
    proper_use_of_auxiliary_lights: Yup.string().required('Required'),
    adjusts_speed_to_range_of_headlights: Yup.string().required('Required')
});

const PlacingVehicleInMotion = ({ member, isDriverFile }: { member?: IMember; isDriverFile?: boolean }) => {
    const { mode } = useThemeMode();
    const { currentUser } = useAuth();
    const { data } = useGetPlacingVehiclesInMotionAndUseOfControlsByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    });

    const [createPlacingVehiclesInMotionAndUseOfControls, { isLoading: isCreating }] =
        useCreatePlacingVehiclesInMotionAndUseOfControlsMutation();
    const [updatePlacingVehiclesInMotionAndUseOfControls, { isLoading: isUpdating }] =
        useUpdatePlacingVehiclesInMotionAndUseOfControlsMutation();
    const [deletePlacingVehiclesInMotionAndUseOfControls] = useDeletePlacingVehiclesInMotionAndUseOfControlsMutation();

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (data) {
                // @ts-ignore
                updatePlacingVehiclesInMotionAndUseOfControls({
                    ...values,
                    id: data.id
                });
            } else {
                // @ts-ignore
                createPlacingVehiclesInMotionAndUseOfControls({
                    ...values,
                    // @ts-ignore
                    member: member?.id,
                    // @ts-ignore
                    company: member?.company?.id
                });
            }
            resetForm();
        },
        validateOnBlur: true,
        validateOnChange: true
    });

    useEffect(() => {
        if (data) {
            formik.setValues(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const setAllAsSatisfactory = () => {
        formik.setValues({
            adjusts_speed_to_range_of_headlights: InspectionTextChoices.SATISFACTORY,
            allows_proper_warm_up: InspectionTextChoices.SATISFACTORY,
            build_full_air_pressure_beofre_moving: InspectionTextChoices.SATISFACTORY,
            controls_steering_wheel: InspectionTextChoices.SATISFACTORY,
            dim_lights_when_meeting_or_following_other_traffic: InspectionTextChoices.SATISFACTORY,
            does_not_abuse_motor: InspectionTextChoices.SATISFACTORY,
            good_driving_posture_and_good_grip_on_wheel: InspectionTextChoices.SATISFACTORY,
            knows_lighting_regulations: InspectionTextChoices.SATISFACTORY,
            knows_proper_use_of_tractor_protection_valve: InspectionTextChoices.SATISFACTORY,
            maintains_proper_engine_speed_rpm_while_driving: InspectionTextChoices.SATISFACTORY,
            places_transmission_in_neutral_before_starting_engine: InspectionTextChoices.SATISFACTORY,
            proper_use_of_auxiliary_lights: InspectionTextChoices.SATISFACTORY,
            shifts_gears_smoothly: InspectionTextChoices.SATISFACTORY,
            starts_engine_without_difficulty: InspectionTextChoices.SATISFACTORY,
            starts_loaded_unit_smoothly: InspectionTextChoices.SATISFACTORY,
            tests_service_brakes: InspectionTextChoices.SATISFACTORY,
            times_gearshifts_properly: InspectionTextChoices.SATISFACTORY,
            understands_gauges_on_instrument_panel: InspectionTextChoices.SATISFACTORY,
            understands_low_air_warning: InspectionTextChoices.SATISFACTORY,
            use_proper_gear_sequence: InspectionTextChoices.SATISFACTORY,
            uses_clutch_properly: InspectionTextChoices.SATISFACTORY,
            uses_proper_lighting_beam: InspectionTextChoices.SATISFACTORY
        });
    };

    return (
        <div
            style={{
                padding: 10,
                margin: 10
            }}
        >
            <Typography
                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: 'center' }}
                variant="h4"
                gutterBottom
                component="h4"
            >
                Placing Vehicle in Motion and Use of Controls
            </Typography>
            <Typography
                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: 'justify' }}
                variant="body1"
                gutterBottom
                component="p"
            >
                For the items that apply, please mark the driver's performance accordingly. Explain unsatisfactory items
                under Remarks.
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Button size="small" fullWidth variant="contained" onClick={setAllAsSatisfactory}>
                        Set all As satisfactory
                    </Button>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        A. Engine
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Places transmission in neutral before starting engine
                    </Typography>
                    <TextField
                        fullWidth
                        id="places_transmission_in_neutral_before_starting_engine"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.places_transmission_in_neutral_before_starting_engine}
                        onChange={(e) => {
                            formik.setFieldValue(
                                'places_transmission_in_neutral_before_starting_engine',
                                e.target.value
                            );
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.places_transmission_in_neutral_before_starting_engine &&
                                        Boolean(formik.errors.places_transmission_in_neutral_before_starting_engine)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.places_transmission_in_neutral_before_starting_engine &&
                                        Boolean(formik.errors.places_transmission_in_neutral_before_starting_engine)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.places_transmission_in_neutral_before_starting_engine &&
                                        Boolean(formik.errors.places_transmission_in_neutral_before_starting_engine)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Starts engine without difficulty
                    </Typography>
                    <TextField
                        fullWidth
                        id="starts_engine_without_difficulty"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.starts_engine_without_difficulty}
                        onChange={(e) => {
                            formik.setFieldValue('starts_engine_without_difficulty', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.starts_engine_without_difficulty &&
                                        Boolean(formik.errors.starts_engine_without_difficulty)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.starts_engine_without_difficulty &&
                                        Boolean(formik.errors.starts_engine_without_difficulty)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.starts_engine_without_difficulty &&
                                        Boolean(formik.errors.starts_engine_without_difficulty)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Allows proper warm-up
                    </Typography>
                    <TextField
                        fullWidth
                        id="allows_proper_warm_up"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.allows_proper_warm_up}
                        onChange={(e) => {
                            formik.setFieldValue('allows_proper_warm_up', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.allows_proper_warm_up &&
                                        Boolean(formik.errors.allows_proper_warm_up)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.allows_proper_warm_up &&
                                        Boolean(formik.errors.allows_proper_warm_up)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.allows_proper_warm_up &&
                                        Boolean(formik.errors.allows_proper_warm_up)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Understands gauges on instrument panel
                    </Typography>
                    <TextField
                        fullWidth
                        id="understands_gauges_on_instrument_panel"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.understands_gauges_on_instrument_panel}
                        onChange={(e) => {
                            formik.setFieldValue('understands_gauges_on_instrument_panel', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.understands_gauges_on_instrument_panel &&
                                        Boolean(formik.errors.understands_gauges_on_instrument_panel)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.understands_gauges_on_instrument_panel &&
                                        Boolean(formik.errors.understands_gauges_on_instrument_panel)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.understands_gauges_on_instrument_panel &&
                                        Boolean(formik.errors.understands_gauges_on_instrument_panel)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Maintains proper engine speed (rpm) while driving
                    </Typography>
                    <TextField
                        fullWidth
                        id="maintains_proper_engine_speed_rpm_while_driving"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.maintains_proper_engine_speed_rpm_while_driving}
                        onChange={(e) => {
                            formik.setFieldValue('maintains_proper_engine_speed_rpm_while_driving', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.maintains_proper_engine_speed_rpm_while_driving &&
                                        Boolean(formik.errors.maintains_proper_engine_speed_rpm_while_driving)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.maintains_proper_engine_speed_rpm_while_driving &&
                                        Boolean(formik.errors.maintains_proper_engine_speed_rpm_while_driving)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.maintains_proper_engine_speed_rpm_while_driving &&
                                        Boolean(formik.errors.maintains_proper_engine_speed_rpm_while_driving)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Does not abuse motor
                    </Typography>
                    <TextField
                        fullWidth
                        id="does_not_abuse_motor"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.does_not_abuse_motor}
                        onChange={(e) => {
                            formik.setFieldValue('does_not_abuse_motor', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.does_not_abuse_motor &&
                                        Boolean(formik.errors.does_not_abuse_motor)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.does_not_abuse_motor &&
                                        Boolean(formik.errors.does_not_abuse_motor)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.does_not_abuse_motor &&
                                        Boolean(formik.errors.does_not_abuse_motor)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        B. Clutch and Transmission
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Starts loaded unit smoothly
                    </Typography>
                    <TextField
                        fullWidth
                        id="starts_loaded_unit_smoothly"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.starts_loaded_unit_smoothly}
                        onChange={(e) => {
                            formik.setFieldValue('starts_loaded_unit_smoothly', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.starts_loaded_unit_smoothly &&
                                        Boolean(formik.errors.starts_loaded_unit_smoothly)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.starts_loaded_unit_smoothly &&
                                        Boolean(formik.errors.starts_loaded_unit_smoothly)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.starts_loaded_unit_smoothly &&
                                        Boolean(formik.errors.starts_loaded_unit_smoothly)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Uses clutch properly
                    </Typography>
                    <TextField
                        fullWidth
                        id="uses_clutch_properly"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.uses_clutch_properly}
                        onChange={(e) => {
                            formik.setFieldValue('uses_clutch_properly', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.uses_clutch_properly &&
                                        Boolean(formik.errors.uses_clutch_properly)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.uses_clutch_properly &&
                                        Boolean(formik.errors.uses_clutch_properly)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.uses_clutch_properly &&
                                        Boolean(formik.errors.uses_clutch_properly)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Times gearshifts properly
                    </Typography>
                    <TextField
                        fullWidth
                        id="times_gearshifts_properly"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.times_gearshifts_properly}
                        onChange={(e) => {
                            formik.setFieldValue('times_gearshifts_properly', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.times_gearshifts_properly &&
                                        Boolean(formik.errors.times_gearshifts_properly)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.times_gearshifts_properly &&
                                        Boolean(formik.errors.times_gearshifts_properly)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.times_gearshifts_properly &&
                                        Boolean(formik.errors.times_gearshifts_properly)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Shifts gears smoothly
                    </Typography>
                    <TextField
                        fullWidth
                        id="shifts_gears_smoothly"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.shifts_gears_smoothly}
                        onChange={(e) => {
                            formik.setFieldValue('shifts_gears_smoothly', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.shifts_gears_smoothly &&
                                        Boolean(formik.errors.shifts_gears_smoothly)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.shifts_gears_smoothly &&
                                        Boolean(formik.errors.shifts_gears_smoothly)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.shifts_gears_smoothly &&
                                        Boolean(formik.errors.shifts_gears_smoothly)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Use proper gear sequence
                    </Typography>
                    <TextField
                        fullWidth
                        id="use_proper_gear_sequence"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.use_proper_gear_sequence}
                        onChange={(e) => {
                            formik.setFieldValue('use_proper_gear_sequence', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.use_proper_gear_sequence &&
                                        Boolean(formik.errors.use_proper_gear_sequence)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.use_proper_gear_sequence &&
                                        Boolean(formik.errors.use_proper_gear_sequence)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.use_proper_gear_sequence &&
                                        Boolean(formik.errors.use_proper_gear_sequence)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        C. Brakes
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Knows proper use of tractor protection valve
                    </Typography>
                    <TextField
                        fullWidth
                        id="knows_proper_use_of_tractor_protection_valve"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.knows_proper_use_of_tractor_protection_valve}
                        onChange={(e) => {
                            formik.setFieldValue('knows_proper_use_of_tractor_protection_valve', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.knows_proper_use_of_tractor_protection_valve &&
                                        Boolean(formik.errors.knows_proper_use_of_tractor_protection_valve)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.knows_proper_use_of_tractor_protection_valve &&
                                        Boolean(formik.errors.knows_proper_use_of_tractor_protection_valve)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.knows_proper_use_of_tractor_protection_valve &&
                                        Boolean(formik.errors.knows_proper_use_of_tractor_protection_valve)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Understands low air warning
                    </Typography>
                    <TextField
                        fullWidth
                        id="understands_low_air_warning"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.understands_low_air_warning}
                        onChange={(e) => {
                            formik.setFieldValue('understands_low_air_warning', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.understands_low_air_warning &&
                                        Boolean(formik.errors.understands_low_air_warning)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.understands_low_air_warning &&
                                        Boolean(formik.errors.understands_low_air_warning)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.understands_low_air_warning &&
                                        Boolean(formik.errors.understands_low_air_warning)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Tests service brakes
                    </Typography>
                    <TextField
                        fullWidth
                        id="tests_service_brakes"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.tests_service_brakes}
                        onChange={(e) => {
                            formik.setFieldValue('tests_service_brakes', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.tests_service_brakes &&
                                        Boolean(formik.errors.tests_service_brakes)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.tests_service_brakes &&
                                        Boolean(formik.errors.tests_service_brakes)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.tests_service_brakes &&
                                        Boolean(formik.errors.tests_service_brakes)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Builds full air pressure before moving
                    </Typography>
                    <TextField
                        fullWidth
                        id="build_full_air_pressure_beofre_moving"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.build_full_air_pressure_beofre_moving}
                        onChange={(e) => {
                            formik.setFieldValue('build_full_air_pressure_beofre_moving', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.build_full_air_pressure_beofre_moving &&
                                        Boolean(formik.errors.build_full_air_pressure_beofre_moving)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.build_full_air_pressure_beofre_moving &&
                                        Boolean(formik.errors.build_full_air_pressure_beofre_moving)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.build_full_air_pressure_beofre_moving &&
                                        Boolean(formik.errors.build_full_air_pressure_beofre_moving)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        D. Steering
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Controls steering wheel
                    </Typography>
                    <TextField
                        fullWidth
                        id="controls_steering_wheel"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.controls_steering_wheel}
                        onChange={(e) => {
                            formik.setFieldValue('controls_steering_wheel', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.controls_steering_wheel &&
                                        Boolean(formik.errors.controls_steering_wheel)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.controls_steering_wheel &&
                                        Boolean(formik.errors.controls_steering_wheel)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.controls_steering_wheel &&
                                        Boolean(formik.errors.controls_steering_wheel)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Good driving posture and good grip on wheel
                    </Typography>
                    <TextField
                        fullWidth
                        id="good_driving_posture_and_good_grip_on_wheel"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.good_driving_posture_and_good_grip_on_wheel}
                        onChange={(e) => {
                            formik.setFieldValue('good_driving_posture_and_good_grip_on_wheel', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.good_driving_posture_and_good_grip_on_wheel &&
                                        Boolean(formik.errors.good_driving_posture_and_good_grip_on_wheel)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.good_driving_posture_and_good_grip_on_wheel &&
                                        Boolean(formik.errors.good_driving_posture_and_good_grip_on_wheel)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.good_driving_posture_and_good_grip_on_wheel &&
                                        Boolean(formik.errors.good_driving_posture_and_good_grip_on_wheel)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        E. Lights
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Knows lighting regulations
                    </Typography>
                    <TextField
                        fullWidth
                        id="knows_lighting_regulations"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.knows_lighting_regulations}
                        onChange={(e) => {
                            formik.setFieldValue('knows_lighting_regulations', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.knows_lighting_regulations &&
                                        Boolean(formik.errors.knows_lighting_regulations)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.knows_lighting_regulations &&
                                        Boolean(formik.errors.knows_lighting_regulations)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.knows_lighting_regulations &&
                                        Boolean(formik.errors.knows_lighting_regulations)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Uses proper headlight beam
                    </Typography>
                    <TextField
                        fullWidth
                        id="uses_proper_lighting_beam"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.uses_proper_lighting_beam}
                        onChange={(e) => {
                            formik.setFieldValue('uses_proper_lighting_beam', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.uses_proper_lighting_beam &&
                                        Boolean(formik.errors.uses_proper_lighting_beam)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.uses_proper_lighting_beam &&
                                        Boolean(formik.errors.uses_proper_lighting_beam)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.uses_proper_lighting_beam &&
                                        Boolean(formik.errors.uses_proper_lighting_beam)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Dim lights when meeting or following other traffic
                    </Typography>
                    <TextField
                        fullWidth
                        id="dim_lights_when_meeting_or_following_other_traffic"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.dim_lights_when_meeting_or_following_other_traffic}
                        onChange={(e) => {
                            formik.setFieldValue('dim_lights_when_meeting_or_following_other_traffic', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.dim_lights_when_meeting_or_following_other_traffic &&
                                        Boolean(formik.errors.dim_lights_when_meeting_or_following_other_traffic)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.dim_lights_when_meeting_or_following_other_traffic &&
                                        Boolean(formik.errors.dim_lights_when_meeting_or_following_other_traffic)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.dim_lights_when_meeting_or_following_other_traffic &&
                                        Boolean(formik.errors.dim_lights_when_meeting_or_following_other_traffic)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Adjusts speed to range of headlights
                    </Typography>
                    <TextField
                        fullWidth
                        id="adjusts_speed_to_range_of_headlights"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.adjusts_speed_to_range_of_headlights}
                        onChange={(e) => {
                            formik.setFieldValue('adjusts_speed_to_range_of_headlights', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.adjusts_speed_to_range_of_headlights &&
                                        Boolean(formik.errors.adjusts_speed_to_range_of_headlights)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.adjusts_speed_to_range_of_headlights &&
                                        Boolean(formik.errors.adjusts_speed_to_range_of_headlights)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.adjusts_speed_to_range_of_headlights &&
                                        Boolean(formik.errors.adjusts_speed_to_range_of_headlights)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Proper use of auxiliary lights
                    </Typography>
                    <TextField
                        fullWidth
                        id="proper_use_of_auxiliary_lights"
                        size="small"
                        variant="outlined"
                        select
                        value={formik.values.proper_use_of_auxiliary_lights}
                        onChange={(e) => {
                            formik.setFieldValue('proper_use_of_auxiliary_lights', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.proper_use_of_auxiliary_lights &&
                                        Boolean(formik.errors.proper_use_of_auxiliary_lights)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.proper_use_of_auxiliary_lights &&
                                        Boolean(formik.errors.proper_use_of_auxiliary_lights)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.proper_use_of_auxiliary_lights &&
                                        Boolean(formik.errors.proper_use_of_auxiliary_lights)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        {Object.values(InspectionTextChoices).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Remarks
                    </Typography>
                    <TextField
                        fullWidth
                        id="remarks"
                        size="small"
                        placeholder="Remarks"
                        multiline
                        rows={4}
                        variant="outlined"
                        value={formik.values.remarks}
                        onChange={(e) => {
                            formik.setFieldValue('remarks', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.remarks && Boolean(formik.errors.remarks) ? 'red' : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.remarks && Boolean(formik.errors.remarks) ? 'red' : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.remarks && Boolean(formik.errors.remarks) ? 'red' : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                {(!data || (data && currentUser && ['Admin', 'SuperAdmin'].includes(currentUser?.role))) &&
                !isDriverFile ? (
                    <Grid item xs={12} sm={12}>
                        <Button
                            fullWidth
                            disabled={isCreating || isUpdating || !!member?.terminated_at}
                            onClick={() => formik.handleSubmit()}
                            variant="contained"
                            color="primary"
                        >
                            Save
                        </Button>
                        {currentUser?.role !== 'Admin' && currentUser?.role !== 'SuperAdmin' && (
                            <Typography
                                sx={{ color: 'red', textAlign: 'center', mt: 2 }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Once you click save, you will not be able to edit this form.
                            </Typography>
                        )}
                    </Grid>
                ) : null}
                {data && currentUser?.role === 'SuperAdmin' && (
                    <Grid item xs={12} sm={12}>
                        <Button
                            fullWidth
                            disabled={!!member?.terminated_at}
                            onClick={() => {
                                const res = window.confirm('Are you sure you want to delete this form?');
                                if (res && data) {
                                    deletePlacingVehiclesInMotionAndUseOfControls(data.id);
                                }
                            }}
                            variant="contained"
                            color="primary"
                        >
                            Delete Form
                        </Button>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default PlacingVehicleInMotion;
