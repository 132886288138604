import client from '../Client';

export function getNotifications(index?: number) {
  return client(`notifications/api/notification/${index ? `?index=${index}` : ''}`);
}

export function markAsViewed(id: number | number[]) {
  return mark(id, 'Viewed');
}

export function markAsClicked(id: number | number[]) {
  return mark(id, 'Clicked');
}

function mark(id: number | number[], type: string) {
  const body = id instanceof Array ? {notification_ids: id, type} : {notification_id: id, type};

  return client(`notifications/api/notification-interaction/`, {
    body,
  });
}
