/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { Theme, ThemeProvider, createTheme } from '@mui/material/styles';
import { FC, useCallback, useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useThemeMode } from '../../compliancemasters/partials';
import App from '../App';
import { AuthPage, Logout, useAuth } from '../modules/auth';
import SalesRepTermsAndConditions from '../modules/auth/components/SalesRepTermsAndConditions';
import TermsConditions from '../modules/auth/components/TermsConditions';
import { ErrorsPage } from '../modules/errors/ErrorsPage';
import CompleteDriverFile from '../pages/complete-driver-file/CompleteDriverFile';
import CompleteDriverFileDrugAlcohol from '../pages/complete-driver-file/CompleteDriverFileDrugAlcohol';
import PasswordReset from '../pages/password-reset/PasswordReset';
import { PrivateRoutes } from './PrivateRoutes';

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {
    const { currentUser } = useAuth();
    const { mode, updateMode, updateMenuMode } = useThemeMode();
    const [theme, setTheme] = useState<Theme>({} as Theme);

    const createNewTheme = useCallback(() => {
        if (mode === 'light') {
            return createTheme({
                components: {
                    MuiInputBase: {
                        // Targeting the InputBase component
                        styleOverrides: {
                            root: {
                                '& .MuiInputBase-input.Mui-disabled': {
                                    WebkitTextFillColor: 'black'
                                },
                                '& .MuiInputBase-input': {
                                    WebkitTextFillColor: 'black'
                                }
                            }
                        }
                    },
                    MuiTypography: {
                        styleOverrides: {
                            root: {
                                color: 'black'
                            }
                        }
                    }
                }
            });
        } else {
            return createTheme({
                components: {
                    MuiInputBase: {
                        // Targeting the InputBase component
                        styleOverrides: {
                            root: {
                                '& .MuiInputBase-input.Mui-disabled': {
                                    WebkitTextFillColor: 'white'
                                },
                                '& .MuiInputBase-input': {
                                    WebkitTextFillColor: 'white'
                                }
                            }
                        }
                    },
                    MuiTypography: {
                        styleOverrides: {
                            root: {
                                color: 'white'
                            }
                        }
                    }
                }
            });
        }
    }, [mode]);

    useEffect(() => {
        setTheme(createNewTheme());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode]);

    useEffect(() => {
        updateMode('light');
        updateMenuMode('light');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (window.location.pathname.length > 0 && window.location.search === '?ref=') {
            window.localStorage.setItem('compliance_master_refer_path', window.location.pathname);
        }
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter basename={PUBLIC_URL}>
                <Routes>
                    <Route element={<App />}>
                        <Route path="error/*" element={<ErrorsPage />} />
                        <Route path="logout" element={<Logout />} />
                        <Route path="set-new-password" element={<PasswordReset />} />
                        <Route
                            path="/"
                            element={currentUser ? <Navigate to="/dashboard" /> : <Navigate to="/auth" />}
                        />
                        <Route
                            path="terms"
                            element={
                                currentUser && currentUser?.role === 'SalesRep' ? (
                                    <SalesRepTermsAndConditions />
                                ) : (
                                    <TermsConditions />
                                )
                            }
                        />
                        <Route element={<CompleteDriverFile />} path="/complete-driver-file" />
                        <Route element={<CompleteDriverFileDrugAlcohol />} path="/complete-driver-file-drug-alcohol" />
                        {currentUser ? (
                            <>
                                <Route path="/*" element={<PrivateRoutes />} />
                                <Route path="auth/*" element={<AuthPage />} />
                            </>
                        ) : (
                            <>
                                <Route path="auth/*" element={<AuthPage />} />
                                <Route path="*" element={<Navigate to="/auth" />} />
                            </>
                        )}
                    </Route>
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    );
};

export { AppRoutes };
