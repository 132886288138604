import { Box, Button, Grid, Paper, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { useAuth } from '../../../app/modules/auth';
import { IMember } from '../../../models';
import { IProspectiveEmployerInformationDto } from '../../../store/interfaces/PreviousEmployeeSafetyPerformanceHistory/prospective-employer-information';
import { useGetApplicantBasicInfoByUserIdQuery } from '../../../store/reducers/DriversApplicationForEmployment/applicant.basic.info';
import {
    useCreateProspectiveEmployerInformationMutation,
    useDeleteProspectiveEmployerInformationMutation,
    useGetProspectiveEmployerInformationByUserIdQuery,
    useUpdateProspectiveEmployerInformationMutation
} from '../../../store/reducers/PreviousEmployeeSafetyPerformanceHistory/prospective-employer-information';
import { useThemeMode } from '../../partials';

const initialValues: Partial<IProspectiveEmployerInformationDto> = {
    company_name: '',
    attention: '',
    street: '',
    city_state_zip: '',
    phone_number: '',
    email: '',
    date: '',
    applicant_signature: ''
};

const validationSchema = Yup.object({
    company_name: Yup.string().required('Required'),
    attention: Yup.string().required('Required'),
    street: Yup.string().required('Required'),
    city_state_zip: Yup.string().required('Required'),
    phone_number: Yup.string().required('Required'),
    email: Yup.string().required('Required'),
    // this_form_was: Yup.string().required('Required'),
    date: Yup.string().required('Required'),
    // name_of_person_contacted: Yup.string().required('Required'),
    applicant_signature: Yup.string().required('Required')
});

interface IProps {
    member?: IMember;
}

const ProspectiveEmployerInformation = ({ member }: IProps) => {
    const { mode } = useThemeMode();
    const { currentUser } = useAuth();
    const { data } = useGetProspectiveEmployerInformationByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    });

    const { data: application } = useGetApplicantBasicInfoByUserIdQuery(member?.user?.id, {
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
        skip: !member?.user?.id
    });

    const [createProspectiveEmployerInformation, { isLoading: isCreating }] =
        useCreateProspectiveEmployerInformationMutation();
    const [updateProspectiveEmployerInformation, { isLoading: isUpdating }] =
        useUpdateProspectiveEmployerInformationMutation();
    const [deleteProspectiveEmployerInformation] = useDeleteProspectiveEmployerInformationMutation();

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (data) {
                updateProspectiveEmployerInformation({
                    ...data,
                    ...values,
                    id: data.id
                }).then(() => {
                    resetForm();
                });
            } else {
                createProspectiveEmployerInformation({
                    ...values,
                    // @ts-ignore
                    company: member?.company?.id,
                    // @ts-ignore
                    member: member?.id
                }).then(() => {
                    resetForm();
                });
            }
        },
        validateOnBlur: true,
        validateOnChange: true
    });

    useEffect(() => {
        if (data) {
            formik.setValues(data);
        } else {
            formik.setValues({
                ...initialValues,
                company_name: `${member?.company?.name}`,
                street: `${member?.company?.address1}, ${member?.company?.address2}`,
                city_state_zip: `${member?.company?.city ? member?.company?.city : ''}, ${
                    member?.company?.state ? member?.company?.state : ''
                }, ${member?.company?.zip_code ? member?.company?.zip_code : ''}`,
                email: `${member?.company?.user?.email}`,
                phone_number: member?.company?.phone_number ? `${member?.company?.phone_number}` : ''
            });
            if (application) {
                formik.setFieldValue('date', application.date_of_hire);
            }
        }
        // eslint-disable-next-line
    }, [data, member]);

    return (
        <Box
            sx={{
                pl: 3,
                pr: 3,
                pt: 3,
                m: 3,
                borderRadius: 2,
                backgroundColor: mode === 'dark' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                width: {
                    lg: '47vw',
                    md: '70vw',
                    sm: '80vw'
                },
                marginLeft: 'auto',
                marginRight: 'auto'
            }}
            component={Paper}
        >
            <div
                style={{
                    padding: 10,
                    margin: 10
                }}
            >
                <Typography
                    sx={{
                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                        textAlign: 'center'
                    }}
                    variant="h4"
                    gutterBottom
                    component="h4"
                >
                    Prospective Employer Information
                </Typography>
                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12} sm={12}>
                        <Typography
                            sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                            variant="body1"
                            gutterBottom
                            component="p"
                        >
                            Company Name
                        </Typography>
                        <TextField
                            fullWidth
                            id="company_name"
                            size="small"
                            variant="outlined"
                            placeholder="Company Name"
                            value={formik.values.company_name}
                            // onChange={(e) => {
                            //     formik.setFieldValue('company_name', e.target.value)
                            // }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.company_name && Boolean(formik.errors.company_name)
                                                ? 'red'
                                                : 'green'
                                    },
                                    '&:hover fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.company_name && Boolean(formik.errors.company_name)
                                                ? 'red'
                                                : 'green'
                                    },
                                    '&.Mui-focused fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.company_name && Boolean(formik.errors.company_name)
                                                ? 'red'
                                                : 'green'
                                    }
                                }
                            }}
                            InputLabelProps={{
                                style: {
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                            disabled={
                                member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                    ? true
                                    : false
                            }
                            InputProps={{
                                style: {
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography
                            sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                            variant="body1"
                            gutterBottom
                            component="p"
                        >
                            Attention
                        </Typography>
                        <TextField
                            fullWidth
                            id="attention"
                            size="small"
                            variant="outlined"
                            placeholder="Attention"
                            value={formik.values.attention}
                            onChange={(e) => {
                                formik.setFieldValue('attention', e.target.value);
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.attention && Boolean(formik.errors.attention)
                                                ? 'red'
                                                : 'green'
                                    },
                                    '&:hover fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.attention && Boolean(formik.errors.attention)
                                                ? 'red'
                                                : 'green'
                                    },
                                    '&.Mui-focused fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.attention && Boolean(formik.errors.attention)
                                                ? 'red'
                                                : 'green'
                                    }
                                }
                            }}
                            InputLabelProps={{
                                style: {
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                            disabled={
                                member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                    ? true
                                    : false
                            }
                            InputProps={{
                                style: {
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography
                            sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                            variant="body1"
                            gutterBottom
                            component="p"
                        >
                            Street
                        </Typography>
                        <TextField
                            fullWidth
                            id="street"
                            size="small"
                            variant="outlined"
                            placeholder="Street"
                            value={formik.values.street}
                            onChange={(e) => {
                                formik.setFieldValue('street', e.target.value);
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.street && Boolean(formik.errors.street) ? 'red' : 'green'
                                    },
                                    '&:hover fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.street && Boolean(formik.errors.street) ? 'red' : 'green'
                                    },
                                    '&.Mui-focused fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.street && Boolean(formik.errors.street) ? 'red' : 'green'
                                    }
                                }
                            }}
                            InputLabelProps={{
                                style: {
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                            disabled={
                                member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                    ? true
                                    : false
                            }
                            InputProps={{
                                style: {
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography
                            sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                            variant="body1"
                            gutterBottom
                            component="p"
                        >
                            City / State / Zip
                        </Typography>
                        <TextField
                            fullWidth
                            id="city_state_zip"
                            size="small"
                            variant="outlined"
                            placeholder="City / State / Zip"
                            value={formik.values.city_state_zip}
                            onChange={(e) => {
                                formik.setFieldValue('city_state_zip', e.target.value);
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.city_state_zip && Boolean(formik.errors.city_state_zip)
                                                ? 'red'
                                                : 'green'
                                    },
                                    '&:hover fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.city_state_zip && Boolean(formik.errors.city_state_zip)
                                                ? 'red'
                                                : 'green'
                                    },
                                    '&.Mui-focused fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.city_state_zip && Boolean(formik.errors.city_state_zip)
                                                ? 'red'
                                                : 'green'
                                    }
                                }
                            }}
                            InputLabelProps={{
                                style: {
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                            disabled={
                                member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                    ? true
                                    : false
                            }
                            InputProps={{
                                style: {
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography
                            sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                            variant="body1"
                            gutterBottom
                            component="p"
                        >
                            Phone Number
                        </Typography>
                        <TextField
                            fullWidth
                            id="phone_number"
                            size="small"
                            variant="outlined"
                            placeholder="Phone Number"
                            value={formik.values.phone_number}
                            onChange={(e) => {
                                formik.setFieldValue('phone_number', e.target.value);
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.phone_number && Boolean(formik.errors.phone_number)
                                                ? 'red'
                                                : 'green'
                                    },
                                    '&:hover fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.phone_number && Boolean(formik.errors.phone_number)
                                                ? 'red'
                                                : 'green'
                                    },
                                    '&.Mui-focused fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.phone_number && Boolean(formik.errors.phone_number)
                                                ? 'red'
                                                : 'green'
                                    }
                                }
                            }}
                            InputLabelProps={{
                                style: {
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                            disabled={
                                member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                    ? true
                                    : false
                            }
                            InputProps={{
                                style: {
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography
                            sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                            variant="body1"
                            gutterBottom
                            component="p"
                        >
                            Email
                        </Typography>
                        <TextField
                            fullWidth
                            id="email"
                            size="small"
                            variant="outlined"
                            placeholder="Email"
                            type="email"
                            value={formik.values.email}
                            onChange={(e) => {
                                formik.setFieldValue('email', e.target.value);
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.email && Boolean(formik.errors.email) ? 'red' : 'green'
                                    },
                                    '&:hover fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.email && Boolean(formik.errors.email) ? 'red' : 'green'
                                    },
                                    '&.Mui-focused fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.email && Boolean(formik.errors.email) ? 'red' : 'green'
                                    }
                                }
                            }}
                            InputLabelProps={{
                                style: {
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                            disabled={
                                member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                    ? true
                                    : false
                            }
                            InputProps={{
                                style: {
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography
                            sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                            variant="body1"
                            gutterBottom
                            component="p"
                        >
                            Applicant Signature
                        </Typography>
                        <TextField
                            fullWidth
                            id="applicant_signature"
                            size="small"
                            variant="outlined"
                            placeholder="Applicant Signature"
                            value={formik.values.applicant_signature}
                            onChange={(e) => {
                                formik.setFieldValue('applicant_signature', e.target.value);
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.applicant_signature &&
                                            Boolean(formik.errors.applicant_signature)
                                                ? 'red'
                                                : 'green'
                                    },
                                    '&:hover fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.applicant_signature &&
                                            Boolean(formik.errors.applicant_signature)
                                                ? 'red'
                                                : 'green'
                                    },
                                    '&.Mui-focused fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.applicant_signature &&
                                            Boolean(formik.errors.applicant_signature)
                                                ? 'red'
                                                : 'green'
                                    }
                                }
                            }}
                            InputLabelProps={{
                                style: {
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                            disabled={
                                member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                    ? true
                                    : false
                            }
                            InputProps={{
                                style: {
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                    fontStyle: 'italic'
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography
                            sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                            variant="body1"
                            gutterBottom
                            component="p"
                        >
                            Date
                        </Typography>
                        <TextField
                            fullWidth
                            id="date"
                            size="small"
                            variant="outlined"
                            type="date"
                            value={formik.values.date}
                            onChange={(e) => {
                                formik.setFieldValue('date', e.target.value);
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.date && Boolean(formik.errors.date) ? 'red' : 'green'
                                    },
                                    '&:hover fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.date && Boolean(formik.errors.date) ? 'red' : 'green'
                                    },
                                    '&.Mui-focused fieldset': {
                                        // Check Validation
                                        borderColor:
                                            formik.touched.date && Boolean(formik.errors.date) ? 'red' : 'green'
                                    }
                                }
                            }}
                            InputLabelProps={{
                                style: {
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                            disabled={
                                member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                    ? true
                                    : false
                            }
                            InputProps={{
                                style: {
                                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                }
                            }}
                        />
                    </Grid>
                    {!data || (data && currentUser && ['Admin', 'SuperAdmin'].includes(currentUser?.role)) ? (
                        <Grid item xs={12} sm={12}>
                            <Button
                                fullWidth
                                disabled={isCreating || isUpdating || !!member?.terminated_at}
                                onClick={() => formik.handleSubmit()}
                                variant="contained"
                                color="primary"
                            >
                                Save
                            </Button>
                            {currentUser?.role !== 'Admin' && currentUser?.role !== 'SuperAdmin' && (
                                <Typography
                                    sx={{ color: 'red', textAlign: 'center', mt: 2 }}
                                    variant="body1"
                                    gutterBottom
                                    component="p"
                                >
                                    Once you click save, you will not be able to edit this form.
                                </Typography>
                            )}
                        </Grid>
                    ) : null}
                    {data && currentUser?.role === 'SuperAdmin' && (
                        <Grid item xs={12} sm={12}>
                            <Button
                                fullWidth
                                disabled={!!member?.terminated_at}
                                onClick={() => {
                                    const res = window.confirm('Are you sure you want to delete this form?');
                                    if (res && data) {
                                        deleteProspectiveEmployerInformation(data.id);
                                    }
                                }}
                                variant="contained"
                                color="primary"
                            >
                                Delete Form
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </div>
        </Box>
    );
};

export default ProspectiveEmployerInformation;
