/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { KTSVG } from '../../../helpers';
import Button from '../../../../app/modules/buttons/Button';
import { hardDeleteFile, softDeleteFile } from '../../../../shared';

interface IProps {
    fileId?: number;
    fileName?: string;
    userName?: string;
    onDeleteFile: () => void;
}

type RenderType = 'SOFT' | 'HARD' | 'CONFIRM';

const DeleteFileModal = ({ fileId, fileName, userName, onDeleteFile }: IProps) => {
    const [renderType, setRenderType] = useState<RenderType>('CONFIRM');
    return (
        <div className="modal fade" tabIndex={-1} id={`delete_file_modal_${fileId}`}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title">Delete File</h1>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon svg-icon-2x" />
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="text-center mb-10">
                            <h2 className="text-danger">Are you sure you want to delete this file?</h2>
                            <div className="py-6">
                                <h3>Name: {fileName}</h3>
                                <h3>User: {userName}</h3>
                            </div>
                            <p className="text-muted">This action cannot be undone.</p>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="d-flex justify-content-end">
                            {renderType === 'CONFIRM' && (
                                <div className="row gx-2">
                                    <div className="col-auto">
                                        <Button
                                            className={'btn btn-bg-light btn-active-color-primary'}
                                            dataBsDismiss={'modal'}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                    <div className="col-auto">
                                        <Button
                                            className={
                                                'btn btn-outline btn-outline-solid btn-outline-danger btn-active-light-danger'
                                            }
                                            onClick={() => setRenderType('HARD')}
                                        >
                                            <i className="fa-solid fa-thumbs-down text-danger"></i>
                                            Hard Delete
                                        </Button>
                                    </div>
                                    <div className="col-auto">
                                        <Button className={'btn btn-danger'} onClick={() => setRenderType('SOFT')}>
                                            <i className="fa-solid fa-thumbs-up text-white"></i>
                                            Soft Delete
                                        </Button>
                                    </div>
                                </div>
                            )}
                            {renderType === 'SOFT' && (
                                <div className="row gx-2">
                                    <div className="col-auto">
                                        <Button
                                            className={'btn btn-bg-light btn-active-color-primary'}
                                            onClick={() => setRenderType('CONFIRM')}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                    <div className="col-auto">
                                        <Button
                                            className={'btn btn-danger'}
                                            dataBsDismiss={'modal'}
                                            onClick={async () => {
                                                const res = await softDeleteFile(fileId!);
                                                onDeleteFile();
                                            }}
                                        >
                                            Confirm Soft Delete
                                        </Button>
                                    </div>
                                </div>
                            )}
                            {renderType === 'HARD' && (
                                <div className="row gx-2">
                                    <div className="col-auto">
                                        <Button
                                            className={'btn btn-bg-light btn-active-color-primary'}
                                            onClick={() => setRenderType('CONFIRM')}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                    <div className="col-auto">
                                        <Button
                                            className={'btn btn-danger'}
                                            dataBsDismiss={'modal'}
                                            onClick={async () => {
                                                const res = await hardDeleteFile(fileId!);
                                                onDeleteFile();
                                            }}
                                        >
                                            Confirm Hard Delete
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeleteFileModal;
