import React, { useState } from 'react';
import TableWrapper from '../../../../compliancemasters/partials/widgets/tables/components/TableWrapper';
import TableContainerHeader from '../../../../compliancemasters/partials/widgets/tables/components/TableContainerHeader';
import TableContainerHeaderTitle from '../../../../compliancemasters/partials/widgets/tables/components/TableContainerHeaderTitle';
import { KTSVG as Icon } from '../../../../compliancemasters/helpers';
import TableLoader from '../../../../compliancemasters/partials/widgets/tables/components/TableLoader';
import { IMember } from '../../../../models';
import AddManagerNote from './AddManagerNote';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IManagerNotes } from '../../../../models/IMaanagerNotes';
import { deleteManagerNotes, getManagerNotesByUser } from '../../../../shared/client/manager-notes';
import {
    IconButton,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import { MoreVertRounded } from '@mui/icons-material';
import ViewManagerNoteModal from './ViewManagerNote';
import { toast } from 'react-toastify';

interface IProps {
    member?: IMember;
}

const ManagerNotes = ({ member }: IProps) => {
    const [open, setOpen] = useState(false);
    const [managerNoteOpen, setManagerNoteOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(anchorEl);
    const [managerNote, setManagerNote] = useState<IManagerNotes>();
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const queryClient = useQueryClient();

    const { data } = useQuery<IManagerNotes[]>(
        ['manager-notes', member?.user?.id],
        () => getManagerNotesByUser(member?.user?.id!),
        {
            enabled: !!member?.user?.id,
            refetchOnWindowFocus: true,
            refetchInterval: 1000 * 10
        }
    );

    const deleteManagerNoteMutation = useMutation(deleteManagerNotes, {
        onSuccess: () => {
            toast.success('Manager Note Deleted');
        }
    });

    const handleDeleteManagerNote = async (id: number) => {
        const managerNote = await deleteManagerNoteMutation.mutateAsync(id);
        if (managerNote) {
            queryClient.invalidateQueries(['manager-notes', member?.user?.id]);
        }
    };

    return (
        <div className="row g-5 g-xxl-8">
            <div className="col-xl-12">
                <TableWrapper>
                    <TableContainerHeader>
                        <TableContainerHeaderTitle title="Manager Notes" />
                        <div
                            role="button"
                            className="btn btn-sm btn-light-primary d-inline-flex align-items-center"
                            onClick={() => setOpen(true)}
                            onKeyDown={() => {
                                console.log('key down');
                            }}
                        >
                            <Icon path="media/icons/duotune/arrows/arr075.svg" className="svg-icon-3" />
                            Add Manager Note
                        </div>
                    </TableContainerHeader>
                    <TableContainer>
                        <Table stickyHeader style={{ maxHeight: 440 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Subject of Note</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Time</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.map((item) => {
                                    const utcDate = new Date(item.date);
                                    const offsetMinutes = utcDate.getTimezoneOffset();
                                    utcDate.setMinutes(utcDate.getMinutes() + offsetMinutes);
                                    const adjustedYear = utcDate.getFullYear();
                                    const adjustedMonth = utcDate.getMonth();
                                    const adjustedDay = utcDate.getDate();
                                    const formattedDate = `${adjustedMonth}-${adjustedDay}-${adjustedYear}`;
                                    return (
                                        <TableRow key={item.id}>
                                            <TableCell>{item.note_subject}</TableCell>
                                            <TableCell>{formattedDate}</TableCell>
                                            <TableCell>
                                                {Intl.DateTimeFormat('en-US', { timeStyle: 'short' }).format(
                                                    new Date(`2000-01-01T${item.time}`)
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={(e) => {
                                                        setManagerNote(item);
                                                        handleClick(e);
                                                    }}
                                                >
                                                    <MoreVertRounded />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TableLoader length={data?.length ?? 0} emptyText="No Notes Yet." />
                    <AddManagerNote
                        open={open}
                        setOpen={setOpen}
                        member={member}
                        managerNote={managerNote}
                        setManagerNote={setManagerNote}
                    />
                    <ViewManagerNoteModal
                        open={managerNoteOpen}
                        setOpen={setManagerNoteOpen}
                        managerNote={managerNote}
                        setManagerNote={setManagerNote}
                    />
                    <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl}
                        open={menuOpen}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                        }}
                    >
                        <MenuItem
                            onClick={() => {
                                handleClose();
                                setManagerNoteOpen(true);
                            }}
                        >
                            View Note
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                handleClose();
                                setOpen(true);
                            }}
                        >
                            Update Note
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                const res = window.confirm('Are you sure you want to delete this note?');
                                if (res) {
                                    handleDeleteManagerNote(managerNote?.id!);
                                    handleClose();
                                }
                            }}
                        >
                            Delete Note
                        </MenuItem>
                    </Menu>
                </TableWrapper>
            </div>
        </div>
    );
};

export default ManagerNotes;
