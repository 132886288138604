import { TableCell } from '@mui/material';

const cellStyles = {
    transition: 'color 0.2s ease',
    padding: '12px'
};

const DriverSignatureCell = ({ signature }: { signature?: string }) => (
    <TableCell
        style={{
            ...cellStyles,
            color: signature ? '#000000' : '#DC2626'
        }}
    >
        {signature ?? 'Not Entered'}
    </TableCell>
);

const MechanicSignatureCell = ({
    conditionSatisfactory,
    mechanicSignature
}: {
    conditionSatisfactory: boolean;
    mechanicSignature?: string;
}) => {
    const showRed = !conditionSatisfactory && !mechanicSignature;
    let content = 'N/A';

    if (!conditionSatisfactory) {
        content = mechanicSignature || 'Not Entered';
    }

    return (
        <TableCell
            style={{
                ...cellStyles,
                color: showRed ? '#DC2626' : '#000000'
            }}
        >
            {content}
        </TableCell>
    );
};

const NextDriverSignatureCell = ({
    conditionSatisfactory,
    nextDriverSignature
}: {
    conditionSatisfactory: boolean;
    nextDriverSignature?: string;
}) => (
    <TableCell
        style={{
            ...cellStyles,
            color: !conditionSatisfactory && !nextDriverSignature ? '#DC2626' : '#000000'
        }}
    >
        {!conditionSatisfactory && !nextDriverSignature
            ? 'Not Entered'
            : conditionSatisfactory
            ? 'N/A'
            : nextDriverSignature}
    </TableCell>
);

const OdometerDisplay = ({ reading }: { reading: number }) => {
    const formatOdometerReading = (reading: number) => {
        const value = Number(reading);
        if (!reading || value === 0) {
            return (
                <span
                    style={{
                        color: '#DC2626'
                    }}
                >
                    Not Entered
                </span>
            );
        }

        return (
            <>
                {Intl.NumberFormat('en-US', {
                    compactDisplay: 'short'
                }).format(value)}{' '}
                Miles
            </>
        );
    };

    return <TableCell>{formatOdometerReading(reading)}</TableCell>;
};

export { DriverSignatureCell, MechanicSignatureCell, NextDriverSignatureCell, OdometerDisplay };
