import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
  IPerformanceRatingAndOwnerSignature,
  IPerformanceRatingAndOwnerSignatureDto,
} from '../../interfaces/RecordOfRoadTest/performance-rating-and-owner-signature';

export const performanceRatingAndOwnerSignatureApi = createApi({
  reducerPath: 'performance_rating_and_owner_signature',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/performance-rating-and-owner-signature`,
  }),
  tagTypes: ['PerformanceRatingAndOwnerSignature'],
  keepUnusedDataFor: 60 * 10, // 10 minutes
  endpoints: (builder) => ({
    createPerformanceRatingAndOwnerSignature: builder.mutation<
      IPerformanceRatingAndOwnerSignature,
      IPerformanceRatingAndOwnerSignatureDto
    >({
      query: (body) => ({
        url: '/',
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [
        {
          type: 'PerformanceRatingAndOwnerSignature',
          id: 'PerformanceRatingAndOwnerSignature',
        },
      ],
    }),
    updatePerformanceRatingAndOwnerSignature: builder.mutation<
      IPerformanceRatingAndOwnerSignature,
      IPerformanceRatingAndOwnerSignature
    >({
      query: (body) => ({
        url: `/${body.id}/`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [
        {
          type: 'PerformanceRatingAndOwnerSignature',
          id: 'PerformanceRatingAndOwnerSignature',
        },
      ],
    }),
    deletePerformanceRatingAndOwnerSignature: builder.mutation<void, number>({
      query: (id) => ({
        url: `/${id}/`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [
        {
          type: 'PerformanceRatingAndOwnerSignature',
          id: 'PerformanceRatingAndOwnerSignature',
        },
      ],
    }),
    getPerformanceRatingAndOwnerSignature: builder.query<
      IPerformanceRatingAndOwnerSignature,
      number | void
    >({
      query: (id) => ({
        url: `/get-performance-rating-and-owner-signature/`,
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [
        {
          type: 'PerformanceRatingAndOwnerSignature',
          id: 'PerformanceRatingAndOwnerSignature',
        },
      ],
    }),
    getPerformanceRatingAndOwnerSignatureByUser: builder.query<
      IPerformanceRatingAndOwnerSignature,
      void
    >({
      query: () => ({
        url: `/get-performance-rating-and-owner-signature-by-user/`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [
        {
          type: 'PerformanceRatingAndOwnerSignature',
          id: 'PerformanceRatingAndOwnerSignature',
        },
      ],
    }),
    getPerformanceRatingAndOwnerSignatureByUserId: builder.query<
      IPerformanceRatingAndOwnerSignature,
      number | void
    >({
      query: (id) => ({
        url: `/get-performance-rating-and-owner-signature-by-user-id/`,
        method: 'POST',
        body: {
          userId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [
        {
          type: 'PerformanceRatingAndOwnerSignature',
          id: 'PerformanceRatingAndOwnerSignature',
        },
      ],
    }),
  }),
});

export const {
  useCreatePerformanceRatingAndOwnerSignatureMutation,
  useUpdatePerformanceRatingAndOwnerSignatureMutation,
  useGetPerformanceRatingAndOwnerSignatureQuery,
  useGetPerformanceRatingAndOwnerSignatureByUserQuery,
  useGetPerformanceRatingAndOwnerSignatureByUserIdQuery,
  useDeletePerformanceRatingAndOwnerSignatureMutation,
} = performanceRatingAndOwnerSignatureApi;
