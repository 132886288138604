import {ICompany, IMember} from '../../../models';
import {IAbstract} from '../abstract';

export enum DatTextChoices {
  DAY_ONE = 'Day 1',
  DAY_TWO = 'Day 2',
  DAY_THREE = 'Day 3',
  DAY_FOUR = 'Day 4',
  DAY_FIVE = 'Day 5',
  DAY_SIX = 'Day 6',
  DAY_SEVEN = 'Day 7',
}

export interface ITotalHoursDto {
  total_hours: number;
  record_of_duty_status: boolean;
  drivers_name: string;
  drivers_license_number: string;
  company: ICompany;
  member: IMember;
}

export interface ITotalHours extends ITotalHoursDto, IAbstract {}

export interface IHoursWorkedDto {
  date: string;

  hours_worked: number | string;

  day: DatTextChoices;

  total_hours: ITotalHours;

  company: ICompany;

  member: IMember;
}

export interface IHoursWorked extends IHoursWorkedDto, IAbstract {}
