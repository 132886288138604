import { DeleteForeverRounded } from '@mui/icons-material';
import { Button, IconButton, TableBody, TableCell, TableRow, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { KTSVG } from '../../../compliancemasters/helpers';
import TableContainer from '../../../compliancemasters/partials/widgets/tables/components/TableContainer';
import TableContainerHeader from '../../../compliancemasters/partials/widgets/tables/components/TableContainerHeader';
import TableContainerHeaderTitle from '../../../compliancemasters/partials/widgets/tables/components/TableContainerHeaderTitle';
import TableHeader from '../../../compliancemasters/partials/widgets/tables/components/TableHeader';
import TableHeaderColumn from '../../../compliancemasters/partials/widgets/tables/components/TableHeaderColumn';
import TableWrapper from '../../../compliancemasters/partials/widgets/tables/components/TableWrapper';
import {
    ITrainingResourcesDto,
    TrainingResourcesFileType,
    deleteTrainingResource,
    downloadFile,
    getTrainingResources
} from '../../../shared/client/training-resources';
import { useAuth } from '../../modules/auth';
import NewFileModal from './NewFileModal';

const TrainingTable = () => {
    const [open, setOpen] = useState(false);
    const [resource, setResource] = useState<ITrainingResourcesDto | undefined>(undefined);
    const { currentUser } = useAuth();
    const { data, refetch } = useQuery<ITrainingResourcesDto[]>('training-resources', () => getTrainingResources(), {
        refetchOnWindowFocus: true,
        refetchInterval: 1000 * 10
    });

    const deleteTrainingMutation = useMutation(deleteTrainingResource, {
        onSuccess: () => {
            toast.success('Training Resource Deleted');
            refetch();
        }
    });

    function handleDownloadFile(id: number) {
        downloadFile(id).then((res) => {
            window.open(res.url, '_blank');
        });
    }

    return (
        <>
            <TableWrapper>
                <TableContainerHeader>
                    <TableContainerHeaderTitle title="Training Resources" />
                    {['Admin', 'SuperAdmin'].includes(currentUser?.role!) && (
                        <div
                            className="btn btn-sm btn-light-primary d-inline-flex align-items-center"
                            onClick={() => setOpen(true)}
                        >
                            <KTSVG path="media/icons/duotune/arrows/arr075.svg" className="svg-icon-3" />
                            Add Resource
                        </div>
                    )}
                </TableContainerHeader>
                <TableContainer>
                    <TableHeader>
                        <TableHeaderColumn>Title</TableHeaderColumn>
                        <TableHeaderColumn>Desc.</TableHeaderColumn>
                        <TableHeaderColumn>Resource Type</TableHeaderColumn>
                        <TableHeaderColumn>Link</TableHeaderColumn>
                        <TableHeaderColumn> </TableHeaderColumn>
                        <TableHeaderColumn> </TableHeaderColumn>
                    </TableHeader>
                    <TableBody>
                        {data?.map((resource) => (
                            <TableRow key={resource.id}>
                                <TableCell>{resource.title}</TableCell>
                                <TableCell>{resource.description}</TableCell>
                                <TableCell>{resource.training_type}</TableCell>
                                <TableCell
                                    sx={{
                                        cursor: 'pointer',
                                        color: 'blue'
                                    }}
                                    onClick={() => {
                                        if (resource.training_type === TrainingResourcesFileType.FILE) {
                                            handleDownloadFile(resource.id);
                                        } else {
                                            window.open(resource.link, '_blank');
                                        }
                                    }}
                                >
                                    {resource.training_type === TrainingResourcesFileType.LINK
                                        ? resource.link
                                        : resource.file_name}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        cursor: 'pointer',
                                        color: 'blue'
                                    }}
                                >
                                    <Button
                                        size="small"
                                        variant="contained"
                                        color="info"
                                        onClick={() => {
                                            setResource(resource);
                                            setOpen(true);
                                        }}
                                    >
                                        Update Resource
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <Tooltip title="Delete Training Resource">
                                        <IconButton
                                            onClick={(e) => {
                                                const res = window.confirm(
                                                    'Are you sure you want to delete this resource?'
                                                );
                                                if (res) {
                                                    deleteTrainingMutation.mutate(resource.id);
                                                }
                                            }}
                                        >
                                            <DeleteForeverRounded color="error" fontSize="large" />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </TableContainer>
            </TableWrapper>
            <NewFileModal open={open} setOpen={setOpen} onAdd={refetch} resource={resource} />
        </>
    );
};

export default TrainingTable;
