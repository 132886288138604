import { Button, Grid, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { useAuth } from '../../../app/modules/auth';
import { IMember } from '../../../models';
import {
    IApplicantQuestions,
    IApplicantQuestionsDto
} from '../../../store/interfaces/EmploymentHistory/applicant-questions';
import {
    useCreateApplicantQuestionsMutation,
    useDeleteApplicantQuestionsMutation,
    useGetApplicantQuestionsByUserIdQuery,
    useUpdateApplicantQuestionsMutation
} from '../../../store/reducers/EmploymentHistory/applicant-questions';
import { useThemeMode } from '../../partials';

const initialValues: Partial<IApplicantQuestionsDto> = {
    states_operated: '',
    special_forces: '',
    awards: '',
    experiences: '',
    any_other_courses: '',
    equipment: ''
};

const validationSchema = Yup.object({
    states_operated: Yup.string().required('Required'),
    special_forces: Yup.string().required('Required'),
    awards: Yup.string().required('Required'),
    experiences: Yup.string().required('Required'),
    any_other_courses: Yup.string().required('Required'),
    equipment: Yup.string().required('Required')
});

const ApplicantQuestions = ({ member, isDriverFile }: { member?: IMember; isDriverFile?: boolean }) => {
    const { mode } = useThemeMode();
    const { currentUser } = useAuth();
    const { data } = useGetApplicantQuestionsByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnReconnect: true,
        refetchOnFocus: true
    });

    const [createApplicantQuestions, { isLoading: isCreating }] = useCreateApplicantQuestionsMutation();

    const [updateApplicantQuestions, { isLoading: isUpdating }] = useUpdateApplicantQuestionsMutation();

    const [deleteApplicantQuestions] = useDeleteApplicantQuestionsMutation();

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (data) {
                // @ts-ignore
                await updateApplicantQuestions({
                    // @ts-ignore
                    ...values
                });
            } else {
                // @ts-ignore
                await createApplicantQuestions({
                    // @ts-ignore
                    ...values,
                    // @ts-ignore
                    member: member?.id,
                    // @ts-ignore
                    company: member?.company?.id
                });
            }
        },
        validateOnBlur: true,
        validateOnChange: true,
        validate: (values) => {
            const errors: Partial<IApplicantQuestions> = {};
            if (!values.states_operated) {
                errors.states_operated = 'Required';
            }
            if (!values.special_forces) {
                errors.special_forces = 'Required';
            }
            if (!values.awards) {
                errors.awards = 'Required';
            }
            if (!values.experiences) {
                errors.experiences = 'Required';
            }
            if (!values.any_other_courses) {
                errors.any_other_courses = 'Required';
            }
            if (!values.equipment) {
                errors.equipment = 'Required';
            }
        }
    });

    useEffect(() => {
        if (data) {
            formik.setValues(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <div
            style={{
                padding: 10,
                margin: 10
            }}
        >
            <Typography
                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: 'center' }}
                variant="h4"
                gutterBottom
                component="h4"
            >
                Applicant Questions
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        List all states operated in for the past 5 years (if none, type none):
                    </Typography>
                    <TextField
                        fullWidth
                        id="states_operated"
                        placeholder="States Operated"
                        size="small"
                        variant="outlined"
                        {...formik.getFieldProps('states_operated')}
                        multiline
                        rows={4}
                        className={
                            formik.touched.states_operated && Boolean(formik.errors.states_operated) ? 'green' : 'red'
                        }
                        error={formik.touched.states_operated && Boolean(formik.errors.states_operated)}
                        helperText={formik.touched.states_operated && formik.errors.states_operated}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.states_operated && Boolean(formik.errors.states_operated)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.states_operated && Boolean(formik.errors.states_operated)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.states_operated && Boolean(formik.errors.states_operated)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        List the special courses or training that will help you as a driver (if none, type none):
                    </Typography>
                    <TextField
                        fullWidth
                        id="special_forces"
                        placeholder="Special Courses or Training"
                        size="small"
                        variant="outlined"
                        {...formik.getFieldProps('special_forces')}
                        multiline
                        rows={4}
                        className={
                            formik.touched.special_forces && Boolean(formik.errors.special_forces) ? 'green' : 'red'
                        }
                        error={formik.touched.special_forces && Boolean(formik.errors.special_forces)}
                        helperText={formik.touched.special_forces && formik.errors.special_forces}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.special_forces && Boolean(formik.errors.special_forces)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.special_forces && Boolean(formik.errors.special_forces)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.special_forces && Boolean(formik.errors.special_forces)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        List any safe driving awards you hold and from who (if none, type none)
                    </Typography>
                    <TextField
                        fullWidth
                        id="awards"
                        placeholder="Awards"
                        size="small"
                        variant="outlined"
                        {...formik.getFieldProps('awards')}
                        multiline
                        rows={4}
                        className={formik.touched.awards && Boolean(formik.errors.awards) ? 'green' : 'red'}
                        error={formik.touched.awards && Boolean(formik.errors.awards)}
                        helperText={formik.touched.awards && formik.errors.awards}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.awards && Boolean(formik.errors.awards) ? 'red' : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.awards && Boolean(formik.errors.awards) ? 'red' : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.awards && Boolean(formik.errors.awards) ? 'red' : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        State any trucking, transportation, or other experiences that may help in your work for this
                        company (if none, type none):
                    </Typography>
                    <TextField
                        fullWidth
                        id="experiences"
                        placeholder="Other Experiences"
                        size="small"
                        variant="outlined"
                        {...formik.getFieldProps('experiences')}
                        multiline
                        rows={4}
                        className={formik.touched.experiences && Boolean(formik.errors.experiences) ? 'green' : 'red'}
                        error={formik.touched.experiences && Boolean(formik.errors.experiences)}
                        helperText={formik.touched.experiences && formik.errors.experiences}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.experiences && Boolean(formik.errors.experiences)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.experiences && Boolean(formik.errors.experiences)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.experiences && Boolean(formik.errors.experiences)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        List any other courses or training not already stated in this application (if none, type none):
                    </Typography>
                    <TextField
                        fullWidth
                        id="any_other_courses"
                        placeholder="Other Courses"
                        size="small"
                        variant="outlined"
                        {...formik.getFieldProps('any_other_courses')}
                        multiline
                        rows={4}
                        className={
                            formik.touched.any_other_courses && Boolean(formik.errors.any_other_courses)
                                ? 'green'
                                : 'red'
                        }
                        error={formik.touched.any_other_courses && Boolean(formik.errors.any_other_courses)}
                        helperText={formik.touched.any_other_courses && formik.errors.any_other_courses}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.any_other_courses && Boolean(formik.errors.any_other_courses)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.any_other_courses && Boolean(formik.errors.any_other_courses)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.any_other_courses && Boolean(formik.errors.any_other_courses)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        List any special equipment or technical materials you can work with (other than those already
                        shown, if none, type none)
                    </Typography>
                    <TextField
                        fullWidth
                        id="equipment"
                        placeholder="Equipment"
                        size="small"
                        variant="outlined"
                        {...formik.getFieldProps('equipment')}
                        multiline
                        rows={4}
                        className={formik.touched.equipment && Boolean(formik.errors.equipment) ? 'green' : 'red'}
                        error={formik.touched.equipment && Boolean(formik.errors.equipment)}
                        helperText={formik.touched.equipment && formik.errors.equipment}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.equipment && Boolean(formik.errors.equipment) ? 'red' : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.equipment && Boolean(formik.errors.equipment) ? 'red' : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.equipment && Boolean(formik.errors.equipment) ? 'red' : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                {(!data || (data && currentUser && ['Admin', 'SuperAdmin'].includes(currentUser?.role))) &&
                !isDriverFile ? (
                    <Grid item xs={12} sm={12}>
                        <Button
                            fullWidth
                            disabled={isCreating || isUpdating || !!member?.terminated_at}
                            onClick={() => formik.handleSubmit()}
                            variant="contained"
                            color="primary"
                        >
                            Save
                        </Button>
                        {currentUser?.role !== 'Admin' && currentUser?.role !== 'SuperAdmin' && (
                            <Typography
                                sx={{ color: 'red', textAlign: 'center', mt: 2 }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Once you click save, you will not be able to edit this form.
                            </Typography>
                        )}
                    </Grid>
                ) : null}
                {data && currentUser?.role === 'SuperAdmin' && (
                    <Grid item xs={12} sm={12}>
                        <Button
                            fullWidth
                            disabled={!!member?.terminated_at}
                            onClick={() => {
                                const res = window.confirm('Are you sure you want to delete this form?');
                                if (res && data) {
                                    deleteApplicantQuestions(data.id);
                                }
                            }}
                            variant="contained"
                            color="primary"
                        >
                            Delete Form
                        </Button>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default ApplicantQuestions;
