import {certificateOfRoadTestApi} from './certificate-of-road-test';
import {certificateOfRoadTestProgress} from './progress';

export const CertificateOfRoadTestReducers = {
  [certificateOfRoadTestApi.reducerPath]: certificateOfRoadTestApi.reducer,
  [certificateOfRoadTestProgress.reducerPath]: certificateOfRoadTestProgress.reducer,
};

export const CertificateOfRoadTestMiddleware = [
  certificateOfRoadTestApi.middleware,
  certificateOfRoadTestProgress.middleware,
];
