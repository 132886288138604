import { MoreVertRounded } from '@mui/icons-material';
import { Grid, IconButton, Menu, MenuItem, TextField, Tooltip } from '@mui/material';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { KTSVG as Ktsvg } from '../../../compliancemasters/helpers';
import TableContainer from '../../../compliancemasters/partials/widgets/tables/components/TableContainer';
import TableContainerHeader from '../../../compliancemasters/partials/widgets/tables/components/TableContainerHeader';
import TableContainerHeaderTitle from '../../../compliancemasters/partials/widgets/tables/components/TableContainerHeaderTitle';
import TableHeader from '../../../compliancemasters/partials/widgets/tables/components/TableHeader';
import TableHeaderColumn from '../../../compliancemasters/partials/widgets/tables/components/TableHeaderColumn';
import TableWrapper from '../../../compliancemasters/partials/widgets/tables/components/TableWrapper';
import MemberRow from '../../../compliancemasters/partials/widgets/tables/MemberTable/components/MemberRow';
import NewMemberModalButton from '../../../compliancemasters/partials/widgets/tables/MemberTable/components/NewMemberModalButton';
import InviteDriversModal from '../../../compliancemasters/partials/widgets/tables/MemberTable/InviteDriversModal';
import { ICompany, IMember } from '../../../models';
import { getSubscriptionPlans } from '../../../shared';
import { useAuth } from '../../modules/auth';
import { StripeProduct } from '../../modules/models';
import PreventableMaintenanceContainer from '../preventableMaintenance/PreventableMaintenanceContainer';
import UpdateMemberModal from './UpdateMemberModal';

enum Filter {
    Active = 'Active',
    Terminated = 'Terminated'
}

const CompanyDetails = () => {
    const [filter, setFilter] = useState<Filter>(Filter.Active);
    const [userFilterInput, setUserFilterInput] = useState<string>('');
    const { id } = useParams<{ id: string }>();
    const [company, setCompany] = useState<ICompany>();
    const [loading, setLoading] = useState<boolean>(true);
    const { getCompany } = useAuth();
    const [subscriptions, setSubscriptions] = useState<StripeProduct[]>([]);
    const [filteredMembers, setFilteredMembers] = useState<IMember[]>([]);
    const [owner, setOwner] = useState<IMember>();
    const [member, setMember] = useState<IMember>();
    const [groupedData, setGroupedData] = useState<IMember[]>([]);
    const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [updateMemberModalOpen, setUpdateMemberModalOpen] = useState(false);
    const [inviteDriversModalOpen, setInviteDriversModalOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const userMenuOpen = Boolean(userMenuAnchorEl);

    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleUserMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
        setUserMenuAnchorEl(event.currentTarget);
    };

    const handleUserMenuClose = () => {
        setUserMenuAnchorEl(null);
    };

    useEffect(() => {
        const ownerAccount = filteredMembers?.find((x: IMember) => x.user.role === 'Owner');
        setOwner(ownerAccount);
    }, [filteredMembers]);

    const getSubs = useCallback(async () => {
        getSubscriptionPlans().then((res) => {
            const optionList: StripeProduct[] = [];
            for (const pkg of company?.package ?? []) {
                const option = res.data.find((x: any) => x.product_id === pkg.id);
                if (option) {
                    optionList.push(option);
                }
            }
            setSubscriptions(optionList);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company, company?.members]);

    useEffect(() => {
        getSubs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getSubs]);

    useEffect(() => {
        getCompany(Number(id)).then((res: ICompany) => {
            setCompany(res);
            setLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (company?.members) {
            if (filter === Filter.Active) {
                setFilteredMembers(company?.members.filter((x: IMember) => !x.terminated_at));
            } else if (filter === Filter.Terminated) {
                setFilteredMembers(company?.members.filter((x: IMember) => x.terminated_at));
            }
        }
    }, [filter, company?.members]);

    useEffect(() => {
        if (company?.members) {
            if (userFilterInput.length > 0) {
                setFilteredMembers(
                    company?.members.filter((x: IMember) =>
                        `${x?.user?.first_name} ${x?.user?.last_name}`
                            .toLowerCase()
                            .includes(userFilterInput.toLowerCase())
                    )
                );
            } else {
                setFilteredMembers(company?.members);
            }
        }
    }, [userFilterInput, company?.members]);

    useEffect(() => {
        const groupedData = filteredMembers.reduce((groups: any, item) => {
            const product_id = item.product_id;

            if (!groups[product_id]) {
                groups[product_id] = [];
            }

            groups[product_id].push(item);
            return groups;
        }, {});

        // Sort each group by date_joined in descending order
        for (const key in groupedData) {
            if (groupedData.hasOwnProperty(key)) {
                groupedData[key].sort((a: any, b: any) => {
                    const dateA = new Date(a.user.created_at).getTime();
                    const dateB = new Date(b.user.created_at).getTime();
                    return dateB - dateA;
                });
            }
        }
        const members = Object.values(groupedData).flat() as IMember[];
        setGroupedData(members.filter((x: IMember) => (filter === Filter.Active ? !x.terminated_at : x.terminated_at)));
    }, [filteredMembers, filter]);

    return (
        <div className="row gy-5 g-xl-8">
            <div className="col-xl-12">
                {!loading && company ? (
                    <TableWrapper>
                        <TableContainerHeader>
                            <TableContainerHeaderTitle title="Employees" />
                        </TableContainerHeader>
                        <Grid container spacing={2} p={2}>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <TextField
                                    fullWidth
                                    value={filter}
                                    variant="outlined"
                                    size="small"
                                    select
                                    helperText="Filter Employees"
                                >
                                    {Object.values(Filter).map((option: Filter) => (
                                        <MenuItem key={option} value={option} onClick={() => setFilter(option)}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <TextField
                                    fullWidth
                                    value={userFilterInput}
                                    onChange={(e) => setUserFilterInput(e.target.value)}
                                    variant="outlined"
                                    size="small"
                                    placeholder="Search for Employees"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4.5} lg={3.5}>
                                {filter !== Filter.Terminated ? (
                                    <Tooltip title="More Options">
                                        <IconButton onClick={handleClick}>
                                            <MoreVertRounded />
                                        </IconButton>
                                    </Tooltip>
                                ) : null}
                            </Grid>
                        </Grid>
                        {filter !== Filter.Terminated ? <NewMemberModalButton /> : null}
                        {/* begin::Body */}
                        <TableContainer>
                            <TableHeader>
                                <TableHeaderColumn>Employee</TableHeaderColumn>
                                <TableHeaderColumn>Driver Status / Screened</TableHeaderColumn>
                                <TableHeaderColumn>Date Screened</TableHeaderColumn>
                                <TableHeaderColumn>Confirmation Date</TableHeaderColumn>
                                <TableHeaderColumn>Status</TableHeaderColumn>
                                <TableHeaderColumn>Subscription</TableHeaderColumn>
                                <TableHeaderColumn end>Form Progress</TableHeaderColumn>
                            </TableHeader>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                                {owner && (
                                    <MemberRow
                                        member={owner}
                                        key={owner?.id}
                                        product_name={
                                            subscriptions.find((x: any) => x.product_id === owner?.product_id)?.name
                                        }
                                        onActionComplete={() =>
                                            getCompany(Number(id)).then((res: ICompany) => {
                                                setCompany(res);
                                                setLoading(false);
                                            })
                                        }
                                        setMember={setMember}
                                        setMenuOpen={handleUserMenuClick}
                                    />
                                )}
                                {groupedData
                                    .filter((x) => x?.user?.role !== 'Owner')
                                    .sort(
                                        (a, b) =>
                                            new Date(b.created_at!).getMinutes() - new Date(a.created_at!).getMinutes()
                                    )
                                    ?.map((member: IMember) => {
                                        return (
                                            <MemberRow
                                                member={member}
                                                key={member.id}
                                                product_name={
                                                    subscriptions.find((x: any) => x.product_id === member.product_id)
                                                        ?.name
                                                }
                                                onActionComplete={() =>
                                                    getCompany(Number(id)).then((res: ICompany) => {
                                                        setCompany(res);
                                                        setLoading(false);
                                                    })
                                                }
                                                setMember={setMember}
                                                setMenuOpen={handleUserMenuClick}
                                            />
                                        );
                                    })}
                            </tbody>
                        </TableContainer>
                        {/* begin::Body */}
                    </TableWrapper>
                ) : null}
                {loading ? <div className="spinner spinner-primary spinner-lg mr-15"></div> : null}
            </div>
            <Menu anchorEl={userMenuAnchorEl} open={userMenuOpen} onClose={handleUserMenuClose}>
                <MenuItem
                    onClick={() => {
                        handleUserMenuClose();
                        setUpdateMemberModalOpen(true);
                    }}
                >
                    <div>
                        <Ktsvg path="media/icons/duotune/arrows/arr075.svg" className="svg-icon-3" />
                        Update Profile
                    </div>
                </MenuItem>
            </Menu>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem onClick={handleClose}>
                    <div data-bs-toggle="modal" data-bs-target="#kt_modal_1">
                        <Ktsvg path="media/icons/duotune/arrows/arr075.svg" className="svg-icon-3" />
                        New Employee
                    </div>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleClose();
                        setInviteDriversModalOpen(true);
                    }}
                >
                    <Ktsvg path="media/icons/duotune/arrows/arr075.svg" className="svg-icon-3" />
                    Invite Drivers
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleClose();
                    }}
                    disabled
                >
                    <Ktsvg path="media/icons/duotune/arrows/arr075.svg" className="svg-icon-3" />
                    New Bulk Request
                </MenuItem>
            </Menu>
            <InviteDriversModal open={inviteDriversModalOpen} setOpen={setInviteDriversModalOpen} />
            <UpdateMemberModal open={updateMemberModalOpen} setOpen={setUpdateMemberModalOpen} member={member} />
            <TableWrapper>
                <PreventableMaintenanceContainer />
            </TableWrapper>
        </div>
    );
};

export default CompanyDetails;
