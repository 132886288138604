import { TableCell } from '@mui/material';
import { FC, useEffect, useState } from 'react';

interface IProps {
    date: Date | string;
}

function formatDateToMMDDYYYY(dateString: string): string {
    const date = new Date(dateString);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
}

const DateComponent: FC<IProps> = ({ date }) => {
    const [formattedDate, setFormattedDate] = useState<string>('');

    useEffect(() => {
        if (typeof date === 'string') {
            setFormattedDate(formatDateToMMDDYYYY(date));
            return;
        }

        const dateObj = new Date(date);
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
        const day = dateObj.getDate().toString().padStart(2, '0');
        const year = dateObj.getFullYear();
        const formattedDate = `${month}-${day}-${year}`;
        setFormattedDate(formattedDate);
    }, [date]);

    return <TableCell>{formattedDate}</TableCell>;
};

export default DateComponent;
