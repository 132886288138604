/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useState } from 'react';
import ViewDriverFileButton from '../../../../compliancemasters/partials/driver-file-pdf/ViewDriverFileButton';
import ViewDriverFileModel from '../../../../compliancemasters/partials/driver-file-pdf/ViewDriverFileModel';
import TableContainer from '../../../../compliancemasters/partials/widgets/tables/components/TableContainer';
import TableContainerHeader from '../../../../compliancemasters/partials/widgets/tables/components/TableContainerHeader';
import TableContainerHeaderTitle from '../../../../compliancemasters/partials/widgets/tables/components/TableContainerHeaderTitle';
import TableHeader from '../../../../compliancemasters/partials/widgets/tables/components/TableHeader';
import TableHeaderColumn from '../../../../compliancemasters/partials/widgets/tables/components/TableHeaderColumn';
import TableWrapper from '../../../../compliancemasters/partials/widgets/tables/components/TableWrapper';
import { IMember } from '../../../../models';
import { getCompanyAddOns } from '../../../../shared';
import { useAuth } from '../../auth';
import { StripeProduct } from '../../models';
import AnnualReviewOfDrivingRecords from './AnnualReviewOfRoadRecord';
import CertificateOfRoadTest from './CertificateOfRoadTest';
import CertificateOfCompliance from './CertOfCompliance';
import DachCertifiedReceipt from './DachCertifiedReceipt';
import DachClearingHouseQueries from './DachClearingHouseQueries';
import DriverApplicationForEmployment from './DriverApplicationForEmployment';
import DriversHandbookReceipt from './DriversHandbookReceipt';
import DriverStatementDutyHours from './DriverStatementDutyHours';
import EmploymentHistory from './EmploymentHistory';
import MedicalExaminerRegistryVerification from './MedicalExaminerRegistryVerification';
import PrevEmployeeSafetyPerformanceHistory from './PrevEmployeeSafetyPerformanceHistory';
import PrevPreEmploymentDachTestStatement from './PrevPreEmploymentDachTestStatement';
import RecordOfRoadTest from './RecordOfRoadTest';
import RequestForCheckDrivingRecords from './RequestForCheckDrivingRecords';
import WrittenDrugAlcoholPolicy from './WrittenDrugAlcoholPolicy';

const NewFormTable = ({
    member,
    subscription,
    pageLoading
}: {
    member: IMember;
    subscription: StripeProduct;
    pageLoading?: boolean;
}) => {
    const { currentUser } = useAuth();
    const [driverFileOpen, setDriverFileOpen] = useState<boolean>(false);
    const [addons, setAddons] = useState<StripeProduct[]>([]);
    const [showWrittenPolicy, setShowWrittenPolicy] = useState<boolean>(false);

    const getAddOns = useCallback(async () => {
        const res = await getCompanyAddOns(
            currentUser && ['Admin', 'SuperAdmin'].includes(currentUser?.role) ? Number(member?.company?.id) : undefined
        );
        setAddons(res);
    }, [currentUser, member]);

    useEffect(() => {
        getAddOns();
    }, [getAddOns]);

    useEffect(() => {
        if (
            addons.some((addon) => addon.name === 'D&A Written Policy') ||
            subscription?.name === 'Master Package' ||
            subscription?.base_product === 'Master Package'
        ) {
            setShowWrittenPolicy(true);
        } else {
            setShowWrittenPolicy(false);
        }
    }, [addons, subscription]);

    return (
        <TableWrapper>
            <TableContainerHeader>
                <TableContainerHeaderTitle title="Forms Overview" />
                {member?.pre_employment_screened && (
                    <ViewDriverFileButton memberId={member?.id!} setOpen={setDriverFileOpen} />
                )}
            </TableContainerHeader>
            <TableContainer>
                <TableHeader>
                    <TableHeaderColumn>Form</TableHeaderColumn>
                    <TableHeaderColumn>Progress</TableHeaderColumn>
                    <TableHeaderColumn>Status</TableHeaderColumn>
                    <TableHeaderColumn>{''}</TableHeaderColumn>
                    <TableHeaderColumn>Actions</TableHeaderColumn>
                </TableHeader>
                <tbody>
                    {!['Drug and Alcohol Package', 'Driver File'].includes(subscription?.name) ? (
                        <>
                            {showWrittenPolicy ? <WrittenDrugAlcoholPolicy id={Number(member?.id)} /> : null}
                            <DriverApplicationForEmployment id={Number(member?.id)} />
                            <EmploymentHistory id={Number(member?.id)} />
                            {!member?.pre_employment_screened ||
                            (member?.pre_employment_screened && member?.pre_employment_investigation.length > 0) ? (
                                <PrevEmployeeSafetyPerformanceHistory
                                    userId={member?.user?.id}
                                    id={Number(member?.id)}
                                />
                            ) : null}
                            {member?.pre_employment_screened ? (
                                <CertificateOfCompliance id={Number(member?.id)} />
                            ) : null}
                            {member?.pre_employment_screened ? (
                                <RequestForCheckDrivingRecords id={Number(member?.id)} />
                            ) : null}
                            {member?.pre_employment_screened ? (
                                <DriverStatementDutyHours id={Number(member?.id)} />
                            ) : null}
                            {member?.pre_employment_screened ? (
                                <MedicalExaminerRegistryVerification id={Number(member?.id)} />
                            ) : null}
                            {member?.pre_employment_screened ? (
                                <DriversHandbookReceipt id={Number(member?.id)} />
                            ) : null}
                            {currentUser && currentUser.role !== 'Member' ? (
                                <RecordOfRoadTest id={Number(member?.id)} />
                            ) : null}

                            {currentUser && currentUser.role !== 'Member' && (
                                <CertificateOfRoadTest id={Number(member?.id)} />
                            )}
                        </>
                    ) : null}
                    {member?.pre_employment_screened ||
                    (!member?.pre_employment_screened && ['Drug and Alcohol Package'].includes(subscription?.name)) ? (
                        <DachClearingHouseQueries id={Number(member?.id)} />
                    ) : null}
                    {!['Drug and Alcohol Package', 'Driver File'].includes(subscription?.name) &&
                    member?.pre_employment_screened ? (
                        <>
                            <PrevPreEmploymentDachTestStatement id={Number(member?.id)} />
                            <DachCertifiedReceipt id={Number(member?.id)} />

                            {currentUser && currentUser.role !== 'Member' && (
                                <AnnualReviewOfDrivingRecords id={Number(member?.id)} />
                            )}
                        </>
                    ) : null}
                </tbody>
            </TableContainer>
            <ViewDriverFileModel open={driverFileOpen} setOpen={setDriverFileOpen} memberId={member?.id} />
        </TableWrapper>
    );
};

export default NewFormTable;
