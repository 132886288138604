import client from '../Client';

export const changeStringLast4 = (inputString: string, replacementChar: string = '*') => {
  if (inputString.length <= 4) {
    return replacementChar.repeat(inputString.length);
  }

  const modifiedPart = replacementChar.repeat(inputString.length - 4);
  const last4Characters = inputString.substring(inputString.length - 4);

  return modifiedPart + last4Characters;
};

export interface CreateAnswerBody {
  question_id: string | number;
  answer: string;
}
export function getForm(id: string | number) {
  return client(`form/api/form/${id}`);
}

export function createAnswer(questionId: string | number, answer: string) {
  return client(`form/api/answer/`, {
    body: {
      question_id: questionId,
      answer: answer,
    } as CreateAnswerBody,
  });
}

export function updateAnswer(answerId: string | number, answer: string) {
  return client(`form/api/answer/${answerId}`, {
    body: {
      answer: answer,
    },
  });
}

export function repeatFormElementGroup(elementGroupId: string | number) {
  return client(`form/api/form/repeat-element-group/`, {
    body: {
      element_group_id: elementGroupId,
    },
  });
}

export function deleteFormElementGroup(elementGroupId: string | number) {
  return client(`form/api/form/delete-element-group/`, {
    body: {
      element_group_id: elementGroupId,
    },
  });
}

export function massUpdateForm(body: Record<string, unknown>) {
  return client(`form/api/form/mass-update/`, {
    body: body,
  });
}
