import { Button, Grid, InputLabel, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import * as Papa from 'papaparse';
import { forwardRef, ReactElement, Ref, useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { ICompany } from '../../../models';
import { getCompanies, getSubscriptionPlans } from '../../../shared';
import { StripeProduct } from '../../modules/models';
import { IDriverUploadData } from './DriverBulkUpload';

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement<any, any>;
    },
    ref: Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IUploadResult {
    'No.': string;
    FirstName: string;
    LastName: string;
    Email: string;
    PhoneNumber: string;
    DriversLicenseNumber: string;
    SocialSecurityNumber: string;
    DateOfBirth: string;
    PackageSignedUpWith: string;
}

interface IProps {
    open: boolean;
    setOpen: (value: boolean) => void;
    setUploadList: (value: IDriverUploadData[]) => void;
    clearDriverList: () => void;
    invitationId: number;
}

export default function BulkUploadModal({
    clearDriverList,
    open,
    setOpen,
    setUploadList,
    invitationId
}: Readonly<IProps>) {
    const [uploadFile, setUploadFile] = useState<File | null>(null);
    const handleClose = () => {
        setOpen(false);
    };
    const { data } = useQuery<{ data: StripeProduct[] }>('companyPackages', async () => getSubscriptionPlans());
    const { data: companies = [] } = useQuery<ICompany[]>('companies', async () => getCompanies(), {
        refetchOnWindowFocus: true
    });

    const handleCancel = () => {
        clearDriverList();
        setUploadFile(null);
        setOpen(false);
        window.location.reload();
    };

    const handleUpload = () => {
        if (uploadFile) {
            Papa.parse(uploadFile, {
                header: true,
                complete: (result) => {
                    if (!data || (data && data.data.length < 1)) return toast.error('Packages loading');
                    const company = companies.find((item) => Number(item.id) === invitationId);
                    if (!company) return toast.error('Company not found');
                    const driverList: IDriverUploadData[] = [];
                    const rejectedList: IDriverUploadData[] = [];
                    const dataList = result.data as IUploadResult[];
                    for (const item of dataList) {
                        const subPackage = data?.data?.find(
                            (i) => i.name.toLowerCase() === item.PackageSignedUpWith.trim().toLowerCase()
                        );
                        if (!subPackage) return toast.error('Package not found');
                        const packageFound = company.package.find((i) => i.id === subPackage.product_id);
                        if (packageFound) {
                            driverList.push({
                                id: Number(item['No.']),
                                firstName: item.FirstName,
                                lastName: item.LastName,
                                email: item.Email,
                                phoneNumber: item.PhoneNumber,
                                driversLicenseNumber: item.DriversLicenseNumber,
                                socialSecurityNumber: item.SocialSecurityNumber,
                                dateOfBirth: new Date(item.DateOfBirth),
                                productId: subPackage.product_id
                            });
                        } else {
                            rejectedList.push({
                                id: Number(item['No.']),
                                firstName: item.FirstName,
                                lastName: item.LastName,
                                email: item.Email,
                                phoneNumber: item.PhoneNumber,
                                driversLicenseNumber: item.DriversLicenseNumber,
                                socialSecurityNumber: item.SocialSecurityNumber,
                                dateOfBirth: new Date(item.DateOfBirth),
                                productId: subPackage.product_id
                            });
                        }
                    }
                    setUploadList(driverList);
                    if (rejectedList.length > 0) {
                        toast.error('Some drivers were not processed due to package mismatch');
                    }
                    setOpen(false);
                }
            });
        }
    };

    return (
        <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle sx={{ textAlign: 'center' }}>Upload Driver List</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        <InputLabel htmlFor="upload-file">Upload File</InputLabel>
                        <TextField
                            type="file"
                            size="small"
                            fullWidth
                            onClick={() => console.log('clicked')}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (e.target.files) {
                                    setUploadFile(e.target.files[0]);
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        <Button
                            disabled={companies?.length < 1}
                            color="success"
                            onClick={handleUpload}
                            fullWidth
                            variant="contained"
                            size="small"
                        >
                            Review Upload List
                        </Button>
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        <Button onClick={handleCancel} color="error" fullWidth variant="contained" size="small">
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
