import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {IProgress} from '../../interfaces/abstract';
import {
  IDrugAndAlcoholWrittenPolicy,
  IDrugAndAlcoholWrittenPolicyDto,
} from '../../interfaces/DrugAlcoholWrittenPolicy/drug-alcohol-written-policy';

export const drugAlcoholWrittenPolicyApi = createApi({
  reducerPath: 'drug_alcohol_written_policy',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/drug-and-alcohol-written-policy`,
  }),
  tagTypes: ['DrugAlcoholWrittenPolicy'],
  keepUnusedDataFor: 60 * 10, // 10 minutes
  endpoints: (builder) => ({
    createDrugAlcoholWrittenPolicy: builder.mutation<
      IDrugAndAlcoholWrittenPolicy,
      IDrugAndAlcoholWrittenPolicyDto
    >({
      query: (body) => ({
        url: '/',
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'DrugAlcoholWrittenPolicy', id: 'DrugAlcoholWrittenPolicy'}],
    }),
    updateDrugAlcoholWrittenPolicy: builder.mutation<
      IDrugAndAlcoholWrittenPolicy,
      IDrugAndAlcoholWrittenPolicy
    >({
      query: (body) => ({
        url: `/${body.id}/`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'DrugAlcoholWrittenPolicy', id: 'DrugAlcoholWrittenPolicy'}],
    }),
    deleteDrugAlcoholWrittenPolicy: builder.mutation<void, number>({
      query: (id) => ({
        url: `/${id}/`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'DrugAlcoholWrittenPolicy', id: 'DrugAlcoholWrittenPolicy'}],
    }),
    getDrugAlcoholWrittenPolicy: builder.query<IDrugAndAlcoholWrittenPolicy, number>({
      query: (id) => ({
        url: `/get-drug-alcohol-written-policy/`,
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'DrugAlcoholWrittenPolicy', id: 'DrugAlcoholWrittenPolicy'}],
    }),
    getDrugAlcoholWrittenPolicyByUser: builder.query<IDrugAndAlcoholWrittenPolicy, void>({
      query: () => ({
        url: `/get-drug-alcohol-written-policy-by-user/`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'DrugAlcoholWrittenPolicy', id: 'DrugAlcoholWrittenPolicy'}],
    }),
    getCompanyDrugAlcoholWrittenPolicy: builder.query<IDrugAndAlcoholWrittenPolicy[], void>({
      query: () => ({
        url: `/get-drug-alcohol-written-policy-by-company/`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'DrugAlcoholWrittenPolicy', id: 'DrugAlcoholWrittenPolicy'}],
    }),
    getDrugAlcoholWrittenPolicyByUserId: builder.query<IDrugAndAlcoholWrittenPolicy, number>({
      query: (id) => ({
        url: `/get-drug-alcohol-written-policy-by-user-id/`,
        method: 'POST',
        body: {
          userId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'DrugAlcoholWrittenPolicy', id: 'DrugAlcoholWrittenPolicy'}],
    }),
    getMemberDrugAlcoholWrittenPolicyProgress: builder.query<IProgress, number>({
      query: (id) => ({
        url: `/get-member-progress/`,
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'DrugAlcoholWrittenPolicy', id: 'DrugAlcoholWrittenPolicy'}],
    }),
    getDrugAlcoholWrittenPolicyProgress: builder.query<IProgress, number>({
      query: (id) => ({
        url: `/get-total-progress/`,
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'DrugAlcoholWrittenPolicy', id: 'DrugAlcoholWrittenPolicy'}],
    }),
  }),
});

export const {
  useCreateDrugAlcoholWrittenPolicyMutation,
  useUpdateDrugAlcoholWrittenPolicyMutation,
  useGetDrugAlcoholWrittenPolicyQuery,
  useGetDrugAlcoholWrittenPolicyByUserQuery,
  useGetDrugAlcoholWrittenPolicyByUserIdQuery,
  useGetMemberDrugAlcoholWrittenPolicyProgressQuery,
  useGetDrugAlcoholWrittenPolicyProgressQuery,
  useGetCompanyDrugAlcoholWrittenPolicyQuery,
  useDeleteDrugAlcoholWrittenPolicyMutation,
} = drugAlcoholWrittenPolicyApi;
