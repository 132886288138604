import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {IMiscellaneous, IMiscellaneousDto} from '../../interfaces/RecordOfRoadTest/miscellaneous';

export const miscellaneousApi = createApi({
  reducerPath: 'miscellaneous',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/miscellaneous`,
  }),
  tagTypes: ['Miscellaneous'],
  keepUnusedDataFor: 60 * 10, // 10 minutes
  endpoints: (builder) => ({
    createMiscellaneous: builder.mutation<IMiscellaneous, IMiscellaneousDto>({
      query: (body) => ({
        url: '/',
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'Miscellaneous', id: 'Miscellaneous'}],
    }),
    updateMiscellaneous: builder.mutation<IMiscellaneous, IMiscellaneous>({
      query: (body) => ({
        url: `/${body.id}/`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'Miscellaneous', id: 'Miscellaneous'}],
    }),
    deleteMiscellaneous: builder.mutation<void, number>({
      query: (id) => ({
        url: `/${id}/`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'Miscellaneous', id: 'Miscellaneous'}],
    }),
    getMiscellaneous: builder.query<IMiscellaneous, number | void>({
      query: (id) => ({
        url: `/get-miscellaneous/`,
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'Miscellaneous', id: 'Miscellaneous'}],
    }),
    getMiscellaneousByUser: builder.query<IMiscellaneous, void>({
      query: () => ({
        url: `/get-miscellaneous-by-user/`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'Miscellaneous', id: 'Miscellaneous'}],
    }),
    getMiscellaneousByUserId: builder.query<IMiscellaneous, number | void>({
      query: (id) => ({
        url: `/get-miscellaneous-by-user-id/`,
        method: 'POST',
        body: {
          userId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'Miscellaneous', id: 'Miscellaneous'}],
    }),
  }),
});

export const {
  useCreateMiscellaneousMutation,
  useUpdateMiscellaneousMutation,
  useGetMiscellaneousQuery,
  useGetMiscellaneousByUserQuery,
  useGetMiscellaneousByUserIdQuery,
  useDeleteMiscellaneousMutation,
} = miscellaneousApi;
