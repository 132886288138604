import { useState } from 'react';
import Button from '../../../../app/modules/buttons/Button';
import { IMember } from '../../../../models';
import { activateMember, terminateMember } from '../../../../shared';
import { KTSVG } from '../../../helpers';

interface IProps {
    member: IMember;
    onSubmit?: () => void;
}

export function TerminateModal({ member, onSubmit }: IProps) {
    const term = member.terminated_at ? 'Activate' : 'Terminate';
    const [terminationComplete, setTerminationComplete] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [activationComplete, setActivationComplete] = useState(false);

    function handleClick() {
        member.terminated_at ? handleActivate() : handleTerminate();
    }

    function handleTerminate() {
        terminateMember(member.id).then(() => {
            if (onSubmit) {
                setTerminationComplete(true);
                onSubmit();
            }
        });
    }

    function handleActivate() {
        activateMember(member.id).then(() => {
            if (onSubmit) {
                setActivationComplete(true);
                onSubmit();
            }
        });
    }

    const TerminationMessage = () => (
        <div className="row">
            {term === 'Terminate' ? (
                <>
                    <span className="fs-2x fw-bolder">
                        Terminating
                        <span className="text-danger">{` ${member.user.first_name} ${member.user.last_name}  `}</span>
                        will remove 1 driver from the package and change your subscription price. The driver’s profile
                        will remain inactive on the Company dashboard for 3 years. Are you sure you want to terminate?
                    </span>
                </>
            ) : (
                <>
                    <span className="fs-2x fw-bolder">
                        Activating
                        <span className={'text-success'}>
                            {` ${member.user.first_name} ${member.user.last_name}  `}
                        </span>{' '}
                        will add 1 driver to the package and change your subscription price. The driver’s profile will
                        be active on the Company dashboard. Are you sure you want to activate ?
                    </span>
                </>
            )}
        </div>
    );

    const TerminationComplete = () => (
        <div className="row">
            <span className="fs-2x fw-bolder">
                Termination Complete, please go back into the driver’s Driver Application and complete the Termination
                of Employment section
            </span>
        </div>
    );

    return (
        <div className="modal fade" id="terminate_modal" role="dialog" tabIndex={-1} aria-modal="true">
            <div className="modal-dialog modal-dialog-centered mw-650px">
                <div className="modal-content">
                    <div className="modal-header">
                        {/* begin::Modal title */}
                        <h2 className="fw-bolder">{term} Member</h2>
                        {/* end::Modal title */}

                        {/* begin::Close */}
                        <div
                            className="btn btn-icon btn-sm btn-active-icon-primary"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            style={{ cursor: 'pointer' }}
                        >
                            <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon-1" />
                        </div>
                        {/* end::Close */}
                    </div>

                    <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                        <div className="text-center">
                            <div className="mb-10">
                                {terminationComplete ? <TerminationComplete /> : <TerminationMessage />}
                                <div className="row justify-content-center pt-10">
                                    <div className="col">
                                        {!terminationComplete ? (
                                            <>
                                                {term === 'Terminate' ? (
                                                    <>
                                                        <Button onClick={handleClick}>
                                                            <span className="indicator-label">Yes</span>
                                                        </Button>
                                                        <Button
                                                            dataBsDismiss="modal"
                                                            className="btn btn-secondary ms-4"
                                                        >
                                                            <span className="indicator-label">No</span>
                                                        </Button>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Button dataBsDismiss="modal" onClick={handleClick}>
                                                            <span className="indicator-label">Yes</span>
                                                        </Button>
                                                        <Button
                                                            dataBsDismiss="modal"
                                                            className="btn btn-secondary ms-4"
                                                        >
                                                            <span className="indicator-label">No</span>
                                                        </Button>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <Button
                                                dataBsDismiss="modal"
                                                onClick={() => {
                                                    setTerminationComplete(false);
                                                }}
                                            >
                                                <span className="indicator-label">Close</span>
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
