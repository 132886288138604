import { Button, Grid, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { useAuth } from '../../../app/modules/auth';
import { IMember } from '../../../models';
import { IPSPDisclosureFormDto } from '../../../store/interfaces/PSPDisclosure/PSPDisclosure';
import { useGetDrugAlcoholWrittenPolicyByUserIdQuery } from '../../../store/reducers/DrugAlcoholWrittenPolicy/drug-alcohol-written-policy';
import {
    useCreatePSPDisclosureFormMutation,
    useDeletePSPDisclosureFormMutation,
    useGetPSPDisclosureFormByUserIdQuery,
    useUpdatePSPDisclosureFormMutation
} from '../../../store/reducers/PSPDisclosure/psp-disclosure';
import { useThemeMode } from '../../partials';

const initialValues: Partial<IPSPDisclosureFormDto> = {
    applicant_name: '',
    signature: '',
    date: ''
};

const PSPDisclosureFormSchema = Yup.object().shape({
    applicant_name: Yup.string().required('Applicant Name is required'),
    signature: Yup.string().required('Signature is required'),
    date: Yup.string().required('Date is required')
});

const PSPDisclosureForm = ({ member }: { member?: IMember }) => {
    const { mode } = useThemeMode();
    const { currentUser } = useAuth();
    const { data } = useGetPSPDisclosureFormByUserIdQuery(Number(member?.user?.id), {
        skip: !member?.id,
        refetchOnMountOrArgChange: true,
        refetchOnReconnect: true
    });

    const { data: writtenPolicy } = useGetDrugAlcoholWrittenPolicyByUserIdQuery(Number(member?.user?.id), {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnReconnect: true,
        refetchOnFocus: true
    });

    const [createPSPDisclosureForm, { isLoading: isCreating }] = useCreatePSPDisclosureFormMutation();
    const [updatePSPDisclosureForm, { isLoading: isUpdating }] = useUpdatePSPDisclosureFormMutation();
    const [deletePSPDisclosureForm] = useDeletePSPDisclosureFormMutation();

    const formik = useFormik({
        initialValues,
        validationSchema: PSPDisclosureFormSchema,
        onSubmit: (values, { resetForm }) => {
            if (data) {
                // @ts-ignore
                updatePSPDisclosureForm({
                    ...data,
                    ...values
                }).then(() => {
                    resetForm();
                });
            } else {
                // @ts-ignore
                createPSPDisclosureForm({
                    ...values,
                    company: Number(member?.company?.id),
                    member: Number(member?.id)
                }).then(() => {
                    resetForm();
                });
            }
            resetForm();
        },
        validateOnBlur: true,
        validateOnChange: true
    });

    useEffect(() => {
        if (data) {
            const utcDate = new Date(data.date);
            const year = utcDate.getFullYear();
            const month = String(utcDate.getMonth() + 1).padStart(2, '0');
            const day = String(utcDate.getDate()).padStart(2, '0');
            const formattedDate = `${year}-${month}-${day}`;
            formik.setValues({
                applicant_name: data.applicant_name,
                signature: data.signature,
                date: formattedDate
            });
        } else if (member && writtenPolicy) {
            const utcDate = new Date(writtenPolicy?.date);

            const offsetMinutes = utcDate.getTimezoneOffset();
            utcDate.setMinutes(utcDate.getMinutes() + offsetMinutes);

            // Format the date to "YYYY-MM-DD"
            const adjustedYear = utcDate.getFullYear();
            const adjustedMonth = String(utcDate.getMonth() + 1).padStart(2, '0');
            const adjustedDay = String(utcDate.getDate()).padStart(2, '0');
            const formattedDate = `${adjustedYear}-${adjustedMonth}-${adjustedDay}`;
            formik.setValues({
                applicant_name: member?.user?.first_name + ' ' + member?.user?.last_name,
                signature: '',
                date: formattedDate
            });
        } else if (member) {
            // const utcDate = new Date()

            // const offsetMinutes = utcDate.getTimezoneOffset();
            // utcDate.setMinutes(utcDate.getMinutes() + offsetMinutes);

            // // Format the date to "YYYY-MM-DD"
            // const adjustedYear = utcDate.getFullYear();
            // const adjustedMonth = String(utcDate.getMonth() + 1).padStart(2, '0');
            // const adjustedDay = String(utcDate.getDate()).padStart(2, '0');
            // const formattedDate = `${adjustedYear}-${adjustedMonth}-${adjustedDay}`;
            const date_today = new Date();
            const year_today = date_today.getFullYear();
            const month_today = String(date_today.getMonth() + 1).padStart(2, '0');
            const day_today = String(date_today.getDate()).padStart(2, '0');
            const formattedDateToday = `${year_today}-${month_today}-${day_today}`;
            formik.setValues({
                applicant_name: member?.user?.first_name + ' ' + member?.user?.last_name,
                signature: '',
                date: formattedDateToday
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, member, writtenPolicy]);

    return (
        <div
            style={{
                padding: 10,
                margin: 10
            }}
        >
            <Typography
                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: 'center' }}
                variant="h5"
                gutterBottom
                component="h5"
            >
                THE BELOW DISCLOSURE AND AUTHORIZATION LANGUAGE IS FOR MANDATORY USE BY ALL ACCOUNT HOLDERS
            </Typography>
            <Typography
                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: 'center' }}
                variant="h5"
                gutterBottom
                component="h5"
            >
                IMPORTANT DISCLOSURE
            </Typography>
            <Typography
                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: 'center' }}
                variant="h5"
                gutterBottom
                component="h5"
            >
                REGARDING BACKGROUND REPORTS FROM THE PSP Online Service
            </Typography>
            <Typography
                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: 'justify' }}
                variant="body1"
                gutterBottom
                component="p"
            >
                In connection with your application for employment with{' '}
                {member?.company ? (
                    <span
                        style={{
                            fontWeight: 'bold',
                            textDecoration: 'underline'
                        }}
                    >
                        {member?.company?.name}
                    </span>
                ) : (
                    '_____________________________'
                )}{' '}
                (“Prospective Employer”), Prospective Employer, its employees, agents or contractors may obtain one or
                more reports regarding your driving, and safety inspection history from the Federal Motor Carrier Safety
                Administration (FMCSA).
            </Typography>
            <Typography
                sx={{
                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                    textAlign: 'justify',
                    mt: 1
                }}
                variant="body1"
                gutterBottom
                component="p"
            >
                When the application for employment is submitted in person, if the Prospective Employer uses any
                information it obtains from FMCSA in a decision to not hire you or to make any other adverse employment
                decision regarding you, the Prospective Employer will provide you with a copy of the report upon which
                its decision was based and a written summary of your rights under the Fair Credit Reporting Act before
                taking any final adverse action. If any final adverse action is taken against you based upon your
                driving history or safety report, the Prospective Employer will notify you that the action has been
                taken and that the action was based in part or in whole on this report.
            </Typography>
            <Typography
                sx={{
                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                    textAlign: 'justify',
                    mt: 1
                }}
                variant="body1"
                gutterBottom
                component="p"
            >
                When the application for employment is submitted by mail, telephone, computer, or other similar means,
                if the Prospective Employer uses any information it obtains from FMCSA in a decision to not hire you or
                to make any other adverse employment decision regarding you, the Prospective Employer must provide you
                within three business days of taking adverse action oral, written or electronic notification: that
                adverse action has been taken based in whole or in part on information obtained from FMCSA; the name,
                address, and the toll free telephone number of FMCSA; that the FMCSA did not make the decision to take
                the adverse action and is unable to provide you the specific reasons why the adverse action was taken;
                and that you may, upon providing proper identification, request a free copy of the report and may
                dispute with the FMCSA the accuracy or completeness of any information or report. If you request a copy
                of a driver record from the Prospective Employer who procured the report, then, within 3 business days
                of receiving your request, together with proper identification, the Prospective Employer must send or
                provide to you a copy of your report and a summary of your rights under the Fair Credit Reporting Act.
            </Typography>
            <Typography
                sx={{
                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                    textAlign: 'justify',
                    mt: 1
                }}
                variant="body1"
                gutterBottom
                component="p"
            >
                Neither the Prospective Employer nor the FMCSA contractor supplying the crash and safety information has
                the capability to correct any safety data that appears to be incorrect. You may challenge the accuracy
                of the data by submitting a request to https://dataqs.fmcsa.dot.gov. If you challenge crash or
                inspection information reported by a State, FMCSA cannot change or correct this data. Your request will
                be forwarded by the DataQs system to the appropriate State for adjudication.
            </Typography>
            <Typography
                sx={{
                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                    textAlign: 'justify',
                    mt: 1
                }}
                variant="body1"
                gutterBottom
                component="p"
            >
                Any crash or inspection in which you were involved will display on your PSP report. Since the PSP report
                does not report, or assign, or imply fault, it will include all Commercial Motor Vehicle (CMV) crashes
                where you were a driver or co-driver and where those crashes were reported to FMCSA, regardless of
                fault. Similarly, all inspections, with or without violations, appear on the PSP report. State citations
                associated with Federal Motor Carrier Safety Regulations (FMCSR) violations that have been adjudicated
                by a court of law will also appear, and remain, on a PSP report.
            </Typography>
            <Typography
                sx={{
                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                    textAlign: 'justify',
                    mt: 1
                }}
                variant="body1"
                gutterBottom
                component="p"
            >
                The Prospective Employer cannot obtain background reports from FMCSA without your authorization.
            </Typography>
            <Typography
                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: 'center' }}
                variant="h6"
                gutterBottom
                component="h6"
            >
                AUTHORIZATION
            </Typography>
            <Typography
                sx={{
                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                    textAlign: 'justify',
                    mt: 1
                }}
                variant="body1"
                gutterBottom
                component="p"
            >
                If you agree that the Prospective Employer may obtain such background reports, please read the following
                and sign below:
            </Typography>
            <Typography
                sx={{
                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                    textAlign: 'justify',
                    mt: 1
                }}
                variant="body1"
                gutterBottom
                component="p"
            >
                I authorize{' '}
                {member?.company ? (
                    <span
                        style={{
                            fontWeight: 'bold',
                            textDecoration: 'underline'
                        }}
                    >
                        {member?.company?.name}
                    </span>
                ) : (
                    '_______________________'
                )}{' '}
                (“Prospective Employer”) to access the FMCSA Pre-Employment Screening Program (PSP) system to seek
                information regarding my commercial driving safety record and information regarding my safety inspection
                history. I understand that I am authorizing the release of safety performance information including
                crash data from the previous five (5) years and inspection history from the previous three (3) years. I
                understand and acknowledge that this release of information may assist the Prospective Employer to make
                a determination regarding my suitability as an employee.
            </Typography>
            <Typography
                sx={{
                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                    textAlign: 'justify',
                    mt: 1
                }}
                variant="body1"
                gutterBottom
                component="p"
            >
                I further understand that neither the Prospective Employer nor the FMCSA contractor supplying the crash
                and safety information has the capability to correct any safety data that appears to be incorrect. I
                understand I may challenge the accuracy of the data by submitting a request to
                https://dataqs.fmcsa.dot.gov. If I challenge crash or inspection information reported by a State, FMCSA
                cannot change or correct this data. I understand my request will be forwarded by the DataQs system to
                the appropriate State for adjudication.
            </Typography>
            <Typography
                sx={{
                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                    textAlign: 'justify',
                    mt: 1
                }}
                variant="body1"
                gutterBottom
                component="p"
            >
                I understand that any crash or inspection in which I was involved will display on my PSP report. Since
                the PSP report does not report, or assign, or imply fault, I acknowledge it will include all CMV crashes
                where I was a driver or co-driver and where those crashes were reported to FMCSA, regardless of fault.
                Similarly, I understand all inspections, with or without violations, will appear on my PSP report, and
                State citations associated with FMCSR violations that have been adjudicated by a court of law will also
                appear, and remain, on my PSP report.
            </Typography>
            <Typography
                sx={{
                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                    textAlign: 'justify',
                    mt: 1
                }}
                variant="body1"
                gutterBottom
                component="p"
            >
                I have read the above Disclosure Regarding Background Reports provided to me by Prospective Employer and
                I understand that if I sign this Disclosure and Authorization, Prospective Employer may obtain a report
                of my crash and inspection history. I hereby authorize Prospective Employers and its employees,
                authorized agents, and/or affiliates to obtain the information authorized above.
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Name
                    </Typography>
                    <TextField
                        fullWidth
                        id="name"
                        placeholder="Name"
                        size="small"
                        variant="outlined"
                        value={formik.values.applicant_name}
                        className={
                            formik.touched.applicant_name && Boolean(formik.errors.applicant_name) ? 'green' : 'red'
                        }
                        error={formik.touched.applicant_name && Boolean(formik.errors.applicant_name)}
                        helperText={formik.touched.applicant_name && formik.errors.applicant_name}
                        onChange={(e) => formik.setFieldValue('applicant_name', e.target.value)}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.applicant_name && Boolean(formik.errors.applicant_name)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.applicant_name && Boolean(formik.errors.applicant_name)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.applicant_name && Boolean(formik.errors.applicant_name)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Signature
                    </Typography>
                    <TextField
                        fullWidth
                        id="name"
                        placeholder="Signature"
                        size="small"
                        variant="outlined"
                        value={formik.values.signature}
                        className={formik.touched.signature && Boolean(formik.errors.signature) ? 'green' : 'red'}
                        error={formik.touched.signature && Boolean(formik.errors.signature)}
                        helperText={formik.touched.signature && formik.errors.signature}
                        onChange={(e) => formik.setFieldValue('signature', e.target.value)}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.signature && Boolean(formik.errors.signature) ? 'red' : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.signature && Boolean(formik.errors.signature) ? 'red' : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.signature && Boolean(formik.errors.signature) ? 'red' : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                fontStyle: 'italic'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                fontStyle: 'italic'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Date
                    </Typography>
                    <TextField
                        fullWidth
                        id="name"
                        type="Date"
                        size="small"
                        variant="outlined"
                        value={formik.values.date}
                        className={formik.touched.date && Boolean(formik.errors.date) ? 'green' : 'red'}
                        error={formik.touched.date && Boolean(formik.errors.date)}
                        helperText={formik.touched.date && formik.errors.date}
                        onChange={(e) => formik.setFieldValue('date', e.target.value)}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? 'red' : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? 'red' : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? 'red' : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{
                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            textAlign: 'justify'
                        }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        NOTICE: This form is made available to monthly account holders by NIC on behalf of the U.S.
                        Department of Transportation, Federal Motor Carrier Safety Administration (FMCSA). Account
                        holders are required by federal law to obtain an Applicant’s written or electronic consent prior
                        to accessing the Applicant’s PSP report. Further, account holders are required by FMCSA to use
                        the language contained in this Disclosure and Authorization form to obtain an Applicant’s
                        consent. The language must be used in whole, exactly as provided. Further, the language on this
                        form must exist as one stand-alone document. The language may NOT be included with other consent
                        forms or any other language.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{
                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            textAlign: 'justify'
                        }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        NOTICE: The prospective employment concept referenced in this form contemplates the definition
                        of “employee” contained at 49 C.F.R. 383.5.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{
                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            textAlign: 'justify'
                        }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        LAST UPDATED 2/11/2016
                    </Typography>
                </Grid>
                {!data || (data && currentUser && ['Admin', 'SuperAdmin'].includes(currentUser?.role)) ? (
                    <Grid item xs={12} sm={12}>
                        <Button
                            fullWidth
                            disabled={isCreating || isUpdating || !!member?.terminated_at}
                            onClick={() => formik.handleSubmit()}
                            variant="contained"
                            color="primary"
                        >
                            Save
                        </Button>
                        {currentUser?.role !== 'Admin' && currentUser?.role !== 'SuperAdmin' && (
                            <Typography
                                sx={{ color: 'red', textAlign: 'center', mt: 2 }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Once you click save, you will not be able to edit this form.
                            </Typography>
                        )}
                    </Grid>
                ) : null}
                {data && currentUser?.role === 'SuperAdmin' && (
                    <Grid item xs={12} sm={12}>
                        <Button
                            fullWidth
                            disabled={!!member?.terminated_at}
                            onClick={() => {
                                const res = window.confirm('Are you sure you want to delete this form?');
                                if (res && data) {
                                    deletePSPDisclosureForm(data.id);
                                }
                            }}
                            variant="contained"
                            color="primary"
                        >
                            Delete Form
                        </Button>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default PSPDisclosureForm;
