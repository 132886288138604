import { useEffect, useState } from 'react';
import { IMember } from '../../../models';
import { useParams } from 'react-router-dom';
import { getCompanyMember } from '../../../shared';
import { Box, Button, Container, Paper, Typography } from '@mui/material';
import { StatusTag, useThemeMode } from '../../partials';
import ProgressBar from '../../partials/widgets/statistics/ProgressBar';
import { TabRow, links } from '../Tabs';
import DriversSignature from './DriversSignature';
import RequesterSignature from './RequesterSignature';
import RequestForCheckLetter from './RequestForCheckLetter';
import RequestedBySignature from './RequestedBySignature';
import ActionButtons from '../ActionButtons';
import {
    useGetMemberRequestForCheckOfDrivingRecordsProgressMutation,
    useGetRequestForCheckOfDrivingRecordsProgressMutation
} from '../../../store/reducers/RequestForCheckOfDrivingRecords/progress';
import { useAuth } from '../../../app/modules/auth';

const RequestForCheckOfDrivingRecords = () => {
    const { mode } = useThemeMode();
    const [getProgress, { data: progress }] = useGetRequestForCheckOfDrivingRecordsProgressMutation();
    const { currentUser } = useAuth();
    const [getMemberProgress, { data: memberProgress }] = useGetMemberRequestForCheckOfDrivingRecordsProgressMutation();
    const [member, setMember] = useState<IMember>();
    const { id } = useParams();
    const [value, setValue] = useState(0);

    useEffect(() => {
        if (id) {
            getCompanyMember(id).then((data) => {
                setMember(data);
            });
            getProgress(Number(id));
            getMemberProgress(Number(id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Container style={{ userSelect: 'none' }}>
            <Box
                sx={{
                    pl: 3,
                    pr: 3,
                    pt: 3,
                    m: 3,
                    borderRadius: 2,
                    backgroundColor: mode === 'dark' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    width: {
                        lg: '47vw',
                        md: '70vw',
                        sm: '80vw'
                    },
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }}
                component={Paper}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ mr: 5 }} variant="h4" gutterBottom component="h4">
                        Request for Check of Driving Records
                    </Typography>
                    <Typography sx={{ mb: 2 }} variant="h4" gutterBottom component="h4">
                        <StatusTag
                            status={
                                progress && progress?.progress > 0 && progress?.progress < 100
                                    ? 'In Progress'
                                    : progress && progress.progress > 99
                                    ? 'Completed'
                                    : 'Not Started'
                            }
                        />
                    </Typography>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ mr: 5 }} variant="h6" gutterBottom component="h6">
                        4 Section
                    </Typography>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="h6"
                        gutterBottom
                        component="h6"
                    >
                        19 Items
                    </Typography>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ mr: 5 }} variant="h6" gutterBottom component="h6">
                        Total:
                    </Typography>
                    <ProgressBar
                        progress={progress ? progress.progress : 0}
                        size={10}
                        backgroundColor="bg-secondary"
                        direction="right"
                        fontSize={7}
                    />
                </div>
                <div style={{ display: 'flex', width: '100%' }}>
                    <Typography
                        sx={{ mr: 5, color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="subtitle1"
                        gutterBottom
                        component="span"
                    >
                        {member ? member?.user?.first_name : 'Member: '}{' '}
                        {member ? ` ${member?.user?.last_name} :` : null}
                    </Typography>
                    <ProgressBar
                        progress={memberProgress ? memberProgress.progress : 0}
                        size={10}
                        backgroundColor="bg-secondary"
                        direction="right"
                        fontSize={7}
                    />
                </div>
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            if (currentUser?.role === 'Member') {
                                window.location.assign(`/dashboard/overview`);
                            } else {
                                if (member?.id) {
                                    window.location.assign(`/member/${member?.id}/profile/driver-documents`);
                                }
                            }
                        }}
                    >
                        Back to Forms Overview
                    </Button>
                </div>
                <TabRow current={value} links={links} setCurrent={setValue} />
            </Box>
            <Box
                sx={{
                    pl: 3,
                    pr: 3,
                    Radius: 2,
                    backgroundColor: mode === 'dark' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    width: {
                        lg: '47vw',
                        md: '70vw',
                        sm: '80vw'
                    },
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: 3
                }}
                component={Paper}
            >
                <DriversSignature member={member} />
            </Box>
            <Box
                sx={{
                    pl: 3,
                    pr: 3,
                    Radius: 2,
                    backgroundColor: mode === 'dark' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    width: {
                        lg: '47vw',
                        md: '70vw',
                        sm: '80vw'
                    },
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: 3
                }}
                component={Paper}
            >
                {currentUser && currentUser.role !== 'Member' && <RequesterSignature member={member} />}
            </Box>
            <Box
                sx={{
                    pl: 3,
                    pr: 3,
                    Radius: 2,
                    backgroundColor: mode === 'dark' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    width: {
                        lg: '47vw',
                        md: '70vw',
                        sm: '80vw'
                    },
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: 3
                }}
                component={Paper}
            >
                {/* TODO: Only have one of them and control the progress bar from the back end */}
                {/* <RequestForCheckLetter member={member} /> */}
                {currentUser && currentUser.role !== 'Member' && <RequestForCheckLetter member={member} />}
            </Box>
            <Box
                sx={{
                    pl: 3,
                    pr: 3,
                    Radius: 2,
                    backgroundColor: mode === 'dark' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    width: {
                        lg: '47vw',
                        md: '70vw',
                        sm: '80vw'
                    },
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: 3
                }}
                component={Paper}
            >
                {currentUser && currentUser.role !== 'Member' && <RequestedBySignature member={member} />}
            </Box>
            <ActionButtons
                member={member}
                prevButton={{
                    title: 'Cert of Compliance...',
                    path: 'certificate-of-compliance-with-driver-license-requirements'
                }}
                nextButton={{
                    title: 'Driver Statement of On-Duty Hours',
                    path: 'driver-statement-of-on-duty-hours'
                }}
            />
            <div
                style={{
                    display: 'flex',
                    width: '47vw',
                    justifyContent: 'center',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: 10
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        if (currentUser?.role === 'Member') {
                            window.location.assign(`/dashboard/overview`);
                        } else {
                            if (member?.id) {
                                window.location.assign(`/member/${member?.id}/profile/driver-documents`);
                            }
                        }
                    }}
                    fullWidth
                >
                    Back to Forms Overview
                </Button>
            </div>
        </Container>
    );
};

export default RequestForCheckOfDrivingRecords;
