import { Button, Grid, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { useAuth } from '../../../app/modules/auth';
import { IMember } from '../../../models';
import { changeStringLast4 } from '../../../shared';
import { ICompanyDriverInformationDto } from '../../../store/interfaces/DocumentationofActualKnowledgeofDOTDAndAViolation/company-driver-information';
import {
    useCreateCompanyDriverInformationMutation,
    useDeleteCompanyDriverInformationMutation,
    useGetCompanyDriverInformationByUserIdQuery,
    useUpdateCompanyDriverInformationMutation
} from '../../../store/reducers/DocumentationofActualKnowledgeofDOTDAndAViolation/company-driver-information';
import { useThemeMode } from '../../partials';

const initialValues: Partial<ICompanyDriverInformationDto> = {
    motor_carrier_name: '',
    us_dot_number: '',
    motor_carrier_address: '',
    driver_name: '',
    date_of_birth: '',
    driver_cdl_number: '',
    state_of_issuance: ''
};

const validationSchema = Yup.object({
    motor_carrier_name: Yup.string().required('Required'),
    us_dot_number: Yup.string().required('Required'),
    motor_carrier_address: Yup.string().required('Required'),
    driver_name: Yup.string().required('Required'),
    date_of_birth: Yup.string().required('Required'),
    driver_cdl_number: Yup.string().required('Required'),
    state_of_issuance: Yup.string().required('Required')
});

interface IProps {
    member?: IMember;
    isDriverFile?: boolean;
}

const CompanyDriverInformation = ({ member, isDriverFile }: IProps) => {
    const { mode } = useThemeMode();
    const { currentUser } = useAuth();
    const { data } = useGetCompanyDriverInformationByUserIdQuery(member?.user?.id!, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true
    });

    const [createCompanyDriverInformation, { isLoading: isCreating }] = useCreateCompanyDriverInformationMutation();
    const [updateCompanyDriverInformation, { isLoading: isUpdating }] = useUpdateCompanyDriverInformationMutation();
    const [deleteCompanyInformation, { isLoading: isDeleting }] = useDeleteCompanyDriverInformationMutation();

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (data) {
                updateCompanyDriverInformation({
                    ...data,
                    ...values
                });
            } else {
                createCompanyDriverInformation({
                    ...values,
                    // @ts-ignore
                    company: member?.company?.id,
                    // @ts-ignore
                    member: member?.id
                });
            }
            resetForm();
        },
        validateOnBlur: true,
        validateOnChange: true
    });

    useEffect(() => {
        if (data && currentUser) {
            formik.setValues({
                motor_carrier_name: data.motor_carrier_name,
                us_dot_number: data.us_dot_number,
                motor_carrier_address: data.motor_carrier_address,
                driver_name: data.driver_name,
                date_of_birth: data.date_of_birth,
                driver_cdl_number: ['Member', 'Owner'].includes(currentUser?.role!)
                    ? changeStringLast4(data.driver_cdl_number)
                    : data.driver_cdl_number,
                state_of_issuance: data.state_of_issuance
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, currentUser]);

    return (
        <div
            style={{
                padding: 10,
                margin: 10
            }}
        >
            <Typography
                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: 'center' }}
                variant="h4"
                gutterBottom
                component="h4"
            >
                Company/Driver Information
            </Typography>
            <Typography
                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                variant="body1"
                gutterBottom
                component="p"
            >
                NOTE to Motor Carrier: This form is for employers to report the description of their "actual knowledge"
                (49 CFR Section 382.107) that a driver has violated the Federal Motor Carrier Safety Administration's
                drug or alcohol regulations. Sections 382.205 - 382.217. 382.705 require that any motor carrier with
                "actual knowledge" of certain drug and alcohol violations must not use the driver to perform
                safety-sensitive functions and must report details to Drug and Alcohol Clearinghouse. Section 382.705(b)
                -Motor carriers are required to report their "actual knowledge" to the Clearinghouse by the end of the
                third business day after motor carrier obtained the knowledge."))
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Motor Carrier Name
                    </Typography>
                    <TextField
                        fullWidth
                        id="motor_carrier_name"
                        placeholder="Motor Carrier Name"
                        size="small"
                        variant="outlined"
                        value={formik.values.motor_carrier_name}
                        onChange={(e) => {
                            formik.setFieldValue('motor_carrier_name', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.motor_carrier_name && Boolean(formik.errors.motor_carrier_name)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.motor_carrier_name && Boolean(formik.errors.motor_carrier_name)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.motor_carrier_name && Boolean(formik.errors.motor_carrier_name)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        U.S. DOT Number
                    </Typography>
                    <TextField
                        fullWidth
                        id="us_dot_number"
                        placeholder="U.S. DOT Number"
                        size="small"
                        variant="outlined"
                        value={formik.values.us_dot_number}
                        onChange={(e) => {
                            formik.setFieldValue('us_dot_number', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.us_dot_number && Boolean(formik.errors.us_dot_number)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.us_dot_number && Boolean(formik.errors.us_dot_number)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.us_dot_number && Boolean(formik.errors.us_dot_number)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Motor Carrier Address
                    </Typography>
                    <TextField
                        fullWidth
                        id="motor_carrier_address"
                        placeholder="Motor Carrier Address"
                        size="small"
                        variant="outlined"
                        value={formik.values.motor_carrier_address}
                        onChange={(e) => {
                            formik.setFieldValue('motor_carrier_address', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.motor_carrier_address &&
                                        Boolean(formik.errors.motor_carrier_address)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.motor_carrier_address &&
                                        Boolean(formik.errors.motor_carrier_address)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.motor_carrier_address &&
                                        Boolean(formik.errors.motor_carrier_address)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Driver Name
                    </Typography>
                    <TextField
                        fullWidth
                        id="driver_name"
                        placeholder="Driver Name"
                        size="small"
                        variant="outlined"
                        value={formik.values.driver_name}
                        onChange={(e) => {
                            formik.setFieldValue('driver_name', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.driver_name && Boolean(formik.errors.driver_name)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.driver_name && Boolean(formik.errors.driver_name)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.driver_name && Boolean(formik.errors.driver_name)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Date of Birth
                    </Typography>
                    <TextField
                        fullWidth
                        id="date_of_birth"
                        type="date"
                        size="small"
                        variant="outlined"
                        value={formik.values.date_of_birth}
                        onChange={(e) => {
                            formik.setFieldValue('date_of_birth', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.date_of_birth && Boolean(formik.errors.date_of_birth)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.date_of_birth && Boolean(formik.errors.date_of_birth)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.date_of_birth && Boolean(formik.errors.date_of_birth)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Driver CDL Number
                    </Typography>
                    <TextField
                        fullWidth
                        id="driver_cdl_number"
                        placeholder="Driver CDL Number"
                        size="small"
                        variant="outlined"
                        value={formik.values.driver_cdl_number}
                        onChange={(e) => {
                            formik.setFieldValue('driver_cdl_number', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.driver_cdl_number && Boolean(formik.errors.driver_cdl_number)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.driver_cdl_number && Boolean(formik.errors.driver_cdl_number)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.driver_cdl_number && Boolean(formik.errors.driver_cdl_number)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        State of Issuance
                    </Typography>
                    <TextField
                        fullWidth
                        id="state_of_issuance"
                        placeholder="State of Issuance"
                        size="small"
                        variant="outlined"
                        value={formik.values.state_of_issuance}
                        onChange={(e) => {
                            formik.setFieldValue('state_of_issuance', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.state_of_issuance && Boolean(formik.errors.state_of_issuance)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.state_of_issuance && Boolean(formik.errors.state_of_issuance)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.state_of_issuance && Boolean(formik.errors.state_of_issuance)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                {(!data || (data && currentUser && ['Admin', 'SuperAdmin'].includes(currentUser?.role))) &&
                !isDriverFile ? (
                    <Grid item xs={12} sm={12}>
                        <Button
                            fullWidth
                            disabled={isCreating || isUpdating || !!member?.terminated_at}
                            onClick={() => formik.handleSubmit()}
                            variant="contained"
                            color="primary"
                        >
                            Save
                        </Button>
                        {currentUser?.role !== 'Admin' && currentUser?.role !== 'SuperAdmin' && (
                            <Typography
                                sx={{ color: 'red', textAlign: 'center', mt: 2 }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Once you click save, you will not be able to edit this form.
                            </Typography>
                        )}
                    </Grid>
                ) : null}
                {data && currentUser?.role === 'SuperAdmin' && (
                    <Grid item xs={12} sm={12}>
                        <Button
                            fullWidth
                            disabled={isDeleting || !!member?.terminated_at}
                            onClick={() => {
                                const res = window.confirm('Are you sure you want to delete this form section?');
                                if (res)
                                    deleteCompanyInformation(data?.id).then(() => {
                                        window.location.reload();
                                    });
                            }}
                            variant="contained"
                            color="error"
                        >
                            Delete Company Information
                        </Button>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default CompanyDriverInformation;
