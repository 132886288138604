import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { paymentFailedLink } from '../../../shared/client/memberRequest';
import { Card, Placeholder } from 'react-bootstrap';

const MemberRequestPaymentFailed = () => {
    const { id } = useParams<{ id: string }>();

    const [queryStarted, setQueryStarted] = useState(false);
    const [queryFinished, setQueryFinished] = useState(false);
    const [message, setMessage] = useState('');

    const paymentFailedFn = useCallback(async () => {
        try {
            if (id) {
                setQueryStarted(true);
                const investigation = await paymentFailedLink(Number(id));
                if (investigation) {
                    setMessage('Your payment was unsuccessful. Please try again or contact us for assistance');
                    setQueryFinished(true);
                } else {
                    setMessage('There was an error with your payment. Please try again.');
                    setQueryFinished(true);
                }
            }
        } catch (error) {
            setMessage('There was an error with your payment. Please try again.');
            setQueryFinished(true);
        }
    }, [id]);

    useEffect(() => {
        if (!queryStarted) {
            paymentFailedFn();
        }
    }, [paymentFailedFn, queryStarted]);

    return (
        <div
            className="container"
            style={{
                height: '70vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '80vw'
            }}
        >
            <Card
                style={{
                    width: '40vw',
                    height: '20%'
                }}
            >
                {queryFinished && (
                    <Card.Body>
                        <Card.Title
                            style={{
                                textAlign: 'center'
                            }}
                        >
                            Payment Status
                        </Card.Title>
                        <Card.Text
                            style={{
                                textAlign: 'center'
                            }}
                        >
                            {message}
                        </Card.Text>
                    </Card.Body>
                )}
                {queryStarted && !queryFinished ? (
                    <Card.Body>
                        <Placeholder as={Card.Text} animation="glow">
                            <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} /> <Placeholder xs={6} />{' '}
                            <Placeholder xs={8} />
                        </Placeholder>
                    </Card.Body>
                ) : null}
            </Card>
        </div>
    );
};

export default MemberRequestPaymentFailed;
