import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {IReminder} from '../../interfaces/AnnualReviewOfDrivingRecord/annual-review-of_driving-record';
import {
  IDriverMedicalExaminerInformationAndOwnerSignature,
  IDriverMedicalExaminerInformationAndOwnerSignatureDto,
} from '../../interfaces/DriverMedicalExaminerInformationAndOwnerSignature/DriverMedicalExaminerInformationAndOwnerSignature';

interface IReminderInput {
  company_id?: number;
  member_id?: number;
}

export const driverMedicalExaminerInformationAndOwnerSignatureApi = createApi({
  reducerPath: 'driver_medical_examiner_information_and_owner_signature',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/driver-medical-examiner-information-and-owner-signature`,
  }),
  tagTypes: ['DriverMedicalExaminerInformationAndOwnerSignature'],
  keepUnusedDataFor: 60 * 10, // 10 minutes
  endpoints: (builder) => ({
    createDriverMedicalExaminerInformationAndOwnerSignature: builder.mutation<
      IDriverMedicalExaminerInformationAndOwnerSignature,
      IDriverMedicalExaminerInformationAndOwnerSignatureDto
    >({
      query: (body) => ({
        url: '/',
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [
        {
          type: 'DriverMedicalExaminerInformationAndOwnerSignature',
          id: 'DriverMedicalExaminerInformationAndOwnerSignature',
        },
      ],
    }),
    updateDriverMedicalExaminerInformationAndOwnerSignature: builder.mutation<
      IDriverMedicalExaminerInformationAndOwnerSignature,
      IDriverMedicalExaminerInformationAndOwnerSignature
    >({
      query: (body) => ({
        url: `/${body.id}/`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [
        {
          type: 'DriverMedicalExaminerInformationAndOwnerSignature',
          id: 'DriverMedicalExaminerInformationAndOwnerSignature',
        },
      ],
    }),
    deleteDriverMedicalExaminerInformationAndOwnerSignature: builder.mutation<void, number>({
      query: (id) => ({
        url: `/${id}/`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [
        {
          type: 'DriverMedicalExaminerInformationAndOwnerSignature',
          id: 'DriverMedicalExaminerInformationAndOwnerSignature',
        },
      ],
    }),
    getDriverMedicalExaminerInformationAndOwnerSignature: builder.query<
      IDriverMedicalExaminerInformationAndOwnerSignature[],
      number | void
    >({
      query: (id) => ({
        url: '/get-driver-medical-examiner-information-and-owner-signature/',
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [
        {
          type: 'DriverMedicalExaminerInformationAndOwnerSignature',
          id: 'DriverMedicalExaminerInformationAndOwnerSignature',
        },
      ],
    }),
    getDriverMedicalExaminerInformationAndOwnerSignatureByUser: builder.query<
      IDriverMedicalExaminerInformationAndOwnerSignature[],
      void
    >({
      query: () => ({
        url: '/get-driver-medical-examiner-information-and-owner-signature-by-user/',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [
        {
          type: 'DriverMedicalExaminerInformationAndOwnerSignature',
          id: 'DriverMedicalExaminerInformationAndOwnerSignature',
        },
      ],
    }),
    getDriverMedicalExaminerInformationAndOwnerSignatureByUserId: builder.query<
      IDriverMedicalExaminerInformationAndOwnerSignature[],
      number | void
    >({
      query: (id) => ({
        url: '/get-driver-medical-examiner-information-and-owner-signature-by-user-id/',
        method: 'POST',
        body: {
          userId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [
        {
          type: 'DriverMedicalExaminerInformationAndOwnerSignature',
          id: 'DriverMedicalExaminerInformationAndOwnerSignature',
        },
      ],
    }),
    getNonCurrentDriverMedicalExaminerInformationAndOwnerSignatureByUserId: builder.query<
      IDriverMedicalExaminerInformationAndOwnerSignature[],
      number | void
    >({
      query: (id) => ({
        url: '/get-non-current-driver-medical-examiner-information-and-owner-signature-by-user-id/',
        method: 'POST',
        body: {
          userId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [
        {
          type: 'DriverMedicalExaminerInformationAndOwnerSignature',
          id: 'DriverMedicalExaminerInformationAndOwnerSignature',
        },
      ],
    }),
    getDriverMedicalReminder: builder.query<IReminder[], IReminderInput>({
      query: (body) => ({
        url: '/get-reminder/',
        method: 'POST',
        body: {
          company_id: body.company_id,
          member_id: body.member_id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [
        {
          type: 'DriverMedicalExaminerInformationAndOwnerSignature',
          id: 'DriverMedicalExaminerInformationAndOwnerSignature',
        },
      ],
    }),
  }),
});

export const {
  useCreateDriverMedicalExaminerInformationAndOwnerSignatureMutation,
  useUpdateDriverMedicalExaminerInformationAndOwnerSignatureMutation,
  useGetDriverMedicalExaminerInformationAndOwnerSignatureQuery,
  useGetDriverMedicalExaminerInformationAndOwnerSignatureByUserQuery,
  useGetDriverMedicalExaminerInformationAndOwnerSignatureByUserIdQuery,
  useGetDriverMedicalReminderQuery,
  useGetNonCurrentDriverMedicalExaminerInformationAndOwnerSignatureByUserIdQuery,
  useDeleteDriverMedicalExaminerInformationAndOwnerSignatureMutation,
} = driverMedicalExaminerInformationAndOwnerSignatureApi;
