import { Button, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { useAuth } from '../../../app/modules/auth';
import { IMember } from '../../../models';
import { IDriverCertificationForOtherCompensatedWorkDto } from '../../../store/interfaces/DriversStatementDutyHours/DriverCertificationForOtherCompensatedWork';
import { useGetCertificationByUserIdQuery } from '../../../store/reducers/CertificateOfComplianceWithDriverLicenseRequirements/certification';
import { useGetProcessRecordByUserIdQuery } from '../../../store/reducers/DriversApplicationForEmployment/process-record';
import {
    useCreateDriverCertificateForOtherCompensatedWorkMutation,
    useDeleteDriverCertificateForOtherCompensatedWorkMutation,
    useGetDriverCertificateForOtherCompensatedWorkByUserIdQuery,
    useUpdateDriverCertificateForOtherCompensatedWorkMutation
} from '../../../store/reducers/DriverStatementDutyHours/driver-certificate-for-other-compensated-work';
import { useThemeMode } from '../../partials';

const initialValues: Partial<IDriverCertificationForOtherCompensatedWorkDto> = {
    currently_employed_elsewhere: false,
    intend_to_work_elsewhere: false,
    driver_signature: '',
    date: ''
};

const validationSchema = Yup.object({
    currently_employed_elsewhere: Yup.boolean().required('Required'),
    intend_to_work_elsewhere: Yup.boolean().required('Required'),
    driver_signature: Yup.string().required('Required'),
    date: Yup.string().required('Required')
});

const Certification = ({ member, isDriverFile }: { member?: IMember; isDriverFile?: boolean }) => {
    const { mode } = useThemeMode();
    const { currentUser } = useAuth();
    const { data } = useGetDriverCertificateForOtherCompensatedWorkByUserIdQuery(member?.user?.id, {
        skip: !member?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true
    });

    const { data: cert } = useGetCertificationByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true
    });

    const { data: processRecord } = useGetProcessRecordByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    });

    const [createDriverCertificateForOtherCompensatedWork, { isLoading: isCreating }] =
        useCreateDriverCertificateForOtherCompensatedWorkMutation();
    const [updateDriverCertificateForOtherCompensatedWork, { isLoading: isUpdating }] =
        useUpdateDriverCertificateForOtherCompensatedWorkMutation();
    const [deleteDriverCertificateForOtherCompensatedWork] =
        useDeleteDriverCertificateForOtherCompensatedWorkMutation();

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (data) {
                // @ts-ignore
                updateDriverCertificateForOtherCompensatedWork({
                    ...values,
                    id: data.id
                });
            } else {
                createDriverCertificateForOtherCompensatedWork({
                    ...values,
                    // @ts-ignore
                    member: member?.id,
                    // @ts-ignore
                    company: member?.company?.id
                });
            }
            resetForm();
        }
    });

    useEffect(() => {
        if (data) {
            formik.setValues(data);
        } else if (processRecord) {
            formik.setFieldValue('date', processRecord?.date_employed);
        } else if (cert) {
            formik.setFieldValue('date', cert?.date_of_hire);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, cert, processRecord]);

    return (
        <div
            style={{
                padding: 10,
                margin: 10
            }}
        >
            <Typography
                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: 'center' }}
                variant="h4"
                gutterBottom
                component="h4"
            >
                Driver Certification for Other Compensated Work
            </Typography>
            <Typography
                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: 'justify' }}
                variant="body1"
                gutterBottom
                component="p"
            >
                NOTE: When a driver is employed by a motor carrier, he/she must report to the carrier all on-duty time
                for other compensated work, including time working for other employers, motor carrier, and/or non-motor
                carriers.
            </Typography>
            <Grid container spacing={2} sx={{ mt: 3 }}>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Are you currently working for another employer?
                    </Typography>
                    <TextField
                        fullWidth
                        id="currently_employed_elsewhere"
                        size="small"
                        variant="outlined"
                        value={formik.values.currently_employed_elsewhere ? 'Yes' : 'No'}
                        select
                        onChange={(e) => {
                            formik.setFieldValue(
                                'currently_employed_elsewhere',
                                e.target.value === 'Yes' ? true : false
                            );
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.currently_employed_elsewhere &&
                                        Boolean(formik.errors.currently_employed_elsewhere)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.currently_employed_elsewhere &&
                                        Boolean(formik.errors.currently_employed_elsewhere)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.currently_employed_elsewhere &&
                                        Boolean(formik.errors.currently_employed_elsewhere)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        At this time do you intend to work for another employer while still employed?
                    </Typography>
                    <TextField
                        fullWidth
                        id="intend_to_work_elsewhere"
                        size="small"
                        variant="outlined"
                        value={formik.values.intend_to_work_elsewhere ? 'Yes' : 'No'}
                        select
                        onChange={(e) => {
                            formik.setFieldValue('intend_to_work_elsewhere', e.target.value === 'Yes' ? true : false);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.intend_to_work_elsewhere &&
                                        Boolean(formik.errors.intend_to_work_elsewhere)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.intend_to_work_elsewhere &&
                                        Boolean(formik.errors.intend_to_work_elsewhere)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.intend_to_work_elsewhere &&
                                        Boolean(formik.errors.intend_to_work_elsewhere)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{
                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                            textAlign: 'justify'
                        }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        I hereby certify that the information provided above is true and I understand that once I'm
                        employed with this company, I must inform them immediately if I start any compensated work for
                        any other employer(s)
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Drivers Signature
                    </Typography>
                    <TextField
                        fullWidth
                        id="driver_signature"
                        placeholder="Drivers Signature"
                        size="small"
                        variant="outlined"
                        value={formik.values.driver_signature}
                        onChange={(e) => {
                            formik.setFieldValue('driver_signature', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.driver_signature && Boolean(formik.errors.driver_signature)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.driver_signature && Boolean(formik.errors.driver_signature)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.driver_signature && Boolean(formik.errors.driver_signature)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                                fontStyle: 'italic'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Date of Hire
                    </Typography>
                    <TextField
                        fullWidth
                        id="date"
                        size="small"
                        variant="outlined"
                        value={formik.values.date}
                        type="date"
                        onChange={(e) => {
                            formik.setFieldValue('date', e.target.value);
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? 'red' : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? 'red' : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.date && Boolean(formik.errors.date) ? 'red' : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                {(!data || (data && currentUser && ['Admin', 'SuperAdmin'].includes(currentUser?.role))) &&
                !isDriverFile ? (
                    <Grid item xs={12} sm={12}>
                        <Button
                            fullWidth
                            disabled={isCreating || isUpdating || !!member?.terminated_at}
                            onClick={() => formik.handleSubmit()}
                            variant="contained"
                            color="primary"
                        >
                            Save
                        </Button>
                        {currentUser?.role !== 'Admin' && currentUser?.role !== 'SuperAdmin' && (
                            <Typography
                                sx={{ color: 'red', textAlign: 'center', mt: 2 }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Once you click save, you will not be able to edit this form.
                            </Typography>
                        )}
                    </Grid>
                ) : null}
                {data && currentUser?.role === 'SuperAdmin' && (
                    <Grid item xs={12} sm={12}>
                        <Button
                            fullWidth
                            disabled={!!member?.terminated_at}
                            onClick={() => {
                                const res = window.confirm('Are you sure you want to delete this form?');
                                if (res && data) {
                                    deleteDriverCertificateForOtherCompensatedWork(data.id);
                                }
                            }}
                            variant="contained"
                            color="primary"
                        >
                            Delete Form
                        </Button>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default Certification;
