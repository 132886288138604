import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
  IPreviousPreEmploymentDrugDriversSignature,
  IPreviousPreEmploymentDrugDriversSignatureDto,
} from '../../interfaces/PreviousPreEmploymentDrugAndAlcoholTestStatement/PreviousPreEmploymentDrugDriversSignature';

export const previousPreEmploymentDrugDriversSignatureApi = createApi({
  reducerPath: 'previous_pre_employment_drug_drivers_signature',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/previous-drug-drivers-signature`,
  }),
  tagTypes: ['PreviousPreEmploymentDrugDriversSignature'],
  keepUnusedDataFor: 60 * 10, // 10 minutes
  endpoints: (builder) => ({
    createPreviousPreEmploymentDrugDriversSignature: builder.mutation<
      IPreviousPreEmploymentDrugDriversSignature,
      IPreviousPreEmploymentDrugDriversSignatureDto
    >({
      query: (body) => ({
        url: '/',
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [
        {
          type: 'PreviousPreEmploymentDrugDriversSignature',
          id: 'PreviousPreEmploymentDrugDriversSignature',
        },
      ],
    }),
    updatePreviousPreEmploymentDrugDriversSignature: builder.mutation<
      IPreviousPreEmploymentDrugDriversSignature,
      IPreviousPreEmploymentDrugDriversSignature
    >({
      query: (body) => ({
        url: `/${body.id}/`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [
        {
          type: 'PreviousPreEmploymentDrugDriversSignature',
          id: 'PreviousPreEmploymentDrugDriversSignature',
        },
      ],
    }),
    getPreviousPreEmploymentDrugDriversSignature: builder.query<
      IPreviousPreEmploymentDrugDriversSignature,
      number | void
    >({
      query: (id) => ({
        url: `/get-previous-drug-drivers-signature/`,
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [
        {
          type: 'PreviousPreEmploymentDrugDriversSignature',
          id: 'PreviousPreEmploymentDrugDriversSignature',
        },
      ],
    }),
    getPreviousPreEmploymentDrugDriversSignatureByUser: builder.query<
      IPreviousPreEmploymentDrugDriversSignature,
      void
    >({
      query: () => ({
        url: `/get-previous-drug-drivers-signature-by-user/`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [
        {
          type: 'PreviousPreEmploymentDrugDriversSignature',
          id: 'PreviousPreEmploymentDrugDriversSignature',
        },
      ],
    }),
    getPreviousPreEmploymentDrugDriversSignatureByUserId: builder.query<
      IPreviousPreEmploymentDrugDriversSignature,
      number
    >({
      query: (id) => ({
        url: `/get-previous-drug-drivers-signature-by-user-id/`,
        method: 'POST',
        body: {
          userId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [
        {
          type: 'PreviousPreEmploymentDrugDriversSignature',
          id: 'PreviousPreEmploymentDrugDriversSignature',
        },
      ],
    }),
    deletePreviousPreEmploymentDrugDriversSignature: builder.mutation<void, number>({
      query: (id) => ({
        url: `/${id}/`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [
        {
          type: 'PreviousPreEmploymentDrugDriversSignature',
          id: 'PreviousPreEmploymentDrugDriversSignature',
        },
      ],
    }),
  }),
});

export const {
  useCreatePreviousPreEmploymentDrugDriversSignatureMutation,
  useUpdatePreviousPreEmploymentDrugDriversSignatureMutation,
  useGetPreviousPreEmploymentDrugDriversSignatureQuery,
  useGetPreviousPreEmploymentDrugDriversSignatureByUserQuery,
  useGetPreviousPreEmploymentDrugDriversSignatureByUserIdQuery,
  useDeletePreviousPreEmploymentDrugDriversSignatureMutation,
} = previousPreEmploymentDrugDriversSignatureApi;
