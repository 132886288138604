import { useLayout } from '../../core/LayoutProvider';
import { Toolbar1 } from './Toolbar1';

const Toolbar = () => {
    const { config } = useLayout();

    if (config.toolbar.display) {
        switch (config.toolbar.layout) {
            case 'toolbar1':
                return <Toolbar1 />;

            default:
                return <Toolbar1 />;
        }
    } else {
        return null;
    }
};

export { Toolbar };
