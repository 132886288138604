import { Button, Grid, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { useAuth } from '../../../app/modules/auth';
import { IMember } from '../../../models';
import { IApplicantBasicInfoDto } from '../../../store/interfaces/DriversApplicationForEmployment/applicant.basic-info';
import {
    useCreateApplicantBasicInfoMutation,
    useDeleteApplicantBasicInfoMutation,
    useGetApplicantBasicInfoByUserIdQuery,
    useUpdateApplicantBasicInfoMutation
} from '../../../store/reducers/DriversApplicationForEmployment/applicant.basic.info';
import { useThemeMode } from '../../partials';

const initialValues: IApplicantBasicInfoDto = {
    applicant_name: '',
    applicant_email: '',
    applicant_middle_name: '',
    applicant_suffix: '',
    address_line: '',
    city: '',
    state: '',
    zip_code: '',
    date_of_hire: new Date().toLocaleDateString(),
    member: undefined,
    company: undefined
};

const validationSchema = Yup.object({
    applicant_name: Yup.string().required('Required').min(3, 'Must be at least 3 characters'),
    applicant_email: Yup.string()
        .email('Invalid email format')
        .required('Required')
        .min(3, 'Must be at least 3 characters'),
    address_line: Yup.string().required('Required').min(3, 'Must be at least 3 characters'),
    city: Yup.string().required('Required').min(3, 'Must be at least 3 characters'),
    state: Yup.string().required('Required').min(2, 'Must be at least 2 characters'),
    zip_code: Yup.string().required('Required').min(3, 'Must be at least 3 characters'),
    date_of_hire: Yup.date().required('Required')
});

const BasicApplicantInformation = ({ member, isDriverFile }: { member?: IMember; isDriverFile?: boolean }) => {
    const { mode } = useThemeMode();
    const { currentUser } = useAuth();
    const { data } = useGetApplicantBasicInfoByUserIdQuery(member?.user?.id, {
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
        skip: !member?.user?.id
    });
    const [createApplicantBasicInfo] = useCreateApplicantBasicInfoMutation();
    const [updateApplicantBasicInfo] = useUpdateApplicantBasicInfoMutation();
    const [deleteApplicantBasicInfo] = useDeleteApplicantBasicInfoMutation();

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            if (member && !data) {
                // @ts-ignore
                createApplicantBasicInfo({ ...values, member: member.id, company: member.company.id });
            } else if (member && data) {
                // @ts-ignore
                updateApplicantBasicInfo({ ...values, member: member.id, company: member.company.id, id: data.id });
            }
        },
        validateOnBlur: true,
        validate: (values) => {
            const errors: Partial<IApplicantBasicInfoDto> = {};
            if (!values.applicant_name) {
                errors.applicant_name = 'Required';
            }
            if (!values.applicant_email) {
                errors.applicant_email = 'Required';
            }
            if (!values.address_line) {
                errors.address_line = 'Required';
            }
            if (!values.city) {
                errors.city = 'Required';
            }
            if (!values.state) {
                errors.state = 'Required';
            }
            if (!values.zip_code) {
                errors.zip_code = 'Required';
            }
            if (!values.date_of_hire) {
                errors.date_of_hire = 'Required';
            }
            return errors;
        }
    });

    useEffect(() => {
        if (data) {
            formik.setValues(data);
        } else if (member) {
            formik.setFieldValue('member', member.id);
            formik.setFieldValue('company', member.company.id);
            formik.setFieldValue('applicant_name', member.user.first_name + ' ' + member.user.last_name);
            formik.setFieldValue('applicant_email', member.user.email);
            formik.setFieldValue('state', member.company?.state ?? member?.company?.country);
            formik.setFieldValue('address_line', member?.company?.address1);
            formik.setFieldValue('city', member?.company?.city);
            formik.setFieldValue('zip_code', member?.company?.zip_code);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, member]);

    return (
        <div style={{ margin: 10, padding: 10 }}>
            <Typography
                sx={{ textAlign: 'center', color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                variant="h4"
                gutterBottom
                component="h4"
            >
                Applicant Basic Questions
            </Typography>
            <Typography
                sx={{ textAlign: 'justify', color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                variant="body1"
                gutterBottom
                component="p"
            >
                Section 391.21 A Driver's Application must be completed by each applicant pursuing to drive a commercial
                motor vehicle. This form must be completed and signed by applicant and must contain the information as
                outlined in Section 391.21. Before a driver's application is submitted, the motor carrier shall inform
                the applicant that the information he/she provided can be used and the applicant's prior employers can
                be contacted, for the purpose of investigating the applicant's background.
            </Typography>
            <Grid
                container
                spacing={3}
                p={1}
                sx={{
                    alignItems: 'center'
                }}
            >
                <Grid item xs={12} sm={3}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Applicant Name
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <TextField
                        fullWidth
                        id="applicant_name"
                        label="Applicant Name"
                        placeholder="Applicant Name"
                        size="small"
                        variant="outlined"
                        {...formik.getFieldProps('applicant_name')}
                        className={
                            formik.touched.applicant_name && Boolean(formik.errors.applicant_name) ? 'green' : 'red'
                        }
                        error={formik.touched.applicant_name && Boolean(formik.errors.applicant_name)}
                        helperText={formik.touched.applicant_name && formik.errors.applicant_name}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.applicant_name && Boolean(formik.errors.applicant_name)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.applicant_name && Boolean(formik.errors.applicant_name)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.applicant_name && Boolean(formik.errors.applicant_name)
                                            ? 'red'
                                            : 'green'
                                }
                            },
                            '& .MuiInputBase-input.Mui-disabled': {
                                WebkitTextFillColor: 'white'
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: 'red'
                                // color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Applicant Middle Name
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <TextField
                        fullWidth
                        id="applicant_middle_name"
                        placeholder="Applicant Middle Name"
                        size="small"
                        variant="outlined"
                        {...formik.getFieldProps('applicant_middle_name')}
                        className={
                            formik.touched.applicant_middle_name && Boolean(formik.errors.applicant_middle_name)
                                ? 'green'
                                : 'red'
                        }
                        error={formik.touched.applicant_middle_name && Boolean(formik.errors.applicant_middle_name)}
                        helperText={formik.touched.applicant_middle_name && formik.errors.applicant_middle_name}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.applicant_middle_name &&
                                        Boolean(formik.errors.applicant_middle_name)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.applicant_middle_name &&
                                        Boolean(formik.errors.applicant_middle_name)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.applicant_middle_name &&
                                        Boolean(formik.errors.applicant_middle_name)
                                            ? 'red'
                                            : 'green'
                                }
                            },
                            '& .MuiInputBase-input.Mui-disabled': {
                                WebkitTextFillColor: 'white'
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: 'red'
                                // color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Applicant Suffix
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <TextField
                        fullWidth
                        id="applicant_suffix"
                        placeholder="Applicant Suffix"
                        size="small"
                        variant="outlined"
                        {...formik.getFieldProps('applicant_suffix')}
                        className={
                            formik.touched.applicant_suffix && Boolean(formik.errors.applicant_suffix) ? 'green' : 'red'
                        }
                        error={formik.touched.applicant_suffix && Boolean(formik.errors.applicant_suffix)}
                        helperText={formik.touched.applicant_suffix && formik.errors.applicant_suffix}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.applicant_suffix && Boolean(formik.errors.applicant_suffix)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.applicant_suffix && Boolean(formik.errors.applicant_suffix)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.applicant_suffix && Boolean(formik.errors.applicant_suffix)
                                            ? 'red'
                                            : 'green'
                                }
                            },
                            '& .MuiInputBase-input.Mui-disabled': {
                                WebkitTextFillColor: 'white'
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: 'red'
                                // color: mode === "dark" ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Applicant Email
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <TextField
                        fullWidth
                        id="applicant_email"
                        label="Applicant Emnail"
                        placeholder="Applicant Emnail"
                        size="small"
                        variant="outlined"
                        {...formik.getFieldProps('applicant_email')}
                        className={
                            formik.touched.applicant_email && Boolean(formik.errors.applicant_email) ? 'green' : 'red'
                        }
                        error={formik.touched.applicant_email && Boolean(formik.errors.applicant_email)}
                        helperText={formik.touched.applicant_email && formik.errors.applicant_email}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.applicant_email && Boolean(formik.errors.applicant_email)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.applicant_email && Boolean(formik.errors.applicant_email)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.applicant_email && Boolean(formik.errors.applicant_email)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Company
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <TextField
                        fullWidth
                        id="company"
                        // label="Company"
                        placeholder="Company"
                        size="small"
                        variant="outlined"
                        value={member?.company?.name}
                        className={formik.touched.company && Boolean(formik.errors.company) ? 'green' : 'red'}
                        error={formik.touched.company && Boolean(formik.errors.company)}
                        helperText={formik.touched.company && formik.errors.company}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.company && Boolean(formik.errors.company) ? 'red' : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.company && Boolean(formik.errors.company) ? 'red' : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.company && Boolean(formik.errors.company) ? 'red' : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Company Address
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <TextField
                        fullWidth
                        id="address_line"
                        label="Company Address"
                        placeholder="Company Address"
                        size="small"
                        variant="outlined"
                        {...formik.getFieldProps('address_line')}
                        className={formik.touched.address_line && Boolean(formik.errors.address_line) ? 'green' : 'red'}
                        error={formik.touched.address_line && Boolean(formik.errors.address_line)}
                        helperText={formik.touched.address_line && formik.errors.address_line}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.address_line && Boolean(formik.errors.address_line)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.address_line && Boolean(formik.errors.address_line)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.address_line && Boolean(formik.errors.address_line)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        City
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <TextField
                        fullWidth
                        id="city"
                        label="City"
                        placeholder="City"
                        size="small"
                        variant="outlined"
                        {...formik.getFieldProps('city')}
                        className={formik.touched.city && Boolean(formik.errors.city) ? 'green' : 'red'}
                        error={formik.touched.city && Boolean(formik.errors.city)}
                        helperText={formik.touched.city && formik.errors.city}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.city && Boolean(formik.errors.city) ? 'red' : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.city && Boolean(formik.errors.city) ? 'red' : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.city && Boolean(formik.errors.city) ? 'red' : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        State
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <TextField
                        fullWidth
                        id="state"
                        label="State"
                        placeholder="State"
                        size="small"
                        variant="outlined"
                        {...formik.getFieldProps('state')}
                        className={formik.touched.state && Boolean(formik.errors.state) ? 'green' : 'red'}
                        error={formik.touched.state && Boolean(formik.errors.state)}
                        helperText={formik.touched.state && formik.errors.state}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.state && Boolean(formik.errors.state) ? 'red' : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.state && Boolean(formik.errors.state) ? 'red' : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor: formik.touched.state && Boolean(formik.errors.state) ? 'red' : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        ZIP Code
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <TextField
                        fullWidth
                        id="zip_code"
                        label="ZIP Code"
                        placeholder="ZIP Code"
                        size="small"
                        variant="outlined"
                        {...formik.getFieldProps('zip_code')}
                        className={formik.touched.zip_code && Boolean(formik.errors.zip_code) ? 'green' : 'red'}
                        error={formik.touched.zip_code && Boolean(formik.errors.zip_code)}
                        helperText={formik.touched.zip_code && formik.errors.zip_code}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.zip_code && Boolean(formik.errors.zip_code) ? 'red' : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.zip_code && Boolean(formik.errors.zip_code) ? 'red' : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.zip_code && Boolean(formik.errors.zip_code) ? 'red' : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Typography
                        sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                        variant="body1"
                        gutterBottom
                        component="p"
                    >
                        Date of Application
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <TextField
                        fullWidth
                        id="date_of_hire"
                        size="small"
                        variant="outlined"
                        {...formik.getFieldProps('date_of_hire')}
                        type="date"
                        className={formik.touched.date_of_hire && Boolean(formik.errors.date_of_hire) ? 'green' : 'red'}
                        error={formik.touched.date_of_hire && Boolean(formik.errors.date_of_hire)}
                        helperText={formik.touched.date_of_hire && formik.errors.date_of_hire}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.date_of_hire && Boolean(formik.errors.date_of_hire)
                                            ? 'red'
                                            : 'green'
                                },
                                '&:hover fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.date_of_hire && Boolean(formik.errors.date_of_hire)
                                            ? 'red'
                                            : 'green'
                                },
                                '&.Mui-focused fieldset': {
                                    // Check Validation
                                    borderColor:
                                        formik.touched.date_of_hire && Boolean(formik.errors.date_of_hire)
                                            ? 'red'
                                            : 'green'
                                }
                            }
                        }}
                        InputLabelProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                        disabled={
                            member?.terminated_at || (data && !['Admin', 'SuperAdmin'].includes(currentUser?.role!))
                                ? true
                                : false
                        }
                        InputProps={{
                            style: {
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }
                        }}
                    />
                </Grid>
                {(!data || (data && currentUser && ['Admin', 'SuperAdmin'].includes(currentUser?.role))) &&
                !isDriverFile ? (
                    <Grid item xs={12} sm={12}>
                        <Button
                            fullWidth
                            disabled={!!member?.terminated_at}
                            onClick={() => formik.handleSubmit()}
                            variant="contained"
                            color="primary"
                        >
                            Save
                        </Button>
                        {currentUser?.role !== 'Admin' && currentUser?.role !== 'SuperAdmin' && (
                            <Typography
                                sx={{ color: 'red', textAlign: 'center', mt: 2 }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Once you click save, you will not be able to edit this form.
                            </Typography>
                        )}
                    </Grid>
                ) : null}
                {data && currentUser?.role === 'SuperAdmin' && (
                    <Grid item xs={12} sm={12}>
                        <Button
                            fullWidth
                            disabled={!!member?.terminated_at}
                            onClick={() => {
                                const res = window.confirm('Are you sure you want to delete this form?');
                                if (res && data) {
                                    deleteApplicantBasicInfo(data.id);
                                }
                            }}
                            variant="contained"
                            color="primary"
                        >
                            Delete Form
                        </Button>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default BasicApplicantInformation;
