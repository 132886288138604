import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
  IApplicantBasicInfo,
  IApplicantBasicInfoDto,
} from '../../interfaces/DriversApplicationForEmployment/applicant.basic-info';

export const applicantBasicInfoApi = createApi({
  reducerPath: 'applicant_basic_info',
  keepUnusedDataFor: 60 * 10, // 10 minutes
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/basic-info`,
  }),
  tagTypes: ['ApplicantBasicInfo'],
  endpoints: (builder) => ({
    createApplicantBasicInfo: builder.mutation<IApplicantBasicInfo, IApplicantBasicInfoDto>({
      query: (body) => ({
        url: '/',
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'ApplicantBasicInfo', id: 'ApplicantBasicInfo'}],
    }),
    updateApplicantBasicInfo: builder.mutation<IApplicantBasicInfo, IApplicantBasicInfo>({
      query: (body) => ({
        url: `/${body.id}/`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'ApplicantBasicInfo', id: 'ApplicantBasicInfo'}],
    }),
    deleteApplicantBasicInfo: builder.mutation<void, number>({
      query: (id) => ({
        url: `/${id}/`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'ApplicantBasicInfo', id: 'ApplicantBasicInfo'}],
    }),
    getApplicantBasicInfo: builder.mutation<IApplicantBasicInfo, number | void>({
      query: (id) => ({
        url: `/applicant-basicinfo/`,
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'ApplicantBasicInfo', id: 'ApplicantBasicInfo'}],
    }),
    getApplicantBasicInfoByUser: builder.query<IApplicantBasicInfo, void>({
      query: () => ({
        url: `/applicant-basicinfo-by-user/`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'ApplicantBasicInfo', id: 'ApplicantBasicInfo'}],
    }),
    getApplicantBasicInfoByUserId: builder.query<IApplicantBasicInfo, number | void>({
      query: (id) => ({
        url: `/applicant-basicinfo-by-user-id/`,
        method: 'POST',
        body: {
          userId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'ApplicantBasicInfo', id: 'ApplicantBasicInfo'}],
    }),
  }),
});

export const {
  useCreateApplicantBasicInfoMutation,
  useUpdateApplicantBasicInfoMutation,
  useGetApplicantBasicInfoMutation,
  useGetApplicantBasicInfoByUserQuery,
  useGetApplicantBasicInfoByUserIdQuery,
  useDeleteApplicantBasicInfoMutation,
} = applicantBasicInfoApi;
