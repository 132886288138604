import {createContext} from 'react';
import {ICompanyCreate} from '../../app/pages/dashboard/CreateCompanyModal';
import {IDriverUploadData} from '../../app/pages/drivers-bulk-upload/DriverBulkUpload';

export enum CompanyPaymentStatus {
  PENDING = 'Pending',
  PAID = 'Paid',
}

export interface ICompanyPackage {
  product_id: string;
  numberOfDrivers: number;
}

export type CompanyInvitation = {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  payment_link?: string;
  payment_status: CompanyPaymentStatus;
  company_package: ICompanyPackage[];
  is_active: boolean;
  email_sent: boolean;
  created_at: string;
  updated_at: string;
};

export type CompanyInvitationDto = Omit<
  CompanyInvitation,
  'created_at' | 'updated_at' | 'is_active' | 'payment_link' | 'id'
>;

export type CompanyInvitationContextProps = {
  invitations: CompanyInvitation[];
  invitation: CompanyInvitation | undefined;
  invitationLoading: boolean;
  getInvitation: (id: string) => Promise<void>;
  getInvitations: () => Promise<void>;
  createInvitation: (invitation: CompanyInvitationDto) => Promise<void>;
  updateInvitation: (invitation: CompanyInvitation) => Promise<void>;
  deleteInvitation: (email: number) => Promise<void>;
  sendPaymentLink: (email: string) => Promise<void>;
  sendInvitation: (email: string) => Promise<void>;
  createPaymentLink: (email: string) => Promise<void>;
  createCompanyProfile: (data: ICompanyCreate) => Promise<void>;
  createBulkDrivers: (data: IDriverUploadData[], company_id: number) => Promise<void>;
  inviteDrivers: (email_list: string[], companyId: number) => Promise<void>;
};

export const CompanyInvitationInitialState: CompanyInvitationContextProps = {
  invitations: [],
  invitation: undefined,
  invitationLoading: false,
  getInvitation: async () => {},
  getInvitations: async () => {},
  createInvitation: () => new Promise((resolve: any) => resolve()) as Promise<any>,
  updateInvitation: async () => {},
  deleteInvitation: async () => {},
  sendPaymentLink: async () => {},
  sendInvitation: async () => {},
  createPaymentLink: async () => {},
  createCompanyProfile: async () => {},
  createBulkDrivers: async () => {},
  inviteDrivers: async () => {},
};

export const CompanyInvitationContext = createContext<CompanyInvitationContextProps>(
  CompanyInvitationInitialState,
);
