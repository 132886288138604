import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
  IRequesterSignature,
  IRequesterSignatureDto,
} from '../../interfaces/RequestForCheckOfDrivingRecords/requester-signature';

export const requesterSignatureApi = createApi({
  reducerPath: 'requester_signature',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/requester-signature`,
  }),
  tagTypes: ['RequesterSignature'],
  keepUnusedDataFor: 60 * 10, // 10 minutes
  endpoints: (builder) => ({
    createRequesterSignature: builder.mutation<IRequesterSignature, IRequesterSignatureDto>({
      query: (body) => ({
        url: '/',
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'RequesterSignature', id: 'RequesterSignature'}],
    }),
    updateRequesterSignature: builder.mutation<IRequesterSignature, IRequesterSignature>({
      query: (body) => ({
        url: `/${body.id}/`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'RequesterSignature', id: 'RequesterSignature'}],
    }),
    deleteRequesterSignature: builder.mutation<void, number>({
      query: (id) => ({
        url: `/${id}/`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'RequesterSignature', id: 'RequesterSignature'}],
    }),
    getRequesterSignature: builder.query<IRequesterSignature, number | void>({
      query: (id) => ({
        url: `/get-requester-signature/`,
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'RequesterSignature', id: 'RequesterSignature'}],
    }),
    getRequesterSignatureByUser: builder.query<IRequesterSignature, void>({
      query: () => ({
        url: `/get-requester-signature-by-user/`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'RequesterSignature', id: 'RequesterSignature'}],
    }),
    getRequestBySignatureByUserId: builder.query<IRequesterSignature, number | void>({
      query: (id) => ({
        url: `/get-requester-signature-by-user-id/`,
        method: 'POST',
        body: {
          userId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'RequesterSignature', id: 'RequesterSignature'}],
    }),
  }),
});

export const {
  useCreateRequesterSignatureMutation,
  useUpdateRequesterSignatureMutation,
  useGetRequesterSignatureQuery,
  useGetRequesterSignatureByUserQuery,
  useGetRequestBySignatureByUserIdQuery,
  useDeleteRequesterSignatureMutation,
} = requesterSignatureApi;
