import { Container, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../modules/auth';

const CompleteDriverFileDrugAlcohol = () => {
    const { logout } = useAuth();
    //  Get Company Name from URL
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const companyName = searchParams.get('company');

    useEffect(() => {
        logout();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '90vh',
                    flexDirection: 'column'
                }}
            >
                <img
                    src="/media/logos/compliance-01.png"
                    alt="Compliance Masters Logo"
                    style={{
                        width: 200,
                        height: 200
                    }}
                />
                <Typography sx={{ mr: 5 }} variant="h4" gutterBottom component="h4" align="center">
                    Thank you for completing the required form with Compliance Masters for {companyName}. You will be
                    notified when the investigation is complete, but you won’t ever need to log back in to your account.
                    We wish you the best. Remember, your safety is always first!
                </Typography>
            </div>
        </Container>
    );
};

export default CompleteDriverFileDrugAlcohol;
