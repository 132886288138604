import { Button, Checkbox, FormControlLabel, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useAuth } from '../../../app/modules/auth';
import { IMember } from '../../../models';
import { changeStringLast4 } from '../../../shared';
import {
    ExperienceAndQualifications,
    ExperienceAndQualificationsDto,
    LicenseQuestionsDto
} from '../../../store/interfaces/EmploymentHistory/experience-and-qualifications';
import {
    useCreateExperienceAndQualificationsMutation,
    useCreateLicenseQuestionsMutation,
    useDeleteExperienceAndQualificationsMutationMutation,
    useGetExperieneAndQualificationsByUserIdQuery,
    useGetLicenseQuestionsByUserIdQuery,
    useUpdateExperienceAndQualificationsMutation,
    useUpdateLicenseQuestionsMutation
} from '../../../store/reducers/EmploymentHistory/experience-and-qualification';
import { useThemeMode } from '../../partials';

const initialValues: Partial<ExperienceAndQualificationsDto> = {
    state: '',
    license_number: '',
    license_class: '',
    endorsements: '',
    expiration_date: ''
};

const validationSchema = Yup.object({
    state: Yup.string().required('Required'),
    license_number: Yup.string().required('Required'),
    license_class: Yup.string().required('Required'),
    endorsements: Yup.string().required('Required'),
    expiration_date: Yup.string().required('Required')
});

const licenseInitialValues: Partial<LicenseQuestionsDto> = {
    denied_license: false,
    suspended_license: false,
    details: ''
};
const licenseValidationSchema = Yup.object({});

const ExperienceAndQualificationItem = ({
    item,
    member,
    isDriverFile
}: {
    item: ExperienceAndQualifications;
    member?: IMember;
    isDriverFile?: boolean;
}) => {
    const { mode } = useThemeMode();
    const { currentUser } = useAuth();
    const [updateExperienceAndQualifications, { isLoading: isUpdating }] =
        useUpdateExperienceAndQualificationsMutation();
    const [deleteExperienceAndQualifications, { isLoading: isDeleting }] =
        useDeleteExperienceAndQualificationsMutationMutation();

    const formik = useFormik({
        initialValues: item,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            updateExperienceAndQualifications({
                ...values
            });
            resetForm();
        },
        validateOnBlur: true,
        validateOnChange: true,
        validate: (values) => {
            const errors: Partial<ExperienceAndQualificationsDto> = {};
            if (values.state?.length === 0) {
                errors.state = 'Required';
            }
            if (values.license_number?.length === 0) {
                errors.license_number = 'Required';
            }
            if (values.license_class?.length === 0) {
                errors.license_class = 'Required';
            }
            if (values.endorsements?.length === 0) {
                errors.endorsements = 'Required';
            }
            if (values.expiration_date?.length === 0) {
                errors.expiration_date = 'Required';
            }
            return errors;
        }
    });

    useEffect(() => {
        formik.setValues({
            ...item,
            license_number: ['Member', 'Owner'].includes(currentUser?.role!)
                ? changeStringLast4(item?.license_number, '*')!
                : item?.license_number
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item]);

    return (
        <>
            <Grid item xs={12} sm={12}>
                <Typography
                    sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                    variant="body1"
                    gutterBottom
                    component="p"
                >
                    State
                </Typography>
                <TextField
                    fullWidth
                    id="state"
                    placeholder="State"
                    size="small"
                    variant="outlined"
                    {...formik.getFieldProps('state')}
                    className={formik.touched.state && Boolean(formik.errors.state) ? 'green' : 'red'}
                    error={formik.touched.state && Boolean(formik.errors.state)}
                    helperText={formik.touched.state && formik.errors.state}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                // Check Validation
                                borderColor: formik.touched.state && Boolean(formik.errors.state) ? 'red' : 'green'
                            },
                            '&:hover fieldset': {
                                // Check Validation
                                borderColor: formik.touched.state && Boolean(formik.errors.state) ? 'red' : 'green'
                            },
                            '&.Mui-focused fieldset': {
                                // Check Validation
                                borderColor: formik.touched.state && Boolean(formik.errors.state) ? 'red' : 'green'
                            }
                        }
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                        }
                    }}
                    disabled={!!member?.terminated_at}
                    InputProps={{
                        style: {
                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                        }
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography
                    sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                    variant="body1"
                    gutterBottom
                    component="p"
                >
                    License No
                </Typography>
                <TextField
                    fullWidth
                    id="license_number"
                    placeholder="License No"
                    size="small"
                    variant="outlined"
                    {...formik.getFieldProps('license_number')}
                    className={formik.touched.license_number && Boolean(formik.errors.license_number) ? 'green' : 'red'}
                    error={formik.touched.license_number && Boolean(formik.errors.license_number)}
                    helperText={formik.touched.license_number && formik.errors.license_number}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                // Check Validation
                                borderColor:
                                    formik.touched.license_number && Boolean(formik.errors.license_number)
                                        ? 'red'
                                        : 'green'
                            },
                            '&:hover fieldset': {
                                // Check Validation
                                borderColor:
                                    formik.touched.license_number && Boolean(formik.errors.license_number)
                                        ? 'red'
                                        : 'green'
                            },
                            '&.Mui-focused fieldset': {
                                // Check Validation
                                borderColor:
                                    formik.touched.license_number && Boolean(formik.errors.license_number)
                                        ? 'red'
                                        : 'green'
                            }
                        }
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                        }
                    }}
                    disabled={!!member?.terminated_at}
                    InputProps={{
                        style: {
                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                        }
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography
                    sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                    variant="body1"
                    gutterBottom
                    component="p"
                >
                    Class
                </Typography>
                <TextField
                    fullWidth
                    id="license_class"
                    placeholder="Class"
                    size="small"
                    variant="outlined"
                    {...formik.getFieldProps('license_class')}
                    className={formik.touched.license_class && Boolean(formik.errors.license_class) ? 'green' : 'red'}
                    error={formik.touched.license_class && Boolean(formik.errors.license_class)}
                    helperText={formik.touched.license_class && formik.errors.license_class}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                // Check Validation
                                borderColor:
                                    formik.touched.license_class && Boolean(formik.errors.license_class)
                                        ? 'red'
                                        : 'green'
                            },
                            '&:hover fieldset': {
                                // Check Validation
                                borderColor:
                                    formik.touched.license_class && Boolean(formik.errors.license_class)
                                        ? 'red'
                                        : 'green'
                            },
                            '&.Mui-focused fieldset': {
                                // Check Validation
                                borderColor:
                                    formik.touched.license_class && Boolean(formik.errors.license_class)
                                        ? 'red'
                                        : 'green'
                            }
                        }
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                        }
                    }}
                    disabled={!!member?.terminated_at}
                    InputProps={{
                        style: {
                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                        }
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography
                    sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                    variant="body1"
                    gutterBottom
                    component="p"
                >
                    Endorsements(s)
                </Typography>
                <TextField
                    fullWidth
                    id="endorsements"
                    placeholder="Endorsements(s)"
                    size="small"
                    variant="outlined"
                    {...formik.getFieldProps('endorsements')}
                    className={formik.touched.endorsements && Boolean(formik.errors.endorsements) ? 'green' : 'red'}
                    error={formik.touched.endorsements && Boolean(formik.errors.endorsements)}
                    helperText={formik.touched.endorsements && formik.errors.endorsements}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                // Check Validation
                                borderColor:
                                    formik.touched.endorsements && Boolean(formik.errors.endorsements) ? 'red' : 'green'
                            },
                            '&:hover fieldset': {
                                // Check Validation
                                borderColor:
                                    formik.touched.endorsements && Boolean(formik.errors.endorsements) ? 'red' : 'green'
                            },
                            '&.Mui-focused fieldset': {
                                // Check Validation
                                borderColor:
                                    formik.touched.endorsements && Boolean(formik.errors.endorsements) ? 'red' : 'green'
                            }
                        }
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                        }
                    }}
                    disabled={!!member?.terminated_at}
                    InputProps={{
                        style: {
                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                        }
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography
                    sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                    variant="body1"
                    gutterBottom
                    component="p"
                >
                    Expiration Date
                </Typography>
                <TextField
                    fullWidth
                    id="expiration_date"
                    placeholder="Expiration Date"
                    size="small"
                    variant="outlined"
                    type="date"
                    {...formik.getFieldProps('expiration_date')}
                    className={
                        formik.touched.expiration_date && Boolean(formik.errors.expiration_date) ? 'green' : 'red'
                    }
                    error={formik.touched.expiration_date && Boolean(formik.errors.expiration_date)}
                    helperText={formik.touched.expiration_date && formik.errors.expiration_date}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                // Check Validation
                                borderColor:
                                    formik.touched.expiration_date && Boolean(formik.errors.expiration_date)
                                        ? 'red'
                                        : 'green'
                            },
                            '&:hover fieldset': {
                                // Check Validation
                                borderColor:
                                    formik.touched.expiration_date && Boolean(formik.errors.expiration_date)
                                        ? 'red'
                                        : 'green'
                            },
                            '&.Mui-focused fieldset': {
                                // Check Validation
                                borderColor:
                                    formik.touched.expiration_date && Boolean(formik.errors.expiration_date)
                                        ? 'red'
                                        : 'green'
                            }
                        }
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                        }
                    }}
                    disabled={!!member?.terminated_at}
                    InputProps={{
                        style: {
                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                        }
                    }}
                />
            </Grid>
            {(!item || (item && currentUser && ['Admin', 'SuperAdmin'].includes(currentUser?.role))) &&
            !isDriverFile ? (
                <>
                    <Grid item xs={12} sm={6}>
                        <Button
                            fullWidth
                            disabled={isUpdating || !!member?.terminated_at}
                            onClick={() => formik.handleSubmit()}
                            variant="contained"
                            color="primary"
                        >
                            Update
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            fullWidth
                            disabled={isDeleting || !!member?.terminated_at}
                            onClick={() => {
                                const res = window.confirm('Are you sure you want to delete this item?');
                                if (res) {
                                    deleteExperienceAndQualifications(item.id);
                                }
                            }}
                            variant="contained"
                            color="error"
                        >
                            Delete
                        </Button>
                    </Grid>
                </>
            ) : null}
        </>
    );
};

const ExperienceAndQualificationsComponent = ({
    member,
    isDriverFile
}: {
    member?: IMember;
    isDriverFile?: boolean;
}) => {
    const { mode } = useThemeMode();
    const { currentUser } = useAuth();
    const { data = [] } = useGetExperieneAndQualificationsByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true
    });
    const { data: licenseQuestions } = useGetLicenseQuestionsByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true
    });
    const [createExperienceAndQualifications, { isLoading: isCreating }] =
        useCreateExperienceAndQualificationsMutation();
    const [createLicenseQuestions] = useCreateLicenseQuestionsMutation();
    const [updateLicenseQuestions] = useUpdateLicenseQuestionsMutation();

    const [addNewItem, setAddNewItem] = useState(true);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            createExperienceAndQualifications({
                ...values,
                // @ts-ignore
                member: member?.id,
                // @ts-ignore
                company: member?.company?.id
            });
            resetForm();
        },
        validateOnBlur: true,
        validateOnChange: true,
        validate: (values) => {
            const errors: Partial<ExperienceAndQualificationsDto> = {};
            if (values.state?.length === 0) {
                errors.state = 'Required';
            }
            if (values.license_number?.length === 0) {
                errors.license_number = 'Required';
            }
            if (values.license_class?.length === 0) {
                errors.license_class = 'Required';
            }
            if (values.endorsements?.length === 0) {
                errors.endorsements = 'Required';
            }
            if (values.expiration_date?.length === 0) {
                errors.expiration_date = 'Required';
            }
            return errors;
        }
    });

    const licenseFormik = useFormik({
        initialValues: licenseInitialValues,
        validationSchema: licenseValidationSchema,
        onSubmit: (values, { resetForm }) => {
            if (licenseQuestions) {
                // @ts-ignore
                updateLicenseQuestions({
                    // @ts-ignore
                    ...values,
                    // @ts-ignore
                    id: licenseQuestions.id
                });
                resetForm();
            } else {
                createLicenseQuestions({
                    ...values,
                    // @ts-ignore
                    member: member?.id,
                    // @ts-ignore
                    company: member?.company?.id
                });
                resetForm();
            }
        },
        validateOnBlur: true,
        validateOnChange: true,
        validate: (values) => {
            const errors: Partial<LicenseQuestionsDto> = {};
            if ((values.suspended_license || values.denied_license) && values.details?.length === 0) {
                errors.details = 'Required';
            }
            return errors;
        }
    });

    useEffect(() => {
        if (licenseQuestions) {
            licenseFormik.setValues(licenseQuestions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [licenseQuestions]);

    useEffect(() => {
        if (data.length > 0) {
            setAddNewItem(false);
        } else {
            setAddNewItem(true);
        }
    }, [data]);

    useEffect(() => {
        if (data.length < 1) {
            formik.setFieldValue('license_number', member?.drivers_license_number);
            formik.setFieldValue('state', member?.state);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, member]);

    return (
        <div
            style={{
                padding: 10,
                margin: 10
            }}
        >
            <Typography
                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)', textAlign: 'center' }}
                variant="h4"
                gutterBottom
                component="h4"
            >
                Experience And Qualifications
            </Typography>
            <Typography
                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                variant="h6"
                gutterBottom
                component="h6"
            >
                Record All Driver's Licenses Held in The Past 3 Years
            </Typography>
            <Grid container spacing={2}>
                {data.map((item) => (
                    <ExperienceAndQualificationItem member={member} key={item.id} item={item} />
                ))}
                {data.length > 0 && (
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <FormControlLabel
                            sx={{
                                color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                            }}
                            control={
                                <Checkbox
                                    sx={{
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }}
                                    checked={addNewItem}
                                />
                            }
                            onChange={() => setAddNewItem(!addNewItem)}
                            label="Add Another Driver's License"
                        />
                    </Grid>
                )}
                {addNewItem && (
                    <>
                        <Grid item xs={12} sm={12}>
                            <Typography
                                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                State
                            </Typography>
                            <TextField
                                fullWidth
                                id="state"
                                placeholder="State"
                                size="small"
                                variant="outlined"
                                {...formik.getFieldProps('state')}
                                className={formik.touched.state && Boolean(formik.errors.state) ? 'green' : 'red'}
                                error={formik.touched.state && Boolean(formik.errors.state)}
                                helperText={formik.touched.state && formik.errors.state}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.state && Boolean(formik.errors.state) ? 'red' : 'green'
                                        },
                                        '&:hover fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.state && Boolean(formik.errors.state) ? 'red' : 'green'
                                        },
                                        '&.Mui-focused fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.state && Boolean(formik.errors.state) ? 'red' : 'green'
                                        }
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                                disabled={!!member?.terminated_at}
                                InputProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography
                                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                License No
                            </Typography>
                            <TextField
                                fullWidth
                                id="license_number"
                                placeholder="License No"
                                size="small"
                                variant="outlined"
                                {...formik.getFieldProps('license_number')}
                                className={
                                    formik.touched.license_number && Boolean(formik.errors.license_number)
                                        ? 'green'
                                        : 'red'
                                }
                                error={formik.touched.license_number && Boolean(formik.errors.license_number)}
                                helperText={formik.touched.license_number && formik.errors.license_number}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.license_number && Boolean(formik.errors.license_number)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&:hover fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.license_number && Boolean(formik.errors.license_number)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&.Mui-focused fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.license_number && Boolean(formik.errors.license_number)
                                                    ? 'red'
                                                    : 'green'
                                        }
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                                disabled={!!member?.terminated_at}
                                InputProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography
                                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Class
                            </Typography>
                            <TextField
                                fullWidth
                                id="license_class"
                                placeholder="Class"
                                size="small"
                                variant="outlined"
                                {...formik.getFieldProps('license_class')}
                                className={
                                    formik.touched.license_class && Boolean(formik.errors.license_class)
                                        ? 'green'
                                        : 'red'
                                }
                                error={formik.touched.license_class && Boolean(formik.errors.license_class)}
                                helperText={formik.touched.license_class && formik.errors.license_class}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.license_class && Boolean(formik.errors.license_class)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&:hover fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.license_class && Boolean(formik.errors.license_class)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&.Mui-focused fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.license_class && Boolean(formik.errors.license_class)
                                                    ? 'red'
                                                    : 'green'
                                        }
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                                disabled={!!member?.terminated_at}
                                InputProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography
                                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Endorsements(s)
                            </Typography>
                            <TextField
                                fullWidth
                                id="endorsements"
                                placeholder="Endorsements(s)"
                                size="small"
                                variant="outlined"
                                {...formik.getFieldProps('endorsements')}
                                className={
                                    formik.touched.endorsements && Boolean(formik.errors.endorsements) ? 'green' : 'red'
                                }
                                error={formik.touched.endorsements && Boolean(formik.errors.endorsements)}
                                helperText={formik.touched.endorsements && formik.errors.endorsements}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.endorsements && Boolean(formik.errors.endorsements)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&:hover fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.endorsements && Boolean(formik.errors.endorsements)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&.Mui-focused fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.endorsements && Boolean(formik.errors.endorsements)
                                                    ? 'red'
                                                    : 'green'
                                        }
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                                disabled={!!member?.terminated_at}
                                InputProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography
                                sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                                variant="body1"
                                gutterBottom
                                component="p"
                            >
                                Expiration Date
                            </Typography>
                            <TextField
                                fullWidth
                                id="expiration_date"
                                placeholder="Expiration Date"
                                size="small"
                                variant="outlined"
                                type="date"
                                {...formik.getFieldProps('expiration_date')}
                                className={
                                    formik.touched.expiration_date && Boolean(formik.errors.expiration_date)
                                        ? 'green'
                                        : 'red'
                                }
                                error={formik.touched.expiration_date && Boolean(formik.errors.expiration_date)}
                                helperText={formik.touched.expiration_date && formik.errors.expiration_date}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.expiration_date && Boolean(formik.errors.expiration_date)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&:hover fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.expiration_date && Boolean(formik.errors.expiration_date)
                                                    ? 'red'
                                                    : 'green'
                                        },
                                        '&.Mui-focused fieldset': {
                                            // Check Validation
                                            borderColor:
                                                formik.touched.expiration_date && Boolean(formik.errors.expiration_date)
                                                    ? 'red'
                                                    : 'green'
                                        }
                                    }
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                                disabled={!!member?.terminated_at}
                                InputProps={{
                                    style: {
                                        color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Button
                                fullWidth
                                disabled={isCreating || !!member?.terminated_at}
                                onClick={() => formik.handleSubmit()}
                                variant="contained"
                                color="primary"
                            >
                                Save
                            </Button>
                        </Grid>
                    </>
                )}
            </Grid>

            <Typography
                sx={{
                    color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)',
                    textAlign: 'center',
                    mt: 3
                }}
                variant="h4"
                gutterBottom
                component="h4"
            >
                Select Yes or No for each of the following questions
            </Typography>
            <Grid item xs={12} sm={12}>
                <Typography
                    sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                    variant="body1"
                    gutterBottom
                    component="p"
                >
                    Have you ever been denied a license, permit or privilege to operate a motor vehicle?
                </Typography>
                <TextField
                    fullWidth
                    id="denied_license"
                    size="small"
                    variant="outlined"
                    value={licenseFormik.values.denied_license ? 'Yes' : 'No'}
                    onChange={(e) => {
                        licenseFormik.setFieldValue('denied_license', e.target.value === 'Yes' ? true : false);
                    }}
                    select
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                // Check Validation
                                borderColor:
                                    licenseFormik.touched.denied_license && Boolean(licenseFormik.errors.denied_license)
                                        ? 'red'
                                        : 'green'
                            },
                            '&:hover fieldset': {
                                // Check Validation
                                borderColor:
                                    licenseFormik.touched.denied_license && Boolean(licenseFormik.errors.denied_license)
                                        ? 'red'
                                        : 'green'
                            },
                            '&.Mui-focused fieldset': {
                                // Check Validation
                                borderColor:
                                    licenseFormik.touched.denied_license && Boolean(licenseFormik.errors.denied_license)
                                        ? 'red'
                                        : 'green'
                            }
                        }
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                        }
                    }}
                    disabled={!!member?.terminated_at}
                    InputProps={{
                        style: {
                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                        }
                    }}
                >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                </TextField>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography
                    sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                    variant="body1"
                    gutterBottom
                    component="p"
                >
                    Have any license, permit or privilege ever been suspended or revoked?
                </Typography>
                <TextField
                    fullWidth
                    id="suspended_license"
                    size="small"
                    variant="outlined"
                    value={licenseFormik.values.suspended_license ? 'Yes' : 'No'}
                    onChange={(e) => {
                        licenseFormik.setFieldValue('suspended_license', e.target.value === 'Yes' ? true : false);
                    }}
                    select
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                // Check Validation
                                borderColor:
                                    licenseFormik.touched.suspended_license &&
                                    Boolean(licenseFormik.errors.suspended_license)
                                        ? 'red'
                                        : 'green'
                            },
                            '&:hover fieldset': {
                                // Check Validation
                                borderColor:
                                    licenseFormik.touched.suspended_license &&
                                    Boolean(licenseFormik.errors.suspended_license)
                                        ? 'red'
                                        : 'green'
                            },
                            '&.Mui-focused fieldset': {
                                // Check Validation
                                borderColor:
                                    licenseFormik.touched.suspended_license &&
                                    Boolean(licenseFormik.errors.suspended_license)
                                        ? 'red'
                                        : 'green'
                            }
                        }
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                        }
                    }}
                    disabled={!!member?.terminated_at}
                    InputProps={{
                        style: {
                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                        }
                    }}
                >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                </TextField>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography
                    sx={{ color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)' }}
                    variant="body1"
                    gutterBottom
                    component="p"
                >
                    If the answer to either of the above questions is yes, give details:
                </Typography>
                <TextField
                    fullWidth
                    id="details"
                    size="small"
                    variant="outlined"
                    value={licenseFormik.values.details}
                    onChange={(e) => {
                        licenseFormik.setFieldValue('details', e.target.value);
                    }}
                    multiline
                    rows={4}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                // Check Validation
                                borderColor:
                                    licenseFormik.touched.details && Boolean(licenseFormik.errors.details)
                                        ? 'red'
                                        : 'green'
                            },
                            '&:hover fieldset': {
                                // Check Validation
                                borderColor:
                                    licenseFormik.touched.details && Boolean(licenseFormik.errors.details)
                                        ? 'red'
                                        : 'green'
                            },
                            '&.Mui-focused fieldset': {
                                // Check Validation
                                borderColor:
                                    licenseFormik.touched.details && Boolean(licenseFormik.errors.details)
                                        ? 'red'
                                        : 'green'
                            }
                        }
                    }}
                    InputLabelProps={{
                        style: {
                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                        }
                    }}
                    disabled={!!member?.terminated_at}
                    InputProps={{
                        style: {
                            color: mode === 'dark' ? 'rgba(255,255,255,255)' : 'rgba(30,30,45,255)'
                        }
                    }}
                />
            </Grid>
            {(!licenseQuestions ||
                (licenseQuestions && currentUser && ['Admin', 'SuperAdmin'].includes(currentUser?.role))) &&
            !isDriverFile ? (
                <Grid item xs={12} sm={12} sx={{ mt: 2 }}>
                    <Button
                        fullWidth
                        disabled={isCreating || !!member?.terminated_at}
                        onClick={() => licenseFormik.handleSubmit()}
                        variant="contained"
                        color="primary"
                    >
                        Save
                    </Button>
                    {currentUser?.role !== 'Admin' && currentUser?.role !== 'SuperAdmin' && (
                        <Typography
                            sx={{ color: 'red', textAlign: 'center', mt: 2 }}
                            variant="body1"
                            gutterBottom
                            component="p"
                        >
                            Once you click save, you will not be able to edit this form.
                        </Typography>
                    )}
                </Grid>
            ) : null}
        </div>
    );
};

export default ExperienceAndQualificationsComponent;
