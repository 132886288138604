import { Box, Grid, Paper, TableBody, TableCell, TablePagination, TableRow, Typography } from '@mui/material';
import { useState } from 'react';
import { KTSVG } from '../../../../compliancemasters/helpers';
import TableContainer from '../../../../compliancemasters/partials/widgets/tables/components/TableContainer';
import TableContainerHeader from '../../../../compliancemasters/partials/widgets/tables/components/TableContainerHeader';
import TableContainerHeaderTitle from '../../../../compliancemasters/partials/widgets/tables/components/TableContainerHeaderTitle';
import TableHeader from '../../../../compliancemasters/partials/widgets/tables/components/TableHeader';
import TableHeaderColumn from '../../../../compliancemasters/partials/widgets/tables/components/TableHeaderColumn';
import TableWrapper from '../../../../compliancemasters/partials/widgets/tables/components/TableWrapper';
import { downloadFile } from '../../../../shared/client/service-orders';
import { IMaintenanceVehicle } from '../../../../store/interfaces/PreventableMaintenance/maintenance.vehicles';
import { ServiceOrderType } from '../../../../store/interfaces/PreventableMaintenance/service.orders';
import { useGetServiceOrderByVehicleQuery } from '../../../../store/reducers/PreventableMaintenance/service.orders';
import { AddServiceOrderModal } from './AddServiceOrderModal';

interface IProps {
    vehicle?: IMaintenanceVehicle;
}

const ServiceOrders: React.FunctionComponent<IProps> = ({ vehicle }) => {
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState<number>(0);
    const [miscPage, setMiscPage] = useState<number>(0);
    const { data: service_orders = [] } = useGetServiceOrderByVehicleQuery(Number(vehicle?.id) ?? 0, {
        skip: !vehicle?.id,
        refetchOnFocus: true
    });
    const [limit, setLimit] = useState<number>(5);
    const [miscLimit, setMiscLimit] = useState<number>(5);

    const handlePageChange = (event: any, newPage: number): void => {
        setPage(newPage);
    };

    const handleMiscPageChange = (event: any, newPage: number): void => {
        setMiscPage(newPage);
    };

    const handleLimitChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setLimit(parseInt(event.target.value));
    };

    const handleMiscLimitChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setMiscLimit(parseInt(event.target.value));
    };

    function handleDownloadFile(id: number) {
        downloadFile(id).then((res) => {
            window.open(res.url, '_blank');
        });
    }

    return (
        <>
            <Box component={Paper} elevation={2} boxShadow={3} sx={{ borderRadius: 2 }} p={2} mt={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'gray' }}>
                            PM Program Service Order Uploads
                        </Typography>
                    </Grid>
                </Grid>
                <TableWrapper>
                    <TableContainer>
                        <TableContainer>
                            <TableHeader>
                                <TableHeaderColumn>#</TableHeaderColumn>
                                <TableHeaderColumn>File Name</TableHeaderColumn>
                                <TableHeaderColumn>Desc.</TableHeaderColumn>
                                <TableHeaderColumn>Date</TableHeaderColumn>
                            </TableHeader>
                            <TableBody>
                                {service_orders
                                    ?.filter((order) => order.type === ServiceOrderType.DVIR_REPORT)
                                    ?.slice(page * limit, page * limit + limit)
                                    .map((service_order, index) => (
                                        <TableRow hover key={index}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell
                                                sx={{
                                                    cursor: 'pointer',
                                                    color: 'blue'
                                                }}
                                                onClick={() => handleDownloadFile(Number(service_order.id))}
                                            >
                                                {service_order.title}
                                            </TableCell>
                                            <TableCell>
                                                {service_order?.description ? service_order.description : 'N/A'}
                                            </TableCell>
                                            <TableCell>
                                                {service_order?.upload_finished_at
                                                    ? Intl.DateTimeFormat('en-US', {
                                                          year: 'numeric',
                                                          month: 'long',
                                                          day: '2-digit'
                                                      }).format(new Date(service_order.upload_finished_at))
                                                    : 'N/A'}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </TableContainer>
                    </TableContainer>
                    <TablePagination
                        component="div"
                        count={
                            service_orders?.filter((item) => item.type === ServiceOrderType.DVIR_REPORT)?.length ?? 0
                        }
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleLimitChange}
                        page={page}
                        rowsPerPage={limit}
                        rowsPerPageOptions={[10, 25, 30]}
                    />
                </TableWrapper>
                <AddServiceOrderModal open={open} setOpen={setOpen} vehicle={vehicle} />
            </Box>
            <Box component={Paper} elevation={2} boxShadow={3} sx={{ borderRadius: 2 }} p={2} mt={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'gray' }}>
                            Miscellaneous Service Orders
                        </Typography>
                        <Typography variant="body1" sx={{ color: 'green' }}>
                            Please upload any service orders / receipts for this vehicle
                        </Typography>
                    </Grid>
                </Grid>
                <TableWrapper>
                    <TableContainer>
                        <TableContainerHeader>
                            <TableContainerHeaderTitle title="" />
                            <div
                                className="btn btn-sm btn-light-primary d-inline-flex align-items-center"
                                onClick={() => setOpen(true)}
                            >
                                <KTSVG path="media/icons/duotune/arrows/arr075.svg" className="svg-icon-3" />
                                Upload Service Order
                            </div>
                        </TableContainerHeader>
                        <TableContainer>
                            <TableHeader>
                                <TableHeaderColumn>#</TableHeaderColumn>
                                <TableHeaderColumn>File Name</TableHeaderColumn>
                                <TableHeaderColumn>Desc.</TableHeaderColumn>
                                <TableHeaderColumn>Date</TableHeaderColumn>
                            </TableHeader>
                            <TableBody>
                                {service_orders
                                    ?.filter((order) => order.type === ServiceOrderType.MISCELLANEOUS)
                                    ?.slice(page * limit, page * limit + limit)
                                    .map((service_order, index) => (
                                        <TableRow hover key={index}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell
                                                sx={{
                                                    cursor: 'pointer',
                                                    color: 'blue'
                                                }}
                                                onClick={() => handleDownloadFile(Number(service_order.id))}
                                            >
                                                {service_order.title}
                                            </TableCell>
                                            <TableCell>
                                                {service_order?.description ? service_order.description : 'N/A'}
                                            </TableCell>
                                            <TableCell>
                                                {service_order?.upload_finished_at
                                                    ? Intl.DateTimeFormat('en-US', {
                                                          year: 'numeric',
                                                          month: 'long',
                                                          day: '2-digit'
                                                      }).format(new Date(service_order.upload_finished_at))
                                                    : 'N/A'}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </TableContainer>
                    </TableContainer>
                    <TablePagination
                        component="div"
                        count={
                            service_orders?.filter((item) => item.type === ServiceOrderType.MISCELLANEOUS)?.length ?? 0
                        }
                        onPageChange={handleMiscPageChange}
                        onRowsPerPageChange={handleMiscLimitChange}
                        page={miscPage}
                        rowsPerPage={miscLimit}
                        rowsPerPageOptions={[10, 25, 30]}
                    />
                </TableWrapper>
                <AddServiceOrderModal open={open} setOpen={setOpen} vehicle={vehicle} />
            </Box>
        </>
    );
};

export default ServiceOrders;
