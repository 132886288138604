import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IMember } from '../../../models';
import { getCompanyMember } from '../../../shared';
import { Box, Button, Checkbox, Container, FormControlLabel, Paper } from '@mui/material';
import FormHeaderCard from '../HeaderCard';
import DriverIdentification from './DriverIdentification';
import ProspectiveEmployerInformation from './ProspectiveEmployerInformation';
import ActionButtons from '../ActionButtons';
import {
    useGetMemberPreviousEmployeeSafetyPerformanceHistoryProgressMutation,
    useGetPreviousEmployeeSafetyPerformanceHistoryProgressMutation
} from '../../../store/reducers/PreviousEmployeeSafetyPerformanceHistory/progress';
import { useAuth } from '../../../app/modules/auth';
import { useThemeMode } from '../../partials';
import EmployerHistory from '../EmploymentHistory/EmployerHistory';
import AttemptsMade from './AttemptsMade';
import PerformanceHistoryComplete from './PerformanceHistoryComplete';
import { useGetPerformanceHistoryCompleteByUserIdQuery } from '../../../store/reducers/PreviousEmployeeSafetyPerformanceHistory/driver-identification';
import {
    useCreatePerformanceHistoryFormsCompleteMutation,
    useGetPerformanceHistoryFormsCompleteByUserIdQuery,
    useUpdatePerformanceHistoryFormsCompleteMutation
} from '../../../store/reducers/PreviousEmployeeSafetyPerformanceHistory/performance-history-forms-complete';

const PreviousEmployeeSafetyPerformance = () => {
    const { currentUser } = useAuth();
    const { mode } = useThemeMode();
    const [getProgress, { data: progress }] = useGetPreviousEmployeeSafetyPerformanceHistoryProgressMutation();
    const [getMemberProgress, { data: memberProgress }] =
        useGetMemberPreviousEmployeeSafetyPerformanceHistoryProgressMutation();
    const [member, setMember] = useState<IMember>();
    const { data: formsComplete } = useGetPerformanceHistoryFormsCompleteByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true
    });
    const [createPerformanceHistoryComplete, { isLoading: isCreating }] =
        useCreatePerformanceHistoryFormsCompleteMutation();
    const [updatePerformanceHistoryComplete, { isLoading: isUpdating }] =
        useUpdatePerformanceHistoryFormsCompleteMutation();
    const [performanceFormsComplete, setPerformanceFormsComplete] = useState<boolean>(false);
    const { id } = useParams();
    const [value, setValue] = useState(0);

    const { data: complete } = useGetPerformanceHistoryCompleteByUserIdQuery(member?.user?.id, {
        skip: !member?.user?.id,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true
    });

    useEffect(() => {
        if (id) {
            getCompanyMember(id).then((data) => {
                setMember(data);
            });
            getProgress(Number(id));
            getMemberProgress(Number(id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (formsComplete) {
            setPerformanceFormsComplete(formsComplete.form_complete);
        }
    }, [formsComplete]);

    return (
        <Container style={{ userSelect: 'none' }}>
            <Box
                sx={{
                    pl: 3,
                    pr: 3,
                    pt: 3,
                    m: 3,
                    borderRadius: 2,
                    backgroundColor: mode === 'dark' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    width: {
                        lg: '47vw',
                        md: '70vw',
                        sm: '80vw'
                    },
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }}
                component={Paper}
            >
                <FormHeaderCard
                    headerTitle="Previous Employee Safety Performance History"
                    status={
                        progress && progress?.progress > 0 && progress?.progress < 100
                            ? 'In Progress'
                            : progress && progress.progress > 99
                            ? 'Completed'
                            : 'Not Started'
                    }
                    sectionCount={4}
                    itemCount={29}
                    totalProgress={
                        complete && complete?.performance_history_complete ? 100 : progress ? progress.progress : 0
                    }
                    memberProgress={
                        complete && complete?.performance_history_complete
                            ? 100
                            : memberProgress
                            ? memberProgress.progress
                            : 0
                    }
                    nextButton={{ title: 'Previous DACH Statement', path: '' }}
                    member={member}
                    setValue={setValue}
                    value={value}
                />
            </Box>
            {currentUser?.role && ['Admin', 'SuperAdmin', 'Owner'].includes(currentUser?.role) ? (
                <Box
                    sx={{
                        pl: 3,
                        pr: 3,
                        pt: 3,
                        m: 3,
                        borderRadius: 2,
                        backgroundColor: mode === 'dark' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                        width: {
                            lg: '47vw',
                            md: '70vw',
                            sm: '80vw'
                        },
                        marginLeft: 'auto',
                        marginRight: 'auto'
                    }}
                    component={Paper}
                >
                    <PerformanceHistoryComplete member={member} />
                </Box>
            ) : null}
            {!complete || (complete && !complete?.performance_history_complete) ? (
                <>
                    <Box
                        sx={{
                            pl: 3,
                            pr: 3,
                            pt: 3,
                            m: 3,
                            borderRadius: 2,
                            backgroundColor: mode === 'dark' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                            width: {
                                lg: '47vw',
                                md: '70vw',
                                sm: '80vw'
                            },
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}
                        component={Paper}
                    >
                        <DriverIdentification member={member} />
                    </Box>
                    <ProspectiveEmployerInformation member={member} />
                    <Box
                        sx={{
                            pl: 3,
                            pr: 3,
                            Radius: 2,
                            backgroundColor: mode === 'dark' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                            width: {
                                lg: '47vw',
                                md: '70vw',
                                sm: '80vw'
                            },
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginBottom: 3
                        }}
                        component={Paper}
                    >
                        <EmployerHistory member={member} />
                    </Box>
                    <Box
                        sx={{
                            pl: 3,
                            pr: 3,
                            Radius: 2,
                            backgroundColor: mode === 'dark' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                            width: {
                                lg: '47vw',
                                md: '70vw',
                                sm: '80vw'
                            },
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            marginBottom: 3
                        }}
                        component={Paper}
                    >
                        {currentUser && currentUser.role !== 'Member' && <AttemptsMade member={member} />}
                    </Box>
                </>
            ) : null}
            {!performanceFormsComplete && currentUser && ['Admin', 'SuperAdmin'].includes(currentUser?.role) ? (
                <Paper
                    style={{
                        display: 'flex',
                        width: '47vw',
                        justifyContent: 'center',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginTop: 5,
                        marginBottom: 15
                    }}
                >
                    <FormControlLabel
                        onChange={() => {
                            if (formsComplete) {
                                updatePerformanceHistoryComplete({
                                    id: formsComplete?.id,
                                    company: Number(member?.company?.id),
                                    form_complete: !performanceFormsComplete,
                                    member: Number(member?.id)
                                });
                            } else {
                                createPerformanceHistoryComplete({
                                    company: Number(member?.company?.id),
                                    form_complete: true,
                                    member: Number(member?.id)
                                });
                            }
                        }}
                        control={<Checkbox checked={performanceFormsComplete} />}
                        label="Performance History Forms Complete"
                        disabled={
                            isCreating ||
                            isUpdating ||
                            Boolean(member?.terminated_at) ||
                            (formsComplete &&
                                formsComplete.form_complete &&
                                !['Admin', 'SuperAdmin'].includes(currentUser?.role))
                        }
                    />
                </Paper>
            ) : null}
            <ActionButtons
                member={member}
                prevButton={{ title: 'Employment History', path: 'employment-history' }}
                nextButton={{
                    title: member?.pre_employment_screened
                        ? 'Cert. Of Compliance With Driver License Requirements'
                        : '',
                    path: member?.pre_employment_screened
                        ? 'certificate-of-compliance-with-driver-license-requirements'
                        : ''
                }}
            />
            {currentUser?.role === 'Member' && !member?.pre_employment_screened ? (
                <div
                    style={{
                        width: '47vw',
                        marginLeft: 'auto',
                        marginRight: 'auto'
                    }}
                >
                    <Button
                        variant="contained"
                        fullWidth={true}
                        sx={{ mt: 3 }}
                        color="primary"
                        onClick={() => {
                            window.location.assign(`/dashboard/overview`);
                        }}
                    >
                        Done
                    </Button>
                </div>
            ) : null}
            {member?.pre_employment_screened && (
                <div
                    style={{
                        display: 'flex',
                        width: '47vw',
                        justifyContent: 'center',
                        marginLeft: 'auto',
                        marginRight: 'auto'
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            if (currentUser?.role === 'Member') {
                                window.location.assign(`/dashboard/overview`);
                            } else {
                                if (member?.id) {
                                    window.location.assign(`/member/${member?.id}/profile/driver-documents`);
                                }
                            }
                        }}
                        fullWidth
                    >
                        Back to Forms Overview
                    </Button>
                </div>
            )}
        </Container>
    );
};

export default PreviousEmployeeSafetyPerformance;
