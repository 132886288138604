import {ICompany} from '../../../models';

export enum RandomDACHTestingStatus {
  PENDING = 'Pending',
  COMPLETED = 'Completed',
  CANCELLED = 'Cancelled',
}

interface IMemberObj {
  memberId: number;
  testType: string;
}

export interface IRandomDACHTestingDto {
  company: string;
  quarter: string;
  year: string;
  members: IMemberObj[];
}

export interface IRandomDACHTesting {
  company: ICompany;
  quarter: string;
  year: string;
  members: IMemberObj[];
  created_at: string;
  updated_at: string;
  is_active: boolean;
  id: number;
  status: RandomDACHTestingStatus;
  date_completed: string | null;
  viewed: boolean;
}
