import { ButtonToolbar } from 'react-bootstrap';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { IFile, IMember } from '../../../../models';
import { updatePreEmploymentInvestigation } from '../../../../shared/client/pre-employment';
import Button from '../../buttons/Button';

const EndInvestigationsButtons = ({ member, files }: { member: IMember; files: IFile[] }) => {
    const queryClient = useQueryClient();

    const preEmploymentMutation = useMutation(updatePreEmploymentInvestigation, {
        onSuccess: (data) => {
            queryClient.invalidateQueries('pre-employment-investigations');
            // Refresh the page
            window.location.assign(`/dashboard`);
        }
    });

    return (
        <div className="row g-5 g-xxl-8">
            <div className="col-xl-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                    <div className="card-body d-flex flex-column p-9">
                        <div className="d-flex flex-stack flex-grow-1">
                            <h5 className="fw-bolder my-2">
                                Would you like to end the investigation and hire the applicant, or end the investigation
                                and do not hire the applicant?
                            </h5>
                        </div>
                        <div className="d-flex flex-stack align-items-center justify-content-center flex-grow-1">
                            <ButtonToolbar>
                                <Button
                                    className="my-3 mx-3 btn btn-primary"
                                    onClick={() => {
                                        if (files.length < 3) {
                                            return toast.warn(
                                                'The Safety Performance History form completed for you by Compliance Masters, and the upload of at least 3 pre-employment investigations, must be completed before hiring the applicant'
                                            );
                                        }
                                        preEmploymentMutation.mutate({
                                            // @ts-ignore
                                            member: Number(member?.id),
                                            complete: true,
                                            id: member?.pre_employment_investigation?.find(
                                                (investigation) => investigation.is_current_investigation
                                            )?.id,
                                            screened: true
                                        });
                                    }}
                                >
                                    End Investigation and Hire
                                </Button>
                                <Button
                                    className="my-3 mx-3 btn btn-danger"
                                    dataBsToggle="modal"
                                    dataBsTarget="#end_investigation_modal"
                                >
                                    End Investigation
                                </Button>
                            </ButtonToolbar>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EndInvestigationsButtons;
