import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    InputLabel,
    MenuItem,
    Slide,
    TextField
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useFormik } from 'formik';
import { FC, forwardRef, ReactElement, Ref, useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { IUser } from '../../../models';
import { getRoles, IRole } from '../../../shared/client/roles';
import { useAuth } from '../../modules/auth';

interface IProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    user?: IUser;
    setUser: (user?: IUser) => void;
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement<any, any>;
    },
    ref: Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    role: 0
};

const validationSchema = Yup.object({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    role: Yup.number().required('Role is required')
});

const AddUserModal: FC<IProps> = ({ open, setOpen, setUser, user }) => {
    const { createUser } = useAuth();
    const [roles, setRoles] = useState<IRole[]>([]);
    const handleClose = () => {
        setOpen(false);
        setUser(undefined);
    };

    const userMutation = useMutation(createUser, {
        onSuccess: () => {
            setOpen(false);
            setUser(undefined);
            toast.success('User created successfully');
        },
        onError: (err: any) => {
            toast.error(err.message);
        }
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values) => {
            if (user) {
                // Update User
                toast.error('User update not implemented');
            } else {
                userMutation.mutate({
                    first_name: values.first_name,
                    last_name: values.last_name,
                    email: values.email,
                    role: values.role
                });
            }
        }
    });

    const getUserRoles = useCallback(() => {
        getRoles()
            .then((res: IRole[]) => {
                setRoles(res.sort((a, b) => a.id - b.id));
            })
            .catch((err) => {
                toast.error(err.message);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    useEffect(() => {
        getUserRoles();
    }, [getUserRoles]);

    return (
        <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
        >
            <DialogTitle
                sx={{
                    '& .MuiTypography-root': {
                        fontSize: '1.5rem',
                        fontWeight: 'bold'
                    },
                    textAlign: 'center',
                    color: 'gray'
                }}
            >
                {user ? `Edit ${user.first_name} ${user.last_name}` : 'Add New User'}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <InputLabel>First Name</InputLabel>
                        <TextField {...formik.getFieldProps('first_name')} size="small" fullWidth variant="outlined" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputLabel>Last Name</InputLabel>
                        <TextField {...formik.getFieldProps('last_name')} size="small" fullWidth variant="outlined" />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel>Email</InputLabel>
                        <TextField
                            {...formik.getFieldProps('email')}
                            size="small"
                            fullWidth
                            type="email"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField {...formik.getFieldProps('role')} size="small" fullWidth select variant="outlined">
                            <MenuItem value={0}>Select Role</MenuItem>
                            {roles
                                .filter((item) => ['Admin', 'SuperAdmin', 'Assistant'].includes(item.name))
                                .map((role) => (
                                    <MenuItem key={role.id} value={role.id}>
                                        {role.name}
                                    </MenuItem>
                                ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <Button onClick={formik.submitForm} size="small" fullWidth variant="contained" color="primary">
                            {user ? 'Update' : 'Add'}
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default AddUserModal;
