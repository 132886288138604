import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
  IDrivingExperience,
  IDrivingExperienceDto,
} from '../../interfaces/EmploymentHistory/driving-experience';

export const drivingExperienceApi = createApi({
  reducerPath: 'driving_experience',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp/driving-experience`,
  }),
  tagTypes: ['DrivingExperience'],
  keepUnusedDataFor: 60 * 10, // 10 minutes
  endpoints: (builder) => ({
    createDrivingExperience: builder.mutation<IDrivingExperience, IDrivingExperienceDto>({
      query: (body) => ({
        url: '/',
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'DrivingExperience', id: 'DrivingExperience'}],
    }),
    updateDrivingExperience: builder.mutation<IDrivingExperience, IDrivingExperience>({
      query: (body) => ({
        url: `/${body.id}`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'DrivingExperience', id: 'DrivingExperience'}],
    }),
    deleteDrivingExperience: builder.mutation<void, number>({
      query: (id) => ({
        url: `/${id}/`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'DrivingExperience', id: 'DrivingExperience'}],
    }),
    getDrivingExperience: builder.query<IDrivingExperience, number | void>({
      query: (id) => ({
        url: '/get-driving-experience/',
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'DrivingExperience', id: 'DrivingExperience'}],
    }),
    getDrivingExperienceByUser: builder.query<IDrivingExperience, void>({
      query: () => ({
        url: '/get-driving-experience-by-user/',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'DrivingExperience', id: 'DrivingExperience'}],
    }),
    getDrivingExperienceByUserId: builder.query<IDrivingExperience, number | void>({
      query: (id) => ({
        url: '/get-driving-experience-by-user-id/',
        method: 'POST',
        body: {
          userId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'DrivingExperience', id: 'DrivingExperience'}],
    }),
  }),
});

export const {
  useCreateDrivingExperienceMutation,
  useUpdateDrivingExperienceMutation,
  useGetDrivingExperienceQuery,
  useGetDrivingExperienceByUserQuery,
  useGetDrivingExperienceByUserIdQuery,
  useDeleteDrivingExperienceMutation,
} = drivingExperienceApi;
