import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import * as authHelper from '../../../app/modules/auth/core/AuthHelpers';
import {
  IEmploymentInformation,
  IEmploymentInformationDto,
} from '../../interfaces/EmploymentHistory/employer-information';
import {
  IDriverAccidentRecord,
  IDriverAccidentRecordDto,
  IDriversAccidentHistory,
  IDriversAccidentHistoryDto,
  IEmploymentVerification,
  IEmploymentVerificationDto,
  IFTADachInfo,
  IFTADachInfoDto,
} from '../../interfaces/PreviousEmployeeSafetyPerformanceHistory/previous-employer-information';

export const employmentInformationApi = createApi({
  reducerPath: 'employment_information',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASE_URL}/form-revamp`,
  }),
  tagTypes: ['EmploymentInformation'],
  keepUnusedDataFor: 60 * 10, // 10 minutes
  endpoints: (builder) => ({
    createEmploymentInformation: builder.mutation<
      IEmploymentInformation,
      IEmploymentInformationDto
    >({
      query: (body) => ({
        url: '/employer-information/',
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'EmploymentInformation', id: 'EmploymentInformation'}],
    }),
    updateEmploymentInformation: builder.mutation<IEmploymentInformation, IEmploymentInformation>({
      query: (body) => ({
        url: `/employer-information/${body.id}/`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'EmploymentInformation', id: 'EmploymentInformation'}],
    }),
    getEmploymentInformation: builder.query<IEmploymentInformation[], number | void>({
      query: (id) => ({
        url: '/employer-information/get-employer-information',
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'EmploymentInformation', id: 'EmploymentInformation'}],
    }),
    getEmploymentInformationByUser: builder.query<IEmploymentInformation[], void>({
      query: () => ({
        url: '/employer-information/get-employer-information-by-user',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'EmploymentInformation', id: 'EmploymentInformation'}],
    }),
    getEmployerInformationByUserId: builder.query<IEmploymentInformation[], number | void>({
      query: (id) => ({
        url: '/employer-information/get-employer-information-by-user-id/',
        method: 'POST',
        body: {
          userId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'EmploymentInformation', id: 'EmploymentInformation'}],
    }),
    deleteEmployerInformation: builder.mutation<void, number>({
      query: (id) => ({
        url: `/employer-information/delete-employer-information/`,
        method: 'POST',
        body: {
          id: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'EmploymentInformation', id: 'EmploymentInformation'}],
    }),

    //  Related Items
    createEmploymentVerification: builder.mutation<
      IEmploymentVerification,
      IEmploymentVerificationDto
    >({
      query: (body) => ({
        url: '/previous-employer-information/create-employment-verification/',
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'EmploymentInformation', id: 'EmploymentInformation'}],
    }),
    updateEmploymentVerification: builder.mutation<
      IEmploymentVerification,
      IEmploymentVerification
    >({
      query: (body) => ({
        url: `/previous-employer-information/update-employment-verification/${body.id}/`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'EmploymentInformation', id: 'EmploymentInformation'}],
    }),
    getEmploymentVerification: builder.query<IEmploymentVerification[], number | void>({
      query: (id) => ({
        url: `/previous-employer-information/get-employment-verification/`,
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'EmploymentInformation', id: 'EmploymentInformation'}],
    }),
    getEmploymentVerificationByUser: builder.query<IEmploymentVerification[], void>({
      query: () => ({
        url: `/previous-employer-information/get-employment-verification-by-user/`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'EmploymentInformation', id: 'EmploymentInformation'}],
    }),
    getEmploymentVerificationByUserId: builder.query<IEmploymentVerification[], number | void>({
      query: (id) => ({
        url: `/previous-employer-information/get-employment-verification-by-user-id/`,
        method: 'POST',
        body: {
          userId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'EmploymentInformation', id: 'EmploymentInformation'}],
    }),
    //   Accident History
    createDriversAccidentHistory: builder.mutation<
      IDriversAccidentHistory,
      IDriversAccidentHistoryDto
    >({
      query: (body) => ({
        url: '/previous-employer-information/create-drivers-accident-history/',
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'EmploymentInformation', id: 'EmploymentInformation'}],
    }),
    updateDriversAccidentHistory: builder.mutation<
      IDriversAccidentHistory,
      IDriversAccidentHistory
    >({
      query: (body) => ({
        url: `/previous-employer-information/update-drivers-accident-history/`,
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),

      invalidatesTags: [{type: 'EmploymentInformation', id: 'EmploymentInformation'}],
    }),
    getDriversAccidentHistory: builder.query<IDriversAccidentHistory, number | void>({
      query: (id) => ({
        url: `/previous-employer-information/get-drivers-accident-history/`,
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),

      providesTags: [{type: 'EmploymentInformation', id: 'EmploymentInformation'}],
    }),
    getDriversAccidentHistoryByUser: builder.query<IDriversAccidentHistory, void>({
      query: () => ({
        url: `/previous-employer-information/get-drivers-accident-history-by-user/`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),

      providesTags: [{type: 'EmploymentInformation', id: 'EmploymentInformation'}],
    }),
    getDriversAccidentHistoryByUserId: builder.query<IDriversAccidentHistory, number | void>({
      query: (id) => ({
        url: `/previous-employer-information/get-drivers-accident-history-by-user-id/`,
        method: 'POST',
        body: {
          userId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),

      providesTags: [{type: 'EmploymentInformation', id: 'EmploymentInformation'}],
    }),
    //   Accident Record
    createDriverAccidentRecord: builder.mutation<IDriverAccidentRecord, IDriverAccidentRecordDto>({
      query: (body) => ({
        url: '/previous-employer-information/create-driver-accident-record/',
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),

      invalidatesTags: [{type: 'EmploymentInformation', id: 'EmploymentInformation'}],
    }),
    updateDriverAccidentRecord: builder.mutation<IDriverAccidentRecord, IDriverAccidentRecord>({
      query: (body) => ({
        url: `/previous-employer-information/update-driver-accident-record/${body.id}/`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),

      invalidatesTags: [{type: 'EmploymentInformation', id: 'EmploymentInformation'}],
    }),
    getDriverAccidentRecord: builder.query<IDriverAccidentRecord[], number | void>({
      query: (id) => ({
        url: `/previous-employer-information/get-driver-accident-record/`,
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),

      providesTags: [{type: 'EmploymentInformation', id: 'EmploymentInformation'}],
    }),
    getDriverAccidentRecordByUser: builder.query<IDriverAccidentRecord[], void>({
      query: () => ({
        url: `/previous-employer-information/get-driver-accident-record-by-user/`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),

      providesTags: [{type: 'EmploymentInformation', id: 'EmploymentInformation'}],
    }),
    getDriverAccidentRecordByUserId: builder.query<IDriverAccidentRecord[], number | void>({
      query: (id) => ({
        url: `/previous-employer-information/get-driver-accident-record-by-user-id/`,
        method: 'POST',
        body: {
          userId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'EmploymentInformation', id: 'EmploymentInformation'}],
    }),
    createFTADachInfo: builder.mutation<IFTADachInfo, IFTADachInfoDto>({
      query: (body) => ({
        url: '/previous-employer-information/create-fta-dach-info/',
        method: 'POST',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'EmploymentInformation', id: 'EmploymentInformation'}],
    }),
    updateFTADachInfo: builder.mutation<IFTADachInfo, IFTADachInfo>({
      query: (body) => ({
        url: `/previous-employer-information/update-fta-dach-info/${body.id}/`,
        method: 'PUT',
        body,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      invalidatesTags: [{type: 'EmploymentInformation', id: 'EmploymentInformation'}],
    }),
    getFTADachInfo: builder.query<IFTADachInfo[], number | void>({
      query: (id) => ({
        url: `/previous-employer-information/get-fta-dach-info/`,
        method: 'POST',
        body: {
          memberId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'EmploymentInformation', id: 'EmploymentInformation'}],
    }),
    getFtaDachInfoByUser: builder.query<IFTADachInfo[], void>({
      query: () => ({
        url: `/previous-employer-information/get-fta-dach-info-by-user/`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'EmploymentInformation', id: 'EmploymentInformation'}],
    }),
    getFtaDachInfoByUserId: builder.query<IFTADachInfo[], number | void>({
      query: (id) => ({
        url: `/previous-employer-information/get-fta-dach-info-by-user-id/`,
        method: 'POST',
        body: {
          userId: id,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authHelper.getAuth()?.api_token}`,
        },
      }),
      providesTags: [{type: 'EmploymentInformation', id: 'EmploymentInformation'}],
    }),
  }),
});

export const {
  useCreateEmploymentInformationMutation,
  useUpdateEmploymentInformationMutation,
  useGetEmploymentInformationQuery,
  useGetEmploymentInformationByUserQuery,
  useGetEmployerInformationByUserIdQuery,
  useDeleteEmployerInformationMutation,
  useCreateEmploymentVerificationMutation,
  useUpdateEmploymentVerificationMutation,
  useGetEmploymentVerificationQuery,
  useGetEmploymentVerificationByUserQuery,
  useGetEmploymentVerificationByUserIdQuery,
  useCreateDriversAccidentHistoryMutation,
  useUpdateDriversAccidentHistoryMutation,
  useGetDriversAccidentHistoryQuery,
  useGetDriversAccidentHistoryByUserQuery,
  useGetDriversAccidentHistoryByUserIdQuery,
  useCreateDriverAccidentRecordMutation,
  useUpdateDriverAccidentRecordMutation,
  useGetDriverAccidentRecordQuery,
  useGetDriverAccidentRecordByUserQuery,
  useGetDriverAccidentRecordByUserIdQuery,
  useCreateFTADachInfoMutation,
  useUpdateFTADachInfoMutation,
  useGetFTADachInfoQuery,
  useGetFtaDachInfoByUserQuery,
  useGetFtaDachInfoByUserIdQuery,
} = employmentInformationApi;
