import PreEmploymentTable from '../../../compliancemasters/partials/widgets/tables/PreEmploymentTable/PreEmploymentTable';

const PreEmploymentRequestDashboard = () => {
    return (
        <div className="row gy-5 g-xl-8">
            <div className="col-xl-12">
                <PreEmploymentTable />
            </div>
        </div>
    );
};

export default PreEmploymentRequestDashboard;
