import { Grid, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../../app/modules/auth';
import { IMember } from '../../../../../models';
import { getCompanyMembers } from '../../../../../shared';
import { IRandomDACHTesting } from '../../../../../store/interfaces/RandomDach/random-dach';

interface IProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    randomTesting: IRandomDACHTesting;
    setRandomTesting: (randomTesting?: IRandomDACHTesting) => void;
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function RandomTestingModal({ open, randomTesting, setOpen, setRandomTesting }: IProps) {
    const [members, setMembers] = useState<IMember[]>([]);
    const { currentUser } = useAuth();
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (open && randomTesting) {
            getCompanyMembers(randomTesting.company.id)
                .then((res) => {
                    setMembers(res);
                })
                .catch((err) => {
                    console.log(err);
                    toast.error('Failed to get company members');
                });
        }
    }, [open, randomTesting]);

    return (
        <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle
                sx={{
                    backgroundColor: '#F5F6FA',
                    borderBottom: '1px solid #E4E6EF',
                    padding: '20px 30px',
                    fontSize: '18px',
                    fontWeight: 'bold',
                    color: '#252733',
                    textAlign: 'center',
                    textTransform: 'uppercase',
                    letterSpacing: '0.5px'
                }}
                id="alert-dialog-slide-title"
            >
                {'Random Drug and Alcohol Testing Selections'}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                            Quarter:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1">{randomTesting?.quarter}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                            Year:
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="subtitle1">
                            {Intl.DateTimeFormat('en-US', { year: 'numeric' }).format(new Date(randomTesting.year))}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: 'bold', color: 'blue', textDecoration: 'underline' }}
                        >
                            Selection List
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <ol>
                            {randomTesting?.members?.map((m) => {
                                const member = members.find((mem) => Number(mem.id) === m.memberId);
                                if (member) {
                                    return (
                                        <li key={member.id}>
                                            <Typography variant="h6">
                                                {member?.user.first_name} {member?.user.last_name} -{' '}
                                                {member?.user?.email} ({m.testType})
                                            </Typography>
                                        </li>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </ol>
                    </Grid>
                    {currentUser && currentUser.role === 'Owner' ? (
                        <Grid item xs={12} sm={12}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: 'red' }}>
                                You have three (3) months to test the selected driver(s). Please follow the exact order
                                of steps provided below.
                            </Typography>
                            <Typography variant="h6">
                                1. By viewing this selection list you’ve triggered a “Confirm Random Test Request”
                                button to appear on each selected drivers profile. This will be used once you’ve
                                informed each driver of their selection.
                            </Typography>
                            <Typography variant="h6">
                                2. Verbally inform the driver(s) of their selection. Please remember that as soon as the
                                driver is informed the test must be scheduled.
                            </Typography>
                            <Typography variant="h6">
                                3. After informing the driver of their selection, go into their profile and click the
                                green “Confirm Random Test Request” button. Enter the drivers current location and click
                                confirm. We will schedule the test for you and the driver will be texted the testing
                                location information.
                            </Typography>
                            <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }}>
                                Note: If a driver is selected for an alcohol test, or drug and alcohol test, then the
                                test must be done before, during, or directly after being on the road. Alcohol tests can
                                not be done on days off. With that being said, inform those drivers of their selection
                                on a day you know they are driving.
                            </Typography>
                        </Grid>
                    ) : null}
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
