import { useCallback, useEffect, useState } from 'react';
import TableWrapper from '../../../compliancemasters/partials/widgets/tables/components/TableWrapper';
import TableContainerHeader from '../../../compliancemasters/partials/widgets/tables/components/TableContainerHeader';
import TableContainerHeaderTitle from '../../../compliancemasters/partials/widgets/tables/components/TableContainerHeaderTitle';
import TableContainer from '../../../compliancemasters/partials/widgets/tables/components/TableContainer';
import TableHeader from '../../../compliancemasters/partials/widgets/tables/components/TableHeader';
import TableHeaderColumn from '../../../compliancemasters/partials/widgets/tables/components/TableHeaderColumn';
import AddFileButton from './AddFileButton';
import AddFileMoodal from './AddFileMoodal';
import { useParams } from 'react-router-dom';
import { downloadFile, getLiveMvrFiles } from '../../../shared';
import { ICompany, IFileObject, IMember } from '../../../models';
import { useAuth } from '../../modules/auth';

export interface ILiveMVR {
    id: number;
    member: IMember;
    company: ICompany;
    file: IFileObject;
    date: Date;
    is_active: boolean;
    created_at: Date;
    updated_at: Date;
}

const MonitoringMVRTable = ({ subscription_type }: { subscription_type?: string }) => {
    const { memberId } = useParams<{ memberId: string }>();
    const [files, setFiles] = useState<ILiveMVR[]>([]);
    const [open, setOpen] = useState(false);
    const { currentUser } = useAuth();
    const getFiles = useCallback(() => {
        if (memberId) {
            getLiveMvrFiles(memberId).then((res) => {
                setFiles(res);
            });
        }
    }, [memberId]);

    useEffect(() => {
        getFiles();
    }, [getFiles]);

    useEffect(() => {
        if (currentUser?.role === 'Owner') {
            getLiveMvrFiles().then((res) => {
                setFiles(res);
            });
        }
    }, [currentUser]);

    return (
        <>
            <TableWrapper>
                <TableContainerHeader>
                    <TableContainerHeaderTitle title="Live MVR Monitoring" />
                    {currentUser && ['Admin', 'SuperAdmin']?.includes(currentUser?.role) ? (
                        <AddFileButton setOpen={setOpen} />
                    ) : null}
                </TableContainerHeader>
                <TableContainer>
                    <TableHeader>
                        <TableHeaderColumn>Driver</TableHeaderColumn>
                        <TableHeaderColumn>File Name</TableHeaderColumn>
                        <TableHeaderColumn>Date</TableHeaderColumn>
                    </TableHeader>
                    <tbody>
                        {files.map((file) => (
                            <tr key={file.id}>
                                <td>
                                    <span className="text-dark fw-bold mb-1 fs-6">{`${file.member?.user?.first_name} ${file.member?.user?.last_name}`}</span>
                                </td>
                                <td
                                    className="mw-125px"
                                    onClick={() => {
                                        downloadFile(file?.file?.id).then((res) => {
                                            window.open(res.url);
                                        });
                                    }}
                                >
                                    <span className="text-dark fw-bold text-hover-primary mb-1 fs-6 cursor-pointer">
                                        {file.file.original_file_name}
                                    </span>
                                </td>
                                <td>
                                    <span className="text-dark fw-bold mb-1 fs-6">
                                        {file.date ? new Date(file.created_at).toLocaleDateString() : ''}
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </TableContainer>
            </TableWrapper>
            <AddFileMoodal open={open} setOpen={setOpen} onSubmitted={getFiles} subscription_type={subscription_type} />
        </>
    );
};

export default MonitoringMVRTable;
