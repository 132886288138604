import { Box, Button, Container, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../app/modules/auth';
import { StripeProduct } from '../../../app/modules/models';
import { DriverType, IMember } from '../../../models';
import { getCompanyMember } from '../../../shared';
import {
    useGetCertificateOfRoadTestProgressMutation,
    useGetMemberCertificateOfRoadTestProgressMutation
} from '../../../store/reducers/CertificateOfRoadTest/progress';
import { useThemeMode } from '../../partials';
import ActionButtons from '../ActionButtons';
import FormHeaderCard from '../HeaderCard';
import CertificateOfRoadTestComponent from './CertificateOfRoadTestComponent';

interface IProps {
    subscription: StripeProduct | undefined;
}

const CertificateOfRoadTest = ({ subscription }: IProps) => {
    const { mode } = useThemeMode();
    const { currentUser } = useAuth();
    const [getProgress, { data: progress }] = useGetCertificateOfRoadTestProgressMutation();
    const [getMemberProgress, { data: memberProgress }] = useGetMemberCertificateOfRoadTestProgressMutation();
    const [member, setMember] = useState<IMember>();
    const { id } = useParams();
    const [value, setValue] = useState(0);
    useEffect(() => {
        if (id) {
            getCompanyMember(id).then((data) => {
                setMember(data);
            });
            getProgress(Number(id));
            getMemberProgress(Number(id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Container style={{ userSelect: 'none' }}>
            <Box
                sx={{
                    pl: 3,
                    pr: 3,
                    pt: 3,
                    m: 3,
                    borderRadius: 2,
                    width: {
                        lg: '47vw',
                        md: '70vw',
                        sm: '80vw'
                    },
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    backgroundColor: mode === 'dark' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)'
                }}
                component={Paper}
            >
                <FormHeaderCard
                    headerTitle="Certification of Road Test"
                    status={
                        progress && progress?.progress > 0 && progress?.progress < 100
                            ? 'In Progress'
                            : progress && progress.progress > 99
                              ? 'Completed'
                              : 'Not Started'
                    }
                    sectionCount={4}
                    itemCount={29}
                    totalProgress={progress ? progress.progress : 0}
                    memberProgress={memberProgress ? memberProgress.progress : 0}
                    nextButton={{ title: 'Consent for FMCSA Limited Queries', path: '' }}
                    member={member}
                    setValue={setValue}
                    value={value}
                />
            </Box>
            <Box
                sx={{
                    pl: 3,
                    pr: 3,
                    pt: 3,
                    m: 3,
                    borderRadius: 2,
                    backgroundColor: mode === 'dark' ? 'rgba(30,30,45,255)' : 'rgba(255,255,255,255)',
                    width: {
                        lg: '47vw',
                        md: '70vw',
                        sm: '80vw'
                    },
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }}
                component={Paper}
            >
                <CertificateOfRoadTestComponent member={member} />
            </Box>
            <ActionButtons
                member={member}
                nextButton={
                    member?.driver_type === DriverType.EXISTING_DRIVER
                        ? {
                              title: 'Consent for FMCSA Limited Queries',
                              path: 'consent-for-limited-queries-of-the-fmcsa-drug-and-alcohol-clearinghouse'
                          }
                        : undefined
                }
                prevButton={{
                    title: 'Record of Road Test',
                    path: 'record-of-road-test'
                }}
            />
            <div
                style={{
                    display: 'flex',
                    width: '47vw',
                    justifyContent: 'center',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: 10
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        if (currentUser?.role === 'Member') {
                            window.location.assign(`/dashboard/overview`);
                        } else {
                            if (member?.id) {
                                window.location.assign(`/member/${member?.id}/profile/driver-documents`);
                            }
                        }
                    }}
                    fullWidth
                >
                    Back to Forms Overview
                </Button>
            </div>
        </Container>
    );
};

export default CertificateOfRoadTest;
